import {Button} from '@dropbox/dig-components/dist/buttons';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Select, TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Cluster, Split, Stack} from '@dropbox/dig-foundations';
import {ActivityLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {
  Employee,
  EmployeeLdap,
  ProjectLink,
  Workstream,
  WorkstreamCreate,
  WorkstreamEdit,
} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {ProjectLinkEditor} from 'components/projects/ProjectLinkEditor';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {isBefore, startOfToday} from 'date-fns';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {useAuditLogs} from './hooks';
import {getI18nDayName} from './utils';
import {WorkstreamAuditLog} from './WorkstreamAuditLog';
import {MetricEditor, WrappedWorkstreamMetric} from './WorkstreamMetricEditor';

const formatDate = (date: Date) =>
  date
    .toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(',', '')
    .replace('AM', 'am')
    .replace('PM', 'pm');

const EmployeeSelect = ({
  selected,
  onSelect,
}: {
  selected?: EmployeeLdap;
  onSelect: (employee?: Employee) => void;
}) => {
  // add default selected employees

  const selectEmployee = (employee: Employee) => {
    onSelect(employee);
  };

  const selectEmployees = (employees: Employee[]) => {
    if (employees.length > 0) {
      onSelect(employees[0]);
    }
  };

  const removeEmployee = () => {
    onSelect();
  };

  return (
    <PeopleSearchMenu
      selectedEmployees={selected ? [selected as Employee] : []}
      onRemoveEmployee={removeEmployee}
      onSelectEmployee={selectEmployee}
      onSelectEmployees={selectEmployees}
      showReportingLine={true}
      minHeight="48px"
      isSingleSelect={true}
      searchIconPosition="left"
      allowSelf={true}
    />
  );
};

export const WorkstreamModify = ({
  workstream,
  onSubmit,
  isEditing,
  isPending,
}: {
  onSubmit: (data: {data: WorkstreamCreate | WorkstreamEdit; reason?: string}) => Promise<number>;
  workstream?: Workstream;
  isPending: boolean;
  isEditing?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const autofocus = location.state?.autofocus;
  const source = location.state?.source;
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [workstreamDri, setWorkstreamDri] = useState<EmployeeLdap | undefined>(
    workstream?.employee_associations.find(({dri}) => dri === 'dri')?.employee
  );
  const {data: auditLogs} = useAuditLogs(workstream?.id ?? 0, !isEditing);

  const hasDoneChanges = useMemo(() => auditLogs?.find((log) => log.type === 'done'), [auditLogs]);

  const [reporter, setReporter] = useState<EmployeeLdap | undefined>(
    workstream?.employee_associations.find(({dri}) => dri === 'reporter')?.employee
  );

  useDocumentTitle(isEditing ? t('edit_workstream') : t('add_workstream'));

  useEffect(() => {
    analyticsLogger().logEvent('WORKSTREAM_MODIFY_VIEW', {edit: Boolean(isEditing), autofocus});
  }, [isEditing, autofocus]);

  useEffect(() => {
    if (loggedInEmployee?.ldap && !workstreamDri) {
      setWorkstreamDri(loggedInEmployee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInEmployee?.ldap]);

  const [workstreamUpdate, setWorkstreamUpdate] = useState<WorkstreamEdit>(
    workstream
      ? {
          id: workstream.id,
          name: workstream.name,
          done: workstream.done,
          links: workstream.links,
          metrics: workstream.metrics.sort((a, b) => a.order - b.order),
          start_date: workstream.start_date,
          end_date: workstream.end_date,
          status_cadence: workstream.status_cadence,
          status_due: workstream.status_due,
          slug: workstream.slug,
          employee_associations: workstream.employee_associations.map((assoc) => ({
            user_id: assoc.employee.user_id,
            dri: assoc.dri,
          })),
        }
      : ({
          status_cadence: 'weekly',
          status_due: 5,
          slug: null,
          end_date: null,
          done: '',
        } as unknown as WorkstreamEdit)
  );

  const changedRequiredField = useMemo(() => {
    if (!workstream) {
      return false;
    }

    if (workstreamUpdate.end_date !== workstream.end_date) {
      return true;
    }

    // Only invalid if title, current, or target is change
    if (
      JSON.stringify(
        workstream.metrics
          .map(({title, current, target}) => ({title, current, target}))
          .sort((a, b) => a.title.localeCompare(b.title))
      ) !==
      JSON.stringify(
        workstreamUpdate.metrics
          ?.filter(({title}) => title)
          .map(({title, current, target}) => ({title, current, target}))
          .sort((a, b) => a.title.localeCompare(b.title))
      )
    ) {
      return true;
    }
  }, [workstream, workstreamUpdate.end_date, workstreamUpdate.metrics]);

  const hasChanges = useMemo(() => {
    // changedRequiredField
    return true;
  }, []);

  const hasError = useMemo(() => {
    const {name, end_date, metrics, links} = workstreamUpdate;

    if (!name) {
      return true;
    }

    if (!workstreamDri) {
      return true;
    }

    // Validate metrics
    const invalidMetric = metrics?.some(
      ({type, title, current, target}) => type === 'metric' && title && (!current || !target)
    );
    if (!metrics?.length || !metrics.some(({title}) => title) || invalidMetric) {
      return true;
    }

    // Validate links
    let invalidLink = false;

    links?.forEach(({url}) => {
      if (!url) {
        return;
      }
      if (
        !/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w.-]*)*(\?[\w=&]*)?$/i.test(url) &&
        !url.startsWith('drl/') &&
        !url.startsWith('http://drl/')
      ) {
        invalidLink = true;
        return;
      }
      try {
        new URL(
          url.startsWith('drl/')
            ? `http://${url}`
            : !url.startsWith('http')
              ? `https://${url}`
              : url
        );
      } catch {
        invalidLink = true;
        return;
      }
    });

    if (invalidLink || links?.some(({url, text}) => (url && !text) || (!url && text))) {
      return true;
    }

    if (!reason && changedRequiredField) {
      return true;
    }

    if (!end_date) {
      return true;
    }

    return false;
  }, [workstreamUpdate, workstreamDri, reason, changedRequiredField]);

  const handleSubmit = async () => {
    try {
      const id = await onSubmit({
        reason,
        data: {
          id: workstreamUpdate.id,
          name: workstreamUpdate.name,
          slug: workstreamUpdate.slug,
          done: workstreamUpdate.done,
          start_date: workstreamUpdate.start_date ?? new Date().toISOString(),
          end_date: workstreamUpdate.end_date,
          status_cadence: workstreamUpdate.status_cadence,
          status_due: workstreamUpdate.status_due,
          links: workstreamUpdate.links?.filter(({url, text}) => url && text),
          metrics: workstreamUpdate.metrics
            ?.filter(({title}) => title)
            .map((metric, index) => ({
              ...metric,
              id: (metric.id ?? 0) > 0 ? metric.id : undefined,
              order: index + 1,
            })),
          employee_associations: [
            ...(workstreamDri ? [{dri: 'dri', user_id: workstreamDri?.user_id}] : []),
            ...(reporter ? [{dri: 'reporter', user_id: reporter?.user_id}] : []),
          ],
        },
      });

      setSnackbarMessage({text: t('saved')});

      navigate(
        source ?? `/workstreams/${workstreamDri ? workstreamDri.ldap : loggedInEmployee.ldap}`,
        {
          state: {source: isEditing ? 'modify' : 'create', workstreamId: id},
        }
      );
    } catch (e: any) {
      setSnackbarMessage({text: e});
    }
  };

  if (!loggedInEmployee?.email) {
    return null;
  }
  return (
    <Layout.InlineDrawerContainer
      open={isDrawerOpen}
      drawerHeader={<Title size={18}>{t('workstream_activity')}</Title>}
      drawerIcon={ActivityLine}
      drawerBody={workstream && <WorkstreamAuditLog workstream={workstream} />}
      onClose={() => setDrawerOpen(false)}
    >
      <Stack align="start">
        <Header isEditing={isEditing} />

        <FormRow>
          <FormLabel
            withInput={
              <>
                <TextInput
                  required
                  autoFocus={!autofocus}
                  size="large"
                  width="100%"
                  placeholder={t('start_writing_placeholder')}
                  value={workstreamUpdate.name}
                  onChange={(e) => setWorkstreamUpdate((p) => ({...p, name: e.target.value}))}
                />

                {/* <Text monospace className={atoms({margin: '16', paddingY: '8'})}>
                    https://os.dropbox.com/{teamUpdate.name.toLowerCase().replace(' ', '-')}
                  </Text> */}
              </>
            }
          >
            {t('workstream_edit_name')}
          </FormLabel>
        </FormRow>

        <FormRow>
          <Split gap="16">
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_dri')}</FormLabel>
              <EmployeeSelect
                selected={workstreamDri}
                onSelect={(employee) => {
                  setWorkstreamDri(employee);
                }}
              />
            </Split.Item>
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_reporter')}</FormLabel>
              <EmployeeSelect
                selected={reporter}
                onSelect={(employee) => {
                  setReporter(employee);
                }}
              />
            </Split.Item>
          </Split>
        </FormRow>

        <FormRow>
          <FormLabel>{t('workstream_edit_done')}</FormLabel>

          <MetricEditor
            metrics={(workstreamUpdate.metrics ?? []) as WrappedWorkstreamMetric[]}
            updateMetrics={(metrics) => setWorkstreamUpdate((p) => ({...p, metrics}))}
          />

          {hasDoneChanges && (
            <FormHelperText>
              <Split alignY="center" gap="8">
                <Split.Item>
                  <Text size="small" color="subtle">
                    {t('workstream_edit_done_changes', {
                      date_string: formatDate(new Date(hasDoneChanges.date + 'Z')),
                    })}
                  </Text>
                </Split.Item>
                <Split.Item>
                  <Button variant="transparent" onClick={() => setDrawerOpen(true)} size="small">
                    {t('view_activity')}
                  </Button>
                </Split.Item>
              </Split>
            </FormHelperText>
          )}
        </FormRow>

        <FormRow>
          <Cluster>
            <FormLabel subtext={<Toggletip title={t('project_resources_tooltip')} />}>
              {t('resources')}
            </FormLabel>
            <Text color="faint" size="small" className={atoms({marginLeft: 'auto'})}>
              {t('optional')}
            </Text>
          </Cluster>
          <ProjectLinkEditor
            isInvalid={hasError}
            autoFocus={autofocus === 'resources'}
            links={
              workstreamUpdate.links?.length
                ? (workstreamUpdate.links as ProjectLink[])
                : ([{url: '', text: ''}] as ProjectLink[])
            }
            updateLinks={(links) => setWorkstreamUpdate((p) => ({...p, links}))}
          />
        </FormRow>

        <FormRow>
          <Split gap="16">
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_cadence', {when: 'weekly'})}</FormLabel>
              <Select
                id={'select-day-of-week'}
                value={workstreamUpdate.status_due.toString()}
                onChange={(value) =>
                  setWorkstreamUpdate((p) => ({...p, status_due: parseInt(value, 10)}))
                }
              >
                {Array.from({length: 5}).map((_, i) => (
                  <Select.Option key={i + 1} value={(i + 1).toString()}>
                    {getI18nDayName(i + 1)}
                  </Select.Option>
                ))}
              </Select>
            </Split.Item>
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_end_date')}</FormLabel>
              <DatePickerInput
                id="choose-date"
                autocomplete="off"
                placeholder={t('pick_a_date')}
                isDateBlocked={(date) => isBefore(date, startOfToday())}
                value={workstreamUpdate.end_date ? new Date(workstreamUpdate.end_date) : null}
                onChange={(date) =>
                  setWorkstreamUpdate((p) => ({...p, end_date: date ? date.toISOString() : null}))
                }
              />
            </Split.Item>
          </Split>
        </FormRow>

        {changedRequiredField && (
          <FormRow>
            <Box
              backgroundColor="Background Subtle"
              padding="16"
              borderRadius="Medium"
              marginTop="16"
            >
              <Cluster className={atoms({marginBottom: '8'})}>
                <Text>
                  <Trans
                    i18nKey="workstream_edit_update_reason"
                    t={t}
                    components={{b: <Text isBold />}}
                  />
                </Text>

                {/* <Button variant="transparent" size="small" className={atoms({marginLeft: 'auto'})}>
                  {t('undo_changes')}
                </Button> */}
              </Cluster>
              <TextArea
                isInvalid={!reason}
                placeholder={t('team_edit_description_placeholder')}
                size="large"
                value={reason}
                resizable="auto"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
              />
            </Box>
          </FormRow>
        )}

        <FormRow>
          <EditSaveButtons
            cta={t('save')}
            disableSave={!hasChanges || hasError}
            isLoading={isPending}
            handleCancelClick={() =>
              /* eslint-disable-next-line*/
              /* @ts-ignore */
              navigate(-1, {
                state: {source: isEditing ? 'modify' : 'create'},
              })
            }
            handleSaveClick={handleSubmit}
          />
        </FormRow>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

const Header = ({isEditing}: {isEditing?: boolean}) => {
  return (
    <>
      <Split alignY="center" paddingBottom="16" gap="6">
        <Split.Item width="fill">
          <Title size={18}>{isEditing ? t('edit_workstream') : t('add_workstream')}</Title>
        </Split.Item>
      </Split>
    </>
  );
};
