import styles from './BaseEditorTheme.module.css';
import code from './CodeTheme.module.css';
import editor from './EditorTheme.module.css';
import news from './NewsTheme.module.css';
import smallFaint from './SmallFaintTextTheme.module.css';
import small from './SmallTextTheme.module.css';
import xsmall from './XSmallTextTheme.module.css';

export type EditorTheme = 'small-faint' | 'small' | 'large' | 'news' | 'xsmall' | 'editor';

const getTheme = (theme: EditorTheme) => {
  switch (theme) {
    case 'small-faint':
      return smallFaint;
    case 'small':
      return small;
    case 'xsmall':
      return xsmall;
    case 'news':
      return news;
    case 'large':
      return styles;
    case 'editor':
      return editor;
  }
};

export default function getEditorTheme(theme: EditorTheme = 'small') {
  const {text, title, bold, link} = getTheme(theme);

  return {
    placeholder: styles['editor-placeholder'],
    paragraph: text,
    quote: code.quote,
    heading: {
      h1: title,
      h2: title,
      h3: title,
      h4: title,
      h5: title,
    },
    table: styles.text, // `${styles.table} ${styles.text}`,
    // tr: styles.tr,
    // tableCell: styles.td,
    list: {
      nested: {
        listitem: styles.nestedList,
      },
      ol: styles.ol,
      ul: styles.ul,
      listitem: `${text} ${styles.li}`,
    },
    link: `${text} ${link}`,
    text: {
      bold: `${text} ${bold}`,
      italic: `${text} ${styles.italic}`,
      underline: text, // `${styles.text} ${styles.underline}`,
      strikethrough: text, // `${styles.text} ${styles.underline}`,
      underlineStrikethrough: text, // `${styles.text} ${styles.underline}`,
      code: code['editor-text-code'],
    },
    code: code['editor-code'],
    mark: styles.mark,
    markOverlap: styles.markOverlap,
    codeHighlight: {
      atrule: code['editor-tokenAttr'],
      attr: code['editor-tokenAttr'],
      boolean: code['editor-tokenProperty'],
      builtin: code['editor-tokenSelector'],
      cdata: code['editor-tokenComment'],
      char: code['editor-tokenSelector'],
      class: code['editor-tokenFunction'],
      comment: code['editor-tokenComment'],
      constant: code['editor-tokenProperty'],
      deleted: code['editor-tokenProperty'],
      doctype: code['editor-tokenComment'],
      entity: code['editor-tokenOperator'],
      function: code['editor-tokenFunction'],
      important: styles['editor-tokenVariable'],
      inserted: code['editor-tokenSelector'],
      keyword: code['editor-tokenAttr'],
      namespace: styles['editor-tokenVariable'],
      number: code['editor-tokenProperty'],
      operator: code['editor-tokenOperator'],
      prolog: code['editor-tokenComment'],
      property: code['editor-tokenProperty'],
      punctuation: styles['editor-tokenPunctuation'],
      regex: code['editor-tokenVariable'],
      selector: code['editor-tokenSelector'],
      string: code['editor-tokenSelector'],
      symbol: code['editor-tokenProperty'],
      tag: code['editor-tokenProperty'],
      url: code['editor-tokenOperator'],
      variable: code['editor-tokenVariable'],
    },
  };
}
