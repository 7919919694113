/* eslint-disable no-param-reassign */
/*

- Update to team selection when clicking employee team
- Add chips + "organization" label
- Make nodes smaller for 3 column
- Keep all selected nodes expanded

*/

import {avatarColorPalette} from '@dropbox/dig-components/dist/avatar';
import {EmployeeWithHierarchy, TeamWithHierarchyCounts} from 'client';
import * as d3 from 'd3';
import {t} from 'i18next';
import {getBackendUrl, getProfileImageUrl, nameToInitials} from 'utilities';

import styles from './Chart.module.css';

export const nodeSize = (isProfileView: boolean, expanded: boolean) =>
  isProfileView
    ? {width: 170, height: 166}
    : expanded
      ? {width: 284, height: 72}
      : {width: 244, height: 72};

export const padding = 24;
const boundingPadding = padding / 2;
const maxNodesForTwoColumn = 6;

export type HierarchyData = EmployeeWithHierarchy | TeamWithHierarchyCounts;

export type FocusData = HierarchyData;

export const isEmployee = (data: FocusData): data is EmployeeWithHierarchy =>
  (data as EmployeeWithHierarchy).ldap !== undefined;

export const getUid = (data: FocusData) => (isEmployee(data) ? data.ldap : (data.slug ?? ''));

export const getChildrenCount = (data: FocusData) =>
  isEmployee(data)
    ? !data.direct_report_count
      ? undefined
      : (data.total_report_count ?? 0) > data.direct_report_count
        ? data.direct_report_count + '+'
        : data.direct_report_count.toString()
    : !data.subteam_count
      ? undefined
      : data.total_subteam_count > data.subteam_count
        ? data.subteam_count + '+'
        : data.subteam_count.toString();

export const getUrl = (data: FocusData): string =>
  isEmployee(data) ? `/people/${data.ldap}` : `/teams/${data.slug ?? ''}`;

export const getTitle = (data: HierarchyData): string =>
  isEmployee(data) ? data.name : (data.name ?? '');

const getEmployeeSubtitle = (expanded: boolean, data: EmployeeWithHierarchy) => {
  if (expanded) {
    return `<div style="display:flex"><span style="text-overflow: ellipsis;overflow: hidden;">${data.role ?? ''}</span>${data.level ? `<span style="padding-left: 4px">(${data.level})</span>` : ''}</div>`;
  }

  // Create a container element to measure the content
  const container = document.createElement('div');

  // Set the styles for the container
  container.classList.add(styles.nodeCardSubtitle);
  container.style.width = '144px';
  container.style.height = '48px';
  container.style.overflow = 'hidden'; // Prevent scrolling
  container.style.display = '-webkit-box';
  container.style.whiteSpace = 'inherit';
  container.style.webkitLineClamp = '3'; // Restrict to 3 lines
  // eslint-disable-next-line deprecation/deprecation
  container.style.webkitBoxOrient = 'vertical';
  container.style.position = 'absolute'; // Ensure it doesn’t affect layout
  container.style.visibility = 'hidden'; // Hide the element

  // Append to the body temporarily
  document.body.appendChild(container);

  // Populate the container with content
  const level = `(${data.level})`;
  const role = data.role;

  if (data.level) {
    container.innerHTML = `${role} ${level}`;
  } else {
    container.innerHTML = `${role}`;
  }

  // Check for overflow
  const isOverflowing = container.scrollHeight > container.offsetHeight;

  // Remove the container from the DOM
  document.body.removeChild(container);

  // Return the appropriate HTML
  if (isOverflowing && data.level) {
    return `<div style="position: relative"><span style="text-overflow: ellipsis;overflow: hidden;">${data.role ?? ''}</span>${`<span class="${styles.overflowedRoleLevel}">... (${data.level})</span>`}</div>`;
  } else {
    return container.innerHTML;
  }
};

export const getSubtitle = (expanded: boolean, data: HierarchyData): string => {
  return isEmployee(data)
    ? getEmployeeSubtitle(expanded, data)
    : data.total_employee_count || data.total_employee_count === 0
      ? t('member', {
          count: data.total_employee_count,
          countString: data.total_employee_count.toLocaleString(),
        })
      : '';
};

const isThreeColumnLayout = (node: d3.HierarchyPointNode<HierarchyData>) =>
  (node.children?.length ?? 0) > maxNodesForTwoColumn;

const isDeepestLevelChildren = (maxDepth: number) => (node: d3.HierarchyPointNode<HierarchyData>) =>
  node.children?.every((child) => child.depth === maxDepth) ?? false;

export const findPathToRoot = (
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  selection: FocusData
): d3.HierarchyPointNode<HierarchyData>[] => {
  const selectedNode = nodes.find((node) => getUid(node.data) === getUid(selection));
  const pathToRoot: d3.HierarchyPointNode<HierarchyData>[] = [];

  if (selectedNode) {
    let currentNode: d3.HierarchyPointNode<HierarchyData> | null = selectedNode;
    while (currentNode) {
      pathToRoot.push(currentNode);
      currentNode = currentNode.parent;
    }
  }

  return pathToRoot;
};

export const renderLinks = (
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  links: d3.HierarchyPointLink<HierarchyData>[],
  pathToRoot: d3.HierarchyPointNode<HierarchyData>[],
  isProfileView: boolean,
  maxDepth: number,
  expanded: boolean
) => {
  // const isInPath = (link: d3.HierarchyPointLink<HierarchyData>) => {
  //   return pathToRoot.includes(link.target as d3.HierarchyPointNode<HierarchyData>);
  // };

  const isDeepestLevelLink = (link: d3.HierarchyPointLink<HierarchyData>) => {
    return (
      // (link.source.children ?? []).length <= 6 &&
      link.source.children?.every((child) => child.depth === maxDepth) ?? false
    );
  };

  const deepestLevelLinks = links.filter(isDeepestLevelLink);

  // Filter and get unique parents of the deepest-level nodes
  const parentNodesOfDeepest = Array.from(new Set(deepestLevelLinks.map((link) => link.source)));
  // const otherLinks = links.filter((d) => !isDeepestLevelLink(d));

  // Iterate through the source nodes with children
  // const sourceNodesWithThreeColumnLayout = deepestLevelLinks.map((link) => link.source);
  // .filter((node, index, self) => isThreeColumnLayout(node) && self.indexOf(node) === index);

  // Draw bounding boxes around three-column layouts
  parentNodesOfDeepest.forEach((sourceNode) => {
    const children = sourceNode.children!;
    const minX = d3.min(children, (d) => d.x!)! - nodeSize(isProfileView, expanded).width / 2;
    const maxX = d3.max(children, (d) => d.x!)! + nodeSize(isProfileView, expanded).width / 2;
    const minY = d3.min(children, (d) => d.y!)! - nodeSize(isProfileView, expanded).height / 2;
    const maxY = d3.max(children, (d) => d.y!)! + nodeSize(isProfileView, expanded).height / 2;

    const isThreeColumn = isThreeColumnLayout(sourceNode);

    // Check if any child node in the box is selected
    const isSelected = children.some((child) =>
      pathToRoot.includes(child as d3.HierarchyPointNode<HierarchyData>)
    );

    // Calculate midpoint at the top of the bounding box
    const boxMidX = (minX + maxX) / 2;

    // Draw the line from the source node to the bounding box

    // Add a gradient definition for the border if selected
    const gradientId = `org-selection-gradient`;
    if (isSelected) {
      const defs = g.append('defs');
      defs
        .append('radialGradient')
        .attr('id', gradientId)
        .attr('cx', '50%')
        .attr('cy', '0%')
        .attr('fx', '50%')
        .attr('fy', '0%')
        .attr('r', '100%')
        .append('stop')
        .attr('offset', '10%')
        .attr('stop-color', 'var(--dig-color__primary__base)')
        .attr('stop-opacity', '1');

      if (isThreeColumn) {
        defs
          .select(`#${gradientId}`)
          .append('stop')
          .attr('offset', '50%')
          .attr('stop-color', 'var(--dig-color__primary__base)')
          .attr('stop-opacity', '0.5');
        defs
          .select(`#${gradientId}`)
          .append('stop')
          .attr('offset', '75%')
          .attr('stop-color', 'var(--dig-color__primary__base)')
          .attr('stop-opacity', '0.3');
      }
      defs
        .select(`#${gradientId}`)
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', 'var(--dig-color__primary__base)')
        .attr('stop-opacity', '0.3');
    }

    // Draw the bounding box
    g.append('rect')
      .attr('x', minX - boundingPadding)
      .attr('y', minY + boundingPadding)
      .attr('width', maxX - minX + boundingPadding * 2)
      .attr('height', maxY - minY + boundingPadding * 2)
      .attr('fill', 'none')
      .attr('stroke', isSelected ? `url(#${gradientId})` : 'var(--dig-color__border__subtle)')
      .attr('stroke-width', isSelected ? 2 : 1)
      .attr('rx', 12);

    g.append('path')
      .attr(
        'd',
        `
        M${sourceNode.x},${sourceNode.y}
        V${minY + boundingPadding}
        H${boxMidX}
      `
      )
      .attr('fill', 'none')
      .attr(
        'stroke',
        isSelected ? 'var(--dig-color__primary__base)' : 'var(--dig-color__border__subtle)'
      )
      .attr('stroke-width', isSelected ? 2 : 1);
  });

  // Render other links for standard nodes
  g.selectAll<SVGPathElement, d3.HierarchyPointLink<HierarchyData>>('path.other-link')
    .data(links.filter((d) => !isDeepestLevelLink(d)))
    .enter()
    .append('path')
    .attr('class', (d) =>
      pathToRoot.includes(d.target as d3.HierarchyPointNode<HierarchyData>)
        ? 'highlighted-link other-link'
        : 'regular-link other-link'
    )
    .attr('fill', 'none')
    .attr('stroke', (d) =>
      pathToRoot.includes(d.target as d3.HierarchyPointNode<HierarchyData>)
        ? 'var(--dig-color__primary__base)'
        : 'var(--dig-color__border__subtle)'
    )
    .attr('stroke-width', (d) =>
      pathToRoot.includes(d.target as d3.HierarchyPointNode<HierarchyData>) ? 2 : 1
    )
    .attr('d', (d) => {
      const sourceX = d.source.x!;
      const sourceY = d.source.y!;
      const targetX = d.target.x!;
      const targetY = d.target.y! + padding;

      return `
        M${sourceX},${sourceY}
        V${targetY}
        H${targetX}
      `;
    });

  // Ensure highlighted paths are always on top
  g.selectAll('.highlighted-link').raise();
};

const teamAvatar = () => {
  return `<svg viewBox="0 0 24 24" width="32" height="32" style="padding: 4px; clip-path: url(#org-avatar-clip-path); flex-shrink: 0; background-color: var(--dig-color__opacity__surface)" >
    <path d="M8 8h3.5v1.5H8V8Zm3.5 3H8v1.5h3.5V11ZM8 14h3.5v1.5H8V14Z" fill="var(--dig-color__text__subtle)"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4.5v6H19V19H5V4.5h9.5Zm-8 13H13V6H6.5v11.5Zm8 0h3V12h-3v5.5Z" fill="var(--dig-color__text__subtle)"></path>
  </svg>`;
};

const erroredImages = new Set<string>();

const userAvatar = (data: EmployeeWithHierarchy, avatarSize: number) => {
  const initials = nameToInitials(data.name);
  const imageUrl = getBackendUrl() + getProfileImageUrl(getUid(data));
  const color = avatarColorPalette(nameToInitials(data.name));
  const uniqueId = `avatar-${getUid(data)}`;

  // Return an empty placeholder SVG with initials
  const fallbackSvg = `
    <svg width="${avatarSize}" height="${avatarSize}" style="clip-path: url(#org-avatar-clip-path); margin: 0; flex-shrink: 0; background-color: ${color.background};">
      <text
        x="50%"
        y="53%"
        text-anchor="middle"
        alignment-baseline="middle"
        font-size="14"
        style="font-family: var(--__bodyFontStack); font-weight: 500"
        fill="${color.foreground}"
      >
        ${initials}
      </text>
    </svg>
  `;

  if (erroredImages.has(imageUrl)) {
    return fallbackSvg;
  } else {
    const img = new Image();
    img.src = imageUrl;

    img.onerror = () => {
      erroredImages.add(imageUrl);
      const avatarElement = document.getElementById(uniqueId);
      if (avatarElement) {
        avatarElement.innerHTML = fallbackSvg;
        avatarElement.style.backgroundColor = color.background;
      }
    };
  }

  // Return the fallback SVG with a unique ID for replacement
  return `<svg width="${avatarSize}" height="${avatarSize}" id="${uniqueId}" style="clip-path: url(#org-avatar-clip-path); margin: 0; flex-shrink: 0;">
      <image 
        href="${imageUrl}" 
        style="height: auto; width: 100%;" 
      />
  </svg>`;
};

const avatarBadge = (text?: string) => {
  if (!text) {
    return '';
  }

  const fontSize = 10;
  const height = 18;
  const textWidth = text.length * fontSize * 0.6;
  const badgeWidth = Math.max(textWidth + 2 * 4, height);

  return `
      <svg height="${height}" width="${badgeWidth}" style="position: absolute;bottom: 0;right: ${-badgeWidth / 4}px">
        <!-- Background -->
        <rect
          x="0"
          y="0"
          rx="${height / 2}"
          ry="${height / 2}"
          width="${badgeWidth}"
          height="${height}"
          fill="var(--dig-color__primary__surface)"
        />
        <!-- Text -->
        <text
          x="${badgeWidth / 2}"
          y="${height / 2}"
          dy="0.35em"
          font-family="inherit"
          font-size="${fontSize}px"
          fill="var(--dig-color__text__subtle)"
          text-anchor="middle"
        >
          ${text}
        </text>
      </svg>
      `;
};

export const renderStyledNodes = (
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  focusedNode: d3.HierarchyPointNode<HierarchyData> | undefined,
  onClick: (event: MouseEvent, data: FocusData) => void,
  onHover: (data?: FocusData) => void,
  isProfileView: boolean,
  expanded: boolean,
  maxDepth: number // Pass maxDepth as a parameter
) => {
  if (!getUid(nodes?.[0]?.data)) {
    return;
  }

  g.append('defs')
    .append('clipPath')
    .attr('id', 'org-avatar-clip-path')
    .attr('clipPathUnits', 'objectBoundingBox')
    .append('path')
    .attr(
      'd',
      'M0.5,0 C0.164,0,0,0.164,0,0.5 C0,0.836,0.164,1,0.5,1 C0.836,1,1,0.836,1,0.5 C1,0.164,0.836,0,0.5,0'
    );

  // remove tooltip if exists
  d3.select('#tooltip').remove();

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('id', 'tooltip')
    .attr('class', styles.tooltip)
    .style('background', 'var(--color__inverse__standard__background)')
    .style('color', 'var(--color__inverse__standard__text)');

  // Modify children positions deepest level parent children
  nodes.forEach((node) => {
    const isDeepestParent = node.depth === maxDepth - 1;

    if (isDeepestParent && node.children) {
      node.children.forEach((child) => {
        child.y += boundingPadding * 2;
      });
    }
  });

  // Create a container for each node
  const nodeGroup = g
    .selectAll<SVGGElement, d3.HierarchyPointNode<HierarchyData>>('.node-container')
    .data(nodes)
    .enter()
    .append('foreignObject')
    .attr('class', `node-container ${isProfileView ? styles.profile : ''}`)
    .attr('x', (d) => (d.x ?? 0) - nodeSize(isProfileView, expanded).width / 2)
    .attr('y', (d) => (d.y ?? 0) - nodeSize(isProfileView, expanded).height / 2)
    .attr('width', nodeSize(isProfileView, expanded).width)
    .attr('height', nodeSize(isProfileView, expanded).height)
    .style('overflow', 'visible');

  // Append content inside each node box
  const avatarSize = isProfileView ? 56 : 40;

  const nodeCard = nodeGroup
    .append('xhtml:a')
    .on('click', (e, d) => onClick(e, d.data))
    .on('mouseenter', (_, d) => onHover(d.data))
    .on('mouseleave', () => onHover(undefined))
    .attr('href', (d) => `/${isEmployee(d.data) ? 'people' : 'teams'}/` + getUid(d.data))
    .attr('class', (d) => styles.nodeCard + (d === focusedNode ? ` ${styles.focused}` : ''));

  const avatar = nodeCard.append('div').style('position', 'relative').style('display', 'flex');
  avatar
    .append('div')
    .style('width', `${avatarSize}px`)
    .style('height', `${avatarSize}px`)
    .html((d) => (isEmployee(d.data) ? userAvatar(d.data, avatarSize) : teamAvatar()));

  avatar
    .append('div')
    .on('mouseenter', (_, d: any) => {
      const count = isEmployee(d.data) ? d.data.direct_report_count : d.data.subteam_count;
      const total_count = isEmployee(d.data)
        ? d.data.total_report_count
        : d.data.total_subteam_count;

      if (count) {
        tooltip
          .style('display', 'block')
          .html(() =>
            isEmployee(d.data)
              ? `<div>${t('direct_reports', {count})}</div>${total_count !== count ? `<div>${t('total_reports', {count: total_count})}</div>` : ''}`
              : `<div>${t('sub_team', {count})}</div>${total_count !== count ? `<div>${t('total_sub_team', {count: total_count})}</div>` : ''}`
          );
      }
    })
    .on('mousemove', (e) => {
      // const count = isEmployee(d.data) ? d.data.direct_report_count : d.data.subteam_count;
      // const total_count = isEmployee(d.data)
      //   ? d.data.total_report_count
      //   : d.data.total_subteam_count;
      // const {x, y} = e.target.getBoundingClientRect();
      // tooltip
      //   .style('left', `${x - 65}px`)
      //   .style('top', `${y - (total_count === count ? 20 : 40)}px`);
      tooltip.style('left', `${e.pageX + 10}px`).style('top', `${e.pageY + 10}px`);
    })
    .on('mouseleave', () => {
      tooltip.style('display', 'none');
    })
    .html((d) => avatarBadge(getChildrenCount(d.data)));

  const nodeCardContent = nodeCard.append('div').attr('class', styles.nodeCardContent);

  nodeCardContent
    .append('div')
    .attr('class', styles.nodeCardTitle)
    .html((d) => getTitle(d.data))
    .on('mouseenter', (_, d: any) =>
      d.data.name.length > (!expanded ? 17 : 27)
        ? tooltip.style('display', 'block').html(() => d.data.name)
        : undefined
    )
    .on('mousemove', (e) =>
      tooltip.style('left', `${e.pageX + 10}px`).style('top', `${e.pageY + 10}px`)
    )
    .on('mouseleave', () => tooltip.style('display', 'none'));

  nodeCardContent
    .append('div')
    .attr('class', styles.nodeCardSubtitle)
    .html((d) => getSubtitle(expanded, d.data));
};

export const centerOnFocusedNode = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>,
  width: number,
  height: number,
  isProfileView: boolean,
  expanded: boolean,
  nodeWidth: number,
  focusedNode?: d3.HierarchyPointNode<HierarchyData>
) => {
  let centerOffsetX;

  if (!isProfileView) {
    if (focusedNode) {
      const parentNode = focusedNode.parent ?? focusedNode;
      centerOffsetX = width / 2 - (parentNode.x! - 40);
    } else {
      centerOffsetX = width / 2;
    }
    centerOffsetX += padding / 3;
  } else {
    centerOffsetX = nodeWidth * (nodeSize(isProfileView, expanded).width + padding) + padding / 2;
  }

  const offsetY = isProfileView
    ? padding / 1.5
    : Math.min(
        nodeSize(isProfileView, expanded).height / 2 + padding * 2 + (expanded ? 100 : 0),
        height - (focusedNode?.y ?? 0) + padding * 2
      );

  const newTransform = d3.zoomIdentity.translate(centerOffsetX, offsetY);
  svg.call(zoom.transform, newTransform);
};

export function adjustDeepestNodes(
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  maxDepth: number,
  isProfileView: boolean,
  expanded: boolean
) {
  const deepestLevelNodes = nodes.filter(isDeepestLevelChildren(maxDepth));

  deepestLevelNodes.forEach((deepNode) => {
    if (deepNode && deepNode.children) {
      const horizontalSpacing = nodeSize(isProfileView, expanded).width + padding;
      const verticalSpacing = nodeSize(isProfileView, expanded).height + padding;
      const columnCount = deepNode.children.length > maxNodesForTwoColumn ? 3 : 2;
      const centerOffset = ((columnCount - 1) / 2) * horizontalSpacing;

      if (deepNode.children.length === 1) {
        return;
      }

      deepNode.children.forEach((child, index) => {
        const col = index % columnCount;
        const row = Math.floor(index / columnCount);

        const desiredX = deepNode.x! + (col * horizontalSpacing - centerOffset);
        const desiredY = deepNode.y! + (row + 1) * verticalSpacing;

        const shiftX = desiredX - child.x!;
        const shiftY = desiredY - child.y!;

        shiftSubtree(child, shiftX, shiftY);
      });
    }
  });
}

function shiftSubtree(node: d3.HierarchyPointNode<HierarchyData>, shiftX: number, shiftY: number) {
  node.x! += shiftX;
  node.y! += shiftY;
  if (node.children) {
    node.children.forEach((child) => {
      shiftSubtree(child, shiftX, shiftY);
    });
  }
}
