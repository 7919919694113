import {createInstance, Identify} from '@amplitude/analytics-browser';
import {ROUTE_PATHS} from 'constant';
import {isProd} from 'helpers/environment';

import {AnalyticsKeys} from './keys';

const amplitudeApiKeyDropbox = isProd
  ? 'e66da721f58ea768758f76a1c04d796b'
  : 'a2b0da7551dbf5cc5594f26877d355d9';

const amplitudeApiKey = isProd
  ? 'ca88ab3da031ee1bbb10ada67918a271'
  : '8dc6a3b6063b55933ec4e71eb2edfa22';

type EventData = {[key: string]: boolean | number | string | undefined | string[]};

class AmplitudeClient {
  isDisabled = false;
  amplitudeInstanceDropbox = createInstance();
  amplitudeInstance = createInstance();

  constructor() {
    this.amplitudeInstanceDropbox.init(amplitudeApiKeyDropbox, {
      trackingOptions: {ipAddress: false},
      defaultTracking: {
        sessions: true,
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
        pageViews: {
          trackOn: () => this.shouldTrackPage(),
        },
      },
    });

    this.amplitudeInstance.init(amplitudeApiKey, {
      trackingOptions: {ipAddress: false},
      defaultTracking: {
        sessions: true,
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
        pageViews: {
          trackOn: () => this.shouldTrackPage(),
        },
      },
    });
  }

  private shouldTrackPage() {
    const supportedRoutes = [
      ROUTE_PATHS.GOALS,
      ROUTE_PATHS.PEOPLE,
      ROUTE_PATHS.TEAMS,
      ROUTE_PATHS.STRATEGIES,
      ROUTE_PATHS.PROJECTS,
      ROUTE_PATHS.GRATITUDE_BROWSE,
      ROUTE_PATHS.SETTINGS,
      ROUTE_PATHS.NEWS,
      ROUTE_PATHS.DASHBOARDS,
      ROUTE_PATHS.HOME,
    ];
    return supportedRoutes.some((path) => window.location.pathname === path);
  }

  logEvent(name: AnalyticsKeys, data?: EventData) {
    if (this.isDisabled) return;

    if (!isProd) {
      console.info('Analytics:', name, data || {});
    }

    this.amplitudeInstanceDropbox.track(name, data);
    this.amplitudeInstance.track(name, data);
  }

  enable(userId: string) {
    this.amplitudeInstanceDropbox.setUserId(userId);
    this.amplitudeInstance.setUserId(userId);
  }

  setProperty(key: string, value: string | null) {
    if (!value) return;

    const identifyEvent = new Identify();
    identifyEvent.set(key, value);

    this.amplitudeInstanceDropbox.identify(identifyEvent);
    this.amplitudeInstance.identify(identifyEvent);
  }

  logout() {
    this.amplitudeInstanceDropbox.reset();
    this.amplitudeInstance.reset();
  }

  disable() {
    this.isDisabled = true;
  }

  enableLogging() {
    this.isDisabled = false;
  }
}

let instance: AmplitudeClient | undefined;
export const analyticsLogger = () => {
  if (!instance) {
    instance = new AmplitudeClient();
  }
  return instance;
};
