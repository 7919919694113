import {TeamInfoFull} from 'client';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useLoaderData} from 'react-router-dom';
import {TeamsView} from 'views/teams/TeamsView';

import {NotFound} from './NotFound';

const Teams = () => {
  const team = useLoaderData() as TeamInfoFull;
  useDocumentTitle(team ? team.name : '');

  if (!team) {
    return <NotFound />;
  }

  return <TeamsView team={team} />;
};

export {Teams};
