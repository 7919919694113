import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {isMobileAtom} from 'atoms/layout';
import {HomeService} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {format, isWithinInterval} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import {useAtomValue} from 'jotai';
import {Fragment, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {getBackendUrl, getService} from 'utilities';

import styles from './Banner.module.css';
import {BannerConfig} from './BannerConfig';
import {BANNER_CONFIGS} from './Banners';

const aprilFoolsDayStart = new Date(2025, 3, 1);
const aprilFoolsDayEnd = new Date(2025, 3, 2);

export const Banner = () => {
  let boxSize = {width: 200, height: 113};
  const isMobile = useAtomValue(isMobileAtom);
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const [searchParams] = useSearchParams();

  const [clickedFool, setFlickedFool] = useState(false);

  const forceBanner = searchParams.get('banner');

  const {data} = useQuery({
    queryKey: ['home', 'banner'],
    queryFn: getService(HomeService).bannerApiV1HomeBannerGet,
  });

  const aprilFoolsBannerConfig = new BannerConfig({
    type: 'aprilfools',
    title: 'Announcing the new President of Dropbox',
    subtitle: (
      <>
        We’re happy to share that {employee.name?.split(' ')[0]} is being promoted to the role of
        President •{' '}
        <Button
          variant="transparent"
          className={styles.buttonLink}
          onClick={() => {
            setFlickedFool(true);
            analyticsLogger().logEvent('HOME_BANNER_CLICKED', {action: 'aprilfools'});
          }}
        >
          {clickedFool ? 'April fools!' : 'Send a card'}
        </Button>
      </>
    ),
    image: employee.profile_photo_url ? getBackendUrl() + employee.profile_photo_url : undefined,
    imageBackground: 'linear-gradient(90deg, #298BFF 0%, #CDEEFF 100%)',
    calendarRegex: 'Fool',
    background: 'linear-gradient(90deg, #298BFF 0%, #CDEEFF 100%)',
  });

  const isAprilFoolsDay = useMemo(() => {
    return isWithinInterval(new Date(), {start: aprilFoolsDayStart, end: aprilFoolsDayEnd});
  }, []);

  const bannerConfig = {
    ...BANNER_CONFIGS,
    aprilfools: aprilFoolsBannerConfig,
  }[
    (forceBanner?.split('-')?.[0] ||
      data?.company_os_type ||
      (isAprilFoolsDay ? 'aprilfools' : undefined)) as keyof typeof BANNER_CONFIGS
  ];

  if (forceBanner && forceBanner?.split('-')?.[1]) {
    bannerConfig.force = forceBanner?.split('-')?.[1] as 'before' | 'after' | 'live';
  }

  // 9 am PST on 3/25/2025
  const eventDate = new Date('2025-03-25T09:00:00-07:00');

  // const date = new Date();
  // date.setMinutes(0);

  if (!bannerConfig || (bannerConfig.excludeContractor && employee.type === 'Contractor')) {
    return null;
  }

  if (bannerConfig.type === 'aprilfools') {
    boxSize = {width: 150, height: 150};
  }

  return (
    <Box
      borderRadius="Medium"
      borderColor="Text Base"
      borderStyle="Solid"
      marginBottom="32"
      marginTop="16"
      position="relative"
      style={{background: bannerConfig.background, minHeight: 124}}
    >
      {bannerConfig.type !== 'goodbye' && bannerConfig.type !== 'aprilfools' && (
        <Box
          paddingY="6"
          backgroundColor="Background Base"
          borderColor="Text Base"
          borderBottom="Solid"
          style={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}
        >
          <Marquee text={bannerConfig.getMarquee(eventDate)} eventStart={eventDate} />
        </Box>
      )}

      <Box
        style={{
          marginLeft: isMobile ? 16 : boxSize.width + 56,
          marginTop:
            (isMobile ? boxSize.height / 2 + 24 : 0) +
            (bannerConfig.type === 'goodbye' ? 12 : 0) +
            (bannerConfig.type === 'aprilfools' && isMobile ? 48 : 0),
        }}
        paddingY="12"
        display="flex"
        flexDirection="column"
      >
        <ThemeProvider mode="bright">
          <ThemeContainer>
            <Text className={styles.brandSharp}>{bannerConfig.title}</Text>
            <Text>{bannerConfig.getSubtitle(eventDate)}</Text>
          </ThemeContainer>
        </ThemeProvider>
      </Box>

      <Video
        background={bannerConfig.imageBackground}
        image={bannerConfig.image}
        floatingImage={bannerConfig.type === 'goodbye'}
        {...boxSize}
        circular={bannerConfig.type === 'aprilfools'}
      />
    </Box>
  );
};

const Video = ({
  background,
  image,
  floatingImage,
  circular,
  ...boxSize
}: {
  image?: string;
  background?: string;
  floatingImage?: boolean;
  circular?: boolean;
  width: number;
  height: number;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  return (
    <Box position="absolute" top="0" left="0">
      <Box position="relative" style={boxSize}>
        <Box
          position="absolute"
          backgroundColor="Background Base"
          style={{...boxSize, left: 36, bottom: 1}}
          borderRadius={circular ? 'Circular' : 'XLarge'}
          borderColor="Text Base"
          borderStyle="Solid"
        />
        <Box
          position="absolute"
          backgroundColor="Background Base"
          style={{...boxSize, left: 24, bottom: 9}}
          borderRadius={circular ? 'Circular' : 'XLarge'}
          borderColor="Text Base"
          borderStyle="Solid"
          bottom="0"
          right="0"
        />
        {circular ? (
          <>
            <Box
              position="absolute"
              style={{...boxSize, left: 12, bottom: 17, background}}
              borderRadius={circular ? 'Circular' : 'XLarge'}
              borderColor="Text Base"
              borderStyle="Solid"
              bottom="0"
              right="0"
            />
            <Box
              position="absolute"
              style={{left: 23, top: -6, transform: 'scale(1.16)', objectFit: 'cover'}}
              borderRadius="Circular"
              overflow="hidden"
            >
              <Avatar key="april-fool" size="profile" user={employee} />
            </Box>
          </>
        ) : image && !floatingImage ? (
          <Box
            as="img"
            position="absolute"
            backgroundColor="Background Base"
            style={{
              ...boxSize,
              left: 12,
              bottom: 17,
              background,
              objectFit: 'cover',
            }}
            borderRadius={circular ? 'Circular' : 'XLarge'}
            borderColor="Text Base"
            borderStyle="Solid"
            bottom="0"
            right="0"
            src={image}
          />
        ) : (
          <>
            <Box
              position="absolute"
              style={{...boxSize, left: 12, bottom: 17, background}}
              borderRadius={circular ? 'Circular' : 'XLarge'}
              borderColor="Text Base"
              borderStyle="Solid"
              bottom="0"
              right="0"
            />
            {image && (
              <Box
                as="img"
                position="absolute"
                style={{left: 24, bottom: 10, transform: 'scale(1.06)', objectFit: 'cover'}}
                borderRadius={circular ? 'Circular' : 'XLarge'}
                src={image}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

const Marquee = ({text, eventStart}: {text: string; eventStart: Date}) => {
  const date = format(eventStart, 'EEE, MMM d');

  const time = formatInTimeZone(
    eventStart,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    'h:mma'
  ).toLowerCase();

  const timezone = formatInTimeZone(
    eventStart,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    'zzz'
  );

  return (
    <div className={styles.marquee}>
      <Text className={styles.marqueeSlider} isBold size="xsmall">
        <Box
          display="inline-flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{gap: 12, textTransform: 'uppercase'}}
        >
          {Array.from({length: 15}).map((_, i) => (
            <Fragment key={i}>
              <span>{text === '$DATE' ? (i % 2 === 0 ? date : `${time} ${timezone}`) : text}</span>
              <span>—</span>
            </Fragment>
          ))}
        </Box>
        <Box
          display="inline-flex"
          flexDirection="row"
          paddingX="12"
          alignItems="center"
          justifyContent="center"
          style={{gap: 12, textTransform: 'uppercase'}}
        >
          {Array.from({length: 15}).map((_, i) => (
            <Fragment key={i}>
              <span>{text === '$DATE' ? (i % 2 === 0 ? date : `${time} ${timezone}`) : text}</span>
              <span>—</span>
            </Fragment>
          ))}
        </Box>
      </Text>
    </div>
  );
};
