import {Box} from '@dropbox/dig-foundations';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {Thread} from 'client';
import {DeleteModal} from 'components/DSYS/DeleteModal';
import styles from 'components/DSYS/editor/plugins/Comment.module.css';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {t} from 'i18next';
import {useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {CommentThread} from './CommentThread';
import {ADD_COMMENT_COMMAND} from './util';

export const CommentToolbar = ({
  position,
  thread,
  onFocus,
  clearHighlight,
}: {
  position?: {top: number; left: number};
  thread?: Thread;
  onFocus: () => void;
  clearHighlight: () => void;
}) => {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const [commentValue, setCommentValue] = useState<string | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useOutsideClick(
    editorRef,
    () => {
      if (commentValue) {
        setShowDeleteModal(true);
      } else {
        clearHighlight();
      }
    },
    ['dig-Drawer-container', styles.focused],
    true
  );

  return position
    ? createPortal(
        <Box
          style={{
            position: 'absolute',
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
        >
          <Box
            ref={editorRef}
            className={styles.editor}
            boxShadow="Floating"
            borderStyle="Solid"
            borderColor="Border Subtle"
            onFocus={onFocus}
            width="100%"
            backgroundColor="Background Base"
            borderRadius="Medium"
          >
            <CommentThread
              thread={thread}
              onValueChange={setCommentValue}
              onAddComment={(comment) =>
                editor.dispatchCommand(ADD_COMMENT_COMMAND, {comment, threadId: thread?.id})
              }
              onResolveClick={clearHighlight}
              onDeleteComment={() => {
                if ((thread?.comments?.length ?? 1) === 1) {
                  clearHighlight();
                }
              }}
            />
          </Box>
          <DeleteModal
            title={t('discard_comment')}
            subtitle={t('get_rid_of_comment_you_wrote')}
            open={showDeleteModal}
            isPending={false}
            onDelete={() => {
              setCommentValue(undefined);
              return Promise.resolve(clearHighlight());
            }}
            onClose={() => setShowDeleteModal(false)}
          />
        </Box>,
        document.body
      )
    : null;
};
