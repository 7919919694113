import {SVGProps} from 'react';

export const MetricLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125 4.5H4.5V13.125H13.125V4.5ZM3.375 3.375V14.25H14.25V3.375H3.375Z"
        fill={iconFill}
      />
      <path d="M6 8.625H7.125V11.25H6V8.625Z" fill={iconFill} />
      <path d="M8.25 7.5H9.375V11.25H8.25V7.5Z" fill={iconFill} />
      <path d="M10.5 6.375H11.625V11.25H10.5V6.375Z" fill={iconFill} />
    </svg>
  );
};
