import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {TeamWithHierarchy} from 'client';
import {Accordion, AccordionIconButton} from 'components/DSYS/Accordion';
import {Link} from 'components/DSYS/Link';

interface TeamsTreeProps {
  teams: TeamWithHierarchy[];
  selectedIndicies: number[];
}

export const TeamsTree = ({teams, selectedIndicies}: TeamsTreeProps) => {
  const renderTeam = (
    team: TeamWithHierarchy,
    expanded: boolean,
    active: boolean,
    click: () => void,
    level = 0
  ) => {
    const hasChildren = (team.children?.length ?? 0) > 0;

    return (
      <Box as={Stack} marginTop="2">
        <Box as={Link} to={`/teams/${team.slug}`} hasNoUnderline width="100%">
          <Box
            borderRadius="Medium"
            paddingX="8"
            paddingY="6"
            paddingLeft={hasChildren ? undefined : '36'}
            marginLeft={hasChildren ? undefined : '4'}
            {...withShade({state: active ? 'animated' : undefined})}
          >
            <Box as={Split} gap="8" alignY="center">
              {hasChildren && (
                <Split.Item>
                  <AccordionIconButton
                    tabIndex={-1}
                    isActive={expanded}
                    toggle={(e) => {
                      e.preventDefault();
                      click();
                    }}
                  />
                </Split.Item>
              )}
              <Split.Item>
                <Text size="small" variant="paragraph" isBold>
                  {team.name}
                </Text>
              </Split.Item>
            </Box>
          </Box>
        </Box>

        {expanded && team.children && (
          <Accordion
            items={team.children}
            renderItem={renderTeam}
            level={level + 1}
            tabSize={32}
            selectedIndicies={active ? selectedIndicies : []}
          />
        )}
      </Box>
    );
  };

  return (
    <Accordion
      items={teams}
      renderItem={renderTeam}
      tabSize={0}
      selectedIndicies={selectedIndicies}
    />
  );
};
