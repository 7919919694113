/* eslint-disable no-param-reassign */
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {atoms, Box, ThemeContainer, ThemeProvider, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, CloseLine, FullscreenLine, MinusLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {derivedThemeAtom} from 'atoms/layout';
import cx from 'classnames';
import {EmployeeWithHierarchy, TeamWithHierarchyCounts} from 'client';
import {Title} from 'components/DSYS/Title';
import {PageSpinner} from 'components/shared/PageSpinner';
import * as d3 from 'd3';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './Chart.module.css';
import {ChartCard} from './ChartCard';
import {
  adjustDeepestNodes,
  centerOnFocusedNode,
  FocusData,
  getUid,
  getUrl,
  HierarchyData,
  isEmployee,
  nodeSize,
  padding,
  renderLinks,
  renderStyledNodes,
} from './helpers';
import {useChartExpand, useChartKeyboardShortcuts, useChartResize, useData} from './hooks';
import {OrgLine} from './OrgLine';

export type OrgChartProps = {
  overrideTree?: HierarchyData;
  allowZoom?: boolean;
  focus: FocusData;
  toggle: {employee?: FocusData; team?: FocusData};
  setFocus: (focus: FocusData) => void;
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
};

export const FOO = true;

const maxZoom = 1.4;
const minZoom = 0.7;

const profileViewHeight = 254;

export const OrgChart = ({
  overrideTree,
  allowZoom = false,
  toggle,
  focus,
  setFocus,
  expanded,
  setExpanded,
}: OrgChartProps) => {
  const navigate = useNavigate();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<SVGSVGElement, unknown> | null>(null);
  const isLightMode = useAtomValue(derivedThemeAtom) === 'bright';
  const [type, setType] = useState<'employee' | 'team'>(overrideTree ? 'employee' : 'team');
  const [cardOpen, setCardOpen] = useState(false);
  const hoverTimeoutRef = useRef<number | undefined>();
  const ignoreFirstHover = useRef<boolean>(false);

  const [selection, setSelection] = useState<FocusData | undefined>(focus);
  const [hover, setHover] = useState<FocusData | undefined>();

  useEffect(() => {
    if (!expanded) {
      setSelection(focus);
    }
  }, [focus, expanded]);

  const {hierarchyData} = useData({
    focus,
    type,
    selection,
    overrideTree,
    expanded,
  });

  useEffect(() => {
    if (!selection || (isEmployee(selection) && type === 'team')) {
      return;
    }

    setFocus(selection);
  }, [selection, setFocus, type]);

  const [children, setChildren] = useState<number | undefined>(undefined);
  const isProfileView = Boolean(overrideTree && !expanded);
  const dotColor = isLightMode ? '#736c6450' : '#bbb5ae50';

  // Function to adjust zoom scale
  const adjustZoom = (zoomFactor: number, source: string) => {
    if (!zoomRef.current || !svgRef.current) {
      return;
    }

    analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
      type,
      source,
      expanded,
      action: zoomFactor > 1 ? 'zoom_in' : 'zoom_out',
    });

    const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const point = [svgRef.current.clientWidth / 2, svgRef.current.clientHeight / 2];

    const currentTransform = d3.zoomTransform(svgZoom.node() as SVGSVGElement);
    const newScale = Math.max(minZoom, Math.min(maxZoom, currentTransform.k * zoomFactor));

    // Calculate the new x and y to keep the point centered
    const zoomTransform = d3.zoomIdentity
      .translate(
        point[0] - (point[0] - currentTransform.x) * (newScale / currentTransform.k),
        point[1] - (point[1] - currentTransform.y) * (newScale / currentTransform.k)
      )
      .scale(newScale);

    svgZoom.call(zoomRef.current.transform, zoomTransform);
  };

  const recenterChart = useCallback(
    ({resize}: {node?: d3.HierarchyPointNode<HierarchyData>; resize?: boolean}) => {
      if (!zoomRef.current || !svgRef.current || !wrapperRef.current) {
        return;
      }

      if (resize) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const width = isProfileView
          ? hierarchyData?.nodes?.length * (nodeSize(isProfileView, expanded).width + padding) +
            padding * 1.5
          : rect.width - 8;
        svgRef.current.setAttribute('width', width + 'px');
        svgRef.current.setAttribute('height', rect.height + 'px');
      }

      const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const wrapperWidth = wrapperRef.current.clientWidth;
      const width = wrapperWidth - padding * 4;
      const wrapperHeight = wrapperRef.current.clientHeight;
      const height = wrapperHeight - padding * 4;

      const pathToRoot = hierarchyData?.pathToRoot;
      const focusedNode = pathToRoot.length > 0 ? pathToRoot[0] : undefined;

      // Center on the selected node or a default position
      centerOnFocusedNode(
        svgZoom,
        zoomRef.current,
        width,
        height,
        isProfileView,
        Boolean(expanded),
        hierarchyData?.nodes?.length ?? 0,
        focusedNode
      );
    },
    [expanded, hierarchyData?.nodes, hierarchyData?.pathToRoot, isProfileView]
  );

  // Reset zoom function
  const resetZoom = useCallback(
    (source?: string) => {
      if (!zoomRef.current || !svgRef.current) return;

      if (source) {
        analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
          type,
          action: 'zoom_reset',
          expanded,
          source,
        });
      }

      const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const initialTransform = d3.zoomIdentity;
      svgZoom.call(zoomRef.current.transform, initialTransform);

      recenterChart({resize: false});
    },
    [expanded, recenterChart, type]
  );

  useChartExpand(wrapperRef, expanded, recenterChart);
  useChartResize(recenterChart);
  useChartKeyboardShortcuts({expanded, resetZoom, adjustZoom});

  useEffect(() => {
    if (!svgRef.current || !hierarchyData) return;

    const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);
    svg.selectAll('*').remove();
    const g = svg.append('g');

    let currentTransform = d3.zoomIdentity;

    zoomRef.current = d3
      .zoom<SVGSVGElement, unknown>()
      .filter((event) => !['wheel', 'dblclick'].includes(event.type))
      .scaleExtent([minZoom, maxZoom])
      .on('zoom', (event) => {
        currentTransform = event.transform;
        g.attr('transform', currentTransform.toString());
      });

    if (expanded || allowZoom) {
      svg.call(zoomRef.current);

      svg.on('wheel', (event) => {
        event.preventDefault();
        const point = d3.pointer(event, svg.node());

        if (event.ctrlKey || event.metaKey) {
          // Calculate the new zoom scale
          const zoomFactor = event.deltaY > 0 ? 0.96 : 1.04; // Adjusted for less intensity
          const newScale = Math.max(minZoom, Math.min(maxZoom, currentTransform.k * zoomFactor));

          // Compute the new transformation centered around the mouse point
          const zoomTransform = d3.zoomIdentity
            .translate(
              point[0] - (point[0] - currentTransform.x) * (newScale / currentTransform.k),
              point[1] - (point[1] - currentTransform.y) * (newScale / currentTransform.k)
            )
            .scale(newScale);

          // Apply the new zoom transformation
          currentTransform = zoomTransform;
          g.attr('transform', currentTransform.toString());

          // Sync with d3.zoom's internal state
          svg.call(zoomRef.current!.transform, currentTransform);
        } else {
          // Trackpad panning behavior if Ctrl is not pressed
          const deltaX = event.deltaX;
          const deltaY = event.deltaY;
          currentTransform = currentTransform.translate(-deltaX, -deltaY);
          g.attr('transform', currentTransform.toString());

          // Sync with d3.zoom's internal state
          svg.call(zoomRef.current!.transform, currentTransform);
        }
      });
    } else {
      svg.on('wheel', null);
    }

    const {root, pathToRoot, nodes, links, maxDepth} = hierarchyData;

    const treeLayout = d3
      .tree<HierarchyData>()
      .nodeSize(
        isProfileView
          ? [
              nodeSize(isProfileView, expanded).height + padding,
              nodeSize(isProfileView, expanded).width + padding,
            ]
          : [
              nodeSize(isProfileView, expanded).width + padding,
              nodeSize(isProfileView, expanded).height + padding,
            ]
      )
      .separation(() => 1);

    treeLayout(root);

    const focusedNode = pathToRoot.length > 0 ? pathToRoot[0] : undefined;

    if (focusedNode) {
      if (focusedNode.children?.length) {
        setChildren(
          Math.ceil(focusedNode.children.length / (focusedNode.children.length > 6 ? 3 : 2))
        );
      } else {
        const parentChildren = focusedNode.parent?.children?.length ?? 0;

        focusedNode.parent && !isEmployee(focusedNode.parent.data)
          ? setChildren(Math.ceil(parentChildren / (parentChildren > 6 ? 3 : 2)))
          : setChildren(1);
      }
    }

    if (isProfileView) {
      // Make the profile tree horizontal by flipping x and y
      root.descendants().forEach((d) => {
        const x = d.x ?? 0;
        d.x = -1 * ((d.y ?? 0) + nodeSize(isProfileView, expanded).width / 2);
        d.y = profileViewHeight / 2 - x;
      });
    } else {
      // Adjust the deepest nodes to be vertical
      adjustDeepestNodes(nodes, maxDepth, isProfileView, expanded);
    }

    // Render the lines
    renderLinks(g, links, pathToRoot, isProfileView, maxDepth, expanded);

    // Render the nodes
    renderStyledNodes(
      g,
      nodes,
      focusedNode,
      (e, node) => {
        e.stopPropagation();
        e.preventDefault();
        if (expanded) {
          setSelection(node);
          setHover(node);
          ignoreFirstHover.current = true;
          analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
            type,
            expanded,
            action: 'selection',
            source: 'node',
          });
        } else {
          navigate(getUrl(node));
          analyticsLogger().logEvent('PROFILE_INTERACTION', {action: 'reporting'});
          analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
            type,
            expanded,
            action: 'navigate',
            source: 'node',
          });
        }
      },
      (over) => {
        if (hoverTimeoutRef.current) {
          window.clearTimeout(hoverTimeoutRef.current);
        }
        if (!expanded) {
          return;
        }

        if (!over) {
          ignoreFirstHover.current = false;
        }

        if (ignoreFirstHover.current) {
          return;
        }

        if (over) {
          setHover(over);
        } else {
          hoverTimeoutRef.current = window.setTimeout(() => {
            setHover(undefined);
          }, 300);
        }
      },
      isProfileView,
      expanded,
      maxDepth
    );

    setCardOpen(expanded);
    resetZoom();
    recenterChart({resize: true});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchyData]);

  useEffect(() => {
    if (!expanded) {
      setFocus(toggle.employee ?? toggle.team!);
      setSelection(toggle.employee ?? toggle.team);
      setType(toggle.employee ? 'employee' : 'team');
      setHover(undefined);
    }
  }, [expanded, toggle, resetZoom, setFocus]);

  return (
    <Box
      ref={wrapperRef}
      borderRadius="Large"
      borderStyle="Solid"
      borderColor="Border Subtle"
      borderWidth="1"
      className={cx(styles.container, {
        [styles.adjustable]: expanded,
      })}
      style={{
        marginTop: 0,
        minHeight: expanded
          ? undefined
          : isProfileView
            ? profileViewHeight
            : `${padding * 3.5 + ((hierarchyData?.maxDepth ?? 0) + (children ?? 0)) * (nodeSize(isProfileView, expanded).height + padding)}px`,
        backgroundImage: expanded
          ? `radial-gradient(circle, ${dotColor} 1px, var(--dig-color__background__${isLightMode ? 'subtle' : 'raised'}) 1px)`
          : undefined,
        backgroundColor: expanded
          ? undefined
          : `var(--dig-color__background__${isLightMode ? 'subtle' : 'raised'})`,
      }}
    >
      {expanded && (
        <Box
          pointerEvents="none"
          position="absolute"
          style={{
            height: 68,
            background: `linear-gradient(180deg, rgba(${isLightMode ? '247,245,242' : '36,35,33'}, .9) 68%, transparent 100%`,
          }}
          width="100%"
        />
      )}
      <Box position="absolute" paddingX={expanded ? '24' : '20'} paddingY={expanded ? '24' : '16'}>
        <Title
          weightVariant="emphasized"
          size="small"
          withAccessoryStart={<UIIcon src={OrgLine} />}
        >
          {t('organization')}
        </Title>
      </Box>
      <Box
        position="absolute"
        paddingX={expanded ? '24' : '20'}
        paddingY={expanded ? '24' : '16'}
        right="0"
      >
        <Box display="flex" style={{gap: 8}}>
          {overrideTree && (
            <>
              <Chip
                isSelected={type === 'employee'}
                onClick={
                  expanded
                    ? () => {
                        analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                          type: 'employee',
                          action: 'reporting_line_chip',
                          expanded,
                        });
                        setExpanded?.(true);
                        setType('employee');
                        setFocus(toggle.employee!);
                        setSelection(toggle.employee);
                      }
                    : undefined
                }
              >
                {t('reporting_line')}
              </Chip>
              {toggle.team && (
                <Chip
                  isSelected={type === 'team'}
                  onClick={() => {
                    analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                      type: 'employee',
                      action: 'teams_chip',
                      expanded,
                    });
                    setExpanded?.(true);
                    setType('team');
                    setFocus(toggle.team!);
                  }}
                >
                  {t('teams')}
                </Chip>
              )}
            </>
          )}

          {expanded && (
            <Box marginRight="16">
              <ZoomButton onClick={() => adjustZoom(0.8, 'button')} />
              <ZoomButton zoomIn onClick={() => adjustZoom(1.2, 'button')} />
            </Box>
          )}
          <ThemeProvider
            mode={useAtomValue(derivedThemeAtom) === 'bright' && expanded ? 'dark' : 'bright'}
          >
            <ThemeContainer>
              <Box
                as={IconButton}
                variant={expanded ? 'filled' : 'outline'}
                onClick={() => {
                  analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                    type,
                    action: expanded ? 'expand' : 'collapse',
                    source: 'chart_button',
                    expanded,
                  });
                  setExpanded?.(!expanded);
                }}
              >
                <UIIcon
                  src={expanded ? CloseLine : FullscreenLine}
                  className={atoms({color: expanded ? 'Text Base' : 'Text Subtle'})}
                />
              </Box>
            </ThemeContainer>
          </ThemeProvider>
        </Box>
      </Box>
      <ChartCard
        open={cardOpen}
        isFocus={(hover ?? selection ?? focus) === (toggle.employee ?? toggle.team)}
        expanded={Boolean(expanded)}
        onClose={() => {
          setCardOpen(false);
          analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
            type,
            action: 'collapse',
            expanded,
            source: 'keyboard',
          });
        }}
      >
        {isEmployee(hover ?? selection ?? focus) ? (
          <ChartCard.Employee
            employee={(hover ?? selection ?? focus) as EmployeeWithHierarchy}
            onClick={(node) => {
              analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                type,
                action: 'selection',
                expanded,
                source: 'card',
              });
              setType(isEmployee(node) ? 'employee' : 'team');
              setFocus(node);
              setSelection(hover ?? selection ?? focus);
            }}
            onViewProfile={() => {
              analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                type,
                action: 'navigate',
                expanded,
                source: 'card',
              });
              setExpanded?.(false);
            }}
          />
        ) : (
          <ChartCard.Team
            team={(hover ?? selection ?? focus) as TeamWithHierarchyCounts}
            onViewTeam={() => {
              analyticsLogger().logEvent('ORG_CHART_INTERACTION', {
                type,
                action: 'navigate',
                expanded,
                source: 'card',
              });
              setExpanded?.(false);
            }}
          />
        )}
      </ChartCard>
      <Box overflowX={isProfileView ? 'auto' : undefined}>
        <svg ref={svgRef} width="100%" height="100%"></svg>
      </Box>
      {expanded && !getUid(hierarchyData.nodes?.[0].data) && (
        <Box
          pointerEvents="none"
          position="absolute"
          width="100%"
          style={{height: '60%', zIndex: 11, top: 100}}
        >
          <PageSpinner />
        </Box>
      )}
    </Box>
  );
};

const ZoomButton = ({zoomIn, onClick}: {zoomIn?: boolean; onClick: () => void}) => (
  <Box
    as="button"
    color="Text Subtle"
    backgroundColor="Background Subtle"
    borderStyle="Solid"
    borderColor="Border Subtle"
    borderWidth="1"
    paddingY="2"
    cursor="pointer"
    outlineColor="Focus Ring"
    outlineOffset="1"
    onClick={onClick}
    {...withShade({
      direction: 'up',
      style: {
        height: 32,
        borderTopLeftRadius: !zoomIn ? 20 : 0,
        borderBottomLeftRadius: !zoomIn ? 20 : 0,
        borderTopRightRadius: !zoomIn ? 0 : 20,
        borderBottomRightRadius: !zoomIn ? 0 : 20,
        borderLeftWidth: !zoomIn ? 1 : 0,
        borderRightWidth: !zoomIn ? 0 : 1,
        paddingLeft: !zoomIn ? 4 : 0,
        paddingRight: !zoomIn ? 0 : 4,
      },
    })}
  >
    <UIIcon
      src={zoomIn ? AddLine : MinusLine}
      size="small"
      className={atoms({margin: '4', marginBottom: '0'})}
    />
  </Box>
);
