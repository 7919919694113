/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLog } from '../models/AuditLog';
import type { Team } from '../models/Team';
import type { TeamEdit } from '../models/TeamEdit';
import type { TeamInfoFull } from '../models/TeamInfoFull';
import type { TeamWithCounts } from '../models/TeamWithCounts';
import type { TeamWithHierarchy } from '../models/TeamWithHierarchy';
import type { TeamWithHierarchyCounts } from '../models/TeamWithHierarchyCounts';
import type { TeamWithMembers } from '../models/TeamWithMembers';
import type { TeamWithParents } from '../models/TeamWithParents';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

    /**
     * All Employee Endpoint
     * @returns TeamWithHierarchy Successful Response
     * @throws ApiError
     */
    public static getTreeAllApiV1TeamsTreeGet(): CancelablePromise<TeamWithHierarchy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/tree',
        });
    }

    /**
     * All Employee Endpoint
     * @returns TeamWithHierarchyCounts Successful Response
     * @throws ApiError
     */
    public static getTreeCountsAllApiV1TeamsTreecountsGet(): CancelablePromise<TeamWithHierarchyCounts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/treecounts',
        });
    }

    /**
     * Get Teams Area
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static getTeamsAreaApiV1TeamAreaGet(): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/team/area',
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createApiV1TeamPost(
        requestBody: TeamEdit,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/team',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive
     * @param slug
     * @returns string Successful Response
     * @throws ApiError
     */
    public static archiveApiV1TeamSlugArchivePost(
        slug: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/team/{slug}/archive',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unarchive
     * @param slug
     * @returns string Successful Response
     * @throws ApiError
     */
    public static unarchiveApiV1TeamSlugUnarchivePost(
        slug: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/team/{slug}/unarchive',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit
     * Update a team.
     * @param slug
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static editApiV1TeamSlugPut(
        slug: string,
        requestBody: TeamEdit,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/team/{slug}',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Teams Page Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTeamsApiV1TeamsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams',
        });
    }

    /**
     * Count Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCountApiV1CountGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/count',
        });
    }

    /**
     * Team Endpoint
     * @param slug
     * @param includeSubteamMembers
     * @returns TeamInfoFull Successful Response
     * @throws ApiError
     */
    public static getTeamBySlugApiV1TeamsSlugSlugGet(
        slug: string,
        includeSubteamMembers?: (boolean | null),
    ): CancelablePromise<TeamInfoFull> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/slug/{slug}',
            path: {
                'slug': slug,
            },
            query: {
                'include_subteam_members': includeSubteamMembers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @returns string Successful Response
     * @throws ApiError
     */
    public static seedApiV1TeamsSeedGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/seed',
        });
    }

    /**
     * Team Endpoint
     * @param slug
     * @returns TeamWithMembers Successful Response
     * @throws ApiError
     */
    public static getTeamMembersApiV1TeamsSlugSlugMembersGet(
        slug: string,
    ): CancelablePromise<TeamWithMembers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/slug/{slug}/members',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @param slug
     * @returns AuditLog Successful Response
     * @throws ApiError
     */
    public static getTeamLogsApiV1TeamsSlugLogsGet(
        slug: string,
    ): CancelablePromise<Array<AuditLog>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/{slug}/logs',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @param slug
     * @returns TeamWithParents Successful Response
     * @throws ApiError
     */
    public static getHierarchyApiV1TeamsSlugHierarchyGet(
        slug: string,
    ): CancelablePromise<TeamWithParents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/{slug}/hierarchy',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Teams Endpoint
     * @param slugs
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static getTeamBySlugsApiV1TeamsSlugsSlugsGet(
        slugs: string,
    ): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/slugs/{slugs}',
            path: {
                'slugs': slugs,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @returns TeamWithCounts Successful Response
     * @throws ApiError
     */
    public static getHierarchyForTeamApiV1TeamsHierarchyGet(): CancelablePromise<Array<TeamWithCounts>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/hierarchy',
        });
    }

}
