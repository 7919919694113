import {useMutation, useQueries, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {
  Comment,
  CommentCreate,
  CommentService,
  CommentUpdate,
  Goal,
  Thread,
  ThreadCreate,
  ThreadService,
  ThreadUpdate,
  TrackerFull,
  Workstream,
} from 'client';
import {useAtomValue} from 'jotai';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

const threadService = getService(ThreadService);

export const useGoalThreads = ({
  goalId,
  shouldRefetch,
  disabled,
}: {
  goalId: Goal['id'];
  shouldRefetch?: boolean;
  disabled?: boolean;
  withError?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['threads', 'goal', goalId],
    queryFn: !disabled
      ? () => threadService.getThreadsForGoalApiV1ThreadGoalGoalIdGet(goalId)
      : async () => Promise.resolve([]),
    refetchInterval: shouldRefetch ? 1000 * 30 : Infinity, // 30 seconds
    refetchOnWindowFocus: 'always',
  });

  data?.forEach((thread) => queryClient.setQueryData<Thread>(['thread', thread.id], thread));

  return data as Thread[];
};

export const useTrackerThreads = ({
  tracker,
  disabled,
}: {
  tracker?: TrackerFull;
  disabled?: boolean;
}) => {
  const workstreamIds = tracker?.workstreams.map(({workstream}) => workstream.id);

  const queries = useQueries({
    queries: (workstreamIds ?? []).map((workstreamId) => ({
      queryKey: ['threads', 'workstream', workstreamId],
      queryFn: !disabled
        ? () =>
            threadService.getThreadsForWorkstreamApiV1ThreadWorkstreamWorkstreamIdGet(workstreamId)
        : async () => Promise.resolve([]),
      throwOnError: true,
    })),
  });

  const isFetched = queries.every((query) => query.isFetched);

  queries.forEach((result) => {
    result.data?.forEach((thread) =>
      queryClient.setQueryData<Thread>(['thread', thread.id], thread)
    );
  });

  return {threads: queries.map((result) => result.data).flat() as Thread[], isFetched};
};

export const useWorkstreamThreads = ({
  workstreamId,
  shouldRefetch,
  disabled,
}: {
  workstreamId: Workstream['id'];
  shouldRefetch?: boolean;
  disabled?: boolean;
  withError?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['threads', 'workstream', workstreamId],
    queryFn: !disabled
      ? () =>
          threadService.getThreadsForWorkstreamApiV1ThreadWorkstreamWorkstreamIdGet(workstreamId)
      : async () => Promise.resolve([]),
    refetchInterval: shouldRefetch ? 1000 * 30 : Infinity, // 30 seconds
    refetchOnWindowFocus: 'always',
  });

  data?.forEach((thread) => queryClient.setQueryData<Thread>(['thread', thread.id], thread));

  return data as Thread[];
};

export const useWorkstreamIThreads = ({
  workstreamId,
  shouldRefetch,
  disabled,
}: {
  workstreamId: Workstream['id'];
  shouldRefetch?: boolean;
  disabled?: boolean;
  withError?: boolean;
}) => {
  const {data} = useQuery({
    queryKey: ['threads', 'workstream', workstreamId],
    queryFn: !disabled
      ? () =>
          threadService.getThreadsForWorkstreamApiV1ThreadWorkstreamWorkstreamIdGet(workstreamId)
      : async () => Promise.resolve([]),
    refetchInterval: shouldRefetch ? 1000 * 30 : Infinity, // 30 seconds
    refetchOnWindowFocus: 'always',
  });

  data?.forEach((thread) => queryClient.setQueryData<Thread>(['thread', thread.id], thread));

  return data as Thread[] | undefined;
};

export const useThreadService = () => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const onSettled = (response: Thread | undefined, _error: any, _variables: any, context: any) => {
    if (!response) {
      return;
    }

    queryClient.setQueryData(['thread', response.id], response);

    if (response.workstream_id) {
      queryClient.invalidateQueries({
        queryKey: ['threads', 'workstream', response.workstream_id],
      });
    } else {
      const goalId = response.goal_id;

      queryClient.setQueryData(['threads', 'goal', goalId], (old: Thread[] | undefined) => {
        if (!old) {
          queryClient.invalidateQueries({
            queryKey: ['threads', 'goal', goalId],
          });
          return;
        }
        let threads = [...old];
        if (context?.type === 'create') {
          threads = [...threads, response];
        } else if (context.type === 'delete') {
          threads = threads.filter((thread) => thread.id !== response.id);
        } else if (context.type === 'update') {
          // threads = threads.filter((thread) => thread.id !== response.id);
          threads = threads.map((thread) => (thread.id === response.id ? response : thread));
        }
        return threads;
      });
    }
  };

  const {mutateAsync: createThread} = useMutation({
    mutationFn: ({
      goalId,
      keyResultId,
      workstreamId,
      workstreamUpdateId,
      workstreamMetricId,
      data,
    }: {
      goalId: number;
      keyResultId?: number;
      workstreamId?: number;
      workstreamUpdateId?: number;
      workstreamMetricId?: number;
      data: ThreadCreate;
    }) => {
      if (workstreamMetricId) {
        return threadService.createThreadForWorkstreamMetricApiV1ThreadWorkstreamMetricsWorkstreamMetricIdPost(
          workstreamMetricId,
          data
        );
      }
      if (workstreamUpdateId) {
        return threadService.createThreadForWorkstreamUpdateApiV1ThreadWorkstreamUpdatesWorkstreamUpdateIdPost(
          workstreamUpdateId,
          data
        );
      }
      if (workstreamId) {
        return threadService.createThreadForWorkstreamApiV1ThreadWorkstreamWorkstreamIdPost(
          workstreamId,
          data
        );
      }
      if (keyResultId) {
        return getService(
          ThreadService
        ).createThreadForKeyResultApiV1ThreadKeyResultKeyResultIdPost(keyResultId, data);
      }
      return getService(ThreadService).createThreadForGoalApiV1ThreadGoalGoalIdPost(goalId, data);
    },
    onMutate: async (data) => {
      const {goalId, workstreamId} = data;
      const queryKey = workstreamId
        ? ['threads', 'workstream', workstreamId]
        : ['threads', 'goal', goalId];

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({queryKey});

      // Snapshot the previous value
      const previousThreads = queryClient.getQueryData<Thread[]>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Thread[]>(queryKey, (old) => {
        const newThread: Thread = {
          ...data.data,
          id: 'temp-id', // Assign a temporary ID
          employee,
          is_resolved: false,
        };
        return old ? [...old, newThread] : [newThread];
      });

      // Return a context object with the snapshotted value
      return {previousThreads};
    },
    onError: (_err, _variables, context) => {
      // Rollback to the previous value
      const {goalId, workstreamId} = _variables;
      const queryKey = workstreamId
        ? ['threads', 'workstream', workstreamId]
        : ['threads', 'goal', goalId];
      queryClient.setQueryData(queryKey, context?.previousThreads);
    },
    onSettled,
  });

  const {mutateAsync: deleteThread, isPending: isDeletePending} = useMutation({
    mutationFn: ({threadId}: {threadId: string}) =>
      getService(ThreadService).deleteThreadApiV1ThreadThreadIdDelete(threadId),
    onMutate: () => ({type: 'delete'}),
    onSettled,
  });

  const {mutateAsync: updateThread} = useMutation({
    mutationFn: ({threadId, data}: {threadId: string; data: ThreadUpdate}) =>
      getService(ThreadService).updateThreadApiV1ThreadThreadIdPut(threadId, data),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  return {createThread, deleteThread, updateThread, isDeletePending};
};

const getUpdatedThread = (
  oldThread: Thread,
  response: Comment,
  type: 'create' | 'update' | 'delete'
) => {
  let updatedComments: Comment[] = oldThread.comments as Comment[]; // Default to the existing key results

  if (type === 'create') {
    updatedComments = [...updatedComments, response];
  } else if (type === 'update') {
    updatedComments = updatedComments.map((comment) =>
      comment.id === response.id ? {...response} : comment
    );
  } else if (type === 'delete') {
    updatedComments = updatedComments.filter((comment) => comment.id !== response.id);
  }

  return {...oldThread, comments: updatedComments};
};

export const useCommentService = ({type, id}: {type: string | number; id: number}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const onSettled = (response: Comment | undefined, _error: any, _variables: any, context: any) => {
    if (!response) {
      return;
    }

    const threadId = response.thread_id;
    const thread: Thread | undefined = queryClient.getQueryData(['thread', threadId]);

    if (!thread) {
      queryClient.invalidateQueries({queryKey: ['thread', threadId]});
      return;
    }

    // Set the updated goal
    queryClient.setQueryData(
      ['thread', threadId],
      getUpdatedThread(thread, response, context.type)
    );

    if (id === -1) {
      queryClient.invalidateQueries({queryKey: ['threads', type]});
      return;
    }

    // Temporary workaround that resolves by re-fetching.
    queryClient.invalidateQueries({queryKey: ['threads', type, id]});
  };

  const {mutateAsync: createComment} = useMutation({
    mutationFn: ({data}: {data: CommentCreate}) =>
      getService(CommentService).createCommentReplyApiV1CommentReplyPost(data),
    onMutate: async ({data}) => {
      const threadId = data.thread_id;

      const workstreamId = type === 'workstream' ? id : undefined;

      if (!workstreamId) {
        throw new Error('workstreamId is required for optimistic updates');
      }

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({queryKey: ['threads', 'workstream', workstreamId]});

      // Snapshot the previous value
      const previousThreads = queryClient.getQueryData<Thread[]>([
        'threads',
        'workstream',
        workstreamId,
      ]);

      // Optimistically update the specific thread's comments
      queryClient.setQueryData<Thread[]>(['threads', 'workstream', workstreamId], (old) => {
        if (!old) return old;

        return old.map((thread) => {
          if (thread.id === threadId) {
            const updatedComments = [
              ...(thread.comments ?? []),
              {
                ...data,
                id: 'temp-id', // Assign a temporary ID
                is_deleted: false, // Provide default values for missing properties
                employee,
              },
            ];
            return {...thread, comments: updatedComments};
          }
          return thread;
        });
      });

      // Return a context object with the snapshotted value
      return {previousThreads};
    },
    onError: (_err, _variables, context) => {
      // Rollback to the previous value
      if (type === 'workstream') {
        queryClient.setQueryData(['threads', 'workstream', id], context?.previousThreads);
      }
    },
    onSettled,
  });

  const {mutateAsync: updateComment} = useMutation({
    mutationFn: ({commentId, data}: {commentId: string; data: CommentUpdate}) =>
      getService(CommentService).updateCommentApiV1CommentCommentIdPut(commentId, data),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  const {mutateAsync: deleteComment, isPending: isDeletePending} = useMutation({
    mutationFn: ({commentId}: {commentId: string}) =>
      getService(CommentService).deleteCommentApiV1CommentCommentIdDelete(commentId),
    onMutate: () => ({type: 'delete'}),
    onSettled,
  });

  return {createComment, deleteComment, isDeletePending, updateComment};
};
