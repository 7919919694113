import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {FlagLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {LaptopMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {AuditLog, TeamService, WorkstreamAuditLog} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {LinkIcon} from 'components/shared/LinkIcon';
import {format} from 'date-fns';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';
import {MetricLine} from 'views/workstreams/MetricLine';
import {getI18nDayName} from 'views/workstreams/utils';

export const groupByTimeAndType = (logs: (WorkstreamAuditLog | AuditLog)[]) => {
  const grouped: {[key: string]: {[type: string]: (WorkstreamAuditLog | AuditLog)[]}} = {};

  logs.forEach((log) => {
    const date = new Date(log.date);

    const minutes = Math.floor(date.getMinutes() / 1);
    date.setMinutes(minutes, 0, 0);
    const intervalKey = date.toISOString();

    if (!grouped[intervalKey]) {
      grouped[intervalKey] = {};
    }

    if (!grouped[intervalKey][log.type]) {
      grouped[intervalKey][log.type] = [];
    }

    grouped[intervalKey][log.type].push(log);
  });

  return grouped;
};

const FormattedChangeLog = ({type, value}: {type: string; value: string}) => {
  if (type === 'done') {
    return <RichTextArea value={value} theme="xsmall" />;
  } else if (type === 'status_due') {
    return <>{getI18nDayName(parseInt(value, 10))}</>;
  } else if (type === 'end_date') {
    return <>{format(new Date(value), 'MMM d')}</>;
  } else if (type === 'link') {
    try {
      const {icon, text, url} = JSON.parse(value);
      return (
        <Box display="flex">
          <LinkIcon
            className={atoms({color: 'Text Subtle', flexShrink: 0})}
            size="small"
            style={{marginTop: 3}}
            icon={icon}
          />
          <Truncate tooltipControlProps={{placement: 'top'}}>
            <Text color="subtle">
              {text} ({url})
            </Text>
          </Truncate>
        </Box>
      );
    } catch (e) {
      return <>{value}</>;
    }
  } else if (type === 'metric') {
    try {
      const {type: metricType, title, current, target} = JSON.parse(value);

      return (
        <Box display="flex">
          <UIIcon
            src={metricType === 'milestone' ? FlagLine : MetricLine}
            size="small"
            style={{marginTop: 3}}
            className={atoms({color: 'Text Subtle', flexShrink: 0, marginRight: '2'})}
          />
          <Text color="subtle" style={{flexGrow: 1}}>
            {title}
          </Text>
          {target && current && (
            <Text style={{alignSelf: 'flex-end', marginLeft: 8, flexShrink: 0}} color="subtle">
              {current}/{target}
            </Text>
          )}
        </Box>
      );
    } catch (e) {
      return <>{value}</>;
    }
  }

  return <Truncate tooltipControlProps={{placement: 'top'}}>{value}</Truncate>;
};

export const ChangeLogLine = ({
  change,
  type,
  value,
  isEdit,
}: {
  change: 'add' | 'remove';
  type: string;
  value: string;
  isEdit: boolean;
}) => (
  <Box
    backgroundColor={change === 'add' ? 'Success Surface' : 'Alert Surface'}
    paddingX="8"
    borderRadius="XSmall"
    style={
      isEdit
        ? {
            borderBottomLeftRadius: change !== 'add' ? 0 : undefined,
            borderBottomRightRadius: change !== 'add' ? 0 : undefined,
            borderTopLeftRadius: change !== 'remove' ? 0 : undefined,
            borderTopRightRadius: change !== 'remove' ? 0 : undefined,
          }
        : undefined
    }
  >
    {/* <Box display="flex" alignItems="flex-start"> */}
    {/* <Text
        isBold
        color="faint"
        size="small"
        style={{width: 4}}
        className={atoms({marginRight: '8'})}
      >
        {change === 'add' ? '+' : '-'}
      </Text> */}
    <Text color="faint" style={{width: 800}} className={atoms({display: 'block'})}>
      <FormattedChangeLog type={type} value={value} />
    </Text>
    {/* </Box> */}
  </Box>
);

export const TeamAuditLog = ({slug}: {slug: string}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['team', 'logs', slug],
    queryFn: () => getService(TeamService).getTeamLogsApiV1TeamsSlugLogsGet(slug),
  });

  const groupedLogs = groupByTimeAndType(data ?? []);

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center" marginTop="68">
        <Spinner monochromatic />
      </Box>
    );
  }

  return (
    <>
      {!data?.length ? (
        <EmptyState
          title={t('audit_log_empty_title')}
          body={t('audit_log_empty_body')}
          image={<LaptopMini width="64" altText={''} />}
          hideBorder
        />
      ) : (
        Object.entries(groupedLogs)
          .sort(([a], [b]) => (new Date(a) > new Date(b) ? -1 : 1))
          .map(([timestamp, changesByType]) => {
            const changeTypes = Object.keys(changesByType);
            const firstChange = changesByType[changeTypes[0]][0];

            const creationType = changesByType['team'];

            return (
              <div key={timestamp}>
                <UpdatePost employee={firstChange.employee} update={{created_at: timestamp}}>
                  <Stack gap="8">
                    {!creationType &&
                      Object.entries(changesByType).map(([type, changes]) => (
                        <Stack key={`${timestamp}-${type}`}>
                          {changes.map(({before, after, date}) => (
                            <Stack key={`${type}-${before}-${after}—${date}`}>
                              {before && (
                                <ChangeLogLine
                                  isEdit={Boolean(before && after)}
                                  change="remove"
                                  type={type}
                                  value={before}
                                />
                              )}
                              {after && (
                                <ChangeLogLine
                                  isEdit={Boolean(before && after)}
                                  change="add"
                                  type={type}
                                  value={after}
                                />
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      ))}
                    {creationType && (
                      <Text>
                        <Trans
                          i18nKey="audit_log_new_team"
                          t={t}
                          components={{b: <Text isBold />}}
                          values={{data: creationType[0].after}}
                        />
                      </Text>
                    )}
                  </Stack>
                </UpdatePost>
              </div>
            );
          })
      )}
    </>
  );
};
