import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowUpLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Thread} from 'client';
import {useThreadService} from 'components/comments/hooks';
import {Avatar} from 'components/DSYS/Avatar';
import styles from 'components/DSYS/editor/plugins/Comment.module.css';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';

import {CommentBox, ThreadBox} from './CommentBox';

export const CommentThread = ({
  thread,
  beforeComment,
  expanded = true,
  onAddComment,
  onResolveClick,
  onValueChange,
  onDeleteComment,
}: {
  thread: Thread | undefined;
  beforeComment?: ReactNode;
  expanded?: boolean;
  onHeaderClick?: () => void;
  onAddComment: (comment: string) => void;
  onResolveClick: () => void;
  onDeleteComment?: () => void;
  onValueChange?: (value: string) => void;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const editorRef = useRef<{clearEditor: () => void}>(null);

  const {updateThread} = useThreadService();
  const [commentEditor, setCommentEditor] = useState<string>('');
  const [autoFocus, setAutoFocus] = useState(true);

  const handleSubmit = useCallback(() => {
    onAddComment(commentEditor);
    setCommentEditor('');
    editorRef.current?.clearEditor();
  }, [commentEditor, onAddComment]);

  const handleResolveThread = async (isResolved: boolean) => {
    if (!thread) return;

    updateThread({
      threadId: thread.id,
      data: {...thread, is_resolved: isResolved, is_deleted: false},
    });

    setSnackbarMessage({text: t('thread_resolved')});

    if (isResolved) {
      onResolveClick();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleSubmit]);

  if (!expanded && thread) {
    return (
      <ThreadBox
        thread={thread}
        onResolveClick={handleResolveThread}
        beforeComment={beforeComment}
      />
    );
  }

  return (
    <Stack>
      {thread && (
        <CommentBox
          thread={thread}
          onDeleteComment={onDeleteComment}
          onResolveClick={handleResolveThread}
        />
      )}
      {!thread?.is_resolved ? (
        <Box
          className={styles.view}
          borderTop={thread?.comments ? 'Solid' : undefined}
          borderColor="Border Subtle"
          boxShadow={autoFocus ? 'Focus Ring' : undefined}
          borderRadius={autoFocus ? 'Medium' : undefined}
        >
          <Avatar size="small" user={employee} />
          <Box flexGrow={1}>
            <RichTextArea
              ref={editorRef}
              hideToolbar
              editable
              links={false}
              mentions
              backgroundHighlight="Background Base"
              borderHighlight={false}
              minHeight={22}
              onToggleFocused={(isFocused) => setAutoFocus(isFocused)}
              placeholder={<Text color="disabled">{thread ? t('reply') : t('add_a_comment')}</Text>}
              theme="small"
              onChange={(value) => {
                onValueChange?.(value?.toString());
                setCommentEditor(value?.toString());
              }}
              autoFocus
            />
          </Box>

          <IconButton
            variant="opacity"
            disabled={!commentEditor}
            // tabIndex={0}
            onMouseDown={(event: any) => event.preventDefault()}
            onClick={handleSubmit}
            className={atoms({flexShrink: 0})}
          >
            <UIIcon src={ArrowUpLine} />
          </IconButton>
        </Box>
      ) : null}
    </Stack>
  );
};
