import '@dropbox/dig-components/dist/index.web.css';
import '@dropbox/dig-fonts/dist/index.min.css';
import '@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css';
import '@dropbox/dig-foundations/dist/index.css';
import '@dropbox/dpc-ia/index.css';
import './App.css';
import './dig-overrides/index.module.css';
import './translations/i18n';

import {Dropbox} from '@dropbox/api-v2-client';
import {Modal} from '@dropbox/dig-components/modal';
import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {ApiError} from 'client';
import {getAPIForLoggedInUser} from 'helpers/api';
import {reportAndLogError} from 'helpers/logging';
import {useAtom, useSetAtom} from 'jotai';
import {tryPrivacyConsentInit} from 'lib/initializePrivacyModule';
import {createContext, useEffect, useState} from 'react';
import {RouterProvider} from 'react-router-dom';
import {PrivacyConsentCategories} from 'types/privacyConsent';
import {
  emailToLdap,
  getAuthService,
  getProfileImageUrl,
  isUserDeactivatedApiError,
} from 'utilities';

import {analyticsLogger} from './analytics/analyticsLogger';
import {
  analyticsStorageAtom,
  isPrivatePageAtom,
  pulseLoginFailedTimestampStorageAtom,
  pulseTokenAtom,
  pulseUserAtom,
} from './atoms/auth';
import {growthbook} from './helpers/growthbook';
import {hashEmail} from './helpers/utils';
import {router} from './routes';

// 100vh and 100vw goes beyond the actual window.innerHeight and
// window.innerWidth values. Instead of 100vh and 100vw, we should
// use var(--full-inner-height) and var(--full-inner-width).
const getFullWindowDimensionsCss = () => {
  return `:root {--full-inner-height:${window.innerHeight}px;--full-inner-width:${window.innerWidth}px}`;
};
const windowDimensionsVariables = document.createElement('style');
windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
window.addEventListener('resize', () => {
  windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
});
document.body.appendChild(windowDimensionsVariables);

export const DropboxClientContext = createContext<Dropbox | null>(null);

const root = document.getElementById('root');
if (root) Modal.setAppElement(root);

const App = () => {
  const [dropboxClient, setDropboxClient] = useState<Dropbox | null>(null);
  const setIsAuthRequired = useSetAtom(isPrivatePageAtom);
  const setPulseToken = useSetAtom(pulseTokenAtom);
  const [pulseUser, setPulseUser] = useAtom(pulseUserAtom);

  const [loginFailedTimestamp, setLoginFailedTimestamp] = useAtom(
    pulseLoginFailedTimestampStorageAtom
  );

  const [analyticsEnabled, setAnalyticsEnabled] = useAtom(analyticsStorageAtom);

  const updateCategoriesCallback = (categories: Partial<PrivacyConsentCategories>) => {
    if (!categories.analytics) {
      setAnalyticsEnabled(false);
      analyticsLogger().disable();
    } else {
      if (!analyticsEnabled) {
        setAnalyticsEnabled(true);
        analyticsLogger().enableLogging();
      } else {
        analyticsLogger().enableLogging();
      }
    }
  };

  useEffect(() => {
    const loadCurUser = async () => {
      let pulseToken = localStorage.getItem('pulse_token');
      let loggedInUser;

      if (pulseToken && pulseToken !== '""') {
        // Only need to fetch the user data
        loggedInUser = await getAuthService().readUserMeApiV1AuthMeGet();
      } else {
        // Fetch both the user data and the token concurrently
        [loggedInUser, pulseToken] = await Promise.all([
          getAuthService().readUserMeApiV1AuthMeGet(),
          getAuthService().getTokenApiV1AuthTokenGet(),
        ]);
        // tempoary fix for security issue. Remove this once token is nullified fom all client
        setPulseToken('true');
      }

      setDropboxClient(await getAPIForLoggedInUser(loggedInUser.token));
      const ldap = emailToLdap(loggedInUser.email);
      setPulseUser({
        ...loggedInUser,
        profile_photo_url: getProfileImageUrl(ldap),
        ldap,
      });
      setLoginFailedTimestamp(null);

      // Lazily import the analytics client to not impact TTVC
      // const {analyticsLogger} = await import('./analytics/analyticsLogger');
      const hashedEmail = await hashEmail(loggedInUser.email);
      analyticsLogger().enable(hashedEmail);
      analyticsLogger().logEvent('LOGIN_SUCCESS', {email: hashedEmail});
    };

    loadCurUser().catch(async (e) => {
      if (e instanceof ApiError) {
        if (e.status === 403) {
          // auth error, kick them out
          setIsAuthRequired(true);
          setPulseToken('', isUserDeactivatedApiError(e));

          // const {analyticsLogger} = await import('./analytics/analyticsLogger');
          if (
            loginFailedTimestamp &&
            new Date().getTime() - loginFailedTimestamp.getTime() > 1000 * 60
          ) {
            analyticsLogger().logEvent('LOGIN_FAILED', {email: 'unknown'});
          } else {
            setLoginFailedTimestamp(new Date());
            analyticsLogger().logEvent('LOGIN_REFRESH', {email: 'unknown'});
          }
          return;
        }
        reportAndLogError(e, 'Error loading current user');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dropboxClient && pulseUser) {
      dropboxClient
        .ccpaGenerateCcpaEmailToken({
          raw_email_address: pulseUser?.email,
          is_account_verified: true,
        })
        .then((response) => {
          if (response.result.token) {
            tryPrivacyConsentInit({
              emailProofToken: response.result.token,
              updateCategoriesCallback: updateCategoriesCallback,
            });
          }
        })
        .catch((e) => {
          console.debug('Error generating ccpa email token', e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropboxClient, pulseUser]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <DropboxClientContext.Provider value={dropboxClient}>
        <RouterProvider router={router} />
      </DropboxClientContext.Provider>
    </GrowthBookProvider>
  );
};

export default App;
