import {TrackerEdit, TrackerFull} from 'client';
import {useLoaderData} from 'react-router-dom';

import {useTrackerEdit} from './hooks';
import {TrackerModify} from './TrackerModify';

export const TrackerEditor = () => {
  const tracker = useLoaderData() as TrackerFull;

  const {editTracker, isPending} = useTrackerEdit();

  return (
    <TrackerModify
      isEditing
      onSubmit={(data) => editTracker({data: data.data as TrackerEdit})}
      isPending={isPending}
      tracker={tracker}
    />
  );
};
