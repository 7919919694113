import {AvatarProps} from '@dropbox/dig-components/dist/avatar';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text, TextProps} from '@dropbox/dig-components/dist/typography';
import {Box, withShade} from '@dropbox/dig-foundations';
import {EmployeeLdap} from 'client';

import {Avatar} from './Avatar';
import {Link} from './Link';

export const EmployeeButton = ({
  employee,
  avatarSize = 'small',
  ...props
}: TextProps & {
  employee: EmployeeLdap;
  avatarSize?: AvatarProps['size'];
}) => (
  <Box
    borderRadius="Medium"
    paddingX="8"
    paddingY="4"
    display="inline-block"
    {...withShade({style: {marginLeft: -8, marginRight: -8}})}
  >
    <Link to={`/people/${employee.ldap}`} hasNoUnderline>
      <LabelGroup
        withLeftAccessory={<Avatar size={avatarSize} user={employee} />}
        withText={<Text {...props}>{employee.name}</Text>}
      />
    </Link>
  </Box>
);
