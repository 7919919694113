import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowRightLine, LockLine, WarningLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {DialLockMini, PuzzlePiecesMini} from '@dropbox/dig-illustrations';
import {EmployeeLdap, Goal, GoalsByStrategy, GoalUser, KeyResult} from 'client';
import {EmployeeButton} from 'components/DSYS/EmployeeButton';
import {EmptyState} from 'components/DSYS/EmptyState';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Table} from 'components/DSYS/Table';
import {MetricBar} from 'components/shared/MetricBar';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {DrawerGoalData} from 'views/goals_v2/types';

import {Filters} from './Filter';
import styles from './Overview.module.css';

const columns = [
  {
    type: 'goal',
    minWidth: 400,
  },
  {
    type: 'update',
    minWidth: 400,
  },
];

const isKeyResult = (data: Goal | KeyResult): data is KeyResult => {
  return (data as KeyResult).goal_id !== undefined;
};

const NameCellContent = ({
  row,
  privacies,
  driEmployee,
}: {
  row: Goal | KeyResult;
  privacies: string | undefined;
  driEmployee: EmployeeLdap;
}) => {
  const isKR = isKeyResult(row);

  const latest2Updates = row.updates?.slice(-2) ?? [];

  // if the status changed between last 2 updates, set changed = true
  const changedStatus =
    latest2Updates.length > 1 && latest2Updates?.[0].status !== latest2Updates?.[1].status;

  return (
    <Box display="flex" style={{gap: 8}} paddingLeft={isKR ? '24' : undefined}>
      <Stack style={{width: isKR ? 36 : 16}}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {isKR ? (
            <>
              <Box paddingY="4">
                <StatusButtonIcon size="small" status={latest2Updates[0]?.status} />
              </Box>
              {latest2Updates.length >= 2 &&
                latest2Updates[0].status !== latest2Updates[1].status && (
                  <>
                    <UIIcon
                      src={ArrowRightLine}
                      size="small"
                      className={atoms({color: 'Text Subtle', flexShrink: 0})}
                      style={{marginLeft: -2, width: 10, height: 10}}
                    />
                    <Box style={{marginLeft: -2}}>
                      <StatusButtonIcon size="small" status={latest2Updates[1]?.status} />
                    </Box>
                  </>
                )}
            </>
          ) : (
            <StatusButtonIcon size="small" status={latest2Updates[1]?.status} />
          )}
        </Box>

        {!isKR && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {changedStatus && !['on_track', 'complete'].includes(latest2Updates[1].status) && (
              <Tooltip
                title={`Status updated from ${t(latest2Updates[0].status)} to ${t(latest2Updates[1].status)}`}
              >
                <Box>
                  <UIIcon
                    src={WarningLine}
                    className={atoms({color: 'Text Subtle'})}
                    size="small"
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
      </Stack>

      <Stack gap="4">
        <Box>
          <Text isBold={!isKR}>{row.title}</Text>
        </Box>
        <Split alignY="center" gap="8">
          <Split.Item>
            <EmployeeButton
              size="small"
              avatarSize="xsmall"
              color="subtle"
              employee={driEmployee}
            />
          </Split.Item>
          {privacies && (
            <>
              <Split.Item>
                <Box
                  borderLeft="Solid"
                  borderColor="Border Subtle"
                  style={{height: 16, width: 1}}
                />
              </Split.Item>
              <Split.Item>
                <Tooltip title={privacies}>
                  <Box>
                    <UIIcon size="small" src={LockLine} className={atoms({color: 'Text Subtle'})} />
                  </Box>
                </Tooltip>
              </Split.Item>
            </>
          )}
        </Split>
      </Stack>
    </Box>
  );
};

const ObjectiveCell = ({
  isSelected,
  privacies,
  driEmployee,
  row,
}: {
  privacies: string | undefined;
  driEmployee: EmployeeLdap;
  row: Goal;
  isSelected?: boolean;
}) => {
  return (
    <Table.Cell
      colSpan={2}
      className={atoms({
        backgroundColor: isSelected ? undefined : 'Background Subtle',
      })}
    >
      <NameCellContent row={row} privacies={privacies} driEmployee={driEmployee} />
    </Table.Cell>
  );
};

const UpdateCell = ({updates}: {updates: Goal['updates']}) => {
  const {comment, updated_at, current_metric, target_metric} = updates?.[updates.length - 1] ?? {};

  if (!updates || updates.length === 0) {
    return <EmptyCell />;
  }

  return (
    <Table.Cell>
      <Box display="flex" flexDirection="row" style={{gap: 2}}>
        <Stack>
          <RichTextArea value={comment} truncateLines={3} />
          <Split gap="8">
            <Split.Item>
              <TimeAgo timestamp={updated_at} />
            </Split.Item>
            {current_metric && target_metric && (
              <>
                <Split.Item>
                  <Box
                    borderLeft="Solid"
                    borderColor="Border Subtle"
                    style={{height: 16, width: 1}}
                    marginTop="2"
                  />
                </Split.Item>
                <Split.Item>
                  <MetricBar currentValue={current_metric} targetValue={target_metric} />
                </Split.Item>
              </>
            )}
          </Split>
        </Stack>
      </Box>
    </Table.Cell>
  );
};

const OKRTableRow = ({
  row,
  owner,
  privacies,
  // columnConfigs,
  onClick,
  selectedGoal,
}: {
  row: Goal | KeyResult;
  owner: GoalUser;
  privacies?: string;
  // columnConfigs: ColumnConfig[];
  onClick: (data: DrawerGoalData) => void;
  selectedGoal: DrawerGoalData | undefined;
}) => {
  const isKR = isKeyResult(row);

  const rowUser = isKR
    ? row.contributors?.length
      ? row.contributors?.[0]
      : {ldap: owner.email.split('@')[0], name: owner.display_name}
    : {ldap: row.users![0].email.split('@')[0], name: row.users![0].display_name};

  if (!isKR) {
    return (
      <>
        <Table.Row
          isSelectable
          isSelected={selectedGoal?.keyResultId ? undefined : selectedGoal?.goalId === row.id}
          onClick={() => onClick({goalId: row.id})}
        >
          <ObjectiveCell
            isSelected={selectedGoal?.keyResultId ? undefined : selectedGoal?.goalId === row.id}
            privacies={privacies}
            row={row}
            driEmployee={rowUser as EmployeeLdap}
          />
        </Table.Row>
        {!isKR &&
          row.key_results.map((kr) => (
            <OKRTableRow
              selectedGoal={selectedGoal}
              key={kr.id}
              row={kr}
              onClick={onClick}
              owner={owner}
            />
          ))}
      </>
    );
  }

  return (
    <>
      <Table.Row
        isSelectable
        isSelected={selectedGoal?.keyResultId === row.id}
        onClick={() => onClick({goalId: row.goal_id, keyResultId: row.id})}
      >
        <Table.Cell>
          <NameCellContent row={row} privacies={privacies} driEmployee={rowUser as EmployeeLdap} />
        </Table.Cell>

        {isKR && <UpdateCell updates={row.updates} />}
      </Table.Row>
    </>
  );
};

const filterTable = (filter: Filters) => (data: Goal) => {
  let show = true;

  if (filter.status.length) {
    if (!filter.status.includes(data.updates?.[data.updates.length - 1]?.status ?? 'no_status')) {
      show = false;
    }

    if (filter.status.includes('no_status') && show) {
      show = true;
    }
  }

  return show;
};

export const StrategiesTable = ({
  data,
  filter,
  setSelectedGoal,
  selectedGoal,
}: {
  data: GoalsByStrategy;
  filter: Filters;
  setSelectedGoal: (data: DrawerGoalData) => void;
  selectedGoal: DrawerGoalData | undefined;
}) => {
  const sortedGoals = data?.goals?.sort((a, b) => {
    return new Date(a.created_at ?? 0).getTime() - new Date(b.created_at ?? 0).getTime();
  });
  const filteredData = sortedGoals?.filter(filterTable(filter));

  return (
    <Box maxWidth="100%" marginX="auto">
      {data.has_all_private_goals ? (
        <EmptyState
          title={t('goals_confidential')}
          body={t('goals_confidential_subtitle')}
          hideBorder
          image={
            <Box className={styles.dialLock}>
              <DialLockMini altText="dial lock" width={64} height={64} />
            </Box>
          }
        />
      ) : !filteredData?.length ? (
        <EmptyState
          title={t('no_others_goals_defined')}
          body={t('no_others_goals_defined_subtitle')}
          image={
            <Box className={styles.puzzlePieces}>
              <PuzzlePiecesMini altText="puzzle pieces" width={64} height={64} />
            </Box>
          }
        />
      ) : (
        <Table columns={columns} data={filteredData}>
          <Table.Header />
          <Table.Body>
            {filteredData.map((row) => {
              const owner = row.users![0];
              const privacies =
                row.is_custom_privacy_included || row.private
                  ? [
                      ...(row.individual_privacies?.map((e) => e.name) ?? []),
                      ...(row.team_privacies?.map((e) => e.name) ?? []),
                      t('owner_reporting_line'),
                    ].join(', ')
                  : undefined;
              return (
                <OKRTableRow
                  key={row.id}
                  selectedGoal={selectedGoal}
                  // expanded={expandedRow}
                  // columnConfigs={columns}
                  owner={owner}
                  privacies={privacies}
                  row={row}
                  onClick={setSelectedGoal}
                  // onClick={(clickedRow) => {
                  //   // if the row is already expanded, close it
                  //   if (
                  //     expandedRow?.keyResultId === clickedRow.keyResultId ||
                  //     expandedRow?.goalId === clickedRow.goalId
                  //   ) {
                  //     setExpandedRow(undefined);
                  //   } else {
                  //     setExpandedRow(clickedRow);
                  //   }
                  // }}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Box>
  );
};
