import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronRightLine} from '@dropbox/dig-icons/assets';
import {useEffect, useState} from 'react';

interface AccordionProps<T> {
  items: T[];
  renderItem: (
    item: T,
    expanded: boolean,
    active: boolean,
    toggle: () => void,
    level: number
  ) => React.ReactNode;
  id?: string;
  level?: number;
  tabSize?: number;
  selectedIndicies?: number[];
  resetOnIndexChange?: boolean;
}

export const Accordion = ({
  id,
  items,
  renderItem,
  level = 0,
  tabSize = 64,
  selectedIndicies = [],
  resetOnIndexChange = false,
}: AccordionProps<any>) => {
  const selectedIndex = selectedIndicies[level];
  const [activeIndexes, setActiveIndexes] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (resetOnIndexChange) {
      setActiveIndexes({});
    }

    setActiveIndexes((prevIndexes) => ({
      ...prevIndexes,
      [selectedIndex]: true,
    }));
  }, [selectedIndex, resetOnIndexChange]);

  const onToggleClick = (index: number) => {
    setActiveIndexes((prevIndexes) => ({
      ...prevIndexes,
      [index]: !prevIndexes[index],
    }));
  };

  const renderedItems = items.map((item: any, index: number) => {
    const active = activeIndexes[index];
    const toggle = () => onToggleClick(index);
    const style = {marginLeft: `${tabSize}px`};

    return (
      <div id={id} style={style} key={item.name || index}>
        {renderItem(item, active, selectedIndex === index, toggle, level)}
      </div>
    );
  });

  return <div>{renderedItems}</div>;
};

export const AccordionIconButton = ({
  isActive,
  isHidden,
  toggle,
  tabIndex,
  size = 'small',
}: {
  isActive: boolean;
  toggle: (e: any) => void;
  isHidden?: boolean;
  tabIndex?: number;
  size?: 'small' | 'medium';
}) => (
  <Box
    as={IconButton}
    tabIndex={tabIndex}
    visibility={!isHidden ? 'visible' : 'hidden'}
    size={size}
    variant="outline"
    shape="circular"
    color={isActive ? 'Text Base' : 'Text Subtle'}
    borderColor={isActive ? 'transparent' : 'Border Subtle'}
    borderWidth={isActive ? '0' : '1'}
    onClick={toggle}
    {...withShade({state: isActive ? 'animated' : undefined})}
  >
    <UIIcon src={isActive ? ChevronDownLine : ChevronRightLine} size={size} />
  </Box>
);
