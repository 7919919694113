import {List} from '@dropbox/dig-components/dist/list';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Box} from '@dropbox/dig-foundations';
import classNames from 'classnames';
import {FunctionComponent, ReactNode} from 'react';

import styles from './Nav.module.css';

// TODO replace the anys with a generic type
export interface ManagePageNavProps {
  items?: any;
  selected?: any;
  onSelect: (item: any) => void;
  renderItem: (item: any) => ReactNode;
  nameKey?: string;
}

export const ManagePageNav: FunctionComponent<ManagePageNavProps> = ({
  items,
  renderItem,
  selected,
  onSelect,
  nameKey = 'name',
}) => {
  return (
    <>
      <div className={styles.selectNav}>
        <Select
          id="badge-select"
          aria-label="DO_NOT_COPY-Placeholder example"
          value={selected?.id ? selected.id.toString() : undefined}
          onChange={(e) => {
            if (items) {
              const found = items.find((b: any) => b.id === parseInt(e));
              if (found && onSelect) {
                onSelect(found);
              }
            }
          }}
        >
          {(items || []).map((item: any) => (
            <Select.Option key={item.id} value={`${item.id}`}>
              {item[nameKey]}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.container}>
        <List isSelectable verticalAlign="top" className={styles.list}>
          {!items ? (
            <>
              <List.Item>
                <Skeleton.Box />
              </List.Item>
              <List.Item>
                <Skeleton.Box />
              </List.Item>
              <List.Item>
                <Skeleton.Box />
              </List.Item>
            </>
          ) : (
            items.map((item: any) => (
              <Item
                key={item.id}
                active={selected?.id === item.id}
                onClick={() => {
                  onSelect(item);
                  document.body.scrollTo(0, 0);
                }}
              >
                <>{renderItem(item)}</>
              </Item>
            ))
          )}
        </List>
      </div>
    </>
  );
};

interface ItemProps {
  children: ReactNode;
  active?: boolean;
  onClick?: () => void;
  className?: string;
}

const Item: FunctionComponent<ItemProps> = ({children, active, onClick, className}) => {
  return (
    <Box
      as={List.Item}
      borderRadius="Medium"
      onClick={onClick}
      className={classNames(className, {
        [styles.selected]: active,
      })}
    >
      {children}
    </Box>
  );
};
