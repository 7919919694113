import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {is2025Strategy} from 'atoms/strategies';
import {EditGoalContent} from 'components/goals/edit/EditGoalContent';
import {NewGoalHeader} from 'components/goals/edit/NewGoalHeader';
import {useGoal, useIsWritingWindowOpen} from 'components/goals/hooks';
import {DEFAULT_TIMEFRAME} from 'constant';
import {isUserAdmin} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const EditGoalView = ({goalId}: {goalId: number}) => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const windowIsOpen = useIsWritingWindowOpen();
  const goal = useGoal({goalId});
  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const navigate = useNavigate();

  const canEdit =
    goal.users![0]?.email === pulseUser?.email ||
    delegatedBy?.some(({email}) => email === goal.users![0]?.email);

  const assignedDelegateId = delegatedBy?.find(
    ({email}) => email === goal?.users![0].email
  )?.user_id;

  const [delegateId, setDelegateId] = useState<string | undefined>(assignedDelegateId);

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const parentDrawerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (drawerRef.current) {
      parentDrawerRef.current = drawerRef.current.parentElement as HTMLDivElement;
    }
  }, []);

  if (!pulseUser) {
    return null;
  }

  if (!canEdit) {
    return <NotFound />;
  }

  if (windowIsOpen === undefined) {
    return null;
  }

  const isCompanyGoal = goal.strategy_ids?.split(',').some(is2025Strategy);
  if (isCompanyGoal && !isUserAdmin(pulseUser.email)) {
    if (!windowIsOpen || (windowIsOpen && goal.timeframe !== DEFAULT_TIMEFRAME)) {
      navigate(`/goals/${goalId}?requestedit=1`);
      return null;
    }
  }

  return (
    <>
      <NewGoalHeader
        isEditing={true}
        delegateId={assignedDelegateId}
        setDelegateId={setDelegateId}
        goalCreated={false}
      />
      <EditGoalContent delegateId={delegateId} goalToEdit={goal} ref={parentDrawerRef} />
      <div ref={drawerRef} />
    </>
  );
};

export {EditGoalView};
