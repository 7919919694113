import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {EmployeeRoleLevel, WorkstreamEmployee} from 'client';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';

import {useTracker, useTrackers, useWorkstreamReports} from './hooks';
import {TrackerCard} from './TrackerCard';

export const TrackerGrid = ({
  owners,
  isSelectable,
  selected,
  setSelected,
}: {
  owners?: WorkstreamEmployee[];
  isSelectable?: boolean;
  selected?: number[];
  setSelected?: (trackers: number[]) => void;
}) => {
  const {employee, directReports} = useAtomValue(loggedInEmployeeAtom);
  const {data: trackers} = useTrackers();
  const {data} = useTracker(employee.ldap);
  const {data: myReports} = useWorkstreamReports();

  return (
    <Stack>
      <Box
        className={atoms({marginTop: '12'})}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(285px, 1fr))',
          gap: 12,
        }}
      >
        {owners ? (
          owners.map((dri) => (
            <EmployeeTrackerCard
              key={`${dri}-${dri.employee.user_id}`}
              employee={dri.employee}
              showUpdateButton={!isSelectable}
              isSelectable={isSelectable}
            />
          ))
        ) : (
          <TrackerCard
            name={employee?.ldap ? employee.name : <Skeleton.Box width={160} height={20} />}
            id={employee.ldap}
            owner={employee}
            workstreams={data?.workstreams.length ?? 0}
            showUpdateButton={!isSelectable}
            showAvatar
            isSelectable={isSelectable}
            selected
          />
        )}
        {!isSelectable && directReports.length >= 1 && (
          <TrackerCard
            name={t('direct_reports')}
            id={`${employee.ldap}/reports`}
            owner={employee}
            workstreams={myReports?.length ?? 0}
          />
        )}

        {trackers?.map((tracker) => (
          <TrackerCard
            key={tracker.id}
            name={tracker.name}
            id={tracker.id}
            owner={tracker.owner}
            workstreams={tracker.workstreams.length}
            isSelectable={isSelectable}
            selected={selected?.some((e) => e === tracker.id)}
            onSelect={(id) => {
              if (selected?.some((e) => e === id)) {
                setSelected?.(selected.filter((e) => e !== id));
              } else {
                setSelected?.([...(selected ?? []), id]);
              }
            }}
          />
        ))}
      </Box>
    </Stack>
  );
};

const EmployeeTrackerCard = ({
  employee,
  isSelectable,
  showUpdateButton,
}: {
  employee: EmployeeRoleLevel;
  isSelectable?: boolean;
  showUpdateButton?: boolean;
}) => {
  const {data} = useTracker(employee.ldap);

  return (
    <TrackerCard
      name={employee.name}
      id={employee.ldap}
      owner={employee}
      showAvatar
      showUpdateButton={showUpdateButton}
      workstreams={data?.workstreams.length ?? 0}
      isSelectable={isSelectable}
      selected
    />
  );
};
