import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {FormLabel} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, ChevronRightLine, EditLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {GoalCreate, GoalUpdate, ProfilePage} from 'client';
import {StrategyConnectSearch} from 'components/goals/edit/StrategyConnectSearch';
import {CustomPrivacySubtext} from 'components/goals/privacy/GoalPrivacyMenu';
import {countUniqueEmployee} from 'components/goals/privacy/utils';
import {isUserAdmin} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DrawerHeader} from 'views/goals_v2/Drawers/DrawerHeader';

import {DrawerOverlay} from './DrawerOverlay';

const EditGoalDetailsDrawerHeader = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation();
  return (
    <DrawerHeader
      headerTitle={t('edit_details')}
      headerIcon={<Box as={UIIcon} src={EditLine} style={{marginLeft: '-3px'}} />}
      onClose={onClose}
    />
  );
};

const extractQuarter = (timeframe: string) => {
  const [quarter, year] = timeframe.split(' ');
  return {quarter, year};
};

const generateYearOptions = (year: string) => {
  const yearNumber = parseInt(year.slice(2));
  return [year, `FY${yearNumber + 1}`];
};

const formatYear = (year: string) => {
  if (year.startsWith('FY')) {
    return `20${year.slice(2)}`;
  }
  return year;
};

const quarterOptions = ['Q1', 'Q2', 'Q3', 'Q4'];

export const EditGoalDetailsDrawer = ({
  isOpen,
  onClose,
  goalCreateValues,
  handleTimeframeSelection,
  handleVisibilitySelection,
  handleStrategiesSelected,
  delegateProfilePage,
}: {
  isOpen: boolean;
  onClose: () => void;
  goalCreateValues?: GoalCreate | GoalUpdate;
  handleTimeframeSelection: (value: string) => void;
  handleVisibilitySelection: (value: number) => void;
  handleStrategiesSelected: (strategies: string) => void;
  delegateProfilePage?: ProfilePage;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const {quarter, year} = extractQuarter(goalCreateValues?.timeframe ?? '');
  const [yearOptions] = useState(generateYearOptions(year));
  const [selectedQuarter, setSelectedQuarter] = useState(quarter);
  const [selectedYear, setSelectedYear] = useState(year);
  const [showStrategies, setShowStrategies] = useState(Boolean(goalCreateValues?.strategy_ids));
  // const is2025OrLater = formatYear(year) >= '2025';

  const {t} = useTranslation();

  const handleQuaterSelection = (value: string) => {
    setSelectedQuarter(value);
    handleTimeframeSelection(`${value} ${selectedYear}`);
  };

  const handleYearSelection = (value: string) => {
    setSelectedYear(value);
    handleTimeframeSelection(`${selectedQuarter} ${value}`);
  };

  return (
    <DrawerOverlay
      isOpen={isOpen}
      header={<EditGoalDetailsDrawerHeader onClose={onClose} />}
      body={
        <Stack gap="24" paddingTop="8">
          {(isUserAdmin(employee.email) || goalCreateValues?.strategy_ids) && (
            <>
              <Split
                alignY="top"
                className={atoms({
                  paddingTop: '8',
                  paddingBottom: '24',
                  borderBottom: 'Solid',
                  borderWidth: '1',
                  borderColor: 'Border Subtle',
                })}
              >
                <Split.Item width="fill">
                  <LabelGroup
                    withText={<Text isBold>{t('company_goal')}</Text>}
                    withSubtext={t('company_goal_subtext')}
                  />
                </Split.Item>
                <Split.Item width="hug">
                  <Toggle
                    isToggled={showStrategies}
                    onChange={() => {
                      handleStrategiesSelected('');
                      setShowStrategies(!showStrategies);
                    }}
                  />
                </Split.Item>
              </Split>
              {showStrategies && (
                <StrategyConnectSearch
                  title={t('strategy')}
                  initialStrategyIds={goalCreateValues?.strategy_ids}
                  handleStrategiesSelected={handleStrategiesSelected}
                />
              )}
            </>
          )}
          <Stack gap="8">
            <FormLabel>{t('time_period')}</FormLabel>
            <Menu.Wrapper
              closeOnSelection={false}
              onSelection={handleQuaterSelection}
              style={{width: '100%', display: 'flex'}}
            >
              {() => (
                <>
                  <Box as="div" display="flex" style={{gap: '8px', height: '42px'}}>
                    {quarterOptions.map((option) => (
                      <Menu.ActionItem
                        key={option}
                        value={option}
                        role="menuitemcheckbox"
                        aria-checked={selectedQuarter.indexOf(option) !== -1}
                        style={{
                          border:
                            selectedQuarter.indexOf(option) !== -1
                              ? ''
                              : '1px solid var(--dig-color__border__subtle)',
                          borderRadius: '8px',
                          width: '100%',
                          backgroundColor:
                            selectedQuarter.indexOf(option) !== -1
                              ? 'var(--dig-color__opacity__surface)'
                              : '',
                        }}
                      >
                        <Box as="div" display="flex" justifyContent="center">
                          <Text isBold={selectedQuarter.indexOf(option) !== -1}>{option}</Text>
                        </Box>
                      </Menu.ActionItem>
                    ))}
                  </Box>
                </>
              )}
            </Menu.Wrapper>
            <Menu.Wrapper
              closeOnSelection={false}
              onSelection={handleYearSelection}
              style={{width: '100%', display: 'flex'}}
            >
              {() => (
                <>
                  <Box as="div" display="flex" style={{gap: '8px', height: '34px'}}>
                    {yearOptions.map((option) => (
                      <Menu.ActionItem
                        key={option}
                        value={option}
                        role="menuitemcheckbox"
                        aria-checked={selectedYear.indexOf(option) !== -1}
                        style={{
                          border:
                            selectedYear.indexOf(option) !== -1
                              ? ''
                              : '1px solid var(--dig-color__border__subtle)',
                          borderRadius: '8px',
                          width: '100%',
                          display: 'flex',
                          backgroundColor:
                            selectedYear.indexOf(option) !== -1
                              ? 'var(--dig-color__opacity__surface)'
                              : '',
                        }}
                      >
                        <Box as="div" display="flex" justifyContent="center">
                          <Text isBold={selectedYear.indexOf(option) !== -1}>
                            {formatYear(option)}
                          </Text>
                        </Box>
                      </Menu.ActionItem>
                    ))}
                  </Box>
                </>
              )}
            </Menu.Wrapper>
          </Stack>
          <PrivacyMenu
            goalCreateValues={goalCreateValues}
            handleVisibilitySelection={handleVisibilitySelection}
            delegateProfilePage={delegateProfilePage}
          />
        </Stack>
      }
    />
  );
};

const getInitialPrivacyValue = (goalCreateValues?: GoalCreate | GoalUpdate) => {
  if (goalCreateValues?.is_custom_privacy_included) {
    return 2;
  }
  if (goalCreateValues?.private) {
    return 1;
  }
  return 0;
};

const PrivacyMenu = ({
  goalCreateValues,
  handleVisibilitySelection,
  delegateProfilePage,
}: {
  goalCreateValues?: GoalCreate | GoalUpdate;
  handleVisibilitySelection: (value: number) => void;
  delegateProfilePage?: ProfilePage;
}) => {
  const {t} = useTranslation();
  const initialSelectedPrivacy = getInitialPrivacyValue(goalCreateValues);
  const [selectedPrivacy, setSelectedPrivacy] = useState(initialSelectedPrivacy);

  const handleSelection = (value: number) => {
    setSelectedPrivacy(value);
    handleVisibilitySelection(value);
  };

  const {reportingLine, delegates} = useAtomValue(loggedInEmployeeAtom);

  const teamCount = useMemo(
    () => goalCreateValues?.team_privacies?.length ?? 0,
    [goalCreateValues?.team_privacies?.length]
  );

  const employeeCount = useMemo(
    () =>
      countUniqueEmployee(
        goalCreateValues?.individual_privacies || [],
        delegateProfilePage ? delegateProfilePage.reportingLine : reportingLine,
        delegateProfilePage ? delegateProfilePage.delegates || [] : delegates || []
      ),
    [delegateProfilePage, delegates, goalCreateValues?.individual_privacies, reportingLine]
  );

  return (
    <Menu.Wrapper
      closeOnSelection={false}
      onSelection={handleSelection}
      style={{width: '100%', display: 'flex'}}
    >
      {() => (
        <>
          <Stack gap="8">
            <FormLabel
              subtext={<Toggletip title={t('choose_who_may_view_and_align_to_this_goal')} />}
            >
              {t('privacy')}
            </FormLabel>
            <Menu.ActionItem
              key={0}
              value={0}
              withTitle={
                <Text isBold tagName="label">
                  {t('all_dropboxers')}
                </Text>
              }
              withSubtitle={t('all_dropboxers_subtitle')}
              withRightAccessory={
                selectedPrivacy === 0 ? (
                  <Box as={UIIcon} src={CheckmarkLine} color="Border Base" />
                ) : (
                  <></>
                )
              }
              style={{
                padding: '12px 8px',
                borderRadius: '8px',
                border:
                  selectedPrivacy !== 0
                    ? '1px solid var(--dig-color__border__subtle)'
                    : '1px solid transparent',
                backgroundColor:
                  selectedPrivacy === 0 ? 'var(--dig-color__opacity__surface)' : 'transparent',
              }}
            ></Menu.ActionItem>
            <Menu.ActionItem
              key={1}
              value={1}
              withTitle={
                <Text isBold tagName="label">
                  {t('reporting_line')}
                </Text>
              }
              withSubtitle={t('restricted_subtitle')}
              withRightAccessory={
                selectedPrivacy === 1 ? (
                  <Box as={UIIcon} src={CheckmarkLine} color="Border Base" />
                ) : (
                  <></>
                )
              }
              style={{
                padding: '12px 8px',
                borderRadius: '8px',
                border:
                  selectedPrivacy !== 1
                    ? '1px solid var(--dig-color__border__subtle)'
                    : '1px solid transparent',
                backgroundColor:
                  selectedPrivacy === 1 ? 'var(--dig-color__opacity__surface)' : 'transparent',
              }}
            ></Menu.ActionItem>
            <Menu.ActionItem
              key={2}
              value={2}
              withTitle={
                <>
                  <Text isBold tagName="label">
                    {t('custom')}
                  </Text>
                </>
              }
              withSubtitle={
                goalCreateValues?.is_custom_privacy_included ? (
                  <CustomPrivacySubtext employeeCount={employeeCount} teamCount={teamCount} />
                ) : (
                  t('select_teams_or_people_who_may_view_align_towards_this_goal')
                )
              }
              withRightAccessory={<Box as={UIIcon} src={ChevronRightLine} color="Border Base" />}
              style={{
                padding: '12px 8px',
                borderRadius: '8px',
                border:
                  selectedPrivacy !== 2
                    ? '1px solid var(--dig-color__border__subtle)'
                    : '1px solid transparent',
                backgroundColor:
                  selectedPrivacy === 2 ? 'var(--dig-color__opacity__surface)' : 'transparent',
              }}
            ></Menu.ActionItem>
          </Stack>
        </>
      )}
    </Menu.Wrapper>
  );
};
