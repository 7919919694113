import {STEAM_TEAM_SLUG} from 'constant';

export const currentRoute = () => window.location.pathname.replace(/(.*)\/$/, '$1');

export const isGoalRoute = () => /goals\/[\d]+$/.test(window.location.pathname);
export const isProfileRoute = () => /\/people\/[\w]+$/.test(window.location.pathname);
export const isTeamRoute = () => /\/teams\/[-\w]+$/.test(window.location.pathname);
export const isProjectIDRoute = () => /\/projects\/[\d]+$/.test(window.location.pathname);
export const isProjectTeamRoute = () => /\/projects\/[-\w]+$/.test(window.location.pathname);

export const getGoalsViewType = () => {
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.get('reports') === '1') {
    return 'reports';
  }
  const teamSlugsFilter = urlParams.get('teams')?.split(',') || [];
  if (teamSlugsFilter.length === 1 && teamSlugsFilter[0] === STEAM_TEAM_SLUG) {
    return 'company';
  }
  if (teamSlugsFilter.length && !urlParams.get('people')) {
    return 'team';
  }
  return 'employee';
};
