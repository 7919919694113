import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {snackbarAtom} from 'atoms/snackbar';
import {Workstream} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {getStatusOptions, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {WorkstreamLine} from 'components/layout/WorkstreamLine';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';

import {useWorkstreamStatus} from './hooks';
import styles from './WorkstreamUpdate.module.css';
import {WorkstreamUpdateTabs} from './WorkstreamUpdateTabs';

const WorkstreamUpdateContent = ({
  workstream,
  onAfterSubmit,
}: {
  workstream: Workstream;
  onAfterSubmit: () => void;
}) => {
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const editorRef = useRef<{clearEditor: () => void}>(null);

  const {createUpdate} = useWorkstreamStatus();
  const [editorValue, setEditorValue] = useState<EditorState>();
  const [status, setStatus] = useState(workstream.updates?.[0]?.status ?? 'no_status');
  const [rawText, setRawText] = useState<string>();
  const options = getStatusOptions();

  useEffect(() => {
    setStatus(workstream.updates?.[0]?.status ?? 'no_status');
    editorRef.current?.clearEditor();
  }, [workstream]);

  const previousUpdate = workstream.updates?.[0];

  const handleSubmit = async () => {
    await createUpdate({
      ownerId:
        workstream.employee_associations.find(({dri}) => dri === 'dri')?.employee.user_id ?? '',
      update: {
        comment: editorValue?.toString() ?? '',
        status,
        workstream_id: workstream.id,
        created_at: new Date().toISOString(),
      },
    });

    onAfterSubmit();

    setSnackbarMessage({
      text: (
        <Trans
          i18nKey="workstream_updated"
          t={t}
          values={{name: workstream.name}}
          components={{b: <Text isBold />}}
        />
      ),
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row" borderWidth="1" borderColor="Border Subtle">
        <Box flexGrow={1} flexShrink={1} flexBasis={1} padding="24">
          <FormRow>
            <FormLabel htmlFor="name">{t('status')}</FormLabel>
            <Select
              id="status"
              defaultValue={previousUpdate ? previousUpdate.status : 'no_status'}
              onChange={(val) => setStatus(val)}
              value={status}
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
              }}
            >
              {options
                .filter((statusOption) => statusOption.label !== 'draft')
                .map((statusOption) => (
                  <Select.OptGroup key={statusOption.label} withLabel={t(statusOption.label)}>
                    {statusOption.statusOptions.map(({value, icon, color}) => (
                      <Select.Option
                        className={styles.statusOptionOverride}
                        key={value}
                        value={value}
                        withAccessory={
                          <UIIcon
                            src={icon}
                            color={color}
                            size="medium"
                            style={{height: '24px', width: '24px'}}
                          />
                        }
                      >
                        {t(value).toString()}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              <Select.Option
                value={previousUpdate ? previousUpdate.status : 'no_status'}
                withAccessory={<StatusButtonIcon status={previousUpdate?.status} />}
                hidden
              >
                {previousUpdate ? t(previousUpdate.status).toString() : t('no_status').toString()}
              </Select.Option>
            </Select>
          </FormRow>
          <FormRow>
            <FormLabel htmlFor="name">{t('update')}</FormLabel>
            <RichTextArea
              ref={editorRef}
              editable
              source="workstream-status-update"
              placeholder={t('start_writing_placeholder')}
              value={workstream.updates?.[0]?.comment}
              selectAll
              onChange={(editor, text) => {
                setEditorValue(editor);
                setRawText(text);
              }}
            />
          </FormRow>
        </Box>
        <Box
          paddingX="24"
          paddingTop="8"
          borderLeft="Solid"
          borderWidth="1"
          borderColor="Border Subtle"
          style={{width: 542}}
        >
          <WorkstreamUpdateTabs workstream={workstream} content={rawText} />
        </Box>
      </Box>
      <Box
        borderTop="Solid"
        borderColor="Border Subtle"
        padding="24"
        display="flex"
        justifyContent="flex-end"
        style={{gap: 8}}
      >
        <Button variant="opacity">{t('cancel')}</Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={status === 'no_status' || !workstream.end_date || !editorValue?.toString()}
        >
          {t('update')}
        </Button>
      </Box>
    </>
  );
};

export const WorkstreamUpdate = ({
  workstream,
  onAfterSubmit,
}: {
  workstream: Workstream;
  onAfterSubmit: () => void;
}) => {
  return (
    <Box
      boxShadow="Floating"
      borderRadius="Medium"
      borderStyle="Solid"
      borderWidth="1"
      borderColor="Border Subtle"
    >
      <Stack>
        <Box
          display="flex"
          flexDirection="row"
          padding="16"
          style={{gap: 16}}
          borderBottom="Solid"
          borderColor="Border Subtle"
        >
          <Box backgroundColor="Primary Base" borderRadius="Small" style={{width: 4, height: 58}} />
          <Stack gap="6">
            <LabelGroup
              align="center"
              withLeftAccessory={
                <UIIcon src={WorkstreamLine} className={atoms({color: 'Border Base'})} />
              }
              withText={<Eyebrow>{t('workstream')}</Eyebrow>}
            />
            <Title size={18}>{workstream.name}</Title>
          </Stack>
        </Box>
        <WorkstreamUpdateContent workstream={workstream} onAfterSubmit={onAfterSubmit} />
      </Stack>
    </Box>
  );
};
