import {Button} from '@dropbox/dig-components/dist/buttons';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Box} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {snackbarAtom} from 'atoms/snackbar';
import {Card} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {useCardsSuspense} from 'components/gratitiude/hooks';
import {ImageComponent} from 'components/gratitiude/ImageComponent';
import {ManagePageNav} from 'components/shared/ManagePage/Nav';
import {SelectedItem} from 'components/shared/ManagePage/SelectedItem';
import {Title} from 'components/shared/ManagePage/Title';
import {ROUTE_PATHS} from 'constant';
import {isContentAdmin} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {FunctionComponent, Suspense, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getCardsService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const CardManagementView = () => {
  const [addCard, setAddCard] = useState(false);
  const navigate = useNavigate();
  const pulseUser = useAtomValue(pulseUserAtom);

  if (!pulseUser) {
    return null;
  }

  if (!isContentAdmin(pulseUser)) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Layout.Container
        breadcrumb={[
          {children: 'Admin', to: ROUTE_PATHS.ADMIN},
          {children: 'Gratitude Cards', to: ROUTE_PATHS.ADMIN_CARD_MANAGEMENT},
        ]}
      >
        <Title text={t('manage_cards')} addButtonOnClick={() => setAddCard(true)} />
        <Suspense fallback={null}>
          <Body />
        </Suspense>
      </Layout.Container>
      <Modal
        isCentered
        open={addCard}
        onRequestClose={() => setAddCard(false)}
        withCloseButton="edit-close"
      >
        <Modal.Header>
          <Modal.Title>{t('new_card')}</Modal.Title>
        </Modal.Header>
        <AddCardForm onClose={() => setAddCard(false)} />
      </Modal>
    </>
  );
};

const Body = () => {
  const cards = useCardsSuspense();
  const [selected, setSelected] = useState<Card | undefined>();
  useEffect(() => {
    if (cards && cards.length > 0) {
      setSelected(cards[0]);
    }
  }, [cards]);
  return (
    <Box display="flex" flexDirection="row" style={{gap: 16}}>
      <ManagePageNav
        items={cards.sort((a, b) => (a.id < b.id ? -1 : 1))}
        onSelect={(item: Card) => setSelected(item)}
        selected={selected}
        renderItem={(item: Card) => (
          <div>
            <ImageComponent size="small" card={item} emphasis={false} />
          </div>
        )}
        nameKey="image_key"
      />

      <SelectedCard card={selected} />
    </Box>
  );
};

interface SelectedCardProps {
  card?: Card;
}

const SelectedCard: FunctionComponent<SelectedCardProps> = ({card}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hidden, setHidden] = useState(card?.hidden_from_library);

  const setSnackbarMessage = useSetAtom(snackbarAtom);

  useEffect(() => {
    if (card) {
      setHidden(card.hidden_from_library);
    }
  }, [card, setHidden]);

  if (!card) {
    return <Skeleton.Box />;
  }

  return (
    <SelectedItem title={card.image_key}>
      <FormRow>
        <FormLabel htmlFor="image">{t('card_image')}</FormLabel>
        <FormHelperText>{t('card_image_helper')}</FormHelperText>
        <input
          name="image"
          type="file"
          onChange={(event) => {
            if (event.target.files) {
              setSelectedFile(event.target.files[0]);
            }
          }}
        />
      </FormRow>
      <FormRow>
        <FormLabel>{t('hide_card')}</FormLabel>
        <Checkbox
          aria-labelledby="hidden_from_library"
          onChange={(e) => setHidden(e.target.checked)}
          checked={hidden}
        />
      </FormRow>
      <FormRow variant="button" isFooter>
        <Button
          variant="primary"
          onClick={() => {
            getCardsService()
              .updateCardApiV1CardsCardIdPost(card?.id, {
                hidden_from_library: hidden,
                image_key: card.image_key,
                landscape: card.landscape,
              })
              .then(() => {
                if (selectedFile) {
                  return getCardsService().updateCardImageApiV1CardsCardIdImagePost(card?.id, {
                    file: selectedFile,
                  });
                }
              })
              .then(() => {
                setSnackbarMessage({text: t('saved')});
                queryClient.invalidateQueries({queryKey: ['cards']});
              });
          }}
        >
          {t('save')}
        </Button>
      </FormRow>
    </SelectedItem>
  );
};

interface AddCardFormProps {
  onClose: () => void;
}
const AddCardForm: FunctionComponent<AddCardFormProps> = ({onClose}) => {
  const [hidden, setHidden] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  return (
    <>
      <Modal.Body>
        <FormRow>
          <FormLabel htmlFor="image">{t('card_image')}</FormLabel>
          <FormHelperText>{t('card_image_helper')}</FormHelperText>
          <input
            name="image"
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                setSelectedFile(event.target.files[0]);
              }
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel>{t('hide_card')}</FormLabel>
          <Checkbox
            aria-labelledby="hidden_from_library"
            onChange={(e) => setHidden(e.target.checked)}
            checked={hidden}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="opacity"
          onClick={() => {
            onClose();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedFile) {
              getCardsService()
                .addCardApiV1CardsPost({
                  card_data: JSON.stringify({hidden_from_library: hidden}),
                  file: selectedFile,
                })
                .then(() => {
                  queryClient.invalidateQueries({queryKey: ['cards']});
                  setSnackbarMessage({text: t('saved')});
                  onClose();
                });
            }
          }}
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </>
  );
};
