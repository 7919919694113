import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {GoalService, Thread, ThreadService} from 'client';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {toLocaleString} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';
import {getThreads} from 'views/goals_v2/Drawers/utils';

export const GoalCommentAdded = () => {
  const {
    notification: {archived, updated_at, data},
  } = useNotificationContext();
  const {content, to, cta, data: jsonData} = data;
  const {goal_id: goalId, goal_title: goalTitle} = JSON.parse(jsonData);

  const {data: threads} = useQuery<Thread[]>({
    queryKey: ['threads', 'goal', goalId],
    queryFn: () => getService(ThreadService).getThreadsForGoalApiV1ThreadGoalGoalIdGet(goalId),
    refetchOnMount: true,
  });
  const {data: goal} = useQuery({
    queryKey: ['goal', goalId],
    queryFn: () => getService(GoalService).readGoalByIdApiV1GoalsGoalIdGet(goalId),
  });

  const total_thread_count = getThreads(goalId, threads ?? [], undefined).length;
  const resolved_thread_count = threads?.filter((thread) => thread.is_resolved).length ?? 0;

  return (
    <NotificationCard
      accessory={<Box as={UIIcon} src={TargetLine} color="Text Subtle" />}
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{data: goal?.title ?? goalTitle}}
          components={{
            b: <Text isBold />,
          }}
        />
      }
      truncate
      subtext={
        archived
          ? t('goal_comments_published', {
              date: toLocaleString(updated_at),
            })
          : total_thread_count > 1
            ? `${resolved_thread_count} / ${total_thread_count} ${t('comments_resolved')}`
            : `${resolved_thread_count} / ${total_thread_count} ${t('comment_resolved')}`
      }
      buttonLink={{cta, to}}
      archived={archived}
    />
  );
};

export const KeyResultCommentAdded = () => {
  const {
    notification: {archived, updated_at, data},
  } = useNotificationContext();
  const {content, to, cta, data: jsonData} = data;
  const {
    goal_id: goalId,
    key_result_title: keyResultTitle,
    key_result_id: keyResultId,
  } = JSON.parse(jsonData);

  const {data: threads} = useQuery<Thread[]>({
    queryKey: ['threads', 'goal', goalId],
    queryFn: () => getService(ThreadService).getThreadsForGoalApiV1ThreadGoalGoalIdGet(goalId),
    refetchOnMount: true,
  });
  const {data: goal} = useQuery({
    queryKey: ['goal', goalId],
    queryFn: () => getService(GoalService).readGoalByIdApiV1GoalsGoalIdGet(goalId),
  });

  const keyResult = goal?.key_results?.find((kr) => kr.id === keyResultId);

  const total_thread_count = getThreads(goalId, threads ?? [], keyResultId).length;
  const resolved_thread_count = threads?.filter((thread) => thread.is_resolved).length ?? 0;

  return (
    <NotificationCard
      accessory={<Box as={UIIcon} src={TargetLine} color="Text Subtle" />}
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{data: keyResult?.title ?? keyResultTitle}}
          components={{
            b: <Text isBold />,
          }}
        />
      }
      truncate
      subtext={
        archived
          ? t('goal_comments_published', {
              date: toLocaleString(updated_at),
            })
          : total_thread_count > 1
            ? `${resolved_thread_count} / ${total_thread_count} ${t('comments_resolved')}`
            : `${resolved_thread_count} / ${total_thread_count} ${t('comment_resolved')}`
      }
      buttonLink={{cta, to}}
      archived={archived}
    />
  );
};
