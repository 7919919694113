import {BACKSPACE_KEY} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {Workstream} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {WorkstreamLine} from 'components/layout/WorkstreamLine';
import {t} from 'i18next';
import {useEffect, useMemo, useRef, useState} from 'react';

import {useWorkstreams} from './hooks';

export const WorkstreamTypeahead = ({
  autoFocus,
  selectedIds,
  onSelect,
}: {
  selectedIds: number[];
  onSelect: (workstream: Workstream) => void;
  autoFocus?: boolean;
}) => {
  const {data: allWorkstreams, isLoading} = useWorkstreams();
  const workstreamOptions = useMemo(
    () => allWorkstreams?.filter((team) => !selectedIds.includes(team.id)),
    [allWorkstreams, selectedIds]
  );

  const [filteredOptions, setFilteredOptions] = useState<Workstream[]>();

  useEffect(() => {
    if (!isLoading && workstreamOptions?.length) {
      setFilteredOptions(workstreamOptions);
    }
  }, [isLoading, workstreamOptions]);

  const inputContainerRef = useRef<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleSelection = (value: number) => {
    const workstream = workstreamOptions?.find((team) => team.id === value);
    if (workstream) {
      onSelect(workstream);
    }
  };

  const renderTypeaheadRow = (workstream: Workstream) => {
    const owner = workstream.employee_associations.find((ea) => ea.dri === 'dri')?.employee;

    return (
      <Typeahead.Row
        key={workstream.id}
        value={workstream.id}
        withLeftAccessory={<UIIcon src={WorkstreamLine} />}
        withRightAccessory={owner && <Avatar user={owner} size="small" />}
        withSubtitle={owner?.name}
        // ${workstream.end_date ? ` • ${format(new Date(workstream.end_date + 'Z'), 'MMM d')} completion` : ''}`}
        withTitle={workstream.name}
        role="menuitemcheckbox"
        aria-label={`Option ${workstream.name}`}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    if (!e.currentTarget.value) {
      setFilteredOptions(workstreamOptions);
    } else {
      setFilteredOptions(
        workstreamOptions?.filter((workstream) =>
          workstream.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
        )
      );
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
    if (workstreamOptions?.[0] && e.key === BACKSPACE_KEY && inputValue === '') {
      onSelect(workstreamOptions[0]);
    }
  };

  return (
    <Typeahead.Wrapper isPortaled={false} onSelection={handleSelection} closeOnSelection={false}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput.Container ref={inputContainerRef} size="large">
            <TextInput.Accessory>
              <UIIcon src={SearchLine} />
            </TextInput.Accessory>
            <TextInput.Input
              autoFocus={autoFocus}
              placeholder={t('select_workstream')}
              value={inputValue}
              {...getTriggerProps({
                onChange: onInputChange,
                onKeyDown: handleKeyDown,
              })}
            />
          </TextInput.Container>
          <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
            {filteredOptions?.length ? (
              <Typeahead.Results
                results={filteredOptions}
                renderRow={renderTypeaheadRow}
                initialResults={500}
              />
            ) : inputValue.length ? (
              <Typeahead.Results
                // title={t('teams')}
                results={[{}]}
                renderRow={renderNoResultsRow}
              />
            ) : null}
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};
