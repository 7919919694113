import {Text} from '@dropbox/dig-components/dist/typography';
import {Skeleton} from '@dropbox/dig-components/skeleton';
import {atoms, Box} from '@dropbox/dig-foundations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {useAtomValue} from 'jotai';
import {Suspense, useCallback} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {EmptyErrorGoalsTable} from 'views/goals_v2/EmptyGoalsTable';
import {EmployeeGoalsTableComponent} from 'views/goals_v2/TableComponents/EmployeeGoalsTableComponent';
import {DrawerGoalData} from 'views/goals_v2/types';
import {getBrowsePath} from 'views/goals_v2/utils';

import styles from './GoalsList.module.css';
import {useGoals} from './hooks';

interface GoalsListProps {
  type: 'employee' | 'self';
  timeframe: string;
  ldaps?: string[];
  onClick?: (value: string) => void;
  hideOverflow?: boolean;
}

export const GoalsList = (props: GoalsListProps) => {
  return (
    <ErrorBoundary
      key={`profile_goals_error-${props.ldaps?.join('-')}`}
      FallbackComponent={() => <EmptyErrorGoalsTable containerProps={{borderWidth: '0'}} />}
    >
      <Suspense fallback={<GoalsListSkeleton />}>
        <GoalListForType {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

const GoalListForType = (props: GoalsListProps) => {
  switch (props.type) {
    case 'self':
      return <MyGoals {...props} />;
    case 'employee':
      return <EmployeeGoals {...props} />;
  }
};

const MyGoals = ({timeframe, onClick, hideOverflow}: GoalsListProps) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  return (
    <GoalsV2
      ldap={employee.ldap}
      timeframe={timeframe}
      onClick={onClick}
      hideOverflow={hideOverflow}
    />
  );
};

const EmployeeGoals = ({timeframe, ldaps = [], onClick, hideOverflow}: GoalsListProps) => {
  const {numOfPrivateGoals, hasAllPrivateGoals} = useGoals({ldaps, timeframe});

  return (
    <GoalsV2
      ldap={ldaps[0]}
      timeframe={timeframe}
      numOfPrivateGoals={numOfPrivateGoals}
      onClick={onClick}
      showNumOfPrivateGoals={!hasAllPrivateGoals}
      hideOverflow={hideOverflow}
    />
  );
};

const GoalsV2 = ({
  ldap,
  timeframe,
  numOfPrivateGoals = 0,
  onClick,
  showNumOfPrivateGoals,
  hideOverflow,
}: {
  ldap: string | undefined;
  timeframe: string;
  numOfPrivateGoals?: number;
  onClick?: (value: string) => void;
  showNumOfPrivateGoals?: boolean;
  hideOverflow?: boolean;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToGoal = useCallback(
    (goalData: DrawerGoalData) => {
      onClick?.(goalData?.keyResultId ? 'profile-kr' : 'profile-goal');
      if (goalData.goalId) {
        navigate(getBrowsePath(goalData, false), {
          state: {source: 'profile'},
        });
      }
    },
    [navigate, onClick]
  );

  if (!ldap) {
    return <GoalsListSkeleton />;
  }

  const keyPrefix = 'profile_goals';
  return (
    <>
      <EmployeeGoalsTableComponent
        index={0}
        limitGoals={3}
        key={`${keyPrefix}-${ldap}`}
        keyPrefix={keyPrefix}
        ldap={ldap}
        timeframe={timeframe}
        nextTimeframe={getNextQuarter(timeframe)}
        setNextTimeframe={() => {}}
        isGoalsV2DrawerOpen={false}
        onRowClick={handleNavigateToGoal}
        onContributorsClick={handleNavigateToGoal}
        onContinueGoalClick={() => {}}
        hasScrolledToRow={true}
        setScrolledToRow={() => {}}
        selectedGoalData={undefined}
        showActionMenu={false}
        showHeader={false}
        showUpdates={false}
        disableShowTitle={true}
        hideOverflow={hideOverflow}
        disableExpandGoalActionMenu={true}
        disableTooManyGoalsEmptyState={true}
        shouldExcludeUnownedGoals={true}
        showCreate={false}
        containerProps={{
          marginTop: '8',
          paddingX: undefined,
          paddingY: undefined,
          borderRadius: undefined,
          borderColor: undefined,
          borderStyle: undefined,
          borderWidth: undefined,
        }}
        rowProps={{
          borderRadius: 'Medium',
          paddingRight: '8',
        }}
        emptyStateContainerProps={{borderWidth: '0'}}
      />
      {showNumOfPrivateGoals && numOfPrivateGoals > 0 && (
        <Text
          color="faint"
          size="small"
          style={{display: 'flex', alignItems: 'center', marginTop: 8}}
        >
          <div className={styles.privateGoalsVector}></div>
          {t('num_private_goals')}
        </Text>
      )}
    </>
  );
};

const GoalsListSkeleton = () => (
  <Box display="flex" flexDirection="column">
    <Box display="flex">
      <Skeleton.Box
        width={24}
        height={24}
        withBorderRadius="circular"
        className={atoms({margin: '8'})}
      />
      <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    </Box>
    <Box display="flex">
      <Skeleton.Box
        width={24}
        height={24}
        withBorderRadius="circular"
        className={atoms({margin: '8'})}
      />
      <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    </Box>
    <Box display="flex">
      <Skeleton.Box
        width={24}
        height={24}
        withBorderRadius="circular"
        className={atoms({margin: '8'})}
      />
      <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    </Box>
    <Box marginTop="16">
      <Skeleton.Text size="small" width={50} />
    </Box>
  </Box>
);
