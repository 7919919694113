import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AdminConsoleLine,
  BoxViewLine,
  BriefcaseLine,
  CompassLine,
  GratitudeLine,
  HomeLine,
  NewsLine,
  PersonMultipleLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import cx from 'classnames';
import {Link} from 'components/DSYS/Link';
import {getDefaultTeamsRoute, ROUTE_PATHS} from 'constant';
import {isContentAdmin, isMomenticTestAccount, isUserAdmin} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';

import styles from './LeftNav.module.css';
import {WorkstreamLine} from './WorkstreamLine';

interface NavItem {
  text: string;
  path: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  hidden?: boolean;
  state?: {[key: string]: any};
}

export const LeftNav = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const pulseUser = useAtomValue(pulseUserAtom);
  const email = pulseUser?.email;
  const {isDropboxOSEnabled, isWorkstreamsEnabled} = useAtomValue(growthbookCacheAtom);
  const isStageTestAccount = isMomenticTestAccount(email);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  const handleClick = () => {
    setIsExpanded(false);
  };

  const standaloneNavItems = [
    {
      text: t('home'),
      path: ROUTE_PATHS.HOME,
      icon: HomeLine,
    },
  ];

  const categorizedNavItems = [
    {
      category: t('Intranet'),
      items: [
        {
          text: t('people'),
          path: ROUTE_PATHS.PEOPLE,
          icon: PersonMultipleLine,
        },
        {
          text: t('teams'),
          path: getDefaultTeamsRoute(isDropboxOSEnabled),
          icon: TeamLine,
        },
        {
          text: t('news'),
          path: ROUTE_PATHS.NEWS,
          icon: NewsLine,
        },
        {
          text: t('gratitude'),
          path: ROUTE_PATHS.GRATITUDE_BROWSE,
          icon: GratitudeLine,
        },
      ],
    },
    {
      category: t('Work'),
      items: [
        {
          text: t('goals'),
          path: ROUTE_PATHS.GOALS,
          icon: TargetLine,
          state: {resetView: true},
          hidden: isDropboxOSEnabled && !isStageTestAccount,
        },
        {
          text: t('strategy_plural'),
          path: ROUTE_PATHS.STRATEGIES,
          icon: CompassLine,
          hidden: isStageTestAccount || isDropboxOSEnabled,
        },
        {
          text: t('workstreams'),
          path: ROUTE_PATHS.WORKSTREAM_TRACKERS,
          icon: WorkstreamLine,
          hidden: !isWorkstreamsEnabled || isStageTestAccount || isDropboxOSEnabled,
        },
        {
          text: t('dashboards'),
          path: ROUTE_PATHS.DASHBOARDS,
          icon: BoxViewLine,
          hidden: isStageTestAccount || isDropboxOSEnabled,
        },
        {
          text: t('projects'),
          path: ROUTE_PATHS.PROJECTS,
          icon: BriefcaseLine,
          hidden: isStageTestAccount || isDropboxOSEnabled,
        },
      ],
    },
  ];

  const showAdminButton = isContentAdmin(pulseUser) || isUserAdmin(email);

  return (
    <div
      className={cx(styles.collapsedLeftNav, {
        [styles.expanded]: isExpanded,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box as={Stack} gap="8">
        {standaloneNavItems.map((props, key) => (
          <LinkItem onClick={handleClick} key={`standalone-${key}`} {...props} />
        ))}

        {categorizedNavItems.map((category, categoryIndex) => (
          <Box key={`category-${categoryIndex}`}>
            <Box marginTop="24" paddingTop="24" className={styles.categoryBorder}>
              <Box
                fontFamily="Text"
                color="Text Subtle"
                fontWeight="Strong"
                className={styles.categoryMenu}
              >
                {category.category}
              </Box>
            </Box>

            <Stack gap="8">
              {category.items
                .filter((item: NavItem) => !item.hidden)
                .map((props, key) => (
                  <LinkItem onClick={handleClick} key={`${category.category}-${key}`} {...props} />
                ))}
            </Stack>
          </Box>
        ))}

        {/* Render admin section */}
        {showAdminButton && (
          <Box>
            <Box marginTop="24" paddingTop="24" className={styles.categoryBorder}>
              <Box
                fontFamily="Text"
                color="Text Subtle"
                fontWeight="Strong"
                className={styles.categoryMenu}
              >
                {t('admin_mobile')}
              </Box>
            </Box>
            <LinkItem
              onClick={handleClick}
              text={t('admin_controls')}
              path={ROUTE_PATHS.ADMIN}
              icon={AdminConsoleLine}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

const LinkItem = ({
  onClick,
  text,
  path,
  icon,
  state,
}: {
  text: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  path: string;
  onClick?: () => void;
  extra?: React.ReactNode;
  state?: {[key: string]: any};
}) => {
  const location = useLocation();
  const isMobile = useAtomValue(isMobileAtom);

  const isActive =
    path === ROUTE_PATHS.HOME
      ? location.pathname === ROUTE_PATHS.HOME
      : location.pathname.startsWith(path);

  const linkContent = (
    <Box
      as={Link}
      to={path ?? location.pathname}
      onClick={onClick}
      state={{source: 'left-nav', ...state}}
      hasNoUnderline
      borderRadius={isMobile ? 'Circular' : 'Medium'}
      fontFamily="Text"
      fontSize="Text Medium"
      width="100%"
      paddingY="4"
      paddingRight="12"
      paddingLeft={isMobile ? '4' : '8'}
      display="flex"
      alignItems="center"
      backgroundColor={isActive ? 'Opacity Surface' : 'transparent'}
      {...withShade({
        className: cx(styles.itemLink, {
          [styles.activeItemLink]: isActive,
        }),
      })}
    >
      {isMobile ? (
        <Box as={UIIcon} flexShrink={0} src={icon} />
      ) : (
        <Box display="flex" alignItems="center">
          <Box as={UIIcon} flexShrink={0} isOpticallyAligned src={icon} />
          <Box marginLeft="10" fontWeight={isActive ? 'Strong' : 'Base'}>
            {text}
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <Tooltip title={text} placement="right">
          <div>{linkContent}</div>
        </Tooltip>
      ) : (
        linkContent
      )}
    </>
  );
};
