import {useMutation, useQuery} from '@tanstack/react-query';
import {AppService, EmployeeWithDelegates, UserService} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const useDelegateService = () => {
  const {data: employees} = useQuery({
    queryKey: ['delegates'],
    queryFn: () => getService(UserService).getDelegatesApiV1DelegatesAdminGet(),
  });

  const {mutateAsync: updateEmployeeDelegate} = useMutation({
    mutationFn: ({employee}: {employee: EmployeeWithDelegates}) => {
      return getService(UserService).setDelegatesAdminApiV1DelegatesAdminPost(employee);
    },
    onSettled: (response) => {
      if (!response) {
        return;
      }
      queryClient.invalidateQueries({queryKey: ['delegates']});
    },
  });

  return {employees, updateEmployeeDelegate};
};

export const useExternalAppService = () => {
  const {data: apps} = useQuery({
    queryKey: ['apps'],
    queryFn: () => getService(AppService).listAppsApiV1AppGet(),
  });

  const {mutateAsync: createApp} = useMutation({
    mutationFn: ({name, permissions}: {name: string; permissions: string[]}) => {
      return getService(AppService).createApiV1AppPost(name, permissions);
    },
    onSettled: (response) => {
      if (!response) {
        return;
      }
      queryClient.invalidateQueries({queryKey: ['apps']});
    },
  });

  return {apps, createApp};
};
