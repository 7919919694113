import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {Table} from 'components/DSYS/Table';
import {ReactNode} from 'react';

export const GoalsNameCell = ({
  type,
  title,
  subtitle,
  marginLeft,
  paddingLeft,
  paddingTop,
  withLeftAccessory,
  onClick,
  minHeight,
  minWidth,
  maxWidth,
}: {
  // width: number;
  uselessTable?: boolean;
  type?: 'goal' | 'keyresult' | 'subgoal';
  title: string | ReactNode;
  subtitle?: ReactNode;
  marginLeft?: string;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  paddingTop?: BoxProps<'div'>['paddingTop'];
  withLeftAccessory?: ReactNode;
  onClick?: () => void;
  minHeight?: string;
  minWidth?: string;
  maxWidth?: string;
}) => (
  <Table.Cell onClick={onClick}>
    <Box paddingLeft={paddingLeft} paddingRight="24">
      <Box
        display="flex"
        paddingLeft={withLeftAccessory === undefined && marginLeft !== '0' ? '8' : '0'}
        height="100%"
        style={{minHeight: minHeight ? minHeight : '56px', minWidth, maxWidth}}
      >
        {withLeftAccessory !== undefined && (
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={marginLeft === '44' ? '44' : '8'}
            paddingTop={paddingTop || '8'}
            marginRight="2"
          >
            {withLeftAccessory}
          </Box>
        )}
        <Box
          as="div"
          alignItems="flex-start"
          display="flex"
          padding="8"
          style={{gap: '4px', paddingLeft: type === 'goal' ? '8px' : '22px'}}
        >
          <LabelGroup
            align="top"
            withText={
              <Box as={Text}>
                <Box
                  as="div"
                  style={{
                    // maxWidth: width - (withLeftAccessory !== undefined ? 0 : 100),
                    wordBreak: 'break-word',
                  }}
                  overflow="auto"
                >
                  {title}
                </Box>
              </Box>
            }
            withSubtext={
              subtitle !== undefined && (
                <Box as={Text} color="Text Subtle" size="small">
                  {subtitle || 'No description'}
                </Box>
              )
            }
          />
        </Box>
      </Box>
    </Box>
  </Table.Cell>
);
