import {Button} from '@dropbox/dig-components/dist/buttons';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Box} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {snackbarAtom} from 'atoms/snackbar';
import {NewsCategory, NewsPostContent, NewsPostCreate} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {
  CategorySearchMenu,
  NewsContentEditor,
  NewsModifyHeader,
  NewsModifyThumbnail,
  NewsModifyTitle,
  NewsPreviewDrawer,
  PermissionsRow,
} from 'components/news/NewsModify';
import {isContentEditor} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useCategoriesSuspense} from './hooks';
import styles from './NewsCreate.module.css';

export const NewsCreate = ({
  onSubmit,
  uploadThumbnail,
  isLoading,
}: {
  onSubmit: (data: {data: NewsPostCreate}) => Promise<NewsPostContent>;
  uploadThumbnail: (id: number, file: File) => void;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const pulseUser = useAtomValue(pulseUserAtom);
  const possibleCategories = useCategoriesSuspense();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [actionButtons, setActionButtons] = useState<boolean>(false);

  const [value, setValue] = useState<string>();

  const [newsCreate, setNewsCreate] = useState<NewsPostCreate>({
    title: '',
    category: [],
    created_at: '',
    body: '',
    audience: 'Company',
  });

  useEffect(() => {
    if (newsCreate.title && (value ?? '')) {
      setActionButtons(true);
    }
    if (!(newsCreate.title && (value ?? ''))) {
      setActionButtons(false);
    }
  }, [setActionButtons, newsCreate.title, value]);

  const canEdit = pulseUser && isContentEditor(pulseUser);

  const handleSubmit = async (isDraft: boolean) => {
    const {title, category, audience} = newsCreate;

    const response = await onSubmit({
      data: {
        title,
        category,
        body: (value ?? '').toString(),
        created_at: new Date().toISOString(),
        audience,
        is_draft: isDraft,
      },
    });

    if (selectedFile) {
      uploadThumbnail(response.id, selectedFile);
    }

    setSnackbarMessage({text: t('saved')});
    navigate(`/news/${response.id}`, {state: {source: 'news-create'}});
  };

  if (!pulseUser) {
    return null;
  }

  if (!canEdit) {
    return <NotFound />;
  }

  return (
    <Layout.InlineDrawerContainer
      open={drawerIsOpen}
      onClose={() => setDrawerIsOpen(false)}
      drawerHeader={newsCreate.title}
      drawerBody={
        <NewsPreviewDrawer
          isOpen={drawerIsOpen}
          setDrawerIsOpen={(bool) => setDrawerIsOpen(bool)}
          title={newsCreate.title}
          category={newsCreate.category.map((cat) => cat.name)}
          body={value ?? newsCreate.body}
          onDrawerOpen={() => setNewsCreate((p) => ({...p, body: value ?? ''}))}
          onDrawerClose={() => setNewsCreate((p) => ({...p, body: value ?? ''}))}
        />
      }
    >
      <div className={styles.form}>
        <NewsModifyHeader />

        <Box marginLeft="32">
          <NewsModifyTitle
            title={newsCreate.title}
            setTitle={(title) => setNewsCreate((p) => ({...p, title: title}))}
          />

          <CategorySearchMenu
            possibleCategories={possibleCategories}
            selectedCategories={newsCreate.category.map((cat) => cat.name)}
            onRemoveCategory={(category: string) =>
              setNewsCreate((p) => ({
                ...p,
                category: newsCreate.category.filter((cat) => cat.name !== category),
              }))
            }
            onSelectCategory={(category: NewsCategory) => {
              newsCreate.category.push(category);
              setNewsCreate((p) => ({
                ...p,
                category: newsCreate.category,
              }));
            }}
            setCategory={(category: NewsCategory[]) => {
              setNewsCreate((p) => ({
                ...p,
                category: category,
              }));
            }}
          />

          <NewsModifyThumbnail
            id={0}
            selectedFile={selectedFile}
            setSelectedFile={(file) => setSelectedFile(file)}
          />

          <NewsContentEditor
            setValue={(state) => state && setValue(state.toString())}
            value={newsCreate.body}
            actionButtons={actionButtons}
            drawerIsOpen={drawerIsOpen}
            setDrawerIsOpen={(bool) => setDrawerIsOpen(bool)}
          />

          <FormRow></FormRow>

          <PermissionsRow
            audience={newsCreate.audience}
            setNewsModify={(audience) => setNewsCreate((p) => ({...p, audience: audience}))}
          />

          <FormRow>
            <div className={styles.saveButtonContainer}>
              <div className={styles.saveButtonItemContainer}>
                <Button
                  variant="opacity"
                  size="medium"
                  onClick={() => {
                    handleSubmit(true);
                  }}
                  disabled={!actionButtons}
                >
                  {t('save_draft')}
                </Button>
                <Button
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    handleSubmit(false);
                  }}
                  isLoading={isLoading}
                  disabled={!actionButtons}
                >
                  {t('publish')}
                </Button>
              </div>
            </div>
          </FormRow>
        </Box>
      </div>
    </Layout.InlineDrawerContainer>
  );
};
