import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine, MoreHorizontalLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Comment, Thread} from 'client';
import {useCommentService} from 'components/comments/hooks';
import {Avatar} from 'components/DSYS/Avatar';
import styles from 'components/DSYS/editor/plugins/Comment.module.css';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {TimeAgo} from 'components/shared/TimeAgo';
import {format, parseISO} from 'date-fns';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {ReactNode, useEffect, useRef, useState} from 'react';

import {ThreadButtons} from './CommentHeader';

const CommentOverflowButton = ({
  resolved,
  comment,
  onEditClick,
  onDeleteClick,
}: {
  resolved: boolean;
  comment: Comment;
  onEditClick: () => void;
  onDeleteClick?: () => void;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const setSnackbar = useSetAtom(snackbarAtom);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {deleteComment} = useCommentService({
    type: 'workstream',
    id: -1,
  });

  return (
    <>
      <Menu.Wrapper
        shouldPropagateClickOutsideMouseEvents
        className={isMenuOpen ? undefined : styles.actions}
        onToggle={({isOpen}) => setIsMenuOpen(isOpen)}
      >
        {({getContentProps, getTriggerProps}) => (
          <>
            <IconButton
              {...getTriggerProps()}
              variant="transparent"
              className={atoms({display: 'block'})}
              size="small"
            >
              <UIIcon src={MoreHorizontalLine} className={atoms({color: 'Text Subtle'})} />
            </IconButton>

            <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-start">
              <Menu.Segment>
                {!resolved && <Menu.ActionItem onClick={onEditClick}>{t('edit')}</Menu.ActionItem>}

                <Menu.ActionItem
                  onClick={async () => {
                    await deleteComment({commentId: comment.id});
                    setSnackbar({text: t('deleted')});
                    onDeleteClick?.();
                  }}
                >
                  {t('delete')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>

      {/* <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('discard_comment')}
        subtitle={t('get_rid_of_comment_you_wrote')}
        isPending={isDeletePending}
        onDelete={() => deleteComment({commentId: comment.id})}
      /> */}
    </>
  );
};

const UserCommentRow = ({
  isFirstComment,
  privacy,
  comment,
  updateComment,
  resolved,
  onResolveClick,
  onDeleteComment,
}: {
  isFirstComment: boolean;
  privacy: boolean;
  comment: Comment;
  updateComment: (id: string, comment: string) => Promise<Comment>;
  resolved: boolean;
  onResolveClick: (isResolved: boolean) => void;
  onDeleteComment?: () => void;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const [isEditing, setIsEditing] = useState(false);
  const [editorValue, setEditorValue] = useState<EditorState>();

  const editorRef = useRef<{focusEditor: () => void; clearEditor: (resetValue?: string) => void}>(
    null
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && isEditing) {
        updateComment(comment.id, editorValue?.toString() ?? '').finally(() => setIsEditing(false));
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isEditing, editorValue, updateComment, comment.id]);

  return (
    <Split gap="8" paddingX="16" paddingY="8" className={styles.commentRow}>
      <Split.Item width="hug">
        <Avatar size="small" user={comment.employee} />
      </Split.Item>

      <Split.Item width="fill">
        <Stack gap="4" style={{flexGrow: 1}}>
          <Box display="flex" flexDirection="row" style={{gap: 4}} alignItems="center">
            <Text color="faint" size="small" className={atoms({flexGrow: 1})}>
              <Text isBold>{comment.employee.name}</Text> <TimeAgo timestamp={comment.created_at} />
            </Text>
            {privacy && (
              <IconButton variant="borderless" size="small">
                <UIIcon src={LockLine} className={atoms({color: 'Text Subtle'})} />
              </IconButton>
            )}
            {employee.user_id === comment.employee.user_id && (
              <CommentOverflowButton
                resolved={resolved}
                comment={comment}
                onEditClick={() => {
                  setTimeout(() => editorRef.current?.focusEditor(), 100);
                  setIsEditing(true);
                }}
                onDeleteClick={onDeleteComment}
              />
            )}
            {isFirstComment && (
              <ThreadButtons resolved={resolved} onResolveClick={onResolveClick} />
            )}
          </Box>

          <RichTextArea
            ref={editorRef}
            theme="small"
            source="comments"
            value={comment.content}
            autoFocus
            editable={isEditing}
            editablePaddingX="0"
            backgroundHighlight="Background Base"
            onChange={(value) => setEditorValue(value)}
            minHeight={40}
            hideToolbar
          />

          {isEditing && (
            <EditSaveButtons
              border={false}
              size="small"
              cta={(editorValue?.toString() ?? '').length ? t('save') : t('delete')}
              handleCancelClick={() => {
                editorRef.current?.clearEditor(comment.content);
                setIsEditing(false);
              }}
              handleSaveClick={() =>
                updateComment(comment.id, editorValue?.toString() ?? '').finally(() =>
                  setIsEditing(false)
                )
              }
              isLoading={false}
              disableSave={false}
            />
          )}
        </Stack>
      </Split.Item>
    </Split>
  );
};

export const CommentBox = ({
  thread,
  beforeComment,
  onDeleteComment,
  onResolveClick,
}: {
  thread: Thread;
  beforeComment?: ReactNode;
  onDeleteComment?: () => void;
  onResolveClick: (isResolved: boolean) => void;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const {updateComment} = useCommentService({
    type: thread.attached_to_type,
    id: thread.workstream_update_id ?? thread.workstream_id ?? thread.goal_id ?? -1,
  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [thread.comments]);

  return (
    <Box
      ref={scrollRef}
      paddingY="8"
      overflowY="scroll"
      display="flex"
      flexDirection="column"
      style={{minHeight: 100, gap: '8', maxHeight: 600}}
    >
      {beforeComment}
      {thread.comments
        ?.filter((comment) => comment.content.length)
        .map((comment, index) => (
          <UserCommentRow
            isFirstComment={index === 0}
            resolved={thread.is_resolved}
            onResolveClick={onResolveClick}
            key={comment.id}
            privacy={false}
            comment={comment}
            onDeleteComment={onDeleteComment}
            updateComment={(commentId, content) =>
              updateComment({
                commentId,
                data: {
                  content: content.length ? content : comment.content,
                  updated_at: new Date().toISOString(),
                  is_deleted: !content.length,
                },
              })
            }
          />
        ))}
    </Box>
  );
};

export const ThreadBox = ({
  thread,
  beforeComment,
  onResolveClick,
}: {
  thread: Thread;
  beforeComment?: ReactNode;
  onResolveClick: (isResolved: boolean) => void;
}) => {
  const privacy = false;

  const comment = thread.comments?.[0];

  const replies = (thread.comments?.length ?? 0) - 1;

  return !comment ? null : (
    <Split gap="8" style={{opacity: thread?.is_resolved ? 0.5 : 1}}>
      <Split.Item width="hug" paddingTop="4">
        <Avatar size="small" user={comment.employee} />
      </Split.Item>

      <Split.Item width="fill">
        <Stack gap="4" style={{flexGrow: 1}}>
          <Box display="flex" flexDirection="row" style={{gap: 4}} alignItems="center">
            <Text color="faint" size="small" className={atoms({flexGrow: 1})}>
              <Text isBold>{comment.employee.name}</Text>{' '}
              {format(parseISO(comment.created_at), 'MMM d')}
            </Text>
            {privacy && (
              <IconButton variant="borderless" size="small">
                <UIIcon src={LockLine} className={atoms({color: 'Text Subtle'})} />
              </IconButton>
            )}
            <ThreadButtons resolved={thread.is_resolved} onResolveClick={onResolveClick} />
          </Box>

          {beforeComment && <Box>{beforeComment}</Box>}
          <RichTextArea theme="xsmall" value={comment.content} />

          {replies > 0 && (
            <Box>
              <Text color="faint" size="small">
                {t('reply', {count: replies})}
              </Text>
            </Box>
          )}
        </Stack>
      </Split.Item>
    </Split>
  );
};
