import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Table} from 'components/DSYS/Table';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {toLocaleStringConditionalYear, toQuarter} from 'components/shared/TimeAgo';
import {useMemo} from 'react';

export const QuarterCell = ({timeframe}: {timeframe?: string}) => {
  const quarter = useMemo(() => timeframe && toQuarter(timeframe), [timeframe]);

  const localeString = useMemo(
    () => timeframe && toLocaleStringConditionalYear(timeframe),
    [timeframe]
  );

  const [date, year] = (localeString ?? '').split(', ');

  if (!timeframe) {
    return <EmptyCell />;
  }

  return (
    <Table.Cell>
      <LabelGroup
        withText={
          <Box as={Text} size="small" whiteSpace="nowrap">
            {date}
          </Box>
        }
        withSubtext={
          <Box as={Text} color="Text Subtle" size="xsmall" whiteSpace="nowrap">
            {quarter} {year ? `FY${parseInt(year, 10) - 2000}` : ''}
          </Box>
        }
      />
    </Table.Cell>
  );
};
