import {Table} from 'components/DSYS/Table';
import {
  ProjectStatusButton,
  ProjectStatusButtonReadOnly,
} from 'components/projects/ProjectStatusButton';
import {JIRA_STATUS_MAP} from 'views/projects/utils';

export const StatusCell = ({
  status,
  projectId,
  size = 'small',
  onClick,
}: {
  status?: string; // only specific to tickets
  projectId?: number;
  size?: 'small';
  onClick: () => void;
}) => {
  if (projectId) {
    return (
      <Table.Cell>
        <ProjectStatusButton projectId={projectId} size={size} onClick={onClick} />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <ProjectStatusButtonReadOnly
        showIcon
        status={JIRA_STATUS_MAP[status as keyof typeof JIRA_STATUS_MAP]}
        size={size}
      />
    </Table.Cell>
  );
};
