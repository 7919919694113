import {Text} from '@dropbox/dig-components/dist/typography';
import allHandsGif from 'assets/images/home_allhands.gif';
import focusGif from 'assets/images/home_focus.svg';
import qbrGif from 'assets/images/home_qbr.gif';

import {BannerConfig} from './BannerConfig';

export const BANNER_CONFIGS = {
  allhands: new BannerConfig({
    type: 'allhands',
    title: 'Company All Hands',
    image: allHandsGif,
    calendarRegex: 'Company All Hands',
    excludeContractor: true,
    background: 'linear-gradient(90deg, #EE6030 44.09%, rgba(244, 108, 66, 0.1) 100%)',
  }),
  focus: new BannerConfig({
    type: 'focus',
    title: 'Company Focus Day',
    image: focusGif,
    imageBackground: 'linear-gradient(270deg, #6DC7E9 0.18%, #FFFFFF 99.82%)',
    calendarRegex: 'Company Focus',
    isSingleDayEvent: true,
    marqueeText: 'make space for focus',
    subtitle: (
      <>
        Please reschedule meetings if possible for <Text isBold>today&apos;s focus day</Text>
      </>
    ),
    background: 'linear-gradient(90deg, #6DC7E9 44.09%, rgba(109, 199, 233, 0) 100%)',
  }),
  qbr: new BannerConfig({
    type: 'qbr',
    title: 'Quarterly Biz Review',
    excludeContractor: true,
    image: qbrGif,
    calendarRegex: 'Company QBR',
    background: 'linear-gradient(90deg, #BCDA4F 44.09%, rgba(187, 219, 76, 0) 100%)',
  }),
};
