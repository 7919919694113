import {Box} from '@dropbox/dig-foundations';
import {Table} from 'components/DSYS/Table';
import {GoalStatusButton, StatusButtonProps} from 'components/shared/GoalStatusButton';

export const StatusCell = (props: StatusButtonProps & {onClick?: () => void}) => (
  <Table.Cell onClick={props.onClick} style={props.onClick ? {cursor: 'pointer'} : undefined}>
    <Box marginTop="12">
      <GoalStatusButton {...props} isDisabled={!props.canEdit || props.isReadOnlyDueToOpenKRs} />
    </Box>
  </Table.Cell>
);
