import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {snackbarAtom} from 'atoms/snackbar';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {ReactNode} from 'react';

import {Title} from './Title';

type Props<T> = {
  title: ReactNode;
  subtitle: ReactNode;
  open: boolean;
  isPending: boolean;
  onDelete: () => Promise<T>;
  onClose: (deleted?: boolean) => void;
  setSnackbarMessage?: (response: T) => string;
};

export const DeleteModal = <T,>({
  title,
  subtitle,
  open,
  isPending,
  setSnackbarMessage = () => t('deleted'),
  onDelete,
  onClose,
}: Props<T>) => {
  const setSnackbar = useSetAtom(snackbarAtom);

  const handleDeleteClick = async () => {
    const response = await onDelete();
    setSnackbar({text: setSnackbarMessage(response)});
    onClose(true);
  };

  return (
    <Modal
      open={open}
      withCloseButton="close"
      width="small"
      aria-label="Are you sure you want to delete?"
      onRequestClose={() => onClose()}
    >
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>{subtitle}</Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={() => onClose()} disabled={isPending}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleDeleteClick} isLoading={isPending}>
          {t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
