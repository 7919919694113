/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CeleryTaskMeta } from '../models/CeleryTaskMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CeleryMetataskService {

    /**
     * Get Tasks
     * @returns CeleryTaskMeta Successful Response
     * @throws ApiError
     */
    public static getTasksApiV1CeleryMetataskGet(): CancelablePromise<Array<CeleryTaskMeta>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/celery_metatask/',
        });
    }

    /**
     * Schedule Job
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public static scheduleJobApiV1CeleryMetataskScheduleJobEmployeesTypePost(
        type: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/celery_metatask/schedule/job/employees/{type}',
            path: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
