import {Split} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {NotificationService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Table} from 'components/DSYS/Table';
import {Title} from 'components/shared/ManagePage/Title';
import {ROUTE_PATHS} from 'constant';
import {isUserAdmin} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {NewNotificationModal} from './notifications/NewNotificationModal';
import {NotificationRow} from './notifications/NotificationRow';
import {getTodayUTC9am} from './utils';

const columns = [
  {type: 'type', minWidth: 100},
  {type: 'data', minWidth: 200},
  {type: 'audience', minWidth: 200},
  {type: 'send', minWidth: 200},
  {type: 'due', minWidth: 200},
  {type: 'delete', minWidth: 100},
];

export const NotificationScheduler = () => {
  const {data} = useQuery({
    queryKey: ['notifications', 'scheduled'],
    queryFn: () => getService(NotificationService).scheduledApiV1NotificationsScheduledGet(),
  });

  const scheduledData = data?.filter(
    (notification) => new Date(notification.send_at) >= getTodayUTC9am()
  );

  const [notificationModal, addNotificationModal] = useState(false);
  const [sendNow, setSendNow] = useState(false);

  const navigate = useNavigate();

  const pulseUser = useAtomValue(pulseUserAtom);

  useDocumentTitle(t('manage_notifications'));

  if (!pulseUser) {
    return null;
  }

  if (!isUserAdmin(pulseUser?.email)) {
    navigate('/');
    return null;
  }

  return (
    <Layout.Container
      breadcrumb={[
        {children: 'Admin', to: ROUTE_PATHS.ADMIN},
        {children: 'Notifications', to: ROUTE_PATHS.ADMIN_NOTIFICATIONS},
      ]}
    >
      <Split gap="16">
        <Split.Item width="fill">
          <Title
            text={t('manage_notifications')}
            addButtonOnClick={() => {
              setSendNow(false);
              addNotificationModal(true);
            }}
          />
        </Split.Item>
        {/* <Split.Item>
          <Button
            variant="filled"
            onClick={() => {
              setSendNow(true);
              addNotificationModal(true);
            }}
          >
            Send now
          </Button>
        </Split.Item> */}
      </Split>
      <Table columns={columns} data={scheduledData ?? []}>
        <Table.Header />
        <Table.Body>
          {scheduledData?.map((message) => <NotificationRow key={message.id} {...message} />)}
        </Table.Body>
      </Table>
      {notificationModal && (
        <NewNotificationModal sendNow={sendNow} onClose={() => addNotificationModal(false)} />
      )}
    </Layout.Container>
  );
};
