import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {CelebrateLine, PersonMultipleLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {pulseUserAtom} from 'atoms/auth';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import {Layout} from 'components/DSYS/Layout';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';

import {Banner} from './Banner';
import {CalendarSection} from './sections/Calendar';
import {NewsSection} from './sections/News';
import {PeopleSection} from './sections/People';
import {SearchSection} from './sections/Search';
import {StocksSection} from './sections/Stock';
import {VacationLine} from './VacationLine';

export const Home = () => {
  return <HomeView />;
};

export const HomeView = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const isMobile = useAtomValue(isMobileAtom);
  const {isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);

  useDocumentTitle(t('home'));

  if (!pulseUser?.email) {
    return (
      <Box>
        <Stack gap="24">
          <Layout.Container>
            <Box as={Stack} gap="10" paddingBottom="8">
              <SearchSection />
            </Box>
          </Layout.Container>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack gap="24">
        <Layout.Container>
          <Banner />
          <Box as={Stack} gap="10" paddingBottom="32">
            {/* {employee?.name ? (
              <Split gap="4">
                <Split.Item>
                  <Eyebrow>{t('welcome_back', {name: employee.name.split(' ')[0]})} </Eyebrow>
                </Split.Item>
                <Split.Item>
                  <Text size="xsmall" color="faint">
                    {t('welcome_back_subtext')}
                  </Text>
                </Split.Item>
              </Split>
            ) : (
              <Skeleton.Box style={{width: 230, height: 18.5}} />
            )} */}
            <SearchSection />
          </Box>

          <Split
            gap="16"
            style={{alignItems: 'flex-start'}}
            direction={isMobile ? 'vertical' : 'horizontal'}
          >
            <Split.Item width="fill" style={{width: '100%'}}>
              <Stack gap="16">
                <PeopleSection
                  filter={['pto']}
                  iconSrc={VacationLine}
                  title={t('home_out_of_office')}
                />
                <PeopleSection
                  filter={['birthday', 'anniversary']}
                  iconSrc={CelebrateLine}
                  title={t('home_celebrations')}
                />
                <PeopleSection
                  filter={['new']}
                  iconSrc={PersonMultipleLine}
                  title={t('home_new_people')}
                />
              </Stack>
            </Split.Item>
            <Split.Item width="fill" style={{width: '100%'}}>
              <Stack gap="16">
                {!isDropboxOSEnabled && <CalendarSection />}
                <NewsSection />
                {!isDropboxOSEnabled && <StocksSection />}
              </Stack>
            </Split.Item>
          </Split>
        </Layout.Container>
      </Stack>
    </Box>
  );
};
