import {Notifications, NotificationWithContent} from 'client';
import {queryClient} from 'views/QueryClientWrapper';

export const findNotification = ({
  type,
  data,
  includeArchived,
}: {
  type: string;
  data?: string;
  includeArchived?: boolean;
}) => {
  const notifications = queryClient.getQueryData<Notifications>(['notifications']);
  const archived = queryClient.getQueryData<NotificationWithContent>(['notifications', 'archived']);

  const allNotifications = [
    ...(notifications?.action_items ?? []),
    ...(notifications?.notifications ?? []),
  ];

  if (includeArchived) {
    allNotifications.concat(archived ?? []);
  }

  return allNotifications.find((n) => n.type === type && (data ? n.data.data === data : true));
};

export const getActivePulse = () => {
  const notifications = queryClient.getQueryData<Notifications>(['notifications']);

  return (notifications?.action_items ?? []).find((n) => n.type === 'pulse_active');
};
