import {DragEndEvent} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';
import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AttachLine,
  CalendarLine,
  CircleStandardFilledFill,
  FlagLine,
  MoreHorizontalLine,
  WarningLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {LaptopMini} from '@dropbox/dig-illustrations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import cx from 'classnames';
import {
  TrackerFull,
  TrackerWorkstreamFull,
  Workstream,
  WorkstreamLink,
  WorkstreamMetric,
  WorkstreamUpdate,
} from 'client';
import {
  useCommentService,
  useThreadService,
  useWorkstreamIThreads,
} from 'components/comments/hooks';
import {Avatar} from 'components/DSYS/Avatar';
import {DeleteModal} from 'components/DSYS/DeleteModal';
import {EmptyState} from 'components/DSYS/EmptyState';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {StatusButton} from 'components/DSYS/StatusButton';
import {Table} from 'components/DSYS/Table';
import {LinkIcon} from 'components/shared/LinkIcon';
import {LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {calculateTimeAgo} from 'components/shared/TimeAgo';
import {ROUTE_PATHS} from 'constant';
import {addDays, differenceInCalendarDays, parseISO, startOfWeek} from 'date-fns';
import {useDebouncedValue} from 'hooks/useDebounce';
import {t} from 'i18next';
import {atom, useAtom, useAtomValue} from 'jotai';
import {EditorState} from 'lexical';
import {Suspense, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useAuditLogs, useNotesEditor, useTrackerEdit, useWorkstreamDelete} from './hooks';
import {MetricLine} from './MetricLine';
import styles from './TrackerWorkstream.module.css';
import {getExpectedDate, toShortDate} from './utils';
import {AuditLogReason} from './WorkstreamAuditLog';

function isUpdateReady(status: WorkstreamUpdate | undefined, status_due: number) {
  const currentDate = new Date();
  const currentWeekStart = startOfWeek(currentDate, {weekStartsOn: 0}); // Sunday as start of the week

  if (status) {
    const createdAt = parseISO(status.created_at);
    const createdWeekStart = startOfWeek(createdAt, {weekStartsOn: 0});

    // 1. If there's a status for the current week, return false
    if (createdWeekStart.getTime() === currentWeekStart.getTime()) {
      return false;
    }

    // 2. If the status is less than 7 days old and there's an upcoming status_due, return true
    const daysSinceLastUpdate = differenceInCalendarDays(currentDate, createdAt);
    const dueDate = addDays(currentWeekStart, status_due);

    if (daysSinceLastUpdate < 7 && currentDate <= dueDate) {
      return true;
    }

    // 3. If the status is over 7 days old, return how many days it's past due
    if (daysSinceLastUpdate >= 7) {
      const daysPastDue = differenceInCalendarDays(currentDate, dueDate);
      return daysSinceLastUpdate - daysPastDue;
    }
  }

  return true;
}

export const focusedThreadAtom = atom<string | undefined>(undefined);

const TrackerTableRow = (
  workstream: WorkstreamSortWrapper & {
    tracker: string | number;
    trackerName: string;
    onLogsClick: () => void;
    onAddToTrackerClick: () => void;
    onRowClick: () => void;
  }
) => {
  const [focusedThread, setFocusedThread] = useAtom(focusedThreadAtom);

  const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const {editNotes} = useNotesEditor(workstream.tracker, workstream.workstreamId);
  const {data: auditLogs} = useAuditLogs(workstream.workstreamId);
  const threads = useWorkstreamIThreads({
    workstreamId: workstream.workstreamId,
  });
  const {createThread} = useThreadService();
  const {createComment} = useCommentService({
    id: workstream.workstreamId,
    type: 'workstream',
  });

  const lastDoneChange = auditLogs?.find((log) => log.type === 'metric');

  const {
    data: {
      workstream: {name, employee_associations, end_date, metrics, notes, updates, status_due},
    },
    onAddToTrackerClick,
    onRowClick,
  } = workstream;

  const outerRef = useRef<HTMLTableCellElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const initialNotes = useRef(notes ?? '');

  const latest2Updates = updates?.slice(0, 2) ?? [];

  // if the status changed between last 2 updates, set changed = true
  const changedStatus =
    latest2Updates.length > 1 && latest2Updates[0].status !== latest2Updates[1].status;

  const [notesEditor, setNotesEditor] = useState<EditorState>();
  const debouncedNotes = useDebouncedValue(notesEditor, 300);
  const owner = employee_associations.find(({dri}) => dri === 'dri')?.employee;

  const [hovering, setHovering] = useState(false);

  const needsUpdate = isUpdateReady(latest2Updates?.[0], status_due);

  const updatesToShow = !needsUpdate ? latest2Updates : latest2Updates.slice(0, 1);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setFocusedThread(undefined);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setFocusedThread]);

  useEffect(() => {
    const debouncedJson = debouncedNotes?.toString() ?? '';

    if (!initialNotes.current.length && (workstream?.data.workstream.notes || debouncedJson)) {
      initialNotes.current = workstream?.data.workstream.notes || debouncedJson;
    }

    if (debouncedNotes && debouncedJson !== workstream?.data.workstream.notes) {
      editNotes(debouncedJson);
    }
  }, [debouncedNotes, editNotes, workstream?.data.workstream.notes]);

  const handleAddComment = async (
    comment: string,
    attached_text: string,
    start: number,
    end: number,
    update?: WorkstreamUpdate,
    metric?: WorkstreamMetric,
    threadId?: string
  ) => {
    let id = threadId;
    if (!id) {
      const newThread = await createThread({
        workstreamId: workstream.workstreamId,
        workstreamUpdateId: update?.id,
        workstreamMetricId: metric?.id,
        goalId: 0,
        keyResultId: 0,
        data: {
          attached_to_type: update?.id
            ? 'workstream_update'
            : metric?.id
              ? 'workstream_metric'
              : 'workstream',
          created_at: new Date().toISOString(),
          start,
          end,
          attached_text,
        },
      });

      id = newThread.id;
    }

    await createComment({
      data: {
        content: comment,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        reply_to: null,
        thread_id: id,
      },
    });

    return id;
  };

  const canEdit = workstream.data.workstream.employee_associations.some(
    ({employee: {user_id}}) =>
      user_id === employee.user_id || delegatedBy?.some((delegate) => delegate.user_id === user_id)
  );

  return (
    <Table.Row
      className={cx(styles.tableRow)}
      sortableId={typeof workstream.tracker === 'number' ? workstream.id : undefined}
    >
      <Table.Cell className={styles.nameCell}>
        <Box display="flex" flexDirection="row" style={{marginRight: 16}}>
          <Stack className={atoms({paddingTop: '4', paddingLeft: '4', marginBottom: '8'})} gap="2">
            <Box display="flex" flexDirection="row" style={{gap: 8}}>
              {changedStatus &&
                !needsUpdate &&
                !['on_track', 'completed'].includes(latest2Updates[0].status) && (
                  <Tooltip
                    title={`Status updated from ${t(latest2Updates[1].status)} to ${t(latest2Updates[0].status)}`}
                  >
                    <Box>
                      <UIIcon
                        src={WarningLine}
                        className={atoms({color: 'Border Base'})}
                        size="small"
                      />
                    </Box>
                  </Tooltip>
                )}
              <Text isBold style={{marginTop: -1}}>
                <Link
                  to={`${ROUTE_PATHS.WORKSTREAM.replace(':id', workstream.data.workstream.id.toString())}`}
                  state={{tracker: {id: workstream.tracker, name: workstream.trackerName}}}
                  hasNoUnderline
                  showUnderlineOnHover
                  monochromatic
                >
                  {name}
                </Link>
              </Text>
            </Box>

            {owner && (
              <ButtonLink
                to={`${ROUTE_PATHS.PROFILE.replace(':userid', owner.ldap)}`}
                variant="borderless"
                size="small"
                style={{marginLeft: -5}}
                className={atoms({display: 'block'})}
                withIconStart={<Avatar user={owner} size="xsmall" />}
              >
                <Text size="small" style={{marginLeft: -2}}>
                  {owner.name}
                </Text>
              </ButtonLink>
            )}

            <Button
              variant="borderless"
              disabled
              style={{marginLeft: -8}}
              className={atoms({display: 'block'})}
              onClick={onRowClick}
              size="small"
              withIconStart={
                <UIIcon src={CalendarLine} className={atoms({color: 'Border Base'})} />
              }
            >
              <Text size="small">
                {end_date ? <>ETA {toShortDate(new Date(end_date))}</> : 'No completion date'}
              </Text>
            </Button>

            {Boolean(workstream.data.workstream.links?.length) && (
              <Box>
                <AttachmentOverflowButton links={workstream.data.workstream.links!} />
              </Box>
            )}

            <WorkstreamOverflowButton
              tracker={workstream.tracker}
              className={styles.editButton}
              workstream={workstream.data.workstream}
              onAddToTrackerClick={onAddToTrackerClick}
              canEdit={canEdit}
            />
          </Stack>
        </Box>
      </Table.Cell>

      <LabelGroupSubtleCell
        text={
          <Stack style={{marginRight: 16}}>
            {needsUpdate && (
              <Box display="flex" style={{gap: 4, marginLeft: -1}}>
                <UIIcon
                  src={CircleStandardFilledFill}
                  size="small"
                  color="#BBB5AE"
                  className={atoms({marginTop: '2'})}
                />

                <Text size="small" isBold>
                  {typeof needsUpdate === 'number'
                    ? 'Update past due'
                    : `Update due on ${getExpectedDate(status_due)}`}
                </Text>
              </Box>
            )}
            <Stack gap="16">
              {!updatesToShow?.length
                ? null
                : updatesToShow.map((update) => (
                    <Text
                      key={update.id}
                      size="small"
                      className={atoms({
                        display: 'flex',
                        flexDirection: 'column',
                      })}
                    >
                      <Box display="flex" alignItems="center" style={{gap: 8}}>
                        <StatusButton status={update.status} isLabel />
                        <Text color="faint" size="small">
                          {calculateTimeAgo(update.created_at)}
                        </Text>
                      </Box>

                      <RichTextArea
                        value={update.comment}
                        theme="xsmall"
                        activeThreadId={focusedThread}
                        setActiveThreadId={setFocusedThread}
                        comments
                        threads={threads?.filter(
                          (thread) => thread.workstream_update_id === update.id
                        )}
                        onAddComment={({comment, ...rest}) =>
                          'threadId' in rest
                            ? handleAddComment(comment, '', 0, 0, update, undefined, rest.threadId)
                            : handleAddComment(comment, rest.text, rest.start, rest.end, update)
                        }
                      />
                    </Text>
                  ))}
            </Stack>
          </Stack>
        }
      />
      <Table.Cell>
        <Box
          className={cx(
            atoms({
              display: 'block',
              paddingX: '6',
              paddingY: '2',
              paddingRight: '6',
              marginY: '4',
              marginRight: '16',
              borderRadius: 'Medium',
            })
          )}
        >
          {metrics
            .sort((a, b) => a.order - b.order)
            .map((metric) => (
              <Box key={metric.id} display="flex" flexDirection="row" style={{gap: 8}}>
                <UIIcon
                  src={metric.type === 'milestone' ? FlagLine : MetricLine}
                  className={atoms({color: 'Text Subtle', flexShrink: 0, marginTop: '2'})}
                  size="small"
                />
                <Text size="small" className={atoms({flexGrow: 1})}>
                  <Suspense fallback={metric.title}>
                    <RichTextArea
                      value={metric.title}
                      theme="xsmall"
                      activeThreadId={focusedThread}
                      setActiveThreadId={setFocusedThread}
                      comments
                      threads={threads?.filter(
                        (thread) => thread.workstream_metric_id === metric.id
                      )}
                      onAddComment={({comment, ...rest}) =>
                        'threadId' in rest
                          ? handleAddComment(comment, '', 0, 0, undefined, metric, rest.threadId)
                          : handleAddComment(
                              comment,
                              rest.text,
                              rest.start,
                              rest.end,
                              undefined,
                              metric
                            )
                      }
                    />
                  </Suspense>
                </Text>
                {metric.current && metric.target && (
                  <Text size="small" color="subtle" className={atoms({flexShrink: 0})}>
                    {metric.current} / {metric.target}
                  </Text>
                )}
              </Box>
            ))}
          {lastDoneChange?.reason && (
            <Box paddingRight="6" marginTop="4">
              <AuditLogReason reason={lastDoneChange.reason} truncate />
            </Box>
          )}
        </Box>
      </Table.Cell>

      <Table.Cell
        ref={outerRef}
        onClick={() => {
          setHovering(true);
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => {
          if (!isFocused) {
            setHovering(false);
          }
        }}
      >
        <Box display="flex">
          <Box
            flexGrow={1}
            className={cx(
              atoms({
                display: 'block',
                marginY: '4',
              })
            )}
            style={{
              marginLeft: hovering ? -8 : undefined,
              marginRight: hovering ? -8 : undefined,
            }}
          >
            <RichTextArea
              theme="xsmall"
              editable={hovering || isFocused}
              minHeight={(outerRef.current?.offsetHeight ?? 35) - 64}
              topOffset={0}
              editablePaddingX="8"
              value={initialNotes.current}
              defaultText={
                <Box position="absolute" top="0">
                  —
                </Box>
              }
              backgroundHighlight="Background Subtle"
              onToggleFocused={(focused) => {
                setIsFocused(focused);
                if (!focused) {
                  setHovering(false);
                }
              }}
              placeholder={
                <Text color="faint" size="small">
                  {t('start_writing_placeholder')}
                </Text>
              }
              source="workstream-notes"
              hideToolbar
              onChange={setNotesEditor}
              autoFocus={false}
            />
          </Box>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};

const columns = [
  {type: 'workstream', minWidth: 200, width: 200},
  {type: 'updates', minWidth: 300},
  {type: 'definition of done', minWidth: 300},
  {type: 'notes', minWidth: 150, width: 150},
];

export const WorkstreamOverflowButton = ({
  tracker,
  workstream,
  canEdit,
  className,
  onLogsClick,
  onAddToTrackerClick,
}: {
  tracker?: string | number;
  styles?: string;
  workstream: Workstream;
  canEdit: boolean;
  className?: string;
  onLogsClick?: () => void;
  onAddToTrackerClick: () => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isMenuOpen, setIsOpen] = useState(false);

  const owner = workstream.employee_associations.find(({dri}) => dri === 'dri');

  const {deleteWorkstream, isPending} = useWorkstreamDelete(
    owner?.employee.user_id ?? '0',
    tracker
  );

  return (
    <>
      <Menu.Wrapper
        shouldPropagateClickOutsideMouseEvents
        className={isMenuOpen ? undefined : className}
        onToggle={({isOpen}) => setIsOpen(isOpen)}
      >
        {({getContentProps, getTriggerProps}) => (
          <>
            {tracker ? (
              <Button
                {...getTriggerProps()}
                variant="borderless"
                style={{marginLeft: -8}}
                className={atoms({display: 'block'})}
                hasNoUnderline
                size="small"
                withIconStart={
                  <UIIcon src={MoreHorizontalLine} className={atoms({color: 'Border Base'})} />
                }
              >
                <Text size="small">{t('more_title')}</Text>
              </Button>
            ) : (
              <IconButton {...getTriggerProps()} variant="outline">
                <UIIcon src={MoreHorizontalLine} className={atoms({color: 'Text Subtle'})} />
              </IconButton>
            )}

            <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-start">
              <Menu.Segment>
                {canEdit && (
                  <>
                    <Menu.LinkItem
                      href={ROUTE_PATHS.WORKSTREAM_EDIT.replace(':id', workstream.id.toString())}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(
                          ROUTE_PATHS.WORKSTREAM_EDIT.replace(':id', workstream.id.toString()),
                          {state: {source: location.pathname}}
                        );
                      }}
                    >
                      {t('edit')}
                    </Menu.LinkItem>
                    {tracker && (
                      <Menu.LinkItem
                        href={ROUTE_PATHS.WORKSTREAM_STATUS_UPDATE + '?id=' + workstream.id}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(ROUTE_PATHS.WORKSTREAM_STATUS_UPDATE + '?id=' + workstream.id, {
                            state: {source: location.pathname},
                          });
                        }}
                      >
                        {t('update')}
                      </Menu.LinkItem>
                    )}
                  </>
                )}

                <Menu.ActionItem onClick={onAddToTrackerClick}>
                  {t('manage_trackers')}
                </Menu.ActionItem>
              </Menu.Segment>
              {(onLogsClick || canEdit) && (
                <Menu.Segment>
                  {onLogsClick && (
                    <Menu.ActionItem onClick={onLogsClick}>{t('audit_log_title')}</Menu.ActionItem>
                  )}
                  {canEdit && (
                    <>
                      <Menu.ActionItem onClick={() => setShowDeleteModal(true)}>
                        {t('delete')}
                      </Menu.ActionItem>
                    </>
                  )}
                </Menu.Segment>
              )}
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
      <DeleteModal
        open={showDeleteModal}
        onClose={(deleted) =>
          deleted && !tracker
            ? navigate(ROUTE_PATHS.WORKSTREAM_TRACKERS)
            : setShowDeleteModal(false)
        }
        title={t('delete_workstream_confirm')}
        subtitle={t('delete_workstream_confirm_subtitle')}
        isPending={isPending}
        onDelete={() => deleteWorkstream(workstream.id)}
      />
    </>
  );
};

type WorkstreamSortWrapper = {
  id: number;
  workstreamId: number;
  data: TrackerWorkstreamFull;
};

export const TrackerTable = ({
  tracker,
  setAuditLogDrawer,
  setWorkstreamDrawer,
  setAddModalOpen,
}: {
  tracker?: TrackerFull;
  setAuditLogDrawer: (workstream: Workstream) => void;
  setWorkstreamDrawer: (workstream: Workstream) => void;
  setAddModalOpen: (workstream: Workstream) => void;
}) => {
  const [workstreams, setWorkstreams] = useState<WorkstreamSortWrapper[]>([]);

  const {editTracker} = useTrackerEdit();

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (tracker && active.id !== over?.id) {
      setWorkstreams((prevItems) => {
        const oldIndex = prevItems.findIndex((entry) => entry.id === active.id);
        const newIndex = prevItems.findIndex((entry) => entry.id === over?.id);

        const updatedWorkstreams = arrayMove(prevItems, oldIndex, newIndex);

        // network request
        editTracker({
          data: {
            ...tracker,
            id: tracker.id as number,
            workstreams: updatedWorkstreams.map((workstream, index) => ({
              workstream_id: workstream.workstreamId,
              order: index,
            })),
          },
        });

        return updatedWorkstreams;
      });
    }
  }

  useEffect(() => {
    if (!tracker) return;

    setWorkstreams(
      tracker.workstreams
        .sort((a, b) =>
          typeof tracker.id === 'number'
            ? a.order - b.order
            : a.workstream.start_date.localeCompare(b.workstream.start_date)
        )
        .map((workstream, index) => ({
          id: index + 1,
          workstreamId: workstream.workstream.id,
          data: workstream,
        }))
    );
  }, [tracker]);

  return (
    <Box as="div" marginTop="16" maxWidth="100%">
      {tracker && !tracker.workstreams.length ? (
        <EmptyState
          hideBorder
          title={t('workstream_table_empty_title')}
          body={
            typeof tracker.id === 'number' ? (
              <Text>
                <Link
                  to={
                    ROUTE_PATHS.WORKSTREAM_TRACKER_EDIT.replace(':id', tracker.id.toString()) +
                    '?autofocus=workstreams'
                  }
                  state={{source: location.pathname}}
                >
                  Add workstream
                </Link>{' '}
                to this tracker.
              </Text>
            ) : (
              <>{t('workstream_table_empty_body')}</>
            )
          }
          image={<LaptopMini width={64} altText={''} />}
        />
      ) : (
        tracker && (
          <Table columns={columns} data={tracker.workstreams} reorderable onReorder={handleDragEnd}>
            <Table.Header />
            <SortableTableBody
              tracker={tracker}
              workstreams={workstreams}
              onLogsClick={setAuditLogDrawer}
              onAddToTrackerClick={setAddModalOpen}
              onRowClick={setWorkstreamDrawer}
            />
          </Table>
        )
      )}
    </Box>
  );
};

const SortableTableBody = ({
  tracker,
  workstreams,
  onLogsClick,
  onAddToTrackerClick,
  onRowClick,
}: {
  tracker: TrackerFull;
  workstreams: WorkstreamSortWrapper[];
  onLogsClick: (workstream: Workstream) => void;
  onAddToTrackerClick: (workstream: Workstream) => void;
  onRowClick: (workstream: Workstream) => void;
}) => {
  if (!workstreams?.length) {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Box paddingY="132" />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  return (
    <Table.Body>
      {workstreams.map((data) => (
        <TrackerTableRow
          key={data.id.toString()}
          tracker={tracker.id}
          trackerName={tracker.name}
          onLogsClick={() => onLogsClick(data.data.workstream)}
          onAddToTrackerClick={() => onAddToTrackerClick(data.data.workstream)}
          onRowClick={() => onRowClick(data.data.workstream)}
          {...data}
        />
      ))}
    </Table.Body>
  );
};

const AttachmentOverflowButton = ({links}: {links: WorkstreamLink[]}) => {
  return (
    <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button
            {...getTriggerProps()}
            variant="borderless"
            style={{marginLeft: -8}}
            className={atoms({display: 'block'})}
            hasNoUnderline
            size="small"
            withIconStart={<UIIcon src={AttachLine} className={atoms({color: 'Border Base'})} />}
          >
            <Text size="small">{t('workstream_resources', {count: links.length})}</Text>
          </Button>
          <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-start">
            <Menu.Segment>
              {links.map((link) => (
                <Menu.LinkItem
                  key={link.id}
                  href={link.url}
                  withLeftAccessory={<LinkIcon icon={link.icon} />}
                >
                  {link.text}
                </Menu.LinkItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
