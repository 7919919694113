import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Workstream} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {WorkstreamLine} from 'components/layout/WorkstreamLine';
import {format, isAfter} from 'date-fns';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {useTracker} from './hooks';
import {WorkstreamUpdate} from './WorkstreamUpdate';

const WorkstreamCard = ({
  workstream,
  selected,
  onSelect,
}: {
  workstream: Workstream;
  selected?: boolean;
  onSelect?: (workstream: Workstream) => void;
}) => {
  // const workstreamUpdates = useAtomValue(workstreamUpdatesAtom);

  const isLatestUpdateLessThanAweek = isAfter(
    new Date(workstream.updates?.[0]?.created_at ?? ''),
    new Date(new Date().setDate(new Date().getDate() - 7))
  );

  return (
    <Box boxShadow={selected ? 'Raised' : undefined} borderRadius="Medium">
      <Box
        onClick={() => onSelect?.(workstream)}
        boxShadow={selected ? 'Focus Ring' : undefined}
        cursor="pointer"
        padding="16"
        // paddingBottom="0"
        borderRadius="Medium"
        borderStyle="Solid"
        borderColor="Border Subtle"
        {...withShade({
          style: {
            height: 106,
            width: 235,
          },
        })}
      >
        <Stack gap="16">
          <Box display="flex" flexDirection="row" style={{gap: 8}}>
            <UIIcon src={WorkstreamLine} className={atoms({color: 'Border Subtle'})} />
            <Stack gap="4" className={atoms({whiteSpace: 'nowrap', overflow: 'hidden'})}>
              <Text
                isBold
                className={atoms({display: 'block', overflow: 'hidden'})}
                style={{textOverflow: 'ellipsis'}}
              >
                {workstream.name}
              </Text>
              <Text size="xsmall">
                {workstream.updates?.length
                  ? t('project_last_updated', {
                      date: format(new Date(workstream.updates![0].created_at), 'MMM d'),
                    })
                  : t('created_date', {date: format(new Date(workstream.start_date), 'MMM d')})}
              </Text>
            </Stack>
          </Box>

          {
            // Object.keys(workstreamUpdates).includes(workstream.id.toString()) ? (
            //   <Box style={{float: 'right'}}>
            //     <Tooltip title="Pending changes">
            //       <Box>
            //         <UIIcon src={EditLine} className={atoms({color: 'Accent Green'})} />
            //       </Box>
            //     </Tooltip>
            //   </Box>
            // ) : (
            isLatestUpdateLessThanAweek && (
              <Box style={{float: 'right'}}>
                <Tooltip title="Updated within the last 7 days">
                  <Box>
                    <UIIcon src={CheckmarkLine} className={atoms({color: 'Border Subtle'})} />
                  </Box>
                </Tooltip>
              </Box>
            )
            // )
          }
        </Stack>
      </Box>
    </Box>
  );
};

export const WorkstreamStatusUpdate = () => {
  const navigate = useNavigate();
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const {data, isLoading} = useTracker(employee.ldap);
  const [searchParams] = useSearchParams();
  const [selectedWorkstream, setSelectedWorkstream] = useState<Workstream | undefined>();

  useDocumentTitle(t('update_workstreams'));

  const myWorkstreams = data?.workstreams.map((workstream) => workstream.workstream);

  const selectedWithFallback =
    selectedWorkstream ??
    myWorkstreams?.find(({id}) => id === Number(searchParams.get('id'))) ??
    myWorkstreams?.[0];

  useEffect(() => {
    if (selectedWorkstream) {
      searchParams.delete('id');
      navigate({search: searchParams.toString()}, {replace: true});
    }
  }, [selectedWorkstream, searchParams, navigate]);

  const handleAfterSubmit = () => {
    // move to the next workstream after current selected one that is also not updates within the last 7 days

    const currentIndex = myWorkstreams?.findIndex(
      (workstream) => workstream.id === selectedWithFallback?.id
    );

    if (currentIndex === undefined || currentIndex === -1) {
      navigate(`/workstreams/${employee.ldap}`);
      return;
    }

    // Find the next workstream that is not updated within the last 7 days
    const nextWorkstream = myWorkstreams?.find(
      (workstream, index) =>
        index > currentIndex &&
        !isAfter(
          new Date(workstream.updates?.[0]?.created_at ?? ''),
          new Date(new Date().setDate(new Date().getDate() - 7))
        )
    );

    if (nextWorkstream) {
      setSelectedWorkstream(nextWorkstream);
    } else {
      navigate(`/workstreams/${employee.ldap}`);
    }
  };

  if (employee?.ldap && !isLoading && !data?.workstreams.length) {
    navigate(`/workstreams/${employee.ldap}`);
    return null;
  }

  return (
    <Layout.Container
      width="100%"
      breadcrumb={[
        {children: t('workstreams'), to: '/workstreams'},
        {
          children: employee.name ?? (
            <Box style={{width: 105}}>
              <Skeleton.Box height={17} width={105} />
            </Box>
          ),
          to: `/workstreams/${employee.ldap}`,
        },
        {children: t('update_workstreams'), to: `/workstreams/update`},
      ]}
    >
      <Header />
      <Stack gap="24">
        <Box style={{marginLeft: -4, marginRight: -4}}>
          <Split gap="16" className={atoms({overflowX: 'auto', padding: '4'})}>
            {myWorkstreams?.map((workstream) => (
              <Split.Item key={workstream.id}>
                <WorkstreamCard
                  workstream={workstream}
                  selected={selectedWithFallback?.id === workstream.id}
                  onSelect={setSelectedWorkstream}
                />
              </Split.Item>
            ))}
          </Split>
        </Box>
        {selectedWithFallback && (
          <WorkstreamUpdate workstream={selectedWithFallback} onAfterSubmit={handleAfterSubmit} />
        )}
      </Stack>
    </Layout.Container>
  );
};

const Header = () => (
  <Split alignY="center" paddingBottom="16" gap="6">
    <Split.Item width="fill">
      <Title size={18}>{t('update_workstreams')}</Title>
    </Split.Item>
  </Split>
);
