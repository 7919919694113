import {getPublishedStatuses, getStatusStyle} from 'components/DSYS/StatusButtonIcon';
import {isCurrentQuarterEnded} from 'components/shared/TimeAgo';
import {DEFAULT_TIMEFRAME, ROUTE_PATHS} from 'constant';
import {To} from 'react-router';

export interface GoalCountsData {
  status: string;
  statusCount: number;
  statusCounts: {[status: string]: number};
  totalCount: number;
  percentageCeil: number;
  segmentStatusColor: string;
  cellStatusColor?: string;
}

export const getDefaultHeatmapStatus = (timeframe: string) => {
  return isCurrentQuarterEnded(timeframe) ? 'complete' : 'on_track';
};

export const getStatusGoalCountsData = (
  status: string,
  statusCounts?: {[status: string]: number}
): GoalCountsData | undefined => {
  if (!statusCounts) {
    return undefined;
  }
  const statusCount = statusCounts[status] ?? 0;
  const totalCount = Object.values(statusCounts).reduce((acc, current) => acc + current, 0);
  const percentageCeil = Math.ceil((statusCount / totalCount) * 100) || 0;
  const segmentStatusColor =
    status === 'no_status' ? 'var(--dig-color__background__base)' : getStatusStyle(status).color;
  return {
    status,
    statusCount,
    statusCounts,
    totalCount,
    percentageCeil,
    cellStatusColor: undefined,
    segmentStatusColor,
  };
};

export const getGraphSegments = (goalCounts: {[status: string]: number} | undefined) => {
  if (!goalCounts) {
    return {};
  }
  const statusOptions = getPublishedStatuses();
  const firstSegmentStatus = statusOptions.find((status) => goalCounts[status] > 0);
  const lastSegmentStatus = [...statusOptions].reverse().find((status) => goalCounts[status] > 0);
  return {firstSegmentStatus, lastSegmentStatus};
};

export const createDashboardsPath = (timeframe: string): To => {
  const urlParams = new URLSearchParams();
  if (timeframe && timeframe !== DEFAULT_TIMEFRAME) {
    urlParams.set('t', timeframe);
  }
  const highlight = getHeatmapHighlight();
  if (VALID_HEATMAP_HIGHLIGHTS.includes(highlight) && highlight !== 'top25') {
    urlParams.set('highlight', highlight);
  }
  return {pathname: `${ROUTE_PATHS.DASHBOARDS}`, search: urlParams.toString()};
};

export const createTeamDashboardsPath = (teamSlug: string, timeframe: string): To => {
  const urlParams = new URLSearchParams();
  if (timeframe && timeframe !== DEFAULT_TIMEFRAME) {
    urlParams.set('t', timeframe);
  }
  const highlight = getHeatmapHighlight();
  if (VALID_HEATMAP_HIGHLIGHTS.includes(highlight) && highlight !== 'top25') {
    urlParams.set('highlight', highlight);
  }
  return {pathname: `${ROUTE_PATHS.DASHBOARDS}/${teamSlug}`, search: urlParams.toString()};
};

export const createTeamsPath = (teamSlug: string): To => {
  return `/teams/${teamSlug}`;
};

export const createProfilePath = (ldap: string): To => {
  return `/people/${ldap}`;
};

export const createTeamGoalsPath = (
  teamSlug: string,
  status: string | undefined,
  timeframe: string
): To => {
  const urlParams = new URLSearchParams();
  urlParams.set('teams', teamSlug);
  if (status) {
    urlParams.set('status', status);
  }
  if (DEFAULT_TIMEFRAME !== timeframe) {
    urlParams.set('t', timeframe);
  }
  return {pathname: ROUTE_PATHS.GOALS, search: urlParams.toString()};
};

export const createPeopleGoalsPath = (
  ldap: string,
  status?: string,
  timeframe?: string,
  employeeLdap?: string
): To => {
  const urlParams = new URLSearchParams();
  if (ldap !== employeeLdap) {
    urlParams.set('people', ldap);
  }
  if (status) {
    urlParams.set('status', status);
  }
  if (timeframe && DEFAULT_TIMEFRAME !== timeframe) {
    urlParams.set('t', timeframe);
  }
  return {pathname: ROUTE_PATHS.GOALS, search: urlParams.toString()};
};

// TODO (mtruong): Remove highlight demo code
const VALID_HEATMAP_HIGHLIGHTS = ['gradient', 'sort', 'normalized', 'top25'];
export const getHeatmapHighlight = () => {
  const urlParams = new URLSearchParams(location.search);
  const higlight = urlParams.get('highlight') ?? '';
  return VALID_HEATMAP_HIGHLIGHTS.includes(higlight) ? higlight : 'top25';
};

export const getRgbColor = (hexColor: string, opacity?: number): string => {
  const hex = hexColor.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return opacity ? `rgb(${r}, ${g}, ${b}, ${opacity})` : `rgb(${r}, ${g}, ${b})`;
};
