import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowUpLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {isCommentInputFocusedAtom} from 'atoms/comment';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Goal, KeyResult, Thread} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useEffect, useRef} from 'react';
import React from 'react';

import styles from './CommentInput.module.css';
import {useCommentService, useThreadService} from './hooks';
import Range from './Range';

export const CommentInputComponent = ({
  goalId,
  keyResultId,
  workstreamId,
  range,
  thread,
  text,
  setCommentInput,
  targetText,
}: {
  goalId: number;
  keyResultId?: number;
  workstreamId?: number;
  range: Range;
  thread: Thread | undefined;
  text: string;
  setCommentInput: React.Dispatch<React.SetStateAction<string>>;
  targetText: string;
}) => {
  const {createThread} = useThreadService();
  const {createComment} = useCommentService({
    type: workstreamId ? 'workstream' : 'goal',
    id: workstreamId ?? goalId,
  });
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const setIsInputFocused = useSetAtom(isCommentInputFocusedAtom);

  const adjustTextareaHeight = () => {
    const element = textareaRef.current;
    if (element) {
      if (element.scrollHeight > 280) {
        element.style.height = '280px';
        return;
      }
      element.style.height = '24px';
      element.style.height = element.scrollHeight + 'px';
    }
  };

  const handleFocus = useCallback(() => {
    if (textareaRef.current && textareaRef.current === document.activeElement) {
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, []);

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const onSendClick = useCallback(() => {
    const timestamp = new Date().toISOString();

    if (thread) {
      analyticsLogger().logEvent('GOAL_COMMENT', {thread: thread.id});
      createComment({
        data: {
          content: text.trim(),
          created_at: timestamp,
          updated_at: timestamp,
          thread_id: thread.id,
        },
      });
    } else {
      createThread({
        goalId: goalId,
        keyResultId: keyResultId,
        workstreamId: workstreamId,
        data: {
          start: range.start,
          end: range.end,
          attached_text: targetText.substring(range.start, range.end + 1),
          attached_to_type: workstreamId ? 'workstream' : keyResultId ? 'keyresult' : 'goal',
          created_at: timestamp,
        },
      }).then((res) => {
        analyticsLogger().logEvent('GOAL_COMMENT_NEW_THREAD');
        createComment({
          data: {
            content: text.trim(),
            created_at: timestamp,
            updated_at: timestamp,
            thread_id: res.id,
          },
        });
      });
    }
    const element = textareaRef.current;
    if (element) {
      element.style.height = 36 + 'px';
    }
    setIsInputFocused(true);
    setCommentInput('');
  }, [
    createComment,
    createThread,
    goalId,
    keyResultId,
    workstreamId,
    range.end,
    range.start,
    setCommentInput,
    targetText,
    text,
    thread,
    setIsInputFocused,
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (text) {
          onSendClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [onSendClick, text]);

  useEffect(() => {
    const element = textareaRef.current;
    if (element) {
      if (element.scrollHeight > 280) {
        element.style.height = '280px';
      } else {
        element.style.height = element.scrollHeight + 'px';
      }
    }
    if (textareaRef.current) {
      handleFocus();
    }
  }, [handleFocus]);

  return (
    <>
      <div className={thread ? styles.commentInput : styles.commentInputEmpty}>
        <Box
          flexDirection="row"
          display="flex"
          width="100%"
          alignItems="center"
          className={styles.commentInputBox}
        >
          <div className={styles.iconAvatar}>
            <Avatar user={employee} size="small" />
          </div>
          <TextArea
            placeholder={thread ? t('reply') : t('add_a_comment').toString()}
            id="startWriting"
            value={text}
            onChange={(e) => setCommentInput(e.currentTarget.value)}
            className={styles.commentInputTextArea}
            ref={textareaRef}
            onInput={adjustTextareaHeight}
            onFocus={() => setIsInputFocused(true)}
            onBlur={handleBlur}
            autoFocus={false}
          />

          <IconButton
            variant="borderless"
            shape="circular"
            className={styles.iconButton}
            onClick={onSendClick}
            size="standard"
            disabled={!text}
          >
            <UIIcon src={ArrowUpLine} />
          </IconButton>
        </Box>
      </div>
    </>
  );
};

export const DrawerCommentInput = ({
  goal,
  keyResult,
  setActivatedThread,
}: {
  goal: Goal;
  keyResult?: KeyResult;
  setActivatedThread: (id: Thread['id'] | null) => void;
}) => {
  const [text, setCommentInput] = React.useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {createThread} = useThreadService();
  const {createComment} = useCommentService({type: 'goal', id: goal.id});
  const pulseUser = useAtomValue(pulseUserAtom);

  const adjustTextareaHeight = () => {
    const element = textareaRef.current;
    if (element) {
      if (element.scrollHeight > 280) {
        element.style.height = '280px';
        return;
      }
      element.style.height = '24px';
      element.style.height = element.scrollHeight + 'px';
    }
  };

  const onSendClick = useCallback(() => {
    const timestamp = new Date().toISOString();

    createThread({
      goalId: goal.id,
      keyResultId: keyResult?.id,
      data: {
        start: 0,
        end: keyResult ? keyResult.title.length : goal.title.length,
        attached_text: keyResult ? keyResult.title : goal.title,
        attached_to_type: keyResult ? 'keyresult' : 'goal',
        created_at: timestamp,
      },
    }).then((res) => {
      analyticsLogger().logEvent('GOAL_COMMENT_NEW_THREAD');
      createComment({
        data: {
          content: text.trim(),
          created_at: timestamp,
          updated_at: timestamp,
          thread_id: res.id,
        },
      });
    });
    setCommentInput('');
    const element = textareaRef.current;
    if (element) {
      element.style.height = 36 + 'px';
    }
  }, [createComment, createThread, goal.id, goal.title, keyResult, text]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (text) {
          onSendClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [onSendClick, text]);

  if (!pulseUser) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      display="flex"
      alignItems="center"
      className={styles.drawerCommentInputBox}
    >
      <div className={styles.drawerIconAvatar}>
        <Avatar user={pulseUser} size="small" />
      </div>
      <TextArea
        placeholder={t('new_comment')}
        id="startWriting"
        value={text}
        onChange={(e) => setCommentInput(e.currentTarget.value)}
        className={styles.drawerCommentInputTextArea}
        ref={textareaRef}
        onInput={adjustTextareaHeight}
        autoFocus={false}
        onFocus={() => setActivatedThread(null)}
      />

      <IconButton
        variant="borderless"
        shape="circular"
        className={styles.iconButton}
        onClick={onSendClick}
        size="standard"
        disabled={!text}
      >
        <UIIcon src={ArrowUpLine} />
      </IconButton>
    </Box>
  );
};

export const DrawerCommentReplyInput = ({
  goal,
  thread,
  autoFocus,
  setAutoFocus,
}: {
  goal: Goal;
  thread: Thread;
  autoFocus: boolean;
  setAutoFocus: (value: boolean) => void;
}) => {
  const [text, setCommentInput] = React.useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {createComment} = useCommentService({type: 'goal', id: goal.id});

  const adjustTextareaHeight = () => {
    const element = textareaRef.current;
    if (element) {
      if (element.scrollHeight > 280) {
        element.style.height = '280px';
        return;
      }
      element.style.height = '24px';
      element.style.height = element.scrollHeight + 'px';
    }
  };

  const onSendClick = useCallback(() => {
    const timestamp = new Date().toISOString();

    analyticsLogger().logEvent('GOAL_COMMENT', {thread: thread.id});
    createComment({
      data: {
        content: text.trim(),
        created_at: timestamp,
        updated_at: timestamp,
        thread_id: thread.id,
      },
    });
    setCommentInput('');
    const element = textareaRef.current;
    if (element) {
      element.style.height = 36 + 'px';
    }
  }, [createComment, text, thread.id]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (text) {
          onSendClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [onSendClick, text]);

  return (
    <Box
      flexDirection="row"
      display="flex"
      alignItems="center"
      className={styles.drawerCommentReplyBox}
    >
      <TextArea
        placeholder={t('reply')}
        id="startWriting"
        value={text}
        onChange={(e) => setCommentInput(e.currentTarget.value)}
        className={styles.drawerCommentReplyTextArea}
        ref={textareaRef}
        onInput={adjustTextareaHeight}
        autoFocus={autoFocus}
        onFocus={() => setAutoFocus(true)}
        onBlur={() => setAutoFocus(false)}
      />

      <IconButton
        variant="borderless"
        shape="circular"
        className={styles.iconButton}
        onClick={onSendClick}
        size="standard"
        disabled={!text}
      >
        <UIIcon src={ArrowUpLine} />
      </IconButton>
    </Box>
  );
};
