import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  CheckmarkCircleLine,
  CloseLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {Thread} from 'client';
import {t} from 'i18next';

export const ThreadButtons = ({
  resolved,
  onResolveClick,
}: {
  resolved: boolean;
  onResolveClick: (isResolved: boolean) => void;
}) => (
  <Tooltip title={t(resolved ? 'comment_unresolve_tooltip' : 'comment_resolve_tooltip')}>
    <IconButton
      size="small"
      variant="transparent"
      onClick={(e: any) => {
        e.stopPropagation();
        onResolveClick(!resolved);
      }}
    >
      <UIIcon src={resolved ? CheckmarkCircleFill : CheckmarkCircleLine} />
    </IconButton>
  </Tooltip>
);

export const CommentHeader = ({
  thread,
  onClick,
  onResolveClick,
  onCloseClick,
}: {
  thread: Thread;
  onClick?: () => void;
  onResolveClick: (isResolved: boolean) => void;
  onCloseClick?: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      display="flex"
      justifyContent="flex-end"
      paddingRight="8"
      paddingY="8"
      borderBottom="Solid"
      borderColor="Border Subtle"
      style={{gap: 4}}
    >
      <ThreadButtons resolved={thread.is_resolved} onResolveClick={onResolveClick} />
      {onCloseClick && (
        <IconButton variant="transparent" onClick={onCloseClick}>
          <UIIcon src={CloseLine} />
        </IconButton>
      )}
    </Box>
  );
};
