import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {TooltipRefObject} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronRightLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {derivedThemeAtom} from 'atoms/layout';
import {StrategyItem} from 'atoms/strategies';
import cx from 'classnames';
import {Employee} from 'client';
import {Facepile} from 'components/DSYS/Facepile';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {Table as DIGTable} from 'components/DSYS/Table';
import {Title} from 'components/DSYS/Title';
import {LabelGroupCell} from 'components/shared/table/LabelGroupCell';
import {sortEmployees} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useStrategy} from './hooks';
import styles from './Scorecard.module.css';
import {StatusScores} from './StatusScores';

const GoalCount = ({strategy, timeframe}: {strategy: StrategyItem; timeframe: string}) => {
  const {data, isLoading} = useStrategy({strategyId: strategy.id.toString(), timeframe});

  if (isLoading) {
    return <Skeleton.Text width={50} />;
  }

  if (data?.has_all_private_goals) {
    return <>{t('private')}</>;
  }

  return <>{t('goal_count', {count: data?.goals.length})}</>;
};

const DSYSTableRow = ({
  timeframe,
  strategy,
  isSubStrategry,
  hideArrow,
}: {
  timeframe: string;
  strategy: StrategyItem;
  isSubStrategry?: boolean;
  hideArrow?: boolean;
}) => {
  const navigate = useNavigate();
  const isDarkMode = useAtomValue(derivedThemeAtom) === 'dark';

  const [showSubStrategies, setShowSubStrategies] = useState(false);
  const ref = useRef<TooltipRefObject>(null);

  const toggleTooltip = () => {
    if (ref && ref.current) {
      ref.current.update();
    }
  };

  return (
    <>
      <DIGTable.Row
        key={strategy.id}
        className={styles.row}
        isSelectable
        onClick={() => {
          navigate(`/strategies/${strategy.id}`);
          analyticsLogger().logEvent('STRATEGY_VIEW_SCORECARD', {source: 'row'});
        }}
      >
        <LabelGroupCell
          isBold
          className={atoms({paddingY: '0', paddingLeft: !isSubStrategry ? undefined : '52'})}
          text={strategy.name}
          subText={<GoalCount strategy={strategy} timeframe={timeframe} />}
          withLeftAccessory={
            !isSubStrategry &&
            !hideArrow && (
              <IconButton
                variant="outline"
                size="small"
                onClick={(e: any) => {
                  e.stopPropagation();
                  toggleTooltip();
                  setShowSubStrategies(!showSubStrategies);
                }}
                className={atoms({visibility: !strategy.subStrategies ? 'hidden' : 'visible'})}
              >
                <UIIcon src={showSubStrategies ? ChevronDownLine : ChevronRightLine} />
              </IconButton>
            )
          }
        />

        <DIGTable.Cell>
          <StatusScores strategy={strategy} timeframe={timeframe} />
        </DIGTable.Cell>

        <DIGTable.Cell>
          <Box position="relative" height="100%" width="100%">
            <Split gap="8">
              <Split.Item width="hug">
                <Facepile
                  size="small"
                  ldaps={strategy.owners.map((owner) => owner.ldap)}
                  overflow={4}
                />
              </Split.Item>
              <Split.Item width="fill">
                <Text size="small" isBold>
                  {(strategy.owners as Employee[]).sort(sortEmployees).map((owner, index) => (
                    <Link
                      monochromatic
                      key={`${strategy.id}—${owner.ldap}`}
                      to={`/people/${owner.ldap}`}
                      hasNoUnderline
                      showUnderlineOnHover
                    >
                      {`${owner.name}${strategy.owners.length - 1 === index ? ' ' : ', '}`}
                    </Link>
                  ))}
                </Text>
              </Split.Item>
            </Split>
            <Box
              height="100%"
              position="absolute"
              top="0"
              right="0"
              paddingTop="16"
              paddingLeft="32"
              className={cx(styles.overflow, {
                [styles.overflowDark]: isDarkMode,
              })}
            >
              <ButtonLink
                variant="borderless"
                to={`/strategies/${strategy.id}`}
                size="small"
                onClick={() =>
                  analyticsLogger().logEvent('STRATEGY_VIEW_SCORECARD', {source: 'button'})
                }
              >
                View scorecard
              </ButtonLink>
            </Box>
          </Box>
        </DIGTable.Cell>
      </DIGTable.Row>

      {showSubStrategies &&
        strategy.subStrategies?.length &&
        strategy.subStrategies.map((subStrategy) => (
          <DSYSTableRow
            key={subStrategy.id}
            timeframe={timeframe}
            strategy={subStrategy}
            isSubStrategry
          />
        ))}
    </>
  );
};

const columnConfigs = [
  {type: 'name', minWidth: 400},
  {type: 'status', minWidth: 132, fixed: true},
  {type: 'owner', minWidth: 400},
];

const StrategyContainer = ({
  strategies,
  timeframe,
}: {
  strategies: StrategyItem[];
  timeframe: string;
}) => {
  const sortedTable = strategies.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <DIGTable columns={columnConfigs} data={sortedTable}>
      <DIGTable.Header />
      <DIGTable.Body>
        {sortedTable.map((row) => (
          <DSYSTableRow key={row.id} strategy={row} timeframe={timeframe} />
        ))}
      </DIGTable.Body>
    </DIGTable>
  );
};

export const StrategyOverview = ({
  timeframe,
  strategy,
}: {
  timeframe: string;
  strategy: StrategyItem[];
}) => {
  useDocumentTitle(t('strategy_plural'));

  // group strategy by .companyStrategy tru and false first
  const groupedStrategies = strategy
    .filter(({hidden}) => !hidden)
    .reduce(
      (grouped, item) => {
        grouped[item.companyStrategy ? 0 : 1].push(item);
        return grouped;
      },
      [[] as StrategyItem[], [] as StrategyItem[]]
    );

  return (
    <Stack gap="32">
      <StrategyContainer strategies={groupedStrategies[0]} timeframe={timeframe} />

      <Stack gap="16">
        <Title size="small">Additional scorecards</Title>
        <StrategyContainer strategies={groupedStrategies[1]} timeframe={timeframe} />
      </Stack>
    </Stack>
  );
};
