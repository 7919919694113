import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Table} from 'components/DSYS/Table';
import {ReactNode} from 'react';

export const OverflowCell = ({children}: {children: ReactNode}) => {
  return <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{children}</div>;
};

export const LabelGroupCell = ({
  isBold,
  text,
  subText,
  withLeftAccessory,
  className,
  fullText,
}: {
  isBold?: boolean;
  text: ReactNode;
  fullText?: boolean;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
  className?: string;
}) => {
  return (
    <Table.Cell>
      <Box
        padding="4"
        overflow="hidden"
        whiteSpace="nowrap"
        style={{
          // width,
          textOverflow: 'ellipsis',
        }}
        // paddingLeft={paddingLeft}
      >
        <Box paddingY={!className ? '4' : undefined} className={className}>
          <LabelGroup
            align="top"
            withLeftAccessory={withLeftAccessory}
            withText={
              fullText ? (
                <Text isBold={isBold}>{text}</Text>
              ) : (
                <Text isBold={isBold}>
                  <div style={{display: 'flex', whiteSpace: 'nowrap'}}>
                    <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</span>
                  </div>
                </Text>
              )
            }
            withSubtext={
              subText && (
                <Text color="faint" size="small">
                  <div style={{display: 'flex', whiteSpace: 'nowrap'}}>{subText}</div>
                </Text>
              )
            }
          />
        </Box>
      </Box>
    </Table.Cell>
  );
};

export const LabelGroupSubtleCell = ({
  text,
  subText,
  withLeftAccessory,
  className,
}: {
  text: ReactNode;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
  className?: string;
}) => {
  return (
    <Table.Cell>
      <Box paddingY={!className ? '4' : undefined} className={className}>
        <LabelGroup
          withLeftAccessory={withLeftAccessory}
          withText={<Text size="small">{text}</Text>}
          withSubtext={
            subText && (
              <Text color="faint" size="xsmall">
                {subText}
              </Text>
            )
          }
        />
      </Box>
    </Table.Cell>
  );
};
