// eslint-disable-next-line no-restricted-imports
import {Avatar as DIGAvatar, AvatarSizes} from '@dropbox/dig-components/avatar';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon, UIIconSize} from '@dropbox/dig-icons';
import {PulseUser} from 'atoms/auth';
import cx from 'classnames';
import {EmployeeLdap, GoalUser} from 'client';
import {t} from 'i18next';
import {forwardRef, useEffect, useState} from 'react';
import {getBackendUrl, getProfileImageUrl, nameToInitials} from 'utilities';

import styles from './Avatar.module.css';

type ProfileAvatarProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: AvatarSizes | 'xlarge' | 'profile' | 'edit';
  onLeave?: boolean;
  isInactive?: boolean;
  user:
    | {
        name: string;
        profile_photo_url?: string | null;
      }
    | GoalUser
    | EmployeeLdap
    | PulseUser;
};

const userIsGoalUser = (user: any): user is GoalUser | PulseUser => {
  return typeof user !== 'string' && 'display_name' in user;
};

/**
 * Avatar component for displaying user a profile photo or initials
 *
 * @param size - size of the avatar
 * @param user - Employee | GoalUser | PulseUser | ldap
 * @param onLeave - whether the user is on leave
 */
export const Avatar = forwardRef<HTMLElement, ProfileAvatarProps>(
  ({size, user, onLeave, isInactive, className, style}, ref: any) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [transform, setTransform] = useState(0);

    const url =
      typeof user === 'string'
        ? getProfileImageUrl(user)
        : ((user as GoalUser).profile_photo_url ?? getProfileImageUrl((user as EmployeeLdap).ldap));

    const isCustomSize = ['xlarge', 'profile', 'edit'].includes(size ?? '');

    const photoURL = url?.includes('https://') ? url : getBackendUrl() + url;
    const employeeName = userIsGoalUser(user)
      ? user.display_name
      : typeof user === 'string'
        ? user
        : user.name;
    const employeeInitials =
      userIsGoalUser(user) && user.abbreviated_name
        ? user.abbreviated_name
        : nameToInitials(employeeName);

    // Hacky workaround until we allow people to adjust their crop
    useEffect(() => {
      const image = new Image();
      image.src = photoURL;

      const onLoad = () => {
        setImageLoaded(true);

        const width = image.naturalWidth;
        const height = image.naturalHeight;

        if (height === width) {
          // Square thumbnail from editor
          setTransform(0);
        } else if (height === 300 && width === 200) {
          // This seems to be a fairly common dimension
          setTransform(1);
        } else if (height > width) {
          // otherwise they're ~195 x ~315 — ish.
          setTransform(2);
        }
      };
      image.addEventListener('load', onLoad);
      return () => {
        image.removeEventListener('load', onLoad);
      };
    }, [photoURL]);

    if (onLeave && size !== 'profile') {
      throw new Error(
        'DSYS/Avatar.tsx: `onLeave` prop is only supported for `profile` size avatars'
      );
    }

    return (
      <Box
        position="relative"
        ref={ref}
        className={cx(className, {
          [styles.avatar64]: size === 'xlarge',
          [styles.avatar128]: size === 'profile',
          [styles.avatar211]: size === 'edit',
        })}
        style={style}
      >
        {onLeave && (
          <div className={styles.wrapper}>
            <div className={styles.ticker}>
              <div className={styles.tickerText}>
                <Text size="small">{t('currently_on_leave')}</Text>
              </div>
            </div>
          </div>
        )}
        <Box
          as={DIGAvatar}
          key={photoURL}
          backgroundColor="Opacity Surface"
          src={photoURL}
          hasNoOutline
          size={isCustomSize ? 'large' : (size as AvatarSizes)}
          alt={employeeName}
          isInactive={isInactive}
          className={cx(styles.avatar, {
            [styles.avatarLoaded]: imageLoaded,
            [styles.deactivatedAvatarLoaded]: isInactive,
            [styles.moveUpXSmall]: transform === 1 && !isCustomSize,
            [styles.moveUpSmall]: transform === 2 && !isCustomSize,
            [styles.moveUpMedium]: transform === 2 && size === 'xlarge',
            [styles.moveUpBig]: transform === 2 && size === 'profile',
          })}
        >
          {employeeInitials}
        </Box>
      </Box>
    );
  }
);

Avatar.displayName = 'Avatar';

export const AvatarIcon = ({
  src,
  size = 'medium',
  background = 'var(--dig-color__opacity__surface)',
  color = 'Text Subtle',
  className,
}: {
  src: React.ComponentType<React.SVGAttributes<SVGElement>>;
  size?: UIIconSize;
  background?: string;
  color?: BoxProps<typeof UIIcon>['color'];
  className?: string;
}) => (
  <Box className={className}>
    <DIGAvatar colorPalette={{background, foreground: ''}} size={size}>
      <Box as={UIIcon} size={size} src={src} color={color} />
    </DIGAvatar>
  </Box>
);
