import {Box} from '@dropbox/dig-foundations';
import {UIIconProps} from '@dropbox/dig-icons';
import {isMobileAtom} from 'atoms/layout';
import cx from 'classnames';
import {useAtomValue} from 'jotai';
import {Children, createContext, CSSProperties, ReactNode, useContext} from 'react';

import {Breadcrumb, BreadcrumbLinkProps} from './Breadcrumb';
import {DrawerContainer} from './DrawerContainer';
import styles from './Layout.module.css';

type GridSize = 'default' | '100%';

const GridContext = createContext<{
  withNav: boolean;
}>({withNav: false});

const Container = ({
  width = 'default',
  breadcrumb,
  children,
}: {
  width?: GridSize;
  breadcrumb?: BreadcrumbLinkProps[];
  children: ReactNode;
}) => {
  const withNav = Children.toArray(children).some((child: any) => child.type === Nav);
  const hasHelper = Children.toArray(children).some((child: any) =>
    [Nav, Body, Column].includes(child.type)
  );

  if (!hasHelper) {
    // 100% width
    if (width === '100%') {
      return (
        <Box
          backgroundColor="Background Base"
          padding="32"
          paddingBottom="100"
          style={{minHeight: 'calc(100vh - 52px)'}}
        >
          {breadcrumb && <Breadcrumb path={breadcrumb} />}
          {children}
        </Box>
      );
    }

    // Condensed
    return (
      <Box
        padding="32"
        paddingBottom="100"
        backgroundColor="Background Base"
        className={styles.maxWidth}
        style={{minHeight: 'calc(100vh - 52px)'}}
      >
        <Box width="100%" marginX="auto" style={{maxWidth: 1024}}>
          {breadcrumb && <Breadcrumb path={breadcrumb} />}
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <GridContext.Provider value={{withNav}}>
      <Box
        backgroundColor="Background Base"
        className={styles.maxWidth}
        width="100%"
        style={{minHeight: 'calc(100vh - 52px)'}}
        display="flex"
        flexDirection="row"
      >
        {breadcrumb && <Breadcrumb path={breadcrumb} />}
        {children}
      </Box>
    </GridContext.Provider>
  );
};

const Nav = ({children, className}: {children: ReactNode; className?: string}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      position="fixed"
      height="100%"
      borderRight="Solid"
      borderColor="Border Subtle"
      backgroundColor="Background Base"
      padding="32"
      marginBottom={isMobile ? '16' : undefined}
      style={{
        width: 'calc(100vw * .35)',
        minWidth: 385,
        maxWidth: 584,
      }}
      className={className}
    >
      {children}
    </Box>
  );
};

type BreadcrumbBuilder = {title: BreadcrumbLinkProps; path: BreadcrumbLinkProps[]};

const isBreadcrumbBuilder = (
  breadcrumb: ReactNode | BreadcrumbBuilder
): breadcrumb is BreadcrumbBuilder => {
  return (
    typeof breadcrumb === 'object' &&
    breadcrumb !== null &&
    'title' in breadcrumb &&
    'path' in breadcrumb
  );
};

const Body = ({
  children,
  breadcrumb,
  className,
}: {
  breadcrumb?: ReactNode | BreadcrumbBuilder;
  children: ReactNode;
  className?: string;
}) => {
  const {withNav} = useContext(GridContext);
  // const isMobile = useAtomValue(isMobileAtom);

  if (!withNav) {
    throw new Error('Body must be a child of a Container with a Nav');
  }

  return (
    <Box
      width="100%"
      style={{
        marginLeft: 'max(min(calc(100vw * .35), 584px), 385px)',
      }}
      className={className}
    >
      <Box
        backgroundColor="Background Base"
        className={styles.maxWidth}
        padding="32"
        style={{minHeight: 'calc(100vh - 52px)'}}
      >
        <Box marginX="auto" style={{maxWidth: 1024}}>
          {breadcrumb &&
            (isBreadcrumbBuilder(breadcrumb) ? (
              <Breadcrumb path={[breadcrumb.title, ...breadcrumb.path]} />
            ) : (
              breadcrumb
            ))}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

/** Prefer other components to this one */
const Column = ({
  span,
  index,
  // preferComposition,
  children,
}: {
  span: 1 | 2 | 3 | 5 | 7;
  index?: number;
  // preferComposition?: boolean;
  children: ReactNode;
}) => {
  const {withNav} = useContext(GridContext);
  const size = withNav ? 7 : 5;
  const startColumn = index !== undefined ? index + 1 : Math.floor((size - span) / 2) + 1;

  return (
    <Box
      // paddingX={preferComposition ? undefined : '16'}
      style={{gridColumn: `${startColumn} / span ${span}`}}
    >
      {children}
    </Box>
  );
};

// Don't use this one, prefer InlineDrawerContainer
// export const DIGInlineDrawerContainer = ({
//   open,
//   setOpen,
//   breadcrumb,
//   drawerIcon,
//   drawerHeader,
//   drawerBody,
//   children,
//   onDrawerClose,
//   onDrawerOpen,
// }: {
//   open: boolean;
//   setOpen: (open: boolean) => void;
//   drawerHeader: string;
//   breadcrumb?: BreadcrumbLinkProps[];
//   drawerIcon?: UIIconProps['src'];
//   drawerBody: ReactNode;
//   children: ReactNode;
//   onDrawerClose?: () => void;
//   onDrawerOpen?: () => void;
// }) => {
//   return (
//     <Drawer.InlineFrame
//       style={{marginTop: '-52px', marginRight: '-68px'}}
//       withDrawer={
//         <Drawer.InlineDrawer
//           isOpen={open}
//           alignment="right"
//           width="475px"
//           style={{overflow: 'scroll', position: 'fixed', marginTop: '52px'}}
//           onWillClose={onDrawerClose}
//           onWillOpen={onDrawerOpen}
//         >
//           <DrawerContainer
//             header={drawerHeader}
//             icon={drawerIcon}
//             open={open}
//             onClose={() => setOpen(false)}
//           >
//             {drawerBody}
//           </DrawerContainer>
//         </Drawer.InlineDrawer>
//       }
//     >
//       <Box
//         marginTop="52"
//         marginX="auto"
//         // paddingLeft="8"
//         // paddingRight="36"
//         style={{...(open && {maxWidth: '737px'})}}
//       >
//         <Container breadcrumb={breadcrumb} size={open ? 'full' : 'condensed'}>
//           {children}
//         </Container>
//       </Box>
//     </Drawer.InlineFrame>
//   );
// };

const InlineDrawerContainer = ({
  open,
  onClose,
  width = 'default',
  breadcrumb,
  drawerIcon,
  drawerHeader,
  drawerBody,
  drawerWidth,
  children,
  preventEscapeClose,
}: {
  open: boolean;
  onClose: () => void;
  breadcrumb?: BreadcrumbLinkProps[];
  width?: GridSize;
  drawerHeader: ReactNode;
  drawerIcon?: UIIconProps['src'];
  drawerBody: ReactNode;
  preventEscapeClose?: boolean;
  children: ReactNode;
  drawerWidth?: CSSProperties['width'];
}) => {
  return (
    <>
      <Box className={open ? styles.open : undefined}>
        <Box className={cx(styles.container)}>
          {/*  {[styles.slide]: !isMobile && width === 'default'})}> */}
          <Container width={width}>
            {breadcrumb && <Breadcrumb path={breadcrumb} />}
            {children}
          </Container>
        </Box>

        <Box
          className={styles.drawer}
          style={
            drawerWidth
              ? {
                  width: drawerWidth,
                  right: open ? '0' : `-${drawerWidth}`,
                }
              : undefined
          }
        >
          <DrawerContainer
            header={drawerHeader}
            icon={drawerIcon}
            open={open}
            onClose={onClose}
            preventEscapeClose={preventEscapeClose}
          >
            {drawerBody}
          </DrawerContainer>
        </Box>
      </Box>
    </>
  );
};

export const Layout = {
  Container,
  Nav,
  Body,
  Column,
  InlineDrawerContainer,
};
