import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, BoxProps, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ActivityLine, CodeLine} from '@dropbox/dig-icons/assets';
import {Link} from 'components/DSYS/Link';
import {Table} from 'components/DSYS/Table';
import {ReactNode} from 'react';

export const ProjectNameCell2 = ({
  title,
  paddingLeft,
}: {
  title: string;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
}) => {
  return (
    <Table.Cell>
      <Box
        padding="4"
        overflow="hidden"
        whiteSpace="nowrap"
        style={{textOverflow: 'ellipsis'}}
        paddingLeft={paddingLeft}
      >
        <LabelGroup
          withText={
            <Truncate>
              <Text>{title}</Text>
            </Truncate>
          }
        />
      </Box>
    </Table.Cell>
  );
};

export const ProjectNameCell = ({
  indent,
  uselessTable,
  type,
  title,
  hasAssignee,
  subtitle,
  jira,
  assignee,
  marginLeft,
  paddingLeft,
  withLeftAccessory,
  isGroupedTable,
}: {
  indent: number;

  hasAssignee?: boolean;
  uselessTable?: boolean;
  type?: string;
  title: string;
  subtitle?: string;
  jira?: string;
  assignee?: {ldap: string; name: string};
  marginLeft?: BoxProps<'div'>['marginLeft'];
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  withLeftAccessory?: ReactNode;
  isGroupedTable?: boolean;
}) => {
  return (
    <Table.Cell>
      <Box
        overflow="hidden"
        whiteSpace="nowrap"
        style={{textOverflow: 'ellipsis'}}
        paddingLeft={paddingLeft}
      >
        <Box
          marginLeft={marginLeft}
          display="flex"
          paddingLeft={
            type !== 'Epic' && uselessTable
              ? isGroupedTable
                ? '8'
                : '2'
              : withLeftAccessory === undefined && marginLeft !== '0'
                ? '8'
                : '0'
          }
        >
          {uselessTable ||
            (withLeftAccessory !== undefined && (
              <Box display="flex" alignItems="center" padding="8" marginRight="2">
                {withLeftAccessory}
              </Box>
            ))}
          <Box
            as="div"
            borderLeft={uselessTable || withLeftAccessory !== undefined ? undefined : 'Solid'}
            borderColor="Border Subtle"
            borderWidth="1"
            alignItems="center"
            display="flex"
            paddingLeft={
              type !== 'Epic' && uselessTable
                ? indent === 1
                  ? '4'
                  : '0'
                : uselessTable
                  ? '8'
                  : withLeftAccessory !== undefined
                    ? '0'
                    : '20'
            }
            marginLeft="2"
          >
            <LabelGroup
              align="top"
              withText={
                <Split alignY="center" gap="6">
                  {type && (
                    <Split.Item>
                      <Box
                        as={UIIcon}
                        src={type?.toLowerCase() === 'epic' ? ActivityLine : CodeLine}
                        display="block"
                        size="small"
                        borderRadius="XSmall"
                        color={type?.toLowerCase() === 'epic' ? 'Background Base' : 'Text Subtle'}
                        style={{
                          filter: 'opacity(0.85)',
                          backgroundColor:
                            type?.toLowerCase() === 'epic'
                              ? 'rgba(171, 144, 219, 0.85)'
                              : '#9B640033',
                        }}
                      />
                    </Split.Item>
                  )}
                  <Split.Item>
                    <Box as={Text} isBold>
                      <Box
                        as="div"
                        style={{
                          textOverflow: 'ellipsis',
                          // maxWidth: width - (withLeftAccessory !== undefined ? 0 : 100),
                        }}
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {title}
                      </Box>
                    </Box>
                  </Split.Item>
                </Split>
              }
              withSubtext={
                subtitle !== undefined ? (
                  <Box as={Text} color="Text Subtle" size="small">
                    <Box
                      as="div"
                      style={{
                        textOverflow: 'ellipsis',
                        // maxWidth: width - (withLeftAccessory ? 0 : 80),
                      }}
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {subtitle}
                    </Box>
                  </Box>
                ) : (
                  <Box as={Split} gap="4" alignY="center">
                    <Split.Item>
                      <Link
                        hasNoUnderline
                        showUnderlineOnHover
                        to={`https://jira.dropboxer.net/browse/${jira}`}
                        target="_blank"
                      >
                        <Text color="subtle">{jira}</Text>
                      </Link>
                    </Split.Item>
                    {hasAssignee && (
                      <>
                        <Split.Item>•</Split.Item>
                        <Split.Item>
                          <Link
                            isClickable={Boolean(assignee?.ldap)}
                            hasNoUnderline
                            showUnderlineOnHover
                            to={`/people/${assignee?.ldap}`}
                          >
                            <Text color="subtle">{assignee?.name ?? 'Unassigned'}</Text>
                          </Link>
                        </Split.Item>
                      </>
                    )}
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </Table.Cell>
  );
};

const NameCellContent = ({
  width,
  type,
  title,
  subtitle,
  jira,
  assignee,
}: {
  width: number;
  type?: string;
  title: string;
  subtitle?: string;
  jira?: string;
  assignee?: {ldap: string; name: string};
}) => (
  <LabelGroup
    align="top"
    withText={
      <Split alignY="center" gap="6">
        {type && (
          <Split.Item>
            <Box
              as={UIIcon}
              src={type.toLowerCase() === 'epic' ? ActivityLine : CodeLine}
              display="block"
              size="small"
              borderRadius="XSmall"
              color={type.toLowerCase() === 'epic' ? 'Background Base' : 'Text Subtle'}
              style={{
                filter: 'opacity(0.85)',
                backgroundColor:
                  type.toLowerCase() === 'epic' ? 'rgba(171, 144, 219, 0.85)' : '#9B640033',
              }}
            />
          </Split.Item>
        )}
        <Split.Item>
          <Box as={Text} isBold>
            <Box
              as="div"
              style={{
                textOverflow: 'ellipsis',
                maxWidth: width,
              }}
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {title}
            </Box>
          </Box>
        </Split.Item>
      </Split>
    }
    withSubtext={
      subtitle !== undefined ? (
        <Box as={Text} color="Text Subtle" size="small">
          <Box
            as="div"
            style={{textOverflow: 'ellipsis', maxWidth: width}}
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {subtitle}
          </Box>
        </Box>
      ) : (
        <Box as={Split} gap="4" alignY="center">
          <Split.Item>
            <Link to={`https://jira.dropboxer.net/browse/${jira}`} target="_blank">
              <Text color="subtle">{jira}</Text>
            </Link>
          </Split.Item>
          <Split.Item>•</Split.Item>
          <Split.Item>
            <Link isClickable={Boolean(assignee?.ldap)} to={`/people/${assignee?.ldap}`}>
              <Text color="subtle">{assignee?.name ?? 'Unassigned'}</Text>
            </Link>
          </Split.Item>
        </Box>
      )
    }
  />
);

export const BasicNameCell = ({
  width,
  type,
  title,
  subtitle,
  jira,
  assignee,
}: {
  width: number;
  type?: string;
  title: string;
  subtitle?: string;
  jira?: string;
  assignee?: {ldap: string; name: string};
}) => (
  <Table.Cell>
    <Box display="flex">
      <Box as="div" alignItems="center" display="flex" padding="8" marginLeft="2">
        <NameCellContent
          width={width}
          type={type}
          title={title}
          subtitle={subtitle}
          jira={jira}
          assignee={assignee}
        />
      </Box>
    </Box>
  </Table.Cell>
);

// export const ButtonNameCell = ({
//   width,
//   type,
//   title,
//   subtitle,
//   jira,
//   assignee,
//   onClick,
// }: {
//   width: number;
//   type?: string;
//   title: string;
//   subtitle?: string;
//   jira?: string;
//   assignee?: {ldap: string; name: string};
//   onClick: () => void;
// }) => (
//   <Table.Cell
//     onClick={(e: any) => {
//       e.preventDefault();
//       e.stopPropagation();
//       onClick?.();
//     }}
//   >
//     <Box
//       as="button"
//       onClick={onClick}
//       padding="8"
//       borderRadius="Medium"
//       borderStyle="Solid"
//       backgroundColor="transparent"
//       borderWidth="0"
//       cursor="pointer"
//       outline="none"
//       textAlign="left"
//       width="100%"
//       boxShadow={{focusVisible: 'Focus Ring'}}
//       {...withShade({
//         direction: 'up',
//         bgColors: {
//           base: 'Opacity Surface',
//           active: 'Opacity Surface',
//         },
//         style: {marginLeft: -8},
//       })}
//     >
//       <NameCellContent
//         width={width}
//         type={type}
//         title={title}
//         subtitle={subtitle}
//         jira={jira}
//         assignee={assignee}
//       />
//     </Box>
//   </Table.Cell>
// );
