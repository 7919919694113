import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Drawer} from '@dropbox/dig-components/dist/drawer';
import {Split} from '@dropbox/dig-foundations';
import {UIIcon, UIIconProps} from '@dropbox/dig-icons';
import {CloseLine} from '@dropbox/dig-icons/assets';
import {useEffect} from 'react';

export const DrawerContainer = ({
  icon,
  open,
  header,
  children,
  preventEscapeClose,
  onClose,
}: {
  open: boolean;
  icon?: UIIconProps['src'];
  header: React.ReactNode;
  children: React.ReactNode;
  preventEscapeClose?: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    // Add a listener to keyboard esc key to close the live preview drawer
    const handleKeyDown = (event: KeyboardEvent) => {
      if (preventEscapeClose) {
        return;
      }
      if (!open) {
        return;
      }
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onClose, preventEscapeClose]);

  return (
    <Drawer.Container>
      <Drawer.Header>
        <Drawer.Layout style={{alignItems: 'flex-start'}}>
          <Drawer.LayoutItem shift="left" width="fill">
            <Split alignY="center">
              {icon && (
                <Split.Item>
                  <UIIcon src={icon} style={{marginTop: '4px', marginRight: '4px'}} />
                </Split.Item>
              )}
              <Split.Item>{header}</Split.Item>
            </Split>
          </Drawer.LayoutItem>
          <Drawer.LayoutItem shift="right">
            <IconButton variant="borderless" onClick={onClose}>
              <UIIcon src={CloseLine} />
            </IconButton>
          </Drawer.LayoutItem>
        </Drawer.Layout>
      </Drawer.Header>
      <Drawer.Body>{children}</Drawer.Body>
    </Drawer.Container>
  );
};
