/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyPeople } from '../models/MyPeople';
import type { NewsPostWithRead } from '../models/NewsPostWithRead';
import type { RecentUpdate } from '../models/RecentUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HomeService {

    /**
     * News
     * @returns NewsPostWithRead Successful Response
     * @throws ApiError
     */
    public static newsApiV1HomeNewsGet(): CancelablePromise<Array<NewsPostWithRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/news',
        });
    }

    /**
     * Goals
     * @returns RecentUpdate Successful Response
     * @throws ApiError
     */
    public static goalsApiV1HomeGoalsGet(): CancelablePromise<Array<RecentUpdate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/goals',
        });
    }

    /**
     * People
     * @param currentDate
     * @param id
     * @returns MyPeople Successful Response
     * @throws ApiError
     */
    public static peopleApiV1HomePeopleGet(
        currentDate?: (string | null),
        id?: (string | null),
    ): CancelablePromise<Array<MyPeople>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/people',
            query: {
                'current_date': currentDate,
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Calendar
     * @returns any Successful Response
     * @throws ApiError
     */
    public static calendarApiV1HomeCalendarGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/calendar',
        });
    }

    /**
     * Banner
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bannerApiV1HomeBannerGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/banner',
        });
    }

    /**
     * Stock
     * @returns any Successful Response
     * @throws ApiError
     */
    public static stockApiV1HomeStockGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/home/stock',
        });
    }

}
