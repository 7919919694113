import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee, GoalData, KeyResult} from 'client';
import {Facepile} from 'components/DSYS/Facepile';
import {useGoals} from 'components/goals/hooks';
import {groupGoalsByTimeframe} from 'components/shared/GoalSearchMenu';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {TIMEFRAME_OPTIONS} from 'constant';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import React, {Suspense, useMemo} from 'react';
import {emailToLdap} from 'utilities';
import {DrawerHeader} from 'views/goals_v2/Drawers/DrawerHeader';
import {EmptyOthersGoalsTable} from 'views/goals_v2/EmptyGoalsTable';
import {getKeyResultChildren} from 'views/goals_v2/GoalsTableRow';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';
import {groupGoalsByLdap} from 'views/goals_v2/utils';

import styles from './Drawer.module.css';
import {DrawerOverlay} from './DrawerOverlay';

const ParentGoalSearchDrawerHeader = ({onClose}: {onClose: () => void}) => {
  return (
    <DrawerHeader
      headerTitle={t('add_a_parent_goal')}
      headerIcon={<SubtleObjectiveIcon />}
      onClose={onClose}
    />
  );
};

export const ParentGoalSearchDrawer = ({
  isOpen,
  onClose,
  setParentGoal,
  setParentKeyResult,
  selectedParentGoal,
}: {
  isOpen: boolean;
  onClose: () => void;
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  selectedParentGoal?: GoalData;
}) => {
  // only retrieve the employee data if the drawer is open, otherwise it will cause re-rendering issues.
  const data = useEmployee({
    ldap: isOpen && selectedParentGoal ? emailToLdap(selectedParentGoal.users?.[0].email) : '',
  });

  return (
    <DrawerOverlay
      isOpen={isOpen}
      header={<ParentGoalSearchDrawerHeader onClose={onClose} />}
      body={
        data.isLoading ? (
          <Skeleton.Box height={60} style={{width: '100%'}} withBorderRadius="medium" />
        ) : (
          <ParentGoalSearchBody
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            onClose={onClose}
            selectedGoalOwnerEmployee={data.employee}
          />
        )
      }
    />
  );
};

const ParentGoalSearchBody = ({
  setParentGoal,
  setParentKeyResult,
  onClose,
  selectedGoalOwnerEmployee,
}: {
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  onClose: () => void;
  selectedGoalOwnerEmployee?: Employee;
}) => {
  // add default selected employees
  const {reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const initialSelectedEmployees = selectedGoalOwnerEmployee
    ? [selectedGoalOwnerEmployee]
    : reportingLine.length > 1
      ? [reportingLine[1]]
      : [];
  const [selectedEmployees, setSelectedEmployees] =
    React.useState<Employee[]>(initialSelectedEmployees);
  const selectEmployee = (employee: Employee) => {
    setSelectedEmployees([employee]);
  };

  const selectEmployees = (employees: Employee[]) => {
    setSelectedEmployees([...employees]);
  };

  const removeEmployee = (employee: Employee) => {
    setSelectedEmployees(selectedEmployees.filter((e) => e.ldap !== employee.ldap));
  };

  return (
    <>
      <PeopleSearchMenu
        selectedEmployees={selectedEmployees ?? []}
        onRemoveEmployee={removeEmployee}
        onSelectEmployee={selectEmployee}
        onSelectEmployees={selectEmployees}
        showReportingLine={true}
        minHeight="48px"
        isSingleSelect={true}
        searchIconPosition="left"
        allowSelf={true}
      />
      {selectedEmployees.length > 0 && (
        <Suspense
          fallback={
            <Stack gap="8">
              <Skeleton.Box width={60} height={24} withBorderRadius="medium" />
              <Skeleton.Box height={60} style={{width: '100%'}} withBorderRadius="medium" />
            </Stack>
          }
        >
          <GoalsSection
            selectedEmployee={selectedEmployees[0]}
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            onClose={onClose}
          />
        </Suspense>
      )}
      {selectedEmployees.length === 0 && (
        <EmptyOthersGoalsTable
          containerProps={{borderRadius: 'Medium'}}
          title={t('goals_new_parent_goal_none')}
          subtitle={t('goals_new_parent_goal_none_subtitle')}
        />
      )}
    </>
  );
};

const GoalsSection = ({
  selectedEmployee,
  setParentGoal,
  setParentKeyResult,
  onClose,
}: {
  selectedEmployee: Employee;
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  onClose: () => void;
}) => {
  const employeeLdap = selectedEmployee.ldap;
  const {goals} = useGoals({ldaps: [employeeLdap], throwOnError: false});

  const groupGoalsByEmployeeLdap = useMemo(
    () => groupGoalsByLdap(goals, [employeeLdap], true),
    [goals, employeeLdap]
  );
  const sortedGoals = groupGoalsByEmployeeLdap[employeeLdap];
  const groupedGoals = groupGoalsByTimeframe(sortedGoals);
  const sortedTimeframes = Object.keys(groupedGoals).sort((a, b) => {
    return Object.keys(TIMEFRAME_OPTIONS).indexOf(b) - Object.keys(TIMEFRAME_OPTIONS).indexOf(a);
  });

  // check if there are no grouped goals
  const isGroupedGoalsEmpty = Object.values(groupedGoals).every(
    (goals_entry) => goals_entry.length === 0
  );

  if (isGroupedGoalsEmpty) {
    return (
      <EmptyOthersGoalsTable
        containerProps={{borderRadius: 'Medium'}}
        title={t('goals_new_parent_goal_none')}
        subtitle={t('goals_new_parent_goal_none_subtitle')}
      />
    );
  }

  return (
    <Stack gap="16" style={{width: '100%'}}>
      {sortedTimeframes.map((timeframe) => {
        return (
          <TimeframeGoals
            key={timeframe}
            timeframe={timeframe}
            goals={groupedGoals[timeframe]}
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            onClose={onClose}
          />
        );
      })}
    </Stack>
  );
};

const TimeframeGoals = ({
  timeframe,
  goals,
  setParentGoal,
  setParentKeyResult,
  onClose,
}: {
  timeframe: string;
  goals: GoalData[];
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  onClose: () => void;
}) => {
  return (
    <Stack gap="8" style={{width: '100%'}}>
      <Box as={Text} isBold variant="paragraph">
        {timeframe}
      </Box>
      <Stack gap="16">
        {goals.map((goal) => {
          return (
            <GoalCell
              key={goal.id}
              timeframe={timeframe}
              goal={goal}
              setParentGoal={setParentGoal}
              setParentKeyResult={setParentKeyResult}
              onClose={onClose}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

const GoalCell = ({
  timeframe,
  goal,
  setParentGoal,
  setParentKeyResult,
  onClose,
}: {
  timeframe: string;
  goal: GoalData;
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  onClose: () => void;
}) => {
  return (
    <Stack style={{border: '1px solid var(--dig-color__border__subtle)', borderRadius: '8px'}}>
      <Row
        type="goal"
        goal={goal}
        title={goal.title}
        timeframe={timeframe}
        ownerLdap={emailToLdap(goal.users?.[0].email)}
        subGoalsCount={goal.children?.length ?? 0}
        setParentGoal={setParentGoal}
        setParentKeyResult={setParentKeyResult}
        isLastRow={goal.key_results.length === 0}
        onClose={onClose}
      />
      {goal.key_results?.map((keyResult, index) => {
        return (
          <Row
            key={keyResult.id}
            goal={goal}
            keyResult={keyResult}
            type="keyresult"
            title={keyResult.title}
            timeframe={timeframe}
            ownerLdap={emailToLdap(goal.users?.[0].email)}
            subGoalsCount={getKeyResultChildren(keyResult.id, goal)?.length ?? 0}
            contributorsLdap={keyResult.contributors?.map((employeeLdap) => employeeLdap.ldap)}
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            isLastRow={index === goal.key_results.length - 1}
            onClose={onClose}
          />
        );
      })}
    </Stack>
  );
};

const Row = ({
  type,
  goal,
  keyResult,
  title,
  timeframe,
  ownerLdap,
  subGoalsCount,
  contributorsLdap,
  setParentGoal,
  setParentKeyResult,
  isLastRow = false,
  onClose,
}: {
  type: 'goal' | 'keyresult';
  goal: GoalData;
  keyResult?: KeyResult;
  title: string;
  timeframe: string;
  ownerLdap: string;
  subGoalsCount?: number;
  contributorsLdap?: string[];
  setParentGoal?: (parentGoal: GoalData) => void;
  setParentKeyResult?: (parentKeyResult?: KeyResult) => void;
  isLastRow: boolean;
  onClose: () => void;
}) => {
  const format = () => {
    const counts = t('sub_goal', {count: subGoalsCount});
    return subGoalsCount ? `${timeframe} • ${counts}` : timeframe;
  };

  const subTitle = format();

  const onClick = () => {
    if (type === 'goal' && setParentGoal) {
      setParentGoal(goal);
      setParentKeyResult!(undefined);
    } else if (type === 'keyresult' && setParentKeyResult) {
      setParentGoal!(goal);
      setParentKeyResult(keyResult!);
    }
    onClose();
  };

  return (
    <Box
      width="100%"
      display="flex"
      style={{
        borderTop: type === 'goal' ? '' : '1px solid var(--dig-color__border__subtle)',
        borderTopRightRadius: type === 'goal' ? '8px' : '',
        borderTopLeftRadius: type === 'goal' ? '8px' : '',
        borderBottomRightRadius: isLastRow ? '8px' : '',
        borderBottomLeftRadius: isLastRow ? '8px' : '',
      }}
      alignItems="flex-start"
      padding="12"
      onClick={onClick}
      className={styles.parentGoalRow}
    >
      <Box style={{marginTop: '-2px'}}>
        {type === 'goal' ? <SubtleObjectiveIcon /> : <SubtleKeyResultIcon />}
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        marginLeft="6"
      >
        <LabelGroup withText={title} withSubtext={type === 'goal' ? subTitle : ''} />
        <Box>
          <Facepile
            ownerIsFirst
            ldaps={[ownerLdap, ...(type === 'keyresult' ? (contributorsLdap ?? []) : [])]}
            size="small"
          />
        </Box>
      </Box>
    </Box>
  );
};
