import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {EditLine, GratitudeLine, SoundOnLine, ThumbsUpLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {isMobileAtom} from 'atoms/layout';
import {Employee, EmployeeFull, Team} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {isDev} from 'helpers/environment';
import {isSuperUser} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Fragment, useState} from 'react';

import {KudosModal} from './KudosModal';
import styles from './ProfileBody.module.css';
import {ProfileConfetti} from './ProfileConfetti';
import {ProfileDetails} from './ProfileDetails';

interface ProfileBodyProps {
  employee: EmployeeFull;
  employeeTeams: Team[];
  admin?: Employee | null;
  disableConfetti?: boolean;
  onEditTeams?: () => void;
}

const currentDate = new Date();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
const currentDay = String(currentDate.getDate()).padStart(2, '0');
const formattedCurrentDate = `${currentMonth}/${currentDay}`;

const getConfettiType = (employee: EmployeeFull) => {
  if (employee.is_deactivated) {
    return undefined;
  }

  if (employee.birthday === formattedCurrentDate) {
    return 'birthday';
  }

  if (employee?.tenure != null && employee.tenure > 0 && employee.tenure % 12 == 0) {
    return 'anniversary';
  }

  return undefined;
};

export const ProfileBody = (props: ProfileBodyProps) => {
  const isMobile = useAtomValue(isMobileAtom);
  const [isKudosOpen, showKudosModal] = useState(false);
  const pulseUser = useAtomValue(pulseUserAtom);

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  const confettiType = props.disableConfetti ? undefined : getConfettiType(props.employee);

  const canEditTeam = pulseUser?.email === props.employee.email;

  return (
    <div className={styles.profileBodySection}>
      {confettiType && <ProfileConfetti ldap={props.employee.ldap} type={confettiType} />}
      <div className={styles.profileNameAvatar}>
        <Box position="relative">
          <Avatar
            user={props.employee}
            isInactive={Boolean(props.employee.is_deactivated)}
            onLeave={props.employee.on_leave}
            size="profile"
            key={props.employee.ldap}
          />
        </Box>
        <div className={styles.profileNameCard}>
          <div className={styles.profileNameHeader}>
            <Title
              size={24}
              withAccessoryEnd={
                <>
                  {window.speechSynthesis && props.employee.pronunciation && (
                    <>
                      <IconButton
                        variant="borderless"
                        onClick={() => {
                          profileActionLog('pronunciation');
                          window.speechSynthesis.speak(
                            new SpeechSynthesisUtterance(props.employee.pronunciation!)
                          );
                        }}
                      >
                        <Box as={UIIcon} color="Text Subtle" src={SoundOnLine} />
                      </IconButton>{' '}
                    </>
                  )}
                  {props.employee.pronouns && (
                    <Text size="small" color="faint">
                      {props.employee.pronouns}
                    </Text>
                  )}
                </>
              }
            >
              {props.employee.name !== ' ' ? props.employee.name : props.employee.ldap}
            </Title>
          </div>
          <Box>
            <Text size="large" color="faint">
              {props.employee.role}
              {props.employee.type == 'Regular' &&
                props.employee.level &&
                ` (${props.employee.level})`}
            </Text>
            {props.employee.type !== 'Regular' && (
              <Text size="large" color="faint">
                {''} ({props.employee.type})
              </Text>
            )}
            {props.employeeTeams && props.employeeTeams.length > 0 && (
              <Text size="large" color="faint">
                {' '}
                on{' '}
              </Text>
            )}
            {props.employeeTeams &&
              props.employeeTeams.length > 0 &&
              props.employeeTeams.map(
                (team, index) =>
                  team && (
                    <Fragment key={team.slug}>
                      <Text size="large" color="faint">
                        {index > 0 && <strong>{', '}</strong>}
                        <Link
                          to={`/teams/${team.slug}`}
                          onClick={() => profileActionLog('team')}
                          monochromatic
                          hasNoUnderline
                          showUnderlineOnHover
                          isBold
                        >
                          {team.name}
                        </Link>
                      </Text>
                    </Fragment>
                  )
              )}
            {canEditTeam && Boolean(props.employeeTeams?.length) && (
              <IconButton
                onClick={() => props.onEditTeams?.()}
                variant="borderless"
                style={{marginBottom: 6}}
              >
                <UIIcon src={EditLine} />
              </IconButton>
            )}
          </Box>
          {canEditTeam && !props.employeeTeams?.length && (
            <Button
              className={atoms({marginTop: '8'})}
              onClick={() => props.onEditTeams?.()}
              variant="outline"
              withIconStart={<UIIcon src={EditLine} />}
            >
              {t('edit_teams')}
            </Button>
          )}
          {!props.employee.is_deactivated && props.employee.email !== pulseUser?.email && (
            <Split gap={isMobile ? '4' : '12'} direction={isMobile ? 'vertical' : 'horizontal'}>
              <Split.Item>
                <ButtonLink
                  to={`/gratitude?to=${props.employee.ldap}`}
                  variant="outline"
                  withIconStart={<UIIcon src={GratitudeLine} />}
                  className={styles.gratitudeButton}
                  onClick={() => profileActionLog('gratitude')}
                >
                  {t('send_gratitude')}
                </ButtonLink>
              </Split.Item>
              {isDev && isSuperUser(pulseUser?.email) && (
                <Split.Item>
                  <Button
                    variant="outline"
                    withIconStart={<UIIcon src={ThumbsUpLine} />}
                    className={styles.gratitudeButton}
                    onClick={() => showKudosModal(true)}
                  >
                    {t('give_kudos')}
                  </Button>
                </Split.Item>
              )}
            </Split>
          )}
        </div>
      </div>
      {!props.employee.is_deactivated && (
        <ProfileDetails employee={props.employee} admin={props.admin} onClick={profileActionLog} />
      )}
      <KudosModal
        open={isKudosOpen}
        closeModal={() => showKudosModal(false)}
        employee={props.employee}
      />
    </div>
  );
};
