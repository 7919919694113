import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  ChevronDownLine,
  PersonMultipleLine,
  TeamLine,
  WarningLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {growthbookCacheAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import cx from 'classnames';
import {EmployeeWithReportTeams, TeamAssociation, ToolsService} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmployeeTeamTypeahead} from 'components/DSYS/EmployeeTeamTypeahead';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Table} from 'components/DSYS/Table';
import {SavableTeamListTypeahead} from 'components/DSYS/TeamListTypeahead';
import {Title} from 'components/DSYS/Title';
import {LabelGroupCell} from 'components/shared/table/LabelGroupCell';
import {useHierarchy} from 'components/teams/hooks';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {isEmployee, useEditTeams} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {NotFound} from 'pages/NotFound';
import {useEffect, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {Checkmark} from './Checkmark';
import styles from './CheckupTool.module.css';
import {ExportCSVButton} from './ExportCSVButton';
import {getCheckupError} from './util';

export const recentSearchAtom = atomWithStorage(
  'checkup-searches',
  [] as {label: string; type: 'employee' | 'team'; id: string}[]
);

const columnConfigs = [
  {type: 'name', minWidth: 120, width: 270},
  {type: 'status', fixed: true},
  {type: 'allocation', minWidth: 450},
];

const EmployeeTeamSearch = ({
  uid,
  setUid,
}: {
  uid: string;
  setUid: (uid: string, type: 'employee' | 'team') => void;
}) => {
  const [recentSearches, setRecentSearches] = useAtom(recentSearchAtom);
  const {reportingLine, employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  const onSelect = (label: string, type: 'employee' | 'team', id: string) => {
    setUid(id, type);
    if (recentSearches.length >= 7) {
      recentSearches.length = 7;
    }
    setRecentSearches([{label, type, id}, ...recentSearches.filter((search) => search.id !== id)]);
  };

  return (
    <Menu.Wrapper>
      {({getTriggerProps, getContentProps, closeMenu}) => (
        <>
          <IconButton
            {...getTriggerProps({
              onKeyDown: (e) => e.stopPropagation(),
            })}
            variant="transparent"
          >
            <UIIcon src={ChevronDownLine} className={atoms({color: 'Text Subtle'})} />
          </IconButton>
          <Menu.Content {...getContentProps()} placement="right-start" style={{width: 490}}>
            <Box paddingX="16" paddingTop="16">
              <EmployeeTeamTypeahead
                selection={uid}
                onSelect={(label, type, id) => {
                  closeMenu({});
                  onSelect(label, type as 'employee' | 'team', id);
                }}
              />
            </Box>
            <Box
              paddingX="16"
              paddingY="4"
              display="flex"
              paddingBottom="8"
              flexDirection="row"
              style={{gap: 4, marginTop: -16}}
            >
              {employeeTeams?.[0] && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(employeeTeams[0].slug ?? '', 'team');
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={TeamLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{employeeTeams[0].name}</Box>
                </Chip>
              )}
              {employeeTeams?.[1] && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(employeeTeams[1].slug ?? '', 'team');
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={TeamLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{employeeTeams[1].name}</Box>
                </Chip>
              )}
              {reportingLine.length > 1 && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(reportingLine[1].ldap, 'employee');
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={PersonMultipleLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{reportingLine?.[1].name}</Box>
                </Chip>
              )}
            </Box>
            {Boolean(recentSearches.length) && (
              <Box
                paddingX="16"
                paddingBottom="16"
                borderTop="Solid"
                borderColor="Border Subtle"
                borderWidth="1"
              >
                <Menu.Segment withLabel={t('recent')}>
                  {recentSearches.map(({label, id, type}) => (
                    <Menu.ActionItem
                      key={id}
                      onClick={() => setUid(id, type)}
                      className={atoms({borderRadius: 'Small'})}
                    >
                      {label}
                    </Menu.ActionItem>
                  ))}
                </Menu.Segment>
              </Box>
            )}
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const CheckupTool = () => {
  const id = useLoaderData() as string;
  const {employee, directReports, reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const hierarchy = useHierarchy({slug: id});

  const {isExportEnabled} = useAtomValue(growthbookCacheAtom);
  const [employeeDrawer, setDrawerOpen] = useState<EmployeeWithReportTeams | undefined>(undefined);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const navigate = useNavigate();

  const {data, isLoading} = useQuery({
    queryKey: ['checkup', id],
    queryFn: () => getService(ToolsService).teamAuditApiV1AuditToolIdGet(id),
    enabled: Boolean(id),
  });

  const {editTeams, isPending} = useEditTeams({id, ldap: employeeDrawer?.ldap ?? ''});

  useDocumentTitle(data?.checkup.name ?? '');

  const handleSetId = (selected: string, type: 'employee' | 'team') => {
    setDrawerOpen(undefined);
    navigate(`/${type === 'employee' ? 'people' : 'teams'}/${selected}/checkup`);
  };

  const onCancel = () => {
    setDrawerOpen(undefined);
  };

  const onSave = async (selections: TeamAssociation[]) => {
    try {
      await editTeams({
        teams: selections.map((assoc) => ({
          team_id: assoc.team.team_id,
          slug: assoc.team.slug ?? '',
          allocation: assoc.allocation,
        })),
      });

      setSnackbarMessage({text: t('saved')});

      setDrawerOpen(undefined);
    } catch (e) {
      setSnackbarMessage({text: t('error_saving')});
    }
  };

  useEffect(() => {
    if (!id && employee && reportingLine?.length) {
      if (directReports.length) {
        navigate(`/people/${employee.ldap}/checkup`);
      } else if (reportingLine.length > 1) {
        navigate(`/people/${reportingLine[1].ldap}/checkup`);
      }
    }
  }, [directReports.length, employee, id, navigate, reportingLine]);

  if (id && !isLoading && !data) {
    return <NotFound />;
  }

  if (!id) {
    return null;
  }

  let breadcrumbs = [
    {children: t('people'), to: '/people'},
    ...(reportingLine ?? [])
      .map(({name, ldap}) => ({
        key: ldap,
        children: name,
        to: `/people/${ldap}`,
      }))
      .reverse(),
  ];
  if (hierarchy) {
    breadcrumbs = [
      {children: t('teams'), to: '/teams'},
      ...hierarchy.hierarchy.map(({name, slug}) => ({
        key: slug,
        children: name ?? '',
        to: `/teams/${slug}`,
      })),
    ];
  }

  return (
    <Stack gap="16">
      <Layout.InlineDrawerContainer
        open={Boolean(employeeDrawer)}
        breadcrumb={[
          ...breadcrumbs,
          {
            children: t('checkup'),
            to: '/checkup',
          },
        ]}
        drawerHeader={<Title size={18}>{t('edit_teams')}</Title>}
        drawerIcon={TeamLine}
        drawerBody={
          employeeDrawer ? (
            <SavableTeamListTypeahead
              isPending={isPending}
              employee={employeeDrawer}
              memberSort={(a, b) => (a.team.name ?? '').localeCompare(b.team.name ?? '')}
              onSave={onSave}
              onCancel={onCancel}
              withRightAccessory={(assoc) => <Text>{assoc.allocation}%</Text>}
            />
          ) : null
        }
        onClose={() => setDrawerOpen(undefined)}
      >
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" style={{gap: 2}}>
            {isLoading ? (
              <Skeleton.Box width={120} height={32} className={atoms({marginRight: '4'})} />
            ) : (
              <Title className={atoms({marginRight: '4'})}>{data?.checkup.name}</Title>
            )}
            <Checkmark id={id} type="people" />
            <EmployeeTeamSearch uid={id} setUid={handleSetId} />
          </Box>

          {isExportEnabled && <ExportCSVButton />}
        </Box>
        <Text size="small" color="subtle">
          {isLoading ? (
            <Skeleton.Text width={200} />
          ) : isEmployee(data?.checkup) ? (
            t('direct_reports', {count: data?.checkup.direct_report_count ?? 0}) +
            ((data?.employees.length ?? 0) < (data?.checkup.direct_report_count ?? 0)
              ? ` ${t('direct_report_contractors', {count: (data?.checkup.direct_report_count ?? 0) - (data?.employees.length ?? 0)})}`
              : '') +
            ((data?.checkup.total_report_count ?? 0) !== (data?.checkup.direct_report_count ?? 0)
              ? ` • ${t('total_reports', {count: data?.checkup.total_report_count ?? 0})}`
              : '')
          ) : (
            t('member', {
              count: data?.checkup.total_employee_count,
              countString: data?.checkup.total_employee_count.toLocaleString(),
            }) +
            ((data?.employees.length ?? 0) < (data?.checkup.total_employee_count ?? 0)
              ? ` ${t('direct_report_contractors', {
                  count: (data?.checkup.total_employee_count ?? 0) - (data?.employees.length ?? 0),
                })}`
              : '') +
            (data?.checkup.subteam_count
              ? ` • ${t('sub_team', {count: data?.checkup.subteam_count})}`
              : '')
          )}
        </Text>

        <Box marginTop="24">
          <CheckupTable employees={data?.employees} onClick={setDrawerOpen} />
        </Box>
      </Layout.InlineDrawerContainer>
    </Stack>
  );
};

const DSYSTableRow = ({
  onClick,
  ...data
}: EmployeeWithReportTeams & {
  onClick: (employee: EmployeeWithReportTeams) => void;
}) => {
  const status = getCheckupError(data);

  const hasAllocation = data.team_associations.some((assoc) => assoc.allocation !== null);

  return (
    <Box
      as={Table.Row}
      className={cx(atoms({backgroundColor: status ? 'Alert Surface' : undefined}), {
        [styles.error]: status,
      })}
      isSelectable
      onClick={() => onClick(data)}
    >
      <LabelGroupCell
        isBold
        text={data.name}
        subText={
          <>
            <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{data.role ?? ''}</span>
            {data.level ? <span style={{paddingLeft: 4}}>({data.level})</span> : ''}
          </>
        }
        withLeftAccessory={<Avatar user={data} />}
      />
      <LabelGroupCell
        text={
          status === 'checkup_reports_error' ? (
            <ButtonLink variant="transparent" to={`/people/${data.ldap}/checkup`} target="_blank">
              <Box display="flex" alignItems="center">
                {t('checkup_reports_error')}
              </Box>
            </ButtonLink>
          ) : (
            <Box display="flex">{status ? t(status) : undefined}</Box>
          )
        }
        withLeftAccessory={
          <UIIcon
            src={status ? WarningLine : CheckmarkLine}
            className={atoms({color: 'Text Subtle'})}
          />
        }
      />
      <Table.Cell>
        <Text>
          {data.team_associations
            .map(
              ({team, allocation}) =>
                `${team.name ?? ''}${` (${allocation ?? (hasAllocation ? 0 : ((1 / data.team_associations.length) * 100).toFixed())}%)`}`
            )
            .sort()
            .join(', ')}
        </Text>
      </Table.Cell>
    </Box>
  );
};

const checkupErrorSort = [
  'checkup_no_teams',
  'checkup_reports_error',
  'checkup_only_member',
  'checkup_allocation',
  'checkup_too_many_teams',
  '',
];
const sortTable = (a: EmployeeWithReportTeams, b: EmployeeWithReportTeams) => {
  const aError = getCheckupError(a);
  const bError = getCheckupError(b);

  if (aError !== bError) {
    return checkupErrorSort.indexOf(aError ?? '') - checkupErrorSort.indexOf(bError ?? '');
  }

  if (a.level !== b.level) {
    return (b.level ?? '').localeCompare(a.level ?? '');
  }

  return (a.name ?? '').localeCompare(b.name ?? '');
};

const CheckupTable = ({
  employees,
  onClick,
}: {
  employees?: EmployeeWithReportTeams[];
  onClick: (employee: EmployeeWithReportTeams) => void;
}) => {
  const sortedTable = employees?.sort(sortTable);

  return (
    <Box maxWidth="100%" marginX="auto">
      <Table columns={columnConfigs} data={sortedTable ?? []}>
        <Table.Header />
        <Table.Body>
          {!sortedTable ? (
            <Table.Row style={{height: 680}} />
          ) : (
            sortedTable.map((row) => <DSYSTableRow key={row.ldap} {...row} onClick={onClick} />)
          )}
        </Table.Body>
      </Table>
    </Box>
  );
};
