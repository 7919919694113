import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {Twinkle2Line} from '@dropbox/dig-icons/assets';
import cx from 'classnames';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';
import {t} from 'i18next';

import styles from './StatusButton.module.css';
import {StatusButtonIcon} from './StatusButtonIcon';

/*
 * StatusButton is a button that displays a status with an icon
 *
 * @param {string} status - The status to display
 * @param {string} substatus - The optional substatus to display
 * @param {boolean} isLoading - Whether the button is in a loading state
 * @param {boolean} isDisabled - Whether the button is disabled (also removes the border)
 * @param {boolean} isLabel - Whether the button should be displayed as a label (removes the padding)
 * @param {() => void} onClick - The function to call when the button is clicked
 */
export const StatusButton = ({
  status,
  substatus,
  disabled,
  isLoading,
  isLabel,
  tooltipText,
  hideIcon,
  hideIconEnd,
  size = 'small',
  onClick,
}: {
  status: string;
  substatus?: string;
  disabled?: boolean;
  isLoading?: boolean;
  tooltipText?: string;
  isLabel?: boolean;
  size?: 'small' | 'medium';
  hideIcon?: boolean;
  hideIconEnd?: boolean;
  onClick?: () => void;
}) => (
  <OptionalTooltip enabled={!!tooltipText} title={tooltipText}>
    <Box
      as={Button}
      isLoading={isLoading}
      disabled={disabled || isLabel}
      variant="outline"
      withIconStart={!hideIcon && <StatusButtonIcon status={status} substatus={substatus} />}
      withIconEnd={
        !!tooltipText &&
        !hideIconEnd && (
          <Box as={UIIcon} src={Twinkle2Line} color="Text Subtle" size={size} marginLeft="2" />
        )
      }
      className={cx(styles.statusButton, {
        [styles.removePadding]: isLabel,
      })}
      onClick={(e: React.SyntheticEvent) => {
        onClick && onClick();
        e.stopPropagation();
      }}
      size={size}
    >
      <Text isBold size={size}>
        {t(status ?? 'no_status').toString()}
      </Text>
    </Box>
  </OptionalTooltip>
);
