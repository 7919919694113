import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Table} from 'components/DSYS/Table';
import {TimeAgo} from 'components/shared/TimeAgo';

export const TimestampCell = ({
  timestamp,
  onClick,
  canEdit,
}: {
  timestamp: string;
  onClick?: () => void;
  canEdit?: boolean;
}) => {
  return (
    <Table.Cell onClick={onClick}>
      <Box as="div" paddingTop={canEdit ? '8' : '16'} paddingLeft="16">
        <Text color="faint" size="small">
          <TimeAgo timestamp={timestamp} />
        </Text>
      </Box>
    </Table.Cell>
  );
};
