import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {List} from '@dropbox/dig-components/dist/list';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {WinLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {snackbarAtom} from 'atoms/snackbar';
import classNames from 'classnames';
import {Badge, Employee} from 'client';
import {BadgeImage} from 'components/badges/BadgeImage';
import {Avatar} from 'components/DSYS/Avatar';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useSelectEmployees} from 'hooks/useSelectEmployee';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getBadgesService} from 'utilities';

import styles from './BadgeInfo.module.css';
import {BadgeModalHeader, BadgeModalHeaderProps} from './BadgeModalHeader';

interface Props extends BadgeModalHeaderProps {
  badge: Badge;
  recipients?: Employee[];
  owners?: Employee[];
  closeModal?: () => void;
}

export const BadgeInfo = ({
  badge,
  owners,
  recipients,
  closeModal,
  ...backToAllBadgesProps
}: Props) => {
  const user = useAtomValue(pulseUserAtom);

  const [viewState, setViewState] = useState<'info' | 'recipients' | 'nominate'>('info');

  const headerProps = useMemo<BadgeModalHeaderProps>(() => {
    switch (viewState) {
      case 'info':
        return backToAllBadgesProps;
      case 'nominate':
      case 'recipients':
        return {
          label: 'Back',
          onBack: () => setViewState('info'),
        };
    }
  }, [viewState, backToAllBadgesProps]);

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={{backgroundColor: badge.color ? badge.color + '5e' : ''}}
      >
        <BadgeModalHeader {...headerProps} />
        <div className={styles.headerImage}>
          <BadgeImage badge={badge.image_key} size={viewState != 'info' ? 64 : 100} hideSkeleton />
        </div>
        <Text
          tagName="div"
          size="small"
          isBold
          className={classNames(styles.titleHeader, {
            [styles.visible]: viewState !== 'info',
          })}
        >
          {badge.name}
        </Text>
        <div className={styles.overlay} />
      </div>
      {viewState === 'info' && (
        <>
          <div className={styles.body}>
            <div className={styles.nameAndDescription}>
              <Text variant="paragraph" size="large" isBold>
                {badge.name}
              </Text>
              <Text variant="paragraph" color="faint">
                {badge.description}
              </Text>
            </div>
            <AwardedTo recipients={recipients} onClick={() => setViewState('recipients')} />
            <div className={styles.buttons}>
              <Box
                className={styles.buttonGroup}
                as="span"
                display="flex"
                borderRadius="Medium"
                boxShadow={{
                  focusVisible: 'Focus Ring',
                }}
                outlineWidth="0"
                tabIndex={0}
              >
                <NominateButton
                  onClick={() => setViewState('nominate')}
                  disabled={badge.hidden_from_library}
                />
                {owners && owners.find((owner) => owner.email === user?.email) && (
                  <Button variant="outline" href={`/admin/badges?badge=${badge.id}`}>
                    <Text isBold color="standard">
                      {t('manage')}
                    </Text>
                  </Button>
                )}
              </Box>
            </div>
          </div>
          <Footer owners={owners} />
        </>
      )}
      {viewState === 'recipients' && (
        <RecipientList recipients={recipients || []} closeModal={closeModal} />
      )}
      {viewState === 'nominate' && <Nominate badge={badge} onSubmit={() => setViewState('info')} />}
    </div>
  );
};

export const LoadedBadgeInfo = (props: Omit<Props, 'owners' | 'recipients'>) => {
  const [recipients, setRecipients] = useState<Employee[] | undefined>();
  const [owners, setOwners] = useState<Employee[] | undefined>();
  useEffect(() => {
    getBadgesService().getBadgeOwnersApiV1BadgesIdOwnersGet(props.badge.id).then(setOwners);
    getBadgesService()
      .getBadgeRecipientsApiV1BadgesIdRecipientsGet(props.badge.id)
      .then(setRecipients);
  }, [props.badge.id]);
  return <BadgeInfo {...props} recipients={recipients} owners={owners} />;
};

const RecipientList = ({
  recipients,
  closeModal,
}: {
  recipients: Employee[];
  closeModal?: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <List isSelectable paddingBottom={24}>
      {recipients.map((recipient) => (
        <List.Item
          paddingLeft={32}
          paddingRight={24}
          key={recipient.user_id}
          onClick={() => {
            navigate(`/people/${recipient.ldap}`);
            if (closeModal) closeModal();
          }}
        >
          <List.Accessory>
            <Avatar user={recipient} />
          </List.Accessory>
          <List.Content>
            <LabelGroup withText={recipient.name} withSubtext={recipient.role} />
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const Footer = ({owners}: {owners?: Employee[]}) => {
  if (owners === undefined) {
    return (
      <div className={styles.footer}>
        <Skeleton.Box height={16} />
      </div>
    );
  } else {
    return (
      <div className={styles.footer}>
        {owners.length > 0 ? (
          <>
            <Text variant="label" color="faint" size="small">
              {t('badge_managed_by')}
            </Text>
            &nbsp;
            <Text color="standard" variant="label" size="small" isBold>
              {(owners || []).map((owner) => owner.name.replace(/-/g, '\u2011')).join(', ')}
            </Text>
          </>
        ) : (
          <Text variant="label" color="faint" size="small">
            {t('badge_managed_by_no_ne')}
          </Text>
        )}
      </div>
    );
  }
};

const AwardedTo = ({recipients, onClick}: {recipients?: Employee[]; onClick: () => void}) => {
  const isAwarded = recipients && recipients.length > 0;

  if (recipients == undefined) {
    return (
      <Text className={classNames(styles.awarded)}>
        <Skeleton.Box height={31} />
      </Text>
    );
  }
  return (
    <Text
      variant="paragraph"
      color="faint"
      className={classNames(styles.awarded, {[styles.awardedToAtLeastOne]: isAwarded})}
      onClick={() => {
        if (isAwarded) onClick();
      }}
    >
      <UIIcon src={WinLine} />
      <span>
        {isAwarded ? (
          <>
            {t('awarded_to')} <u>{t('people_count', {count: recipients?.length || 0})}</u>
          </>
        ) : (
          t('awarded_to_first')
        )}
      </span>
    </Text>
  );
};

const Nominate = ({badge, onSubmit}: {badge: Badge; onSubmit: () => void}) => {
  const [justification, setJustification] = useState('');
  const [selectedEmployees, selectEmployee, selectEmployees, removeEmployee] = useSelectEmployees();

  const setSnackbarMessage = useSetAtom(snackbarAtom);
  return (
    <div>
      <Modal.Body className={styles.nominateBody}>
        <Text
          tagName="div"
          variant="paragraph"
          size="large"
          isBold
          className={styles.nominationTitle}
        >
          {t('nominate_people')}
        </Text>
        <Text variant="paragraph" color="faint" className={styles.nominationExplaination}>
          {t('nomination_explanation')}
        </Text>
        <PeopleSearchMenu
          showReportingLine={false}
          allowSelf
          autoFocus
          selectedEmployees={selectedEmployees}
          onSelectEmployee={selectEmployee}
          onSelectEmployees={selectEmployees}
          onRemoveEmployee={removeEmployee}
        />
        <FormRow>
          <TextArea
            placeholder={t('nomination_placeholder')}
            value={justification}
            onChange={(e) => setJustification(e.currentTarget.value)}
          />
        </FormRow>
        <Text
          tagName="div"
          size="xsmall"
          variant="paragraph"
          color="faint"
          className={styles.badgeDescription}
        >
          <b>{badge.name}</b>
          {' - '}
          {badge.description}
        </Text>
      </Modal.Body>
      <Modal.Footer hasTopBorder className={styles.nominateFooter}>
        <Button
          variant="primary"
          disabled={!justification || selectedEmployees.length === 0}
          onClick={() =>
            getBadgesService()
              .addNominationApiV1BadgesIdNominationsPost(
                badge.id,
                selectedEmployees.map((e) => ({nominee_id: e.user_id, justification}))
              )
              .then(() => {
                setSnackbarMessage({text: t('nominations_submit')});
                onSubmit();
              })
          }
        >
          {t('submit')}
        </Button>
      </Modal.Footer>
    </div>
  );
};

const NominateButton = ({onClick, disabled}: {onClick: () => void; disabled: boolean}) => {
  if (disabled) {
    return (
      <Tooltip title="This badge can no longer be awarded">
        <Box
          as="span"
          display="inline-block"
          borderRadius="Medium"
          boxShadow={{
            focusVisible: 'Focus Ring',
          }}
          outlineWidth="0"
          tabIndex={0}
        >
          <Button
            variant="outline"
            onClick={onClick}
            disabled={disabled}
            style={{
              pointerEvents: 'none',
            }}
          >
            <Text isBold color="standard">
              {t('nominate')}
            </Text>
          </Button>
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Button variant="outline" onClick={onClick} disabled={disabled}>
        <Text isBold color="standard">
          {t('nominate')}
        </Text>
      </Button>
    );
  }
};
