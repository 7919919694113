import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Box, Stack} from '@dropbox/dig-foundations';
import {MegaphoneMini} from '@dropbox/dig-illustrations';
import {Workstream} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {t} from 'i18next';
import {useState} from 'react';

const WorkstreamTab = ({content}: {content: string}) => {
  return (
    <Box padding="16">
      <RichTextArea value={content} />
    </Box>
  );
};

export const WorkstreamUpdates = ({workstream}: {workstream: Workstream}) => (
  <Stack>
    {workstream.updates?.length ? (
      workstream.updates?.map((update) => (
        <UpdatePost key={update.id} update={update} employee={update.author}>
          <RichTextArea value={update.comment} />
        </UpdatePost>
      ))
    ) : (
      <EmptyState
        hideBorder
        title={t('no_updates_yet')}
        body={t('no_updates_yet_body')}
        image={<MegaphoneMini width="64" altText={t('no_updates_yet')} />}
      />
    )}
  </Stack>
);

// const AssistanceTab = ({workstream, content}: {workstream: Workstream; content?: string}) => {
//   const debouncedValue = useDebouncedValue(content, 600);
//   const {data, isLoading} = useWorkstreamAI(workstream.id, debouncedValue);

//   if (isLoading) {
//     return (
//       <Box
//         width="100%"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//         style={{height: 200}}
//       >
//         <Spinner />
//       </Box>
//     );
//   }

//   return (
//     <Stack padding="16" gap="16">
//       <Box>
//         <Text>{data?.summary}</Text>
//       </Box>
//       <Box>
//         {data?.validated ? (
//           <Text>
//             <UIIcon src={CheckmarkLine} />
//             Great update.
//           </Text>
//         ) : (
//           <Text>{data?.suggestion}</Text>
//         )}
//       </Box>
//     </Stack>
//   );
// };

export const WorkstreamUpdateTabs = ({workstream}: {workstream: Workstream; content?: string}) => {
  const [selectedTab, setSelectedTab] = useState<
    'updates' | 'done' | 'notes' | 'logs' | 'assistant'
  >('updates');

  return (
    <Tabs
      selectedTab={selectedTab}
      onSelection={(newTab: string) => setSelectedTab(newTab as typeof selectedTab)}
    >
      <Tabs.Group>
        <Tabs.Tab id="updates">{t('updates')}</Tabs.Tab>
        <Tabs.Tab id="done">{t('workstream_edit_done')}</Tabs.Tab>
        {/* <Tabs.Tab id="assistant">{t('workstream_assistance_tab')}</Tabs.Tab> */}
      </Tabs.Group>

      <Box overflowY="auto" style={{maxHeight: 445}}>
        <Tabs.Panel tabId="updates">
          <WorkstreamUpdates workstream={workstream} />
        </Tabs.Panel>
        <Tabs.Panel tabId="done">
          <WorkstreamTab content={workstream.done} />
        </Tabs.Panel>
        {/* <Tabs.Panel tabId="assistant">
          <AssistanceTab workstream={workstream} content={content} />
        </Tabs.Panel> */}
      </Box>
    </Tabs>
  );
};
