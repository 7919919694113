import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Split, Stack} from '@dropbox/dig-foundations';
import {growthbookCacheAtom} from 'atoms/layout';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';

import {TrackerGrid} from './TrackerGrid';

const TrackerAddButton = () => {
  const navigate = useNavigate();
  return (
    <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button {...getTriggerProps()} variant="outline" withDropdownIcon>
            {t('tracker_add')}
          </Button>
          <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-end">
            <Menu.Segment>
              <Menu.LinkItem
                href={ROUTE_PATHS.WORKSTREAM_TRACKER_NEW}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTE_PATHS.WORKSTREAM_TRACKER_NEW);
                }}
              >
                {t('add_tracker')}
              </Menu.LinkItem>
              <Menu.LinkItem
                href={ROUTE_PATHS.WORKSTREAM_NEW}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTE_PATHS.WORKSTREAM_NEW);
                }}
              >
                {t('add_workstream')}
              </Menu.LinkItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const Trackers = () => {
  useDocumentTitle(t('workstreams'));

  const {isWorkstreamsEnabled} = useAtomValue(growthbookCacheAtom);
  if (!isWorkstreamsEnabled) {
    return null;
  }

  return (
    <Layout.Container width="100%">
      <Stack gap="24">
        <Split>
          <Split.Item width="fill">
            <Title size={24}>{t('workstreams')}</Title>
          </Split.Item>
          <Split.Item>
            <TrackerAddButton />
          </Split.Item>
        </Split>
        <Title size={16}>{t('trackers')}</Title>
      </Stack>

      <TrackerGrid />
    </Layout.Container>
  );
};
