import {useMutation, useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {
  TrackerCreate,
  TrackerEdit,
  TrackerService,
  Workstream,
  WorkstreamCreate,
  WorkstreamEdit,
  WorkstreamService,
  WorkstreamUpdateCreate,
} from 'client';
import {useAtomValue} from 'jotai';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const useTrackerCreate = () => {
  const {mutateAsync: createTracker, isPending} = useMutation({
    mutationFn: ({data}: {data: TrackerCreate}) =>
      getService(TrackerService).createApiV1TrackerPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['trackers']});
    },
  });

  return {createTracker, isPending};
};

export const useWorkstreamAI = (workstreamId: number, comment?: string) => {
  const {data, isLoading} = useQuery({
    enabled: Boolean(comment),
    queryKey: ['workstream', 'ai', workstreamId, comment],
    queryFn: () =>
      getService(WorkstreamService).generateApiV1WorkstreamUpdateGenerateGet(
        workstreamId,
        comment!
      ),
  });

  return {data, isLoading};
};

export const useWorkstreamStatus = () => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const {mutateAsync: createUpdate, isPending} = useMutation({
    mutationFn: ({ownerId, update}: {ownerId: string; update: WorkstreamUpdateCreate}) =>
      getService(WorkstreamService).statusApiV1WorkstreamUpdatePost(ownerId, update),
    onSettled: (response, _, {update}) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['workstream', update.workstream_id]});
      queryClient.invalidateQueries({queryKey: ['tracker', employee.ldap]});
    },
  });

  return {createUpdate, isPending};
};

export const useWorkstream = (id?: number) => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstream', id],
    queryFn: () => getService(WorkstreamService).getApiV1WorkstreamIdGet(id!),
    enabled: id !== undefined,
  });

  return {workstream: data, isLoading};
};

export const useTrackerAdder = (id?: number) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const {data: existingTrackers} = useQuery({
    queryKey: ['workstream', 'trackers', id],
    queryFn: () => getService(WorkstreamService).getTrackersApiV1WorkstreamIdTrackersGet(id!),
    enabled: id !== undefined,
  });

  const {mutateAsync: addWorkstream, isPending} = useMutation({
    mutationFn: (trackerIds: number[]) =>
      getService(WorkstreamService).addApiV1WorkstreamIdAddPost(id!, trackerIds),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      [...response.added_ids, ...response.removed_ids].forEach((trackerId) => {
        queryClient.invalidateQueries({queryKey: ['tracker', `${trackerId}`]});
      });
      queryClient.invalidateQueries({queryKey: ['workstream', id]});
      queryClient.invalidateQueries({queryKey: ['trackers']});
      queryClient.invalidateQueries({queryKey: ['workstream', 'trackers', id]});
      queryClient.invalidateQueries({queryKey: ['tracker', employee.ldap]});
    },
  });

  return {existingTrackers, addWorkstream, isPending};
};

export const useNotesEditor = (tracker: string | number, id: number) => {
  const {mutateAsync: editNotes, isPending} = useMutation({
    mutationFn: (notes: string) =>
      getService(WorkstreamService).notesApiV1WorkstreamIdNotesPut(id, {notes}),
    onSettled: (response, _, notes) => {
      if (!response) {
        return;
      }

      queryClient.setQueryData(['workstream', id], (old: Workstream) => {
        if (!old) {
          queryClient.invalidateQueries({queryKey: ['workstream', id]});
          return;
        }

        return {...old, notes};
      });

      queryClient.invalidateQueries({queryKey: ['tracker', `${tracker}`]});
    },
  });

  return {editNotes, isPending};
};

export const useTrackers = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['trackers'],
    queryFn: getService(TrackerService).getAllApiV1TrackerAllGet,
  });

  return {data, isLoading};
};

export const useAuditLogs = (workstreamId: number, disabled?: boolean) => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstream', 'logs', workstreamId],
    queryFn: () => getService(WorkstreamService).getLogsApiV1WorkstreamIdLogsGet(workstreamId),
    enabled: !disabled,
  });

  return {data, isLoading};
};

export const useTrackerEdit = () => {
  const {mutateAsync: editTracker, isPending} = useMutation({
    mutationFn: ({data}: {data: TrackerEdit}) =>
      getService(TrackerService).editApiV1TrackerPut(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['trackers']});
      queryClient.invalidateQueries({queryKey: ['tracker', `${response}`]});
    },
  });

  return {editTracker, isPending};
};

export const useTracker = (trackerId?: number | string, reports?: boolean) => {
  const tracker = trackerId?.toString();

  const {data, isLoading} = useQuery({
    queryKey: ['tracker', `${tracker! + (reports ?? '')}`],
    queryFn: () => getService(TrackerService).getApiV1TrackerIdGet(tracker!, reports),
    enabled: !!tracker,
  });

  return {data, isLoading};
};

export const useTrackerDelete = () => {
  const {mutateAsync: deleteTracker, isPending} = useMutation({
    mutationFn: (id: number) => getService(TrackerService).archiveApiV1TrackerIdArchivePost(id),
    onSettled: (response, _, id) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['trackers']});
      queryClient.invalidateQueries({queryKey: ['tracker', `${id}`]});
    },
  });

  return {deleteTracker, isPending};
};

export const useWorkstreamReports = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['tracker', 'reports'],
    queryFn: getService(WorkstreamService).getReportsApiV1WorkstreamReportsGet,
  });

  return {data, isLoading};
};

export const useWorkstreams = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstreams'],
    queryFn: getService(WorkstreamService).getAllApiV1WorkstreamAllGet,
  });

  if (data && !isLoading) {
    data?.forEach((workstream) =>
      queryClient.setQueryData<Workstream>(['workstream', workstream.id], workstream)
    );
  }

  return {data, isLoading};
};

export const useWorkstreamCreate = () => {
  const {mutateAsync: createWorkstream, isPending} = useMutation({
    mutationFn: ({data}: {data: WorkstreamCreate}) =>
      getService(WorkstreamService).createApiV1WorkstreamPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['tracker'], exact: false});
    },
  });

  return {createWorkstream, isPending};
};

export const useWorkstreamEdit = () => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const {mutateAsync: editWorkstream, isPending} = useMutation({
    mutationFn: ({reason, data}: {reason?: string; data: WorkstreamEdit}) => {
      const owner = data.employee_associations.find(({dri}) => dri === 'dri');

      return getService(WorkstreamService).editApiV1WorkstreamPut(
        owner?.user_id ?? employee.user_id,
        data,
        reason
      );
    },
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['tracker'], exact: false});
      queryClient.invalidateQueries({queryKey: ['tracker', employee.ldap]});
      queryClient.invalidateQueries({queryKey: ['workstream', 'logs', response]});
      queryClient.invalidateQueries({queryKey: ['workstream', response]});
    },
  });

  return {editWorkstream, isPending};
};

export const useWorkstreamDelete = (ownerId: string, trackerId?: string | number) => {
  const {mutateAsync: deleteWorkstream, isPending} = useMutation({
    mutationFn: (id: number) =>
      getService(WorkstreamService).deleteApiV1WorkstreamIdArchivePost(id, ownerId),
    onSettled: (response, _, id) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['tracker'], exact: false});
      queryClient.invalidateQueries({queryKey: ['workstream', id]});
      queryClient.invalidateQueries({queryKey: ['trackers']});
      queryClient.invalidateQueries({queryKey: ['tracker', `${trackerId}`]});
    },
  });

  return {deleteWorkstream, isPending};
};
