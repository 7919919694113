import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box} from '@dropbox/dig-foundations';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Link} from 'components/DSYS/Link';
import {
  $applyNodeReplacement,
  DecoratorNode,
  DOMConversionMap,
  type DOMConversionOutput,
  DOMExportOutput,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import {ElementType, ReactNode} from 'react';

export const SHOW_PROFILE = false;

export type SerializedMentionNode = Spread<
  {
    trigger: string;
    value: string;
    data?: Employee;
  },
  SerializedLexicalNode
>;

function convertElement(domNode: HTMLElement): DOMConversionOutput | null {
  const trigger = domNode.getAttribute('data-lexical-beautiful-mention-trigger');
  const value = domNode.getAttribute('data-lexical-beautiful-mention-value');
  let data: Employee | undefined = undefined;
  const dataStr = domNode.getAttribute('data-lexical-beautiful-mention-data');
  if (dataStr) {
    try {
      data = JSON.parse(dataStr);
    } catch (e) {
      console.warn('Failed to parse data attribute of beautiful mention node', e);
    }
  }
  if (trigger != null && value !== null) {
    const node = $createMentionNode(trigger, value, data);
    return {node};
  }
  return null;
}

/**
 * This node is used to represent a mention used in the MentionPlugin.
 */
export class MentionNode extends DecoratorNode<ReactNode> {
  __trigger: string;
  __value: string;
  __data?: Employee;

  static getType(): string {
    return 'Mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__trigger, node.__value, node.__data, node.__key);
  }

  constructor(trigger: string, value: string, data?: Employee, key?: NodeKey) {
    super(key);
    this.__trigger = trigger;
    this.__value = value;
    this.__data = data;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): boolean {
    return false;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-beautiful-mention', 'true');
    element.setAttribute('data-lexical-beautiful-mention-trigger', this.__trigger);
    element.setAttribute('data-lexical-beautiful-mention-value', this.__value);
    if (this.__data) {
      element.setAttribute('data-lexical-beautiful-mention-data', JSON.stringify(this.__data));
    }
    element.textContent = this.getTextContent();
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-beautiful-mention')) {
          return null;
        }
        return {
          conversion: convertElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.trigger, serializedNode.value, serializedNode.data);
  }

  exportJSON(): SerializedMentionNode {
    const data = this.__data;
    return {
      trigger: this.__trigger,
      value: this.__value,
      ...(data ? {data} : {}),
      type: 'Mention',
      version: 1,
    };
  }

  getTextContent(): string {
    const self = this.getLatest();
    return self.__trigger + self.__value;
  }

  getTrigger(): string {
    const self = this.getLatest();
    return self.__trigger;
  }

  getValue(): string {
    const self = this.getLatest();
    return self.__value;
  }

  setValue(value: string) {
    const self = this.getWritable();
    self.__value = value;
  }

  getData(): Employee | undefined {
    const self = this.getLatest();
    return self.__data;
  }

  setData(data?: Employee) {
    const self = this.getWritable();
    self.__data = data;
  }

  component(): ElementType<any> | null {
    return null;
  }

  decorate() {
    if (!SHOW_PROFILE) {
      return (
        <Text isBold className={atoms({color: 'Primary Base'})} style={{fontSize: 'inherit'}}>
          <Link to={`/people/${this.getValue()}`} hasNoUnderline showUnderlineOnHover monochromatic>
            @{this.getTrigger()}
          </Link>
        </Text>
      );
    }

    const data = this.getData();
    return (
      <Link to={`/people/${this.getValue()}`} hasNoUnderline target="_blank">
        <Box
          display="inline-flex"
          backgroundColor="Opacity Surface"
          borderRadius="Small"
          position="relative"
          paddingX="4"
          style={{top: 3}}
        >
          {data && <Avatar user={data} size="xsmall" />}
          <Box style={{marginTop: 1}}>{this.getTrigger()}</Box>
        </Box>
      </Link>
    );
  }
}

export function $createMentionNode(trigger: string, value: string, data?: Employee): MentionNode {
  const mentionNode = new MentionNode(trigger, value, data);
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
