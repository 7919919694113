import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';
import {FunctionComponent} from 'react';

import styles from './index.module.css';

interface TitleProps {
  text: string;
  addButtonOnClick?: () => void;
}

export const Title: FunctionComponent<TitleProps> = ({text, addButtonOnClick}) => {
  return (
    <Text size="xlarge" variant="label" isBold className={styles.title}>
      {text}
      {addButtonOnClick && (
        <IconButton
          onClick={addButtonOnClick}
          variant="opacity"
          shape="circular"
          style={{marginLeft: 'auto'}}
        >
          <UIIcon src={AddLine} />
        </IconButton>
      )}
    </Text>
  );
};
