import {analyticsLogger} from 'analytics/analyticsLogger';
import {Layout} from 'components/DSYS/Layout';
import {NewGoalContent} from 'components/goals/edit/NewGoalContent';
import {NewGoalHeader} from 'components/goals/edit/NewGoalHeader';
import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

export const NewGoalView = () => {
  const location = useLocation();
  const [delegateId, setDelegateId] = useState<string | undefined>(location.state?.delegateId);
  const [goalCreated, setGoalCreated] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const parentDrawerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (location.state?.source) {
      analyticsLogger().logEvent('GOAL_CREATE_VIEW', {source: location.state.source});
    }
  }, [location.state?.source]);

  useEffect(() => {
    if (drawerRef.current) {
      parentDrawerRef.current = drawerRef.current.parentElement as HTMLDivElement;
    }
  }, []);

  return (
    <>
      <Layout.Container>
        <NewGoalHeader
          delegateId={delegateId}
          setDelegateId={setDelegateId}
          goalCreated={goalCreated}
        />
        <NewGoalContent
          delegateId={delegateId}
          goalCreated={goalCreated}
          setGoalCreated={setGoalCreated}
          ref={parentDrawerRef}
        />
      </Layout.Container>
      <div ref={drawerRef} />
    </>
  );
};
