import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {snackbarAtom} from 'atoms/snackbar';
import {OpenAPI} from 'client/core/OpenAPI';
import {request as __request} from 'client/core/request';
import {DeleteModal} from 'components/DSYS/DeleteModal';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {ROUTE_PATHS} from 'constant';
import {isContentAdmin, isSuperUser, isUserAdmin} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {ReactNode, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const AdminLink = ({
  to,
  children,
  onClick,
}: {
  to: string;
  children: ReactNode;
  onClick?: () => void;
}) => (
  <Link
    to={to}
    hasNoUnderline
    showUnderlineOnHover
    className={atoms({display: 'block'})}
    onClick={onClick}
  >
    <Text>{children}</Text>
  </Link>
);

const ContentAdmin = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  if (!isContentAdmin(pulseUser)) {
    return null;
  }

  return (
    <Stack gap="8">
      <Text isBold>Content</Text>
      <AdminLink to={ROUTE_PATHS.ADMIN_MANAGE_BADGES}>{t('manage_badges')}</AdminLink>
      <AdminLink to={ROUTE_PATHS.ADMIN_CARD_MANAGEMENT}>{t('manage_cards')}</AdminLink>
    </Stack>
  );
};

const UserAdmin = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  if (!isUserAdmin(pulseUser?.email)) {
    return null;
  }
  return (
    <Stack gap="8">
      <Text isBold>Goals</Text>
      <AdminLink to={ROUTE_PATHS.ADMIN_DELEGATES}>{t('manage_delegation')}</AdminLink>
      <AdminLink to={ROUTE_PATHS.ADMIN_NOTIFICATIONS}>{t('manage_notifications')}</AdminLink>
    </Stack>
  );
};

const Developer = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const [open, setOpen] = useState(false);

  if (!isSuperUser(pulseUser?.email)) {
    return null;
  }

  const handleWorkday = async () => {
    setSnackbarMessage({
      text: 'Importing profiles...',
    });

    await __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/people/update',
    });

    setSnackbarMessage({
      text: 'Profiles imported.',
    });
  };

  return (
    <>
      <Stack gap="8">
        <Text isBold>Developer tools</Text>
        <AdminLink to={ROUTE_PATHS.ADMIN_APPS}>{t('manage_apps')}</AdminLink>
        <AdminLink to={ROUTE_PATHS.ADMIN_CELERY_TASKS}>Monitor tasks</AdminLink>
        <AdminLink to="#" onClick={() => setOpen(true)}>
          Import profiles
        </AdminLink>
      </Stack>
      <DeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onDelete={handleWorkday}
        title="Import profiles"
        subtitle="Import profiles? It will take a while."
        isPending={false}
      />
    </>
  );
};

export const Admin = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const navigate = useNavigate();

  useDocumentTitle('Admin settings');

  if (!pulseUser) {
    return null;
  }

  if (!isContentAdmin(pulseUser) && !isUserAdmin(pulseUser?.email)) {
    navigate('/');
    return null;
  }

  return (
    <Layout.Container breadcrumb={[{children: 'Admin', to: ROUTE_PATHS.ADMIN}]}>
      <Title size={24}>{t('admin_controls')}</Title>
      <Box as={Stack} gap="24" paddingTop="36">
        <ContentAdmin />
        <UserAdmin />
        <Developer />
      </Box>
    </Layout.Container>
  );
};
