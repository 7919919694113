import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CalendarLine, CommentLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Workstream as WorkstreamType} from 'client';
import {useWorkstreamIThreads} from 'components/comments/hooks';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {StatusButton} from 'components/DSYS/StatusButton';
import {Title} from 'components/DSYS/Title';
import {LinkIcon} from 'components/shared/LinkIcon';
import {ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useLoaderData, useLocation} from 'react-router-dom';

import {useTrackerAdder} from './hooks';
import {TrackerAddModal} from './TrackerAddModal';
import {WorkstreamOverflowButton} from './TrackerTable';
import {WorkstreamCommentThreads} from './TrackerWorkstream';
import {WorkstreamAuditLog} from './WorkstreamAuditLog';

export const isEditing = false;

export const Workstream = () => {
  const location = useLocation();
  const workstream = useLoaderData() as WorkstreamType;

  const {existingTrackers} = useTrackerAdder(workstream.id);

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState<boolean>(false);
  const driEmployee = workstream?.employee_associations.find(({dri}) => dri === 'dri')?.employee;

  const threads = useWorkstreamIThreads({
    workstreamId: workstream.id,
  });

  const commentCount = threads
    ?.filter((thread) => thread && !thread.is_resolved)
    .flatMap((thread) => thread.comments).length;

  useDocumentTitle(t(workstream.name));

  const tracker = location.state?.tracker
    ? location.state.tracker
    : driEmployee
      ? {name: driEmployee.name, id: driEmployee.ldap}
      : undefined;

  return (
    <>
      <Layout.InlineDrawerContainer
        width="100%"
        open={commentsDrawerOpen}
        drawerHeader={
          <Title size={18}>{t('workstream_comments_count', {count: commentCount})}</Title>
        }
        drawerIcon={CommentLine}
        drawerBody={
          <WorkstreamCommentThreads
            workstream={workstream}
            closeDrawer={() => setCommentsDrawerOpen(false)}
          />
        }
        onClose={() => setCommentsDrawerOpen(false)}
        breadcrumb={[
          {children: t('workstreams'), to: ROUTE_PATHS.WORKSTREAM_TRACKERS},
          {
            children: tracker?.name ?? (
              <Box style={{width: 105}}>
                <Skeleton.Box height={17} width={105} />
              </Box>
            ),
            to: `/workstreams/${tracker.id}`,
          },
          {children: workstream.name, to: `/workstream/${workstream.id}`},
        ]}
      >
        <TrackerAddModal
          workstreamId={workstream.id}
          open={addModalOpen}
          onClose={() => setAddModalOpen(false)}
        />

        <Stack align="start">
          <Header
            workstream={workstream}
            onAddToTrackerClick={() => setAddModalOpen(true)}
            onCommentsClick={() => setCommentsDrawerOpen(true)}
          />
          <Split gap="32">
            <Split.Item width="fill">
              <WorkstreamAuditLog workstream={workstream} withUpdates oldestFirst />
            </Split.Item>
            <Split.Item style={{width: 318}} paddingTop="16">
              <Stack gap="16">
                <Stack gap="16" className={atoms({})}>
                  <Stack
                    paddingBottom="16"
                    className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle'})}
                  >
                    <Box>
                      <Text size="small" isBold>
                        {t('status')}
                      </Text>
                    </Box>
                    <StatusButton isLabel status={workstream.updates?.[0]?.status ?? 'no_status'} />
                  </Stack>

                  <Stack
                    gap="4"
                    paddingBottom="16"
                    className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle'})}
                  >
                    <Text size="small" isBold>
                      {t('workstream_edit_end_date')}
                    </Text>
                    <Split gap="4" alignY="center">
                      <Split.Item paddingTop="2">
                        <UIIcon size="small" src={CalendarLine} />
                      </Split.Item>
                      <Split.Item>
                        <Text size="small">
                          {workstream.end_date ? (
                            <>
                              {new Intl.DateTimeFormat('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              }).format(new Date(workstream.end_date))}
                            </>
                          ) : (
                            'No completion date'
                          )}
                        </Text>
                      </Split.Item>
                    </Split>
                  </Stack>

                  <Stack
                    gap="4"
                    paddingBottom="16"
                    className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle'})}
                  >
                    <Text size="small" isBold>
                      {t('workstream_edit_dri')}
                    </Text>
                    {driEmployee && (
                      <Box
                        borderRadius="Medium"
                        paddingX="8"
                        paddingY="4"
                        {...withShade({style: {marginLeft: -8, marginRight: -8}})}
                      >
                        <Link to={`/people/${driEmployee.ldap}`} hasNoUnderline>
                          <LabelGroup
                            size="small"
                            withLeftAccessory={<Avatar user={driEmployee} />}
                            withText={driEmployee.name}
                            withSubtext={
                              <Text size="small" color="subtle" style={{width: 160}}>
                                <Truncate>{driEmployee.role}</Truncate>
                              </Text>
                            }
                          />
                        </Link>
                      </Box>
                    )}
                  </Stack>

                  {!workstream.links?.length ? null : (
                    <Stack
                      gap="4"
                      paddingBottom="16"
                      className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle'})}
                    >
                      <Text size="small" isBold>
                        {t('resources')}
                      </Text>
                      {workstream.links.map((link) => (
                        <Link
                          key={`link-${link.id}`}
                          hasNoUnderline
                          showUnderlineOnHover
                          to={link.url}
                          style={{gap: 8}}
                          className={atoms({
                            paddingY: '2',
                            display: 'flex',
                            alignItems: 'center',
                          })}
                        >
                          <LinkIcon icon={link.icon} />
                          <Text size="small">{link.text}</Text>
                        </Link>
                      ))}
                    </Stack>
                  )}

                  <Stack gap="4">
                    <Text size="small" isBold>
                      {t('trackers')}
                    </Text>
                    {existingTrackers && (
                      <Stack gap="2">
                        {!existingTrackers?.length ? (
                          <Button
                            size="small"
                            variant="transparent"
                            onClick={() => setAddModalOpen(true)}
                          >
                            Add to tracker
                          </Button>
                        ) : (
                          existingTrackers.map((existinTracker) => (
                            <Link
                              key={`tracker-${existinTracker.id}`}
                              hasNoUnderline
                              showUnderlineOnHover
                              to={`/workstreams/${existinTracker.id}`}
                              className={atoms({display: 'block', paddingY: '2'})}
                            >
                              <Text size="small">{existinTracker.name}</Text>
                            </Link>
                          ))
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Split.Item>
          </Split>
        </Stack>
      </Layout.InlineDrawerContainer>
    </>
  );
};

const Header = ({
  workstream,
  onAddToTrackerClick,
  onCommentsClick,
}: {
  workstream: WorkstreamType;
  onAddToTrackerClick: () => void;
  onCommentsClick: () => void;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const threads = useWorkstreamIThreads({
    workstreamId: workstream.id,
  });

  const commentCount = threads
    ?.filter((thread) => thread && !thread.is_resolved)
    .flatMap((thread) => thread.comments).length;

  const canEdit = workstream.employee_associations.some(
    ({employee: {user_id}}) => user_id === employee.user_id
  );

  return (
    <Split alignY="center" paddingBottom="16" gap="6">
      <Split.Item width="fill">
        <Title size={18}>{workstream.name}</Title>
      </Split.Item>
      {canEdit && (
        <Split.Item>
          <ButtonLink
            variant="primary"
            to={ROUTE_PATHS.WORKSTREAM_STATUS_UPDATE + '?id=' + workstream.id}
          >
            {t('update')}
          </ButtonLink>
        </Split.Item>
      )}
      <Split.Item>
        <Button
          variant="outline"
          withIconStart={<UIIcon src={CommentLine} />}
          onClick={onCommentsClick}
        >
          {t('workstream_comments_count', {count: commentCount})}
        </Button>
      </Split.Item>
      <Split.Item>
        <WorkstreamOverflowButton
          workstream={workstream}
          onAddToTrackerClick={onAddToTrackerClick}
          canEdit={canEdit}
        />
      </Split.Item>
    </Split>
  );
};
