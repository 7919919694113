import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {snackbarAtom} from 'atoms/snackbar';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {useEffect, useMemo, useState} from 'react';

import {useTrackerAdder, useWorkstream} from './hooks';
import {TrackerGrid} from './TrackerGrid';

export const TrackerAddModal = ({
  workstreamId,
  open,
  onClose,
}: {
  workstreamId: number | undefined;
  open: boolean;
  onClose: () => void;
}) => {
  const {workstream} = useWorkstream(workstreamId);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {existingTrackers, addWorkstream, isPending} = useTrackerAdder(workstreamId);
  const [selected, setSelected] = useState<number[]>([]);

  const hasChanges = useMemo(() => {
    return (
      existingTrackers &&
      (existingTrackers.length !== selected.length ||
        existingTrackers.some((tracker) => !selected.includes(tracker.id)))
    );
  }, [existingTrackers, selected]);

  useEffect(() => {
    if (existingTrackers) {
      setSelected((prev) => [...prev, ...existingTrackers.map((tracker) => tracker.id)]);
    }
  }, [existingTrackers]);

  const onSubmit = async () => {
    const {added_ids, removed_ids} = await addWorkstream(selected);
    const removed = removed_ids.length;
    const added = added_ids.length;

    const message =
      removed && added ? 'added_removed_trackers' : removed ? 'removed_trackers' : 'added_trackers';

    const count = added || removed;

    setSnackbarMessage({text: t(message, {count, removed})});
    onClose();
  };

  return (
    <Modal
      open={open}
      withCloseButton="close-trackers"
      isCentered
      onRequestClose={onClose}
      width="large"
    >
      <Modal.Header>
        <Modal.Title>{t('track_workstream', {name: workstream?.name})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TrackerGrid
          owners={workstream?.employee_associations}
          isSelectable
          selected={selected}
          setSelected={setSelected}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="opacity" onClick={onClose} disabled={isPending}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit} isLoading={isPending} disabled={!hasChanges}>
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
