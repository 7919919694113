/* eslint-disable react/display-name */
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowDownLine, ArrowUpLine, DragHandleLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import cx from 'classnames';
import {t} from 'i18next';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {Eyebrow} from './Eyebrow';
import styles from './Table.module.css';

const MAX_WIDTH = 1240;
const MIN_WIDTH = 50;

interface BaseColumn<T> {
  type: string;
  sort?: (a: T, b: T) => number;
  fixed?: boolean;
  maxWidth?: number;
  width?: number;
  spanner?: boolean;
}

export type Column<T> = BaseColumn<T> &
  ({fixed: boolean; minWidth?: number} | {fixed?: undefined; minWidth: number});

interface SortConfig {
  columnId: string;
  asc: boolean;
}

interface TableContextValue<T> {
  columns: Column<T>[];
  sortedData: T[];
  sortConfig: SortConfig | null;
  padding?: boolean;
  onSort: (col: Column<T>) => void;
  reorderable: boolean;
  onResizeStart: (index: number, e: React.MouseEvent) => void;
  draggingCol: number | null;
}

const TableContext = createContext<TableContextValue<any> | null>(null);

interface TableProps<T> {
  columns: Column<T>[];
  data: T[];
  padding?: boolean;
  children: ReactNode;
  verticalLines?: boolean;
  reorderable?: boolean;
  onReorder?: (event: DragEndEvent) => void;
}

const TableComponent = <T,>({
  columns,
  data,
  children,
  verticalLines = true,
  padding = true,
  className,
  style,
  reorderable = false,
  onReorder,
}: React.HTMLAttributes<HTMLDivElement> & TableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [draggingCol, setDraggingCol] = useState<number | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  // Store each column's width in px, or 0 if it's unresized (i.e., still "1fr").
  const [colWidths, setColWidths] = useState<number[]>(() =>
    columns.map((col) => {
      if (col.fixed) {
        // For fixed columns, use minWidth if specified, otherwise use -1 to indicate min-content
        return col.minWidth ?? -1;
      }
      if (col.width) return col.width;
      return 0; // still "1fr" if no width specified
    })
  );

  // Improved scroll handler
  const handleScroll = useCallback(() => {
    const element = scrollRef.current;
    if (!element) return;

    const hasLeftScroll = element.scrollLeft > 0;
    const hasRightScroll =
      Math.ceil(element.scrollLeft) < element.scrollWidth - element.clientWidth;

    setShowLeftShadow(hasLeftScroll);
    setShowRightShadow(hasRightScroll);
  }, []);

  // Check initial scroll state
  useEffect(() => {
    handleScroll();
    // Also check on resize
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [handleScroll]);

  // Capture the actual rendered width when first grabbing the handle
  const onResizeStart = useCallback(
    (index: number, e: React.MouseEvent) => {
      e.stopPropagation();

      const column = columns[index];

      analyticsLogger().logEvent('TABLE_COLUMN_RESIZE', {
        type: column.type,
        fixed: column.fixed,
        width: column.width,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
      });

      if (column.fixed) return; // can't resize fixed columns

      // If this column is still at 0 => "1fr", we grab the actual DOM width
      if (colWidths.some((w) => w === 0)) {
        // measure all columns still at 0
        const headerCells = document.querySelectorAll(`.${styles.headerCell}`);
        headerCells.forEach((cell, i) => {
          if (colWidths[i] === 0) {
            const rect = cell.getBoundingClientRect();
            setColWidths((prev) => {
              const newWidths = [...prev];
              newWidths[i] = rect.width;
              return newWidths;
            });
          }
        });
      }

      setDraggingCol(index);
    },
    [columns, colWidths]
  );

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (draggingCol == null) return;

      handleScroll();

      setColWidths((prev) => {
        const next = [...prev];
        const c = columns[draggingCol];
        // Skip resizing fixed columns
        if (c.fixed) return prev;

        // Add pointer movement to the current width
        const newVal = next[draggingCol] + e.movementX;
        const min = c.minWidth || MIN_WIDTH;
        // Apply both min and max constraints
        const max = c.maxWidth || MAX_WIDTH;
        next[draggingCol] = Math.min(max, Math.max(min, newVal));
        return next;
      });
    },
    [draggingCol, handleScroll, columns]
  );

  const onMouseUp = useCallback(() => {
    if (draggingCol === null) return;

    const column = columns[draggingCol];

    analyticsLogger().logEvent('TABLE_COLUMN_RESIZE', {
      type: column.type,
      fixed: column.fixed,
      width: column.width,
      minWidth: column.minWidth,
      maxWidth: column.maxWidth,
      newWidth: colWidths[draggingCol],
    });

    setDraggingCol(null);
  }, [draggingCol, columns, colWidths]);

  useEffect(() => {
    if (draggingCol !== null) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [draggingCol, onMouseMove, onMouseUp]);

  // Build grid sizes
  const getColumnSize = useCallback(
    (col: Column<T>, i: number) => {
      // Special case for fixed columns with no minWidth (using min-content)
      if (colWidths[i] === -1) {
        return 'minmax(min-content, min-content)';
      }

      if (colWidths[i] === 0) {
        if (col.minWidth) {
          // Use minmax to enforce both min and max if specified
          const max = col.maxWidth ? `${col.maxWidth}px` : '1fr';
          return `minmax(${col.minWidth}px, ${max})`;
        }
        return '1fr';
      }

      // if it's the last column, check if any other columns have been resized
      if (col.spanner || i === colWidths.length - 1) {
        const hasResizedColumns = colWidths.some((width, idx) => {
          if (idx === i) return false;
          if (columns[idx].width || columns[idx].fixed) return false;
          return width > 0;
        });
        if (hasResizedColumns) {
          return `minmax(${col.minWidth}px, 1fr)`;
        }
        return `${col.minWidth}px`;
      }

      if (col.fixed) {
        // If minWidth is set, lock to that; otherwise lock to min-content
        if (col.minWidth) {
          return `minmax(${col.minWidth}px, ${col.minWidth}px)`;
        }
        return 'minmax(min-content, min-content)';
      }

      // Use the current width (either initial width or user-resized)
      const min = col.minWidth || 50;
      const max = col.maxWidth || Infinity;
      const w = Math.min(max, Math.max(min, colWidths[i]));
      return `minmax(${w}px, ${w}px)`;
    },
    [colWidths]
  );

  // Sorting
  const onSort = useCallback((col: Column<T>) => {
    if (!col.sort) return;
    setSortConfig((prev) => {
      if (prev?.columnId === col.type) {
        return {columnId: col.type, asc: !prev.asc};
      }
      return {columnId: col.type, asc: true};
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!sortConfig) return data;
    const col = columns.find((c) => c.type === sortConfig.columnId);
    if (!col?.sort) return data;
    const sorted = [...data].sort(col.sort);
    return sortConfig.asc ? sorted : sorted.reverse();
  }, [data, columns, sortConfig]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const value: TableContextValue<T> = {
    columns,
    sortedData,
    sortConfig,
    padding,
    onSort,
    reorderable,
    draggingCol,
    onResizeStart,
  };

  const tableContent = (
    <Box position="relative" className={className} style={style}>
      <div
        className={cx(styles.shadowOverlay, styles.leftShadow, {
          [styles.visible]: showLeftShadow,
        })}
      />
      <div
        className={cx(styles.shadowOverlay, styles.rightShadow, {
          [styles.visible]: showRightShadow,
        })}
      />
      <Box
        ref={scrollRef}
        backgroundColor="Background Base"
        borderRadius="Medium"
        borderStyle="Solid"
        borderColor="Opacity Surface - State 2"
        className={cx(styles.tableWrapper, {
          [styles.verticalLines]: verticalLines,
        })}
        overflowX="auto"
        onScroll={handleScroll}
        style={{
          display: 'grid',
          gridTemplateColumns: columns.map(getColumnSize).join(' '),
        }}
      >
        {children}
      </Box>
    </Box>
  );

  return (
    <TableContext.Provider value={value}>
      {reorderable ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onReorder}
          modifiers={[restrictToFirstScrollableAncestor]}
        >
          <SortableContext
            items={data.map((_, index) => index)}
            strategy={verticalListSortingStrategy}
          >
            {tableContent}
          </SortableContext>
        </DndContext>
      ) : (
        tableContent
      )}
    </TableContext.Provider>
  );
};

// Table.Header
const Header = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const ctx = useContext(TableContext);
  if (!ctx) return null;

  const {columns, sortConfig, onSort, draggingCol, onResizeStart, padding} = ctx;

  return (
    <div
      className={cx(styles.header, props.className)}
      style={{display: 'contents', ...props.style}}
      {...props}
    >
      {columns.map((col, index) => {
        const isSorted = sortConfig?.columnId === col.type;
        return (
          <Text
            color="subtle"
            key={col.type}
            className={cx(styles.headerCell, {
              [styles.padding]: padding,
            })}
          >
            <span
              onClick={(e) => {
                // Only sort if not clicking the resize handle
                if ((e.target as HTMLElement).classList.contains(styles.resizeHandle)) {
                  return;
                }
                if (col.sort) onSort(col);
              }}
            >
              <Eyebrow>{t(col.type)}</Eyebrow>
              {isSorted && (
                <UIIcon src={sortConfig.asc ? ArrowUpLine : ArrowDownLine} size="small" />
              )}
            </span>
            {/* Resize handle: call onResizeStart so we measure actual width first */}
            {!col.fixed && index !== columns.length - 1 && (
              <Box
                userSelect="none"
                borderRadius="Medium"
                padding="4"
                margin="2"
                onMouseDown={(e) => onResizeStart(index, e)}
                {...withShade({
                  className: cx(styles.resizeHandle, {
                    [styles.isDragging]: draggingCol === index,
                  }),
                })}
              >
                ||
              </Box>
            )}
          </Text>
        );
      })}
    </div>
  );
};

const Body = ({children}: {children: ReactNode}) => <div className={styles.body}>{children}</div>;

const Row = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
    isSelectable?: boolean;
    isSelected?: boolean;
    sortableId?: string | number;
  }
>(({children, isSelectable, isSelected, sortableId, className, style, ...rest}, ref) => {
  const ctx = useContext(TableContext);

  const isSortable = ctx?.reorderable && sortableId !== undefined;

  // Add useSortable hook for reorderable rows
  const {attributes, isSorting, listeners, transform, setNodeRef, transition, isDragging} =
    useSortable({
      id: sortableId ?? 0,
      disabled: !isSortable,
    });

  if (!ctx) return null;
  const {padding} = ctx;

  const rowStyles = isSortable
    ? {
        transition,
        cursor: isDragging ? 'grabbing' : undefined,
        transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
        zIndex: isDragging ? 1 : undefined,
        opacity: isSorting && !isDragging ? 0.7 : 1,
      }
    : {
        display: 'contents',
      };

  return (
    <div
      ref={isSortable ? setNodeRef : ref}
      className={cx(styles.row, className, {
        [atoms({
          boxShadow: isDragging ? 'Floating' : undefined,
          position: 'relative',
          backgroundColor: 'Background Base',
          borderTop: isDragging ? 'Solid' : undefined,
          borderColor: 'Opacity Surface - State 2',
        })]: isSortable,
        [styles.selectable]: isSelectable,
        [styles.selected]: isSelected,
        [styles.noPadding]: padding === false,
      })}
      style={{...rowStyles, ...style}}
      {...rest}
    >
      {isSortable && (
        <Box
          position="absolute"
          color={isDragging ? 'Border Base' : 'Border Subtle'}
          className={cx(styles.dragHandle, {
            [styles.isDragging]: isDragging,
          })}
          style={{
            left: -6,
            top: -2,
            zIndex: 100,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          {...listeners}
          {...attributes}
          tabIndex={-1}
        >
          <UIIcon src={DragHandleLine} size="medium" />
        </Box>
      )}
      {children}
    </div>
  );
});

const Cell = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {colSpan?: number; children?: ReactNode}
>(({children, className, style, colSpan, ...rest}, ref) => (
  <div
    ref={ref}
    className={cx(styles.cell, className)}
    style={colSpan ? {...style, gridColumn: `span ${colSpan}`} : style}
    {...rest}
  >
    {children}
  </div>
));

export const Table = Object.assign(TableComponent, {
  Header,
  Body,
  Row,
  Cell,
});
