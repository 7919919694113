import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine, FlagLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {LaptopMini} from '@dropbox/dig-illustrations';
import {
  // Thread,
  Workstream,
  WorkstreamAuditLog as WorkstreamAuditLogType,
  WorkstreamMetric,
  WorkstreamUpdate,
} from 'client';
import {
  useCommentService,
  useThreadService,
  useWorkstreamIThreads,
} from 'components/comments/hooks';
import {EmptyState} from 'components/DSYS/EmptyState';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {StatusButton} from 'components/DSYS/StatusButton';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {ChangeLogLine} from 'components/teams/TeamAuditLog';
import {format} from 'date-fns';
import {t} from 'i18next';
import {useAtom} from 'jotai';
import {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';

import {useAuditLogs} from './hooks';
import {MetricLine} from './MetricLine';
import {focusedThreadAtom} from './TrackerTable';

export const groupByTimeAndType = (logs: WorkstreamAuditLogType[]) => {
  const grouped: {[key: string]: {[type: string]: WorkstreamAuditLogType[]}} = {};

  logs.forEach((log) => {
    let intervalKey: string;

    if (log.type === 'comment') {
      // Use the exact timestamp for comments
      intervalKey = log.date;
    } else {
      const date = new Date(log.date);
      date.setSeconds(0, 0); // Round non-comment logs to the nearest minute
      intervalKey = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    }

    if (!grouped[intervalKey]) {
      grouped[intervalKey] = {};
    }

    if (!grouped[intervalKey][log.type]) {
      grouped[intervalKey][log.type] = [];
    }

    grouped[intervalKey][log.type].push(log);
  });

  return grouped;
};

export const AuditLogReason = ({
  border = 'left',
  reason,
  truncate,
}: {
  reason: string;
  border?: 'top' | 'left';
  truncate?: boolean;
}) => {
  const [expanded, setExpanded] = useState(!truncate);
  return (
    <Box
      borderWidth="1"
      borderLeft={border === 'left' ? 'Solid' : undefined}
      borderTop={border === 'top' ? 'Solid' : undefined}
      borderColor="Border Subtle"
      padding="4"
      cursor={truncate ? 'pointer' : undefined}
      display="flex"
      flexDirection="row"
      // overflow="hidden"
      // whiteSpace={width ? 'nowrap' : undefined}
      alignItems="flex-start"
      {...(truncate ? withShade({style: {gap: 8}}) : {style: {gap: 8}})}
      onClick={truncate ? () => setExpanded(!expanded) : undefined}
    >
      <UIIcon src={CommentLine} size="small" className={atoms({flexShrink: 0, marginTop: '2'})} />
      <Text
        color="faint"
        size={truncate ? 'small' : undefined}
        className={atoms({flexGrow: 1})}
        style={
          expanded
            ? undefined
            : {
                textOverflow: 'ellipsis',
                whiteSpace: expanded ? undefined : 'nowrap',
                width: 1,
                overflow: 'hidden',
              }
        }
      >
        {reason}
      </Text>
    </Box>
  );
};

const workstreamUpdateToAuditLogType = ({updates}: Workstream): WorkstreamAuditLogType[] =>
  (updates ?? []).map((update, index) => ({
    date: update.created_at,
    type: 'update',
    before: JSON.stringify((updates ?? [])[index - 1]),
    after: JSON.stringify(update),
    employee: update.author,
    reason: null,
  }));

// const commentToAuditLogType = (threads?: Thread[]): WorkstreamAuditLogType[] =>
//   (threads ?? []).flatMap((thread) => {
//     return (thread.comments ?? []).map((comment, index, threadComments) => {
//       // Collect only previous comments up to this point in the thread
//       const previousComments = [
//         ...threadComments.slice(0, index).map((c) => c.content),
//         thread.attached_text,
//       ].filter(Boolean); // Remove any falsy values (null, undefined, etc.)

//       return {
//         date: comment.created_at,
//         type: 'comment',
//         before: JSON.stringify(previousComments),
//         after: JSON.stringify(comment),
//         employee: comment.employee,
//         reason: null,
//       };
//     });
//   });

export const WorkstreamAuditLog = ({
  workstream,
  withUpdates,
  oldestFirst,
}: {
  workstream: Workstream;
  withUpdates?: boolean;
  oldestFirst?: boolean;
}) => {
  const {data, isLoading} = useAuditLogs(workstream.id);
  const [focusedThread, setFocusedThread] = useAtom(focusedThreadAtom);
  const threads = useWorkstreamIThreads({
    workstreamId: workstream.id,
  });
  const {createThread} = useThreadService();
  const {createComment} = useCommentService({
    id: workstream.id,
    type: 'workstream',
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setFocusedThread(undefined);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setFocusedThread]);

  const logsAndUpdates = [
    ...(data ?? []),
    ...(withUpdates ? workstreamUpdateToAuditLogType(workstream) : []),
    // convert thread comments to audit log type
    // ...(commentToAuditLogType(threads) ?? []),
  ];

  if (oldestFirst) {
    logsAndUpdates.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
  }

  const groupedLogs = groupByTimeAndType(logsAndUpdates);

  const handleAddComment = async (
    comment: string,
    attached_text: string,
    start: number,
    end: number,
    update?: WorkstreamUpdate,
    metric?: WorkstreamMetric,
    threadId?: string
  ) => {
    let id = threadId;
    if (!id) {
      const newThread = await createThread({
        workstreamId: workstream.id,
        workstreamUpdateId: update?.id,
        workstreamMetricId: metric?.id,
        goalId: 0,
        keyResultId: 0,
        data: {
          attached_to_type: update?.id
            ? 'workstream_update'
            : metric?.id
              ? 'workstream_metric'
              : 'workstream',
          created_at: new Date().toISOString(),
          start,
          end,
          attached_text,
        },
      });

      id = newThread.id;
    }

    await createComment({
      data: {
        content: comment,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        reply_to: null,
        thread_id: id,
      },
    });

    return id;
  };

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center" marginTop="68">
        <Spinner monochromatic />
      </Box>
    );
  }

  return (
    <Stack>
      {!data?.length ? (
        <EmptyState
          title={t('audit_log_empty_title')}
          body={t('workstream_audit_log_empty_body')}
          image={<LaptopMini width="64" altText={''} />}
          hideBorder
        />
      ) : (
        Object.entries(groupedLogs).map(([timestamp, changesByType], index, entries) => {
          const changeTypes = Object.keys(changesByType);
          const firstChange = changesByType[changeTypes[0]][0];

          const creationType = changesByType['workstream'];

          const isLast = index === entries.length - 1;

          const reasonChanges: typeof changesByType = {};
          if (changesByType['metric']) {
            reasonChanges['metric'] = changesByType['metric'];
          }
          if (changesByType['end_date']) {
            reasonChanges['end_date'] = changesByType['end_date'];
          }

          const reason = Object.values(changesByType).find((changes) =>
            changes.some((change) => Boolean(change.reason))
          )?.[0]?.reason;

          const comments = changesByType['comment'];

          // for comments, just return the comments
          if (comments) {
            return comments.map((comment) => {
              // before is an array of all previous comments in the same thread
              const previousComments: string[] = JSON.parse(comment.before ?? '[]');

              const {id, content, thread_id} = JSON.parse(comment.after ?? '{}');

              return (
                <Box
                  id={`comment-${id}`}
                  key={id}
                  position="relative"
                  onClick={() => setFocusedThread(thread_id)}
                >
                  <Box position="relative">
                    <Box
                      position="absolute"
                      padding="4"
                      style={{top: 12, left: 12}}
                      backgroundColor="Background Base"
                    >
                      <UIIcon src={CommentLine} />
                    </Box>
                  </Box>

                  <Box
                    marginLeft="28"
                    paddingLeft="8"
                    borderLeft={isLast ? undefined : 'Solid'}
                    borderColor="Border Subtle"
                  >
                    <UpdatePost employee={comment.employee} update={{created_at: comment.date}}>
                      <Stack gap="8">
                        {previousComments.reduceRight(
                          (nested, prevComment, idx) => (
                            <Box
                              key={`${idx}-${prevComment}`}
                              paddingLeft="16"
                              borderLeft="Solid"
                              borderColor="Identity Blue"
                              style={{borderWidth: 4}}
                            >
                              {nested}
                              <Box paddingY="4">
                                <RichTextArea theme="small-faint" value={prevComment} />
                              </Box>
                            </Box>
                          ),
                          <></>
                        )}
                        <RichTextArea value={content} />
                      </Stack>
                    </UpdatePost>
                  </Box>
                </Box>
              );
            });
          }

          return (
            <Box key={timestamp} position="relative" marginBottom="24">
              {!isLast && (
                <Box
                  position="absolute"
                  backgroundColor="Border Subtle"
                  style={{left: 28, bottom: -24, width: 1, height: 24}}
                />
              )}
              <UpdatePost employee={firstChange.employee} update={{created_at: timestamp}} featured>
                <Stack gap="8">
                  {!creationType ? (
                    <>
                      {!Object.keys(reasonChanges).length ? null : (
                        <Box marginBottom="16">
                          {Object.entries(reasonChanges).map(([type, changes]) => (
                            <Stack key={`${timestamp}-${type}`} gap="8">
                              {type === 'update' ? (
                                changes.slice(-1).map(({after, date}) => {
                                  if (!after) {
                                    return null;
                                  }

                                  const update = JSON.parse(after);
                                  return (
                                    <Stack key={`${type}-${after}—${date}`}>
                                      <StatusButton isLabel status={update.status} />
                                      {update.comment && (
                                        <RichTextArea
                                          comments
                                          value={update.comment}
                                          activeThreadId={focusedThread}
                                          setActiveThreadId={setFocusedThread}
                                          threads={threads?.filter(
                                            (thread) => thread.workstream_update_id === update.id
                                          )}
                                          onAddComment={({comment: commentText, ...rest}) =>
                                            'threadId' in rest
                                              ? handleAddComment(
                                                  commentText,
                                                  '',
                                                  0,
                                                  0,
                                                  update,
                                                  undefined,
                                                  rest.threadId
                                                )
                                              : handleAddComment(
                                                  commentText,
                                                  rest.text,
                                                  rest.start,
                                                  rest.end,
                                                  update
                                                )
                                          }
                                          theme="xsmall"
                                        />
                                      )}
                                    </Stack>
                                  );
                                })
                              ) : (
                                <>
                                  <Box marginBottom="6">
                                    <Text isBold>{t(`workstream_audit_${type}`)}</Text>
                                  </Box>
                                  {changes.map(({before, after, date}) => (
                                    <Stack key={`${type}-${before}-${after}—${date}`} gap="8">
                                      <Stack>
                                        {before && (
                                          <ChangeLogLine
                                            isEdit={Boolean(before && after)}
                                            change="remove"
                                            type={type}
                                            value={before}
                                          />
                                        )}
                                        {after && (
                                          <ChangeLogLine
                                            isEdit={Boolean(before && after)}
                                            change="add"
                                            type={type}
                                            value={after}
                                          />
                                        )}
                                      </Stack>
                                    </Stack>
                                  ))}
                                </>
                              )}
                            </Stack>
                          ))}
                          {reason && (
                            <Box marginTop="16" marginBottom="28">
                              <AuditLogReason reason={reason} border="top" />
                            </Box>
                          )}
                        </Box>
                      )}
                      {Object.entries(changesByType).map(([type, changes]) =>
                        ['metric', 'end_date'].includes(type) ? null : (
                          <Stack key={`${timestamp}-${type}`} gap="8">
                            {type === 'update' ? (
                              changes.slice(-1).map(({after, date}) => {
                                if (!after) {
                                  return null;
                                }

                                const update = JSON.parse(after);
                                return (
                                  <Stack key={`${type}-${after}—${date}`}>
                                    <StatusButton isLabel status={update.status} />
                                    {update.comment && (
                                      <RichTextArea
                                        comments
                                        value={update.comment}
                                        activeThreadId={focusedThread}
                                        setActiveThreadId={setFocusedThread}
                                        threads={threads?.filter(
                                          (thread) => thread.workstream_update_id === update.id
                                        )}
                                        onAddComment={({comment: commentText, ...rest}) =>
                                          'threadId' in rest
                                            ? handleAddComment(
                                                commentText,
                                                '',
                                                0,
                                                0,
                                                update,
                                                undefined,
                                                rest.threadId
                                              )
                                            : handleAddComment(
                                                commentText,
                                                rest.text,
                                                rest.start,
                                                rest.end,
                                                update
                                              )
                                        }
                                        theme="small"
                                      />
                                    )}
                                  </Stack>
                                );
                              })
                            ) : (
                              <>
                                <Box marginBottom="6">
                                  <Text isBold>{t(`workstream_audit_${type}`)}</Text>
                                </Box>
                                {changes.map(({before, after, date}) => (
                                  <Stack key={`${type}-${before}-${after}—${date}`} gap="8">
                                    <Stack>
                                      {before && (
                                        <ChangeLogLine
                                          isEdit={Boolean(before && after)}
                                          change="remove"
                                          type={type}
                                          value={before}
                                        />
                                      )}
                                      {after && (
                                        <ChangeLogLine
                                          isEdit={Boolean(before && after)}
                                          change="add"
                                          type={type}
                                          value={after}
                                        />
                                      )}
                                    </Stack>
                                  </Stack>
                                ))}
                              </>
                            )}
                          </Stack>
                        )
                      )}
                    </>
                  ) : (
                    <Stack gap="2">
                      <Box marginBottom="6">
                        <Text>
                          <Trans
                            i18nKey="audit_log_new_workstream"
                            t={t}
                            components={{b: <Text isBold />}}
                            values={{data: creationType[0].after}}
                          />
                        </Text>
                      </Box>
                      {getLatestMetrics(changesByType?.['metric'])?.map(({after}) => {
                        if (!after) {
                          return null;
                        }

                        try {
                          const {type: metricType, title, current, target, id} = JSON.parse(after);

                          return (
                            <Box display="flex">
                              <UIIcon
                                src={metricType === 'milestone' ? FlagLine : MetricLine}
                                size="small"
                                className={atoms({
                                  color: 'Text Subtle',
                                  flexShrink: 0,
                                  marginRight: '4',
                                  marginTop: '2',
                                })}
                              />
                              <RichTextArea
                                // style={{flexGrow: 1}}
                                value={title}
                                comments
                                activeThreadId={focusedThread}
                                setActiveThreadId={setFocusedThread}
                                threads={threads?.filter(
                                  (thread) => thread.workstream_metric_id === id
                                )}
                                onAddComment={({comment: commentText, ...rest}) =>
                                  'threadId' in rest
                                    ? handleAddComment(
                                        commentText,
                                        '',
                                        0,
                                        0,
                                        undefined,
                                        {id} as WorkstreamMetric,
                                        rest.threadId
                                      )
                                    : handleAddComment(
                                        commentText,
                                        rest.text,
                                        rest.start,
                                        rest.end,
                                        undefined,
                                        {id} as WorkstreamMetric
                                      )
                                }
                              />
                              {target && current && (
                                <Text style={{alignSelf: 'flex-end', marginLeft: 8, flexShrink: 0}}>
                                  {current}/{target}
                                </Text>
                              )}
                            </Box>
                          );
                        } catch (e) {
                          return <>{after}</>;
                        }
                      })}
                    </Stack>
                  )}
                </Stack>
              </UpdatePost>
            </Box>
          );
        })
      )}
    </Stack>
  );
};

function getLatestMetrics(entries?: WorkstreamAuditLogType[]): WorkstreamAuditLogType[] {
  const latestMap = new Map<string, WorkstreamAuditLogType>(); // Maps `title` to latest WorkstreamAuditLogType
  const latestTimestamps = new Map<string, string>(); // Tracks latest timestamp for each `title`
  const beforeToAfter = new Map<string, string>(); // Maps `before.title` to `after.title` for tracking transitions

  entries?.forEach((entry) => {
    if (!entry.after) return; // Ignore entries with no 'after' data

    const afterData = JSON.parse(entry.after);
    const afterTitle = afterData.title;
    const entryDate = new Date(entry.date).toISOString();

    // If there's a before, track its transition
    if (entry.before) {
      const beforeData = JSON.parse(entry.before);
      const beforeTitle = beforeData.title;
      beforeToAfter.set(beforeTitle, afterTitle);
    }

    // Store the latest WorkstreamAuditLogType object per `after.title`
    if (!latestTimestamps.has(afterTitle) || entryDate > latestTimestamps.get(afterTitle)!) {
      latestTimestamps.set(afterTitle, entryDate);
      latestMap.set(afterTitle, entry);
    }
  });

  // Resolve final latest values by following before → after transitions
  const finalEntries = new Set<WorkstreamAuditLogType>();

  latestTimestamps.forEach((_, title) => {
    const latestTitle = title;
    // while (beforeToAfter.has(latestTitle)) {
    //   latestTitle = beforeToAfter.get(latestTitle)!;
    // }
    if (latestMap.has(latestTitle)) {
      finalEntries.add(latestMap.get(latestTitle)!);
    }
  });

  return Array.from(finalEntries);
}
