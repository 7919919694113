/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Goal } from '../models/Goal';
import type { GoalCreate } from '../models/GoalCreate';
import type { GoalData } from '../models/GoalData';
import type { GoalRead } from '../models/GoalRead';
import type { GoalsByStrategy } from '../models/GoalsByStrategy';
import type { GoalsNumOfPrivateGoalsData } from '../models/GoalsNumOfPrivateGoalsData';
import type { GoalUpdate } from '../models/GoalUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GoalService {

    /**
     * Create Goal
     * Create new goal.
     * @param requestBody
     * @param employeeId
     * @returns Goal Successful Response
     * @throws ApiError
     */
    public static createGoalApiV1GoalsPost(
        requestBody: GoalCreate,
        employeeId?: (string | null),
    ): CancelablePromise<Goal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/goals',
            query: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Goals By Ldap Id
     * Retrieve goals for user.
     * @param ldap
     * @param skip
     * @param limit
     * @param timeframe
     * @returns GoalsNumOfPrivateGoalsData Successful Response
     * @throws ApiError
     */
    public static readGoalsByLdapIdApiV1GoalsUsersLdapGet(
        ldap: string,
        skip?: number,
        limit: number = 100,
        timeframe?: (string | null),
    ): CancelablePromise<GoalsNumOfPrivateGoalsData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/goals/users/{ldap}',
            path: {
                'ldap': ldap,
            },
            query: {
                'skip': skip,
                'limit': limit,
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Goals By Team Slug
     * Retrieve goals for team.
     * @param teamSlug
     * @param skip
     * @param limit
     * @param timeframe
     * @param includeSubteamMembers
     * @returns GoalData Successful Response
     * @throws ApiError
     */
    public static readGoalsByTeamSlugApiV1GoalsTeamsTeamSlugGet(
        teamSlug: string,
        skip?: number,
        limit: number = 100,
        timeframe?: (string | null),
        includeSubteamMembers?: (boolean | null),
    ): CancelablePromise<Array<GoalData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/goals/teams/{team_slug}',
            path: {
                'team_slug': teamSlug,
            },
            query: {
                'skip': skip,
                'limit': limit,
                'timeframe': timeframe,
                'include_subteam_members': includeSubteamMembers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Goal By Id
     * Get goal by ID.
     * Todo: Add permissions check
     * @param goalId
     * @returns GoalData Successful Response
     * @throws ApiError
     */
    public static readGoalByIdApiV1GoalsGoalIdGet(
        goalId: number,
    ): CancelablePromise<GoalData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/goals/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Goal
     * Update a goal.
     * @param goalId
     * @param userId
     * @param requestBody
     * @returns Goal Successful Response
     * @throws ApiError
     */
    public static updateGoalApiV1GoalsGoalIdPut(
        goalId: number,
        userId: string,
        requestBody: GoalUpdate,
    ): CancelablePromise<Goal> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/goals/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Goal
     * Delete a goal.
     * Note: This is a soft delete.
     * @param goalId
     * @param userId
     * @returns Goal Successful Response
     * @throws ApiError
     */
    public static deleteGoalApiV1GoalsGoalIdDelete(
        goalId: number,
        userId: string,
    ): CancelablePromise<Goal> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/goals/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Aligned Goals
     * Get aligned goals by goal id from key results.
     * @param goalId
     * @param skip
     * @param limit
     * @returns GoalRead Successful Response
     * @throws ApiError
     */
    public static readAlignedGoalsApiV1AlignedGoalsGoalIdGet(
        goalId: number,
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<Array<GoalRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aligned_goals/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Continue Goal
     * Continue a goal and its key results.
     * @param goalId
     * @param ownerId
     * @param timeframe
     * @param createdAt
     * @param employeeId
     * @returns Goal Successful Response
     * @throws ApiError
     */
    public static continueGoalApiV1GoalsGoalIdContinuePost(
        goalId: number,
        ownerId: string,
        timeframe: string,
        createdAt: string,
        employeeId?: (string | null),
    ): CancelablePromise<Goal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/goals/{goal_id}/continue',
            path: {
                'goal_id': goalId,
            },
            query: {
                'owner_id': ownerId,
                'timeframe': timeframe,
                'created_at': createdAt,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Goals By Strategy
     * Retrieve goals by strategy.
     * @param strategyId
     * @param timeframe
     * @returns GoalsByStrategy Successful Response
     * @throws ApiError
     */
    public static getGoalsByStrategyApiV1GoalsStrategyStrategyIdGet(
        strategyId: string,
        timeframe: string,
    ): CancelablePromise<GoalsByStrategy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/goals/strategy/{strategy_id}',
            path: {
                'strategy_id': strategyId,
            },
            query: {
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
