import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {useQuery} from '@tanstack/react-query';
import {Badge, BadgeService} from 'client';
import {BadgeImage} from 'components/badges/BadgeImage';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';

export const BadgeNomination = () => {
  const {
    notification: {archived, data, updated_at},
  } = useNotificationContext();
  const {content, to, cta, data: badgeId} = data;
  const {data: badge} = useQuery<Badge>({
    queryKey: ['badge', badgeId],
    queryFn: () => getService(BadgeService).getBadgeApiV1BadgesIdGet(parseInt(badgeId, 10)),
  });

  const {data: nominations} = useQuery({
    queryKey: ['nominations', badgeId],
    queryFn: () =>
      getService(BadgeService).getNominationsApiV1BadgesIdNominationsGet(parseInt(badgeId, 10)),
  });

  return (
    <NotificationCard
      accessory={
        badge?.image_key ? (
          <BadgeImage badge={badge?.image_key} size={24} />
        ) : (
          <Skeleton.Box height={24} style={{width: 24}} />
        )
      }
      header={
        archived ? (
          `Review nominations for ${badge?.name ?? 'badge'}`
        ) : (
          <Trans
            i18nKey={content}
            t={t}
            values={{
              count: nominations?.length ?? 0,
              countString: nominations?.length ?? '-',
              data: badge?.name ?? 'a badge',
            }}
            components={{b: <Text isBold />}}
          />
        )
      }
      subtext={<TimeAgo timestamp={updated_at} />}
      buttonLink={{cta, to}}
      archived={archived}
    />
  );
};
