/* eslint-disable no-param-reassign */
import {TeamWithMembers} from 'client';
import * as d3 from 'd3';
import {sortEmployees} from 'helpers/utils';

import {FocusData, getUid, HierarchyData} from './helpers';

export const getData = (
  focus: FocusData,
  overrideData: HierarchyData | undefined,
  treeData: HierarchyData | undefined,
  teamData: TeamWithMembers | undefined
) => overrideData ?? getPeersAndChildren(treeData, focus, teamData);

const getPeersAndChildren = (
  data: HierarchyData | undefined,
  focus: FocusData,
  teamData: TeamWithMembers | undefined
): HierarchyData => {
  if (!data) {
    return {} as HierarchyData;
  }

  const root = d3.hierarchy(data);

  // Locate the focus node and, optionally, the selection node
  const focusNode = root.find((node) => getUid(node.data) === getUid(focus));

  if (!focusNode) {
    return {...data, children: []};
  }

  let processData: HierarchyData | undefined = undefined;

  const ancestors = focusNode.ancestors();

  // Handle the case where the focus node is the root
  if (ancestors?.length === 1) {
    return {
      ...ancestors[0].data,
      children: ancestors[0].children?.map((child) => ({
        ...child.data,
        direct_report_count: child.children?.length ?? 0,
        children: [],
      })) as any,
    } as HierarchyData;
  }

  // Traverse ancestors from the focus node up to the root
  const includePeers = teamData ? false : !focusNode.children || focusNode.children.length === 0;
  ancestors.forEach((node) => {
    const isParentOfFocus = node === focusNode.parent;

    if (isParentOfFocus) {
      // Only include peers if focusNode has no children
      processData = {
        ...node.data,
        children: node.children
          ?.filter((child) => child === focusNode || includePeers)
          .map((child) => ({
            ...child.data,
            children:
              child === focusNode
                ? [
                    ...(child.children?.map((grandChild) => ({
                      ...grandChild.data,
                      children: [],
                    })) ?? []),

                    ...(teamData?.employees.sort(sortEmployees).map((employee) => ({
                      ...employee,
                      children: [],
                    })) ?? []),
                  ]
                : // .reverse()
                  [],
          })) as any,
      } as HierarchyData;
    } else {
      // For other nodes along the path (including the root), keep only the single path down to the focus node
      processData = {
        ...node.data,
        children: processData ? [processData] : [],
      } as HierarchyData;
    }
  });

  return processData!;
};
