import {useQuery} from '@tanstack/react-query';
import {EmployeeService, TeamWithHierarchyCounts} from 'client';
import {useTeamMembers, useTeamsCountTree, useTeamsTree} from 'components/teams/hooks';
import * as d3 from 'd3';
import {useCallback, useEffect, useMemo} from 'react';
import {getService} from 'utilities';

import {getData} from './dataUtils';
import {findPathToRoot, FocusData, HierarchyData, isEmployee} from './helpers';

export const useData = ({
  focus,
  type,
  selection,
  overrideTree,
  expanded,
}: {
  focus: FocusData;
  type: 'employee' | 'team';
  selection?: FocusData;
  overrideTree?: HierarchyData;
  expanded: boolean;
}) => {
  const {data: peopleTree} = useQuery({
    queryKey: ['people', 'tree', 'all'],
    queryFn: getService(EmployeeService).getTreeAllApiV1PeopleTreeGet,
  });

  const {data: teamQuickTree} = useTeamsTree();
  const {data: teamCountTree} = useTeamsCountTree();

  const teamTree = useMemo(() => {
    return teamCountTree ?? teamQuickTree;
  }, [teamQuickTree, teamCountTree]) as TeamWithHierarchyCounts;

  const teamData = useTeamMembers({
    slug: !isEmployee(focus) ? (focus?.slug ?? undefined) : undefined,
  });

  const hierarchyData = useMemo(() => {
    const root = d3.hierarchy<HierarchyData>(
      getData(
        focus,
        !expanded ? overrideTree : undefined,
        type === 'employee' ? (peopleTree ?? undefined) : teamTree,
        expanded ? teamData : undefined
      )
    );

    let maxDepth = 0;
    if (expanded || type === 'team') {
      // Traverse the tree to find the maximum depth
      root.each((node) => {
        if (node.depth > maxDepth) {
          maxDepth = node.depth;
        }
      });
    }

    const nodes = root.descendants() as d3.HierarchyPointNode<HierarchyData>[];
    const links = root.links() as d3.HierarchyPointLink<HierarchyData>[];

    const pathToRoot = findPathToRoot(nodes, selection ?? focus);

    return {root, pathToRoot, nodes, links, maxDepth};
  }, [expanded, focus, overrideTree, peopleTree, selection, teamData, teamTree, type]);

  return {hierarchyData, teamTree};
};

export const useChartExpand = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  expanded: boolean,
  recenterChart: (obj: {resize: boolean}) => void
) => {
  useEffect(() => {
    if (!wrapperRef.current) return;

    const element = wrapperRef.current;
    const rect = element.getBoundingClientRect();

    // Get the bounding box of the element
    if (expanded) {
      // Calculate target dimensions and position for full viewport
      const initialWidth = rect.width;
      const initialHeight = rect.height;
      const initialTop = rect.top;
      const initialLeft = rect.left;

      // Animate to full viewport size
      element.style.position = 'fixed';
      element.style.top = `${initialTop}px`;
      element.style.left = `${initialLeft}px`;
      element.style.width = `${initialWidth}px`;
      element.style.height = `${initialHeight}px`;

      // Trigger reflow for the browser to recognize the initial state
      void element.offsetHeight;

      // Animate to full viewport size
      element.style.transition = 'width 0.4s ease, height 0.4s ease, top 0.4s ease, left 0.4s ease';
      element.style.top = '83px';
      element.style.left = '88px';
      element.style.width = 'calc(100vw - 120px)';
      element.style.height = 'calc(100vh - 114px)';
      element.style.zIndex = '10';

      document.body.style.overflow = 'hidden';
    } else {
      // Reset to initial state
      element.style.width = '';
      element.style.height = '';
      element.style.top = '';
      element.style.left = '';
      element.style.position = 'relative';
      element.style.transition = 'width 0s ease, height 0s ease, top 0s ease, left 0s ease';
      element.style.zIndex = '';
      document.body.style.overflow = '';
    }
  }, [expanded, wrapperRef]);

  useEffect(() => {
    const animateCentering = () => {
      recenterChart({resize: true});

      // Continue the animation loop until expansion is complete
      animationFrame = requestAnimationFrame(animateCentering);
    };

    let animationFrame = requestAnimationFrame(animateCentering);

    setTimeout(() => {
      cancelAnimationFrame(animationFrame);
    }, 900);

    return () => cancelAnimationFrame(animationFrame);
  }, [expanded, recenterChart]);
};

export const useChartResize = (recenterChart: (obj: {resize: boolean}) => void) => {
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        recenterChart({resize: true});
      }, 300);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [recenterChart]);
};

export const useChartKeyboardShortcuts = ({
  expanded,
  adjustZoom,
  resetZoom,
}: {
  expanded: boolean;
  adjustZoom: (zoom: number, source: string) => void;
  resetZoom: (source: string) => void;
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!expanded) {
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '0') {
        event.preventDefault();
        resetZoom('keyboard');
      }

      if ((event.metaKey || event.ctrlKey) && (event.key === '+' || event.key === '=')) {
        event.preventDefault();
        adjustZoom(1.2, 'keyboard');
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '-') {
        event.preventDefault();
        adjustZoom(0.8, 'keyboard');
      }
    },
    [adjustZoom, expanded, resetZoom]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
