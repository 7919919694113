import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, DeleteLine, DragHandleLine, PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {TrackerCreate, TrackerEdit, TrackerFull, Workstream} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {WorkstreamLine} from 'components/layout/WorkstreamLine';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {WorkstreamTypeahead} from './WorkstreamTypeahead';

export const TrackerModify = ({
  tracker,
  onSubmit,
  isEditing,
  isPending,
}: {
  onSubmit: (data: {data: TrackerCreate | TrackerEdit}) => Promise<number>;
  tracker?: TrackerFull;
  isPending: boolean;
  isEditing?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const autofocus = location.state?.autofocus;
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    analyticsLogger().logEvent('TRACKER_MODIFY_VIEW', {edit: Boolean(isEditing), autofocus});
  }, [isEditing, autofocus]);

  const [trackerUpdate, setTrackerUpdate] = useState<TrackerFull>(
    tracker ? tracker : ({workstreams: []} as unknown as TrackerFull)
  );
  const [workstreams, setWorkstreams] = useState<WorkstreamSortWrapper[]>([
    ...trackerUpdate.workstreams
      .sort((a, b) => a.order - b.order)
      .map((workstream, index) => ({
        id: index + 1,
        workstreamId: workstream.workstream.id,
        data: workstream.workstream,
      })),
    ...(isEditing && searchParams.get('autofocus') !== 'workstreams'
      ? []
      : [
          {
            id: trackerUpdate.workstreams.length + 1,
            workstreamId: -1,
            data: undefined,
          },
        ]),
  ]);

  useDocumentTitle(isEditing ? t('edit_tracker') : t('add_tracker'));

  const hasChanges = useMemo(
    () =>
      trackerUpdate.name !== tracker?.name ||
      JSON.stringify(workstreams) !==
        JSON.stringify(
          trackerUpdate.workstreams
            .sort((a, b) => a.order - b.order)
            .map((workstream, index) => ({
              id: index + 1,
              workstreamId: workstream.workstream.id,
              data: workstream.workstream,
            }))
        ),
    [tracker?.name, trackerUpdate.name, trackerUpdate.workstreams, workstreams]
  );

  const hasError = useMemo(() => {
    const {name} = trackerUpdate;

    if (!name) {
      return true;
    }

    return false;
  }, [trackerUpdate]);

  const handleSubmit = async () => {
    if (isEditing && typeof trackerUpdate.id !== 'number') {
      throw new Error('Tracker ID is not a number');
    }

    try {
      const id = await onSubmit({
        data: {
          id: trackerUpdate.id as number,
          name: trackerUpdate.name,
          workstreams: workstreams
            .filter((workstream) => workstream.workstreamId !== -1)
            .map((workstream, index) => ({
              workstream_id: workstream.workstreamId,
              order: index,
            })),
        },
      });

      if (id === -1) {
        throw new Error('Failed to save tracker');
      }

      setSnackbarMessage({text: t('saved')});

      navigate(ROUTE_PATHS.WORKSTREAMS.replace(':id', id.toString()), {
        state: {source: isEditing ? 'modify' : 'create'},
      });
    } catch (e: any) {
      setSnackbarMessage({text: e});
    }
  };

  if (!loggedInEmployee?.email) {
    return null;
  }

  return (
    <Layout.InlineDrawerContainer
      open={isDrawerOpen}
      drawerHeader={<Title size={18}>{t('members_title')}</Title>}
      drawerIcon={PersonMultipleLine}
      drawerBody={<></>}
      onClose={() => setDrawerOpen(false)}
    >
      <Stack align="start">
        <Header isEditing={isEditing} />

        <FormRow>
          <FormLabel
            withInput={
              <TextInput
                required
                autoFocus={!autofocus}
                size="large"
                width="100%"
                placeholder={t('start_writing_placeholder')}
                value={trackerUpdate.name}
                onChange={(e) => setTrackerUpdate((p) => ({...p, name: e.target.value}))}
              />
            }
          >
            {t('tracker_edit_name')}
          </FormLabel>
        </FormRow>

        <FormRow>
          <FormLabel> {t('workstreams')}</FormLabel>
          <ReordableWorkstreams
            autoFocus={searchParams.get('autofocus') === 'workstreams'}
            setAutoFocus={() => {
              searchParams.delete('autofocus');
              navigate({search: searchParams.toString()});
            }}
            workstreams={workstreams}
            setWorkstreams={setWorkstreams}
          />
        </FormRow>

        <FormRow>
          <EditSaveButtons
            cta={'Save'}
            disableSave={!hasChanges || hasError}
            isLoading={isPending}
            handleCancelClick={() =>
              /* eslint-disable-next-line*/
              /* @ts-ignore */
              navigate(-1, {
                state: {source: isEditing ? 'modify' : 'create'},
              })
            }
            handleSaveClick={handleSubmit}
          />
        </FormRow>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

type WorkstreamSortWrapper = {
  id: number;
  workstreamId: number;
  data: Workstream | undefined;
};

const ReordableWorkstreams = ({
  autoFocus,
  setAutoFocus,
  workstreams,
  setWorkstreams,
}: {
  autoFocus: boolean;
  setAutoFocus: (value: boolean) => void;
  workstreams: WorkstreamSortWrapper[];
  setWorkstreams: Dispatch<SetStateAction<WorkstreamSortWrapper[]>>;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setWorkstreams((items) => {
        const oldIndex = items.findIndex((entry) => entry.id === active.id);
        const newIndex = items.findIndex((entry) => entry.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <Stack gap="12">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={workstreams} strategy={verticalListSortingStrategy}>
          {workstreams.map((workstream, index) =>
            !workstream.data ? (
              <Box key={workstream.id} width="100%">
                <WorkstreamTypeahead
                  autoFocus={autoFocus && index === workstreams.length - 1}
                  selectedIds={workstreams.map((ws) => ws.workstreamId)}
                  onSelect={(selectedWorkstream) => {
                    setAutoFocus(false);
                    setWorkstreams((items) => {
                      const newItems = [
                        ...items,
                        {
                          id: items.length + 1,
                          workstreamId: -1,
                          data: undefined,
                        },
                      ];
                      newItems[index] = {
                        id: index + 1,
                        workstreamId: selectedWorkstream.id,
                        data: selectedWorkstream,
                      };

                      return newItems;
                    });
                  }}
                />
              </Box>
            ) : (
              <ReorderableWorkstreamRow
                key={workstream.id}
                row={workstream}
                onDelete={(id) => setWorkstreams((items) => items.filter((item) => item.id !== id))}
                showDelete={workstreams.length > 2}
              />
            )
          )}
        </SortableContext>
      </DndContext>

      <IconButton
        variant="outline"
        shape="circular"
        size="small"
        onClick={() =>
          setWorkstreams((items) => [
            ...items,
            {
              id: items.length + 1,
              workstreamId: -1,
              data: undefined,
            },
          ])
        }
      >
        <Box as={UIIcon} src={AddLine} color="Border Base" />
      </IconButton>
    </Stack>
  );
};

const ReorderableWorkstreamRow = ({
  row,
  onDelete,
  showDelete,
}: {
  row: WorkstreamSortWrapper;
  onDelete: (id: number) => void;
  showDelete?: boolean;
}) => {
  const {attributes, isSorting, listeners, transform, setNodeRef, transition, isDragging} =
    useSortable({
      id: row.id,
    });

  const style = {
    transition,
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    zIndex: isDragging ? 1 : undefined,
    opacity: isSorting && !isDragging ? 0.7 : 1,
  };

  const owner = row.data?.employee_associations.find(
    (association) => association.dri === 'dri'
  )?.employee;

  return (
    <>
      <Box
        backgroundColor="Background Base"
        borderRadius="Small"
        boxShadow={isDragging ? 'Floating' : undefined}
        borderColor="Border Subtle"
        borderWidth="1"
        borderStyle="Solid"
        position="relative"
        padding="12"
        ref={setNodeRef}
        style={style}
      >
        <Split gap="12" alignY="center">
          <Box
            position="absolute"
            color={isDragging ? 'Border Base' : 'Border Subtle'}
            style={{left: -26, top: 12, cursor: isDragging ? 'grabbing' : 'grab'}}
            {...attributes}
            {...listeners}
            tabIndex={-1}
          >
            <Box as={UIIcon} src={DragHandleLine} size="medium" color="Border Base" />
          </Box>

          <Split.Item paddingTop="4">
            <UIIcon src={WorkstreamLine} />
          </Split.Item>

          <Split.Item width="fill">
            <Text>{row.data?.name}</Text>
          </Split.Item>

          {owner && (
            <Split.Item>
              <Avatar size="small" user={owner} />
            </Split.Item>
          )}

          {showDelete && (
            <Split.Item>
              <IconButton
                variant="borderless"
                size="medium"
                shape="circular"
                onClick={() => onDelete(row.id)}
              >
                <UIIcon src={DeleteLine} />
              </IconButton>
            </Split.Item>
          )}
        </Split>
      </Box>
    </>
  );
};

const Header = ({isEditing}: {isEditing?: boolean}) => {
  return (
    <>
      <Split alignY="center" paddingBottom="16" gap="6">
        <Split.Item width="fill">
          <Title size={18}>{isEditing ? t('edit_tracker') : t('add_tracker')}</Title>
        </Split.Item>
      </Split>
    </>
  );
};
