import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';
import {Link} from 'components/DSYS/Link';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';

export const CreateCell = ({
  tooltip,
  to,
  state,
  marginLeft,
}: {
  tooltip: string;
  to: string;
  state: any;
  marginLeft: BoxProps<'div'>['marginLeft'];
}) => (
  <Box padding="8" marginLeft={marginLeft}>
    <OptionalTooltip enabled title={tooltip} placement="right">
      <Link to={to} state={state} hasNoUnderline>
        <IconButton variant="filled" shape="circular" tabIndex={-1} size="small">
          <Box as={UIIcon} src={AddLine} color="Text Subtle" />
        </IconButton>
      </Link>
    </OptionalTooltip>
  </Box>
);
