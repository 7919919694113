import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {GoalUser, Update} from 'client';
import {Table} from 'components/DSYS/Table';
import {MetricBar} from 'components/shared/MetricBar';
import {TimeAgo} from 'components/shared/TimeAgo';
import {emailToLdap} from 'utilities';

import {parseUpdateComment} from './utils';

const getEmployeeFirstName = (employeeName: string) => {
  return employeeName.split(' ')[0];
};

const isUpdatedByOwner = (latestUpdate: Update, goalOwner: GoalUser) => {
  if (!latestUpdate.employee) {
    return true;
  }
  return latestUpdate.employee?.ldap === emailToLdap(goalOwner.email);
};

export const UpdateCell = ({
  latestUpdate,
  onClick,
  hasContributors,
  goalOwner,
  canEdit,
  maxWidth,
}: {
  latestUpdate: Update;
  goalOwner: GoalUser;
  hasContributors?: boolean;
  onClick?: () => void;
  canEdit?: boolean;
  maxWidth?: number;
}) => {
  const parsedText = parseUpdateComment(latestUpdate.comment);
  let updatedText = parsedText;

  if (hasContributors || !isUpdatedByOwner(latestUpdate, goalOwner)) {
    const author = getEmployeeFirstName(latestUpdate.employee?.name ?? goalOwner.display_name);

    if (parsedText.length) {
      updatedText = `${author}: ${parsedText}`;
    }
  }

  return (
    <Table.Cell onClick={onClick} style={{maxWidth}}>
      <Box as="div" padding="16" paddingTop={canEdit ? '12' : '16'} paddingBottom="12">
        <LabelGroup
          size="small"
          withText={
            <Box>
              <Box
                overflow="hidden"
                style={{
                  maxWidth: maxWidth,
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              />
              <Box as={Truncate} lines={3} style={{wordBreak: 'break-word'}}>
                <Text size="small">{updatedText}</Text>
              </Box>
            </Box>
          }
          withSubtext={
            <Box style={{height: '14px'}}>
              {latestUpdate.current_metric && latestUpdate.target_metric && (
                <MetricBar
                  currentValue={latestUpdate.current_metric}
                  targetValue={latestUpdate.target_metric}
                  accessoryRight={' • '}
                />
              )}
              <TimeAgo timestamp={latestUpdate.updated_at} />
            </Box>
          }
        />
      </Box>
    </Table.Cell>
  );
};
