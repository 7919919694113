import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, SearchLine, TeamLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {TeamWithCounts, TeamWithCountsNew} from 'client';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';

import {AvatarIcon} from './Avatar';

export const TeamTypeahead = ({
  autoFocus,
  selection,
  selections,
  placeholder,
  onSingleSelect,
  onMultiSelect,
}: {
  autoFocus?: boolean;
  selection?: string;
  selections?: string[];
  placeholder: TeamWithCountsNew[];
  onSingleSelect?: (team: TeamWithCountsNew) => void;
  onMultiSelect?: (teams: TeamWithCountsNew[]) => void;
}) => {
  if ((selection && !onSingleSelect) || (selections && !onMultiSelect)) {
    throw new Error(
      'Selections must be used with onMultiSelect and selection must be used with onSingleSelect'
    );
  }

  const [userInputValue, setUserInputValue] = useState('');
  // const searchResult = useSearches({
  //   ldaps: userInputValue.split(',').map((e) => (!e.includes('@') ? e : e.trim().split('@')[0])),
  // });
  const {data: searchResult, isLoading} = useGlobalSearch({
    input: userInputValue,
    filter: 'teams',
    showMore: true,
  });

  const searchedResults = searchResult.map((result) => result as TeamWithCountsNew);

  const teamHints = searchedResults.length ? searchedResults : placeholder;

  const filteredTeamHints = teamHints.filter(
    (team) => !selections?.find((slug) => slug === team.slug) && team.slug !== selection
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (slug: string) => {
    const selectedTeam = teamHints.find((e) => e.slug === slug);
    if (selectedTeam) {
      onMultiSelect?.([selectedTeam]);
      onSingleSelect?.(selectedTeam);
      setUserInputValue('');
    }
  };

  useEffect(() => {
    if (userInputValue.length > 0 && userInputValue.includes(',') && searchResult.length) {
      let updatedInputValue = userInputValue;
      const foundTeams = userInputValue.split(',').reduce((acc, value) => {
        const normalizedValue = value.trim().toLowerCase();
        const found = teamHints.find(
          (e) => (e.name ?? '').toLowerCase() === normalizedValue || e.slug === normalizedValue
        );
        if (found) {
          updatedInputValue = updatedInputValue.replace(value, '');
          acc.push(found);
        }
        return acc;
      }, [] as TeamWithCountsNew[]);

      const newSelections = foundTeams.filter(
        (found) => !selections?.find((e) => e === found.email)
      );

      if (newSelections.length) {
        onMultiSelect?.(newSelections);
      }

      setUserInputValue(
        updatedInputValue
          .split(',')
          .filter((e) => e.length)
          .join(',')
      );
    }
  }, [teamHints, onMultiSelect, searchResult, selections, userInputValue]);

  const renderTypeaheadRow = (team: TeamWithCountsNew) => (
    <Typeahead.Row
      key={team.team_id}
      value={team.slug}
      withTitle={
        <Text
          isBold
          style={{textOverflow: 'ellipsis', display: 'inline-block', width: 350}}
          className={atoms({
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          })}
        >
          {team.name}
        </Text>
      }
      withSubtitle={
        t('member', {
          count: (team as TeamWithCounts).members_count ?? 0,
          countString: (team as TeamWithCounts).members_count ?? 0,
        }) +
        ((team as TeamWithCounts).subteams_count
          ? ` • ${t('sub_team', {
              count: (team as TeamWithCounts).subteams_count ?? 0,
              countString: (team as TeamWithCounts).subteams_count ?? 0,
            })}`
          : '')
      }
      withLeftAccessory={<AvatarIcon src={TeamLine} />}
    />
  );

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <FormRow>
          <TextInput
            autoFocus={autoFocus}
            size="large"
            placeholder={t('select_teams')}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withLeftAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            {!userInputValue.length || isLoading || filteredTeamHints?.length ? (
              <Typeahead.Results
                maxResults={8}
                results={filteredTeamHints}
                renderRow={renderTypeaheadRow}
              />
            ) : (
              <Typeahead.Results
                results={[{}]}
                renderRow={() => (
                  <Typeahead.Row
                    withLeftAccessory={<AvatarIcon src={AddLine} />}
                    withTitle={
                      <Trans
                        i18nKey="team_not_found_create"
                        t={t}
                        values={{name: userInputValue}}
                        components={{b: <Text isBold />}}
                      />
                    }
                    onClick={() => {
                      window.open(`/teams/new?name=${userInputValue}`, '_blank');
                    }}
                  />
                )}
              />
            )}
          </Typeahead.Container>
        </FormRow>
      )}
    </Typeahead.Wrapper>
  );
};
