import {Button} from '@dropbox/dig-components/dist/buttons';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Box, Split} from '@dropbox/dig-foundations';
import {useMutation} from '@tanstack/react-query';
import {NotificationService, ScheduledNotificationCreate} from 'client';
import {GenericNotification} from 'components/notifications/types/GenericNotification';
import {OKRAction} from 'components/notifications/types/OKRAction';
import {NotificationProvider} from 'components/notifications/utils/useNotificationContext';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';
import {
  fromServerDate,
  getTodayUTC9am,
  toCalendarDate,
  toEODISOString,
  toServerDate,
} from 'views/admin/utils';
import {queryClient} from 'views/QueryClientWrapper';

import {AudienceSelect} from './AudienceSelect';
import {DataInput, parseAction} from './DataInput';
import {NotificationTypeSelect} from './NotificationTypeSelect';

export const NewNotificationModal = ({
  sendNow,
  onClose,
}: {
  sendNow: boolean;
  onClose: () => void;
}) => {
  const [hasDueDate, setHasDueDate] = useState(false);
  const [errors, setErrors] = useState(false);
  const [audience, setAudience] = useState('');
  const [type, setType] = useState('');
  const [data, setData] = useState('');
  const [sendAt, setSendAt] = useState(getTodayUTC9am().toISOString().slice(0, -1));
  const [dueAt, setDueAt] = useState<string | undefined>(undefined);

  const {mutateAsync: createScheduledNotification} = useMutation({
    mutationFn: ({notification}: {notification: ScheduledNotificationCreate}) =>
      getService(NotificationService).createApiV1NotificationsScheduledCreatePost(notification),
  });

  const handleSetSendAt = (value: Date | string) => {
    setSendAt(toServerDate(sendAt, value));

    const dueDate = new Date(value);
    dueDate.setDate(dueDate.getDate() + 7);
    setDueAt(toEODISOString(dueDate.toISOString()));
  };

  const handleCreate = async () => {
    setErrors(false);
    if (!audience || !data || (!sendNow && !type)) {
      setErrors(true);
      console.log('errors', errors, audience, data, sendNow, type);
      return;
    }
    if (sendNow || type === 'generic') {
      const action = parseAction(data);
      console.log('action', {action});
      // if (action.to || !action.content) {
      //   setErrors(true);
      //   return;
      // }
    }
    if (type.startsWith('okr_')) {
      const [quarter, year] = data.split(' ');
      if (!quarter || !year || year === 'undefined' || quarter === 'undefined') {
        setErrors(true);
        return;
      }
    }

    onClose();

    await createScheduledNotification({
      notification: {
        audience,
        data,
        send_at: sendAt,
        type: sendNow ? 'immediate' : type,
        due_at: toEODISOString(dueAt) ?? null,
      },
    });
    queryClient.invalidateQueries({queryKey: ['notifications', 'scheduled']});
  };

  return (
    <Modal isCentered open withCloseButton="Close" onRequestClose={onClose}>
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title weightVariant="emphasized">
          {t(sendNow ? 'send_notification' : 'new_notification')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!sendNow && (
          <FormRow>
            <FormLabel htmlFor="type">Notification Type</FormLabel>
            <NotificationTypeSelect
              value={type}
              onSelect={(value) => {
                setData('');
                setType(value);
                if (value.startsWith('okr_')) {
                  setHasDueDate(true);
                  if (!dueAt) {
                    setDueAt(getTodayUTC9am().toISOString());
                  }
                }
              }}
              error={errors}
            />
          </FormRow>
        )}

        <FormRow>
          <FormLabel htmlFor="type">Data</FormLabel>
          <DataInput
            error={errors}
            type={sendNow ? 'generic' : type}
            value={data}
            onChange={setData}
          />
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="type">Audience</FormLabel>
          <AudienceSelect value={audience} onSelect={setAudience} error={errors} />
        </FormRow>

        {!sendNow && (
          <FormRow>
            <FormLabel htmlFor="type">Send date</FormLabel>
            <Split gap="8" alignY="center">
              <Split.Item>
                <DatePickerInput
                  id="send-at-date"
                  value={toCalendarDate(sendAt)}
                  onChange={(value) => handleSetSendAt(value ?? getTodayUTC9am())}
                />
              </Split.Item>
              <Split.Item>
                <Select
                  id="send-at-time"
                  value={fromServerDate(sendAt).getUTCHours().toString()}
                  onChange={handleSetSendAt}
                >
                  {Array.from({length: 24}, (_, i) => i).map((hour) => (
                    <Select.Option key={hour} value={hour.toString()}>
                      {`${hour % 12 || 12}${hour < 12 ? 'am' : 'pm'}`}
                    </Select.Option>
                  ))}
                </Select>
              </Split.Item>
            </Split>
          </FormRow>
        )}

        {!sendNow && (
          <FormRow>
            <FormLabel htmlFor="type">Due date</FormLabel>
            <Split gap="8" alignY="center">
              <Split.Item>
                <Toggle
                  checked={hasDueDate}
                  disabled={type.startsWith('okr_')}
                  onChange={() => {
                    setHasDueDate(!hasDueDate);
                    if (hasDueDate && !dueAt) {
                      setDueAt(getTodayUTC9am().toISOString());
                    }
                  }}
                />
              </Split.Item>
              <Split.Item>
                <DatePickerInput
                  id="due-at"
                  disabled={!hasDueDate}
                  value={toCalendarDate(dueAt)}
                  onChange={(value) => setDueAt(value?.toISOString())}
                />
              </Split.Item>
            </Split>
          </FormRow>
        )}

        {type.startsWith('okr_') && (
          <FormRow>
            <FormLabel htmlFor="type">Preview</FormLabel>
            <NotificationProvider
              notification={{
                data: {
                  content: `notification_${type}`,
                  to: '/',
                  cta: 'Open',
                  icon: '',
                  data,
                },
                type,
                due_at: toEODISOString(dueAt) ?? null,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                id: 0,
                archived: false,
              }}
              separator={false}
              onClick={() => {}}
            >
              <Box borderColor="Border Subtle" borderWidth="1" borderStyle="Solid" padding="4">
                <OKRAction />
              </Box>
            </NotificationProvider>
          </FormRow>
        )}
        {type === 'generic' && (
          <FormRow>
            <FormLabel htmlFor="type">Preview</FormLabel>
            <NotificationProvider
              notification={{
                data: {
                  content: data?.startsWith('{') ? JSON.parse(data).content : '',
                  to: '/',
                  cta: 'Open',
                  icon: 'generic',
                  data,
                },
                type,
                due_at: toEODISOString(dueAt) ?? null,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                id: 0,
                archived: false,
              }}
              archiveable
              separator={false}
              onClick={() => {}}
            >
              <Box borderColor="Border Subtle" borderWidth="1" borderStyle="Solid" padding="4">
                <GenericNotification />
              </Box>
            </NotificationProvider>
          </FormRow>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
