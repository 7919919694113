// import './Overrides.module.css';

import {Box} from '@dropbox/dig-foundations';
import {start} from '@dropbox/ttvc';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {normalizedRoute} from 'analytics/ttvc';
import {isPrivatePageAtom, pulseTokenAtom, pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import {newsPostIdAtom} from 'atoms/news';
import {filterAtom, showSearchAtom} from 'atoms/search';
import {Employee} from 'client';
import {LeftNav} from 'components/layout/LeftNav';
import {Navbar} from 'components/Navbar';
import {useNotificationArchiver} from 'components/notifications/utils/useNotificationArchiver';
import {SearchInput} from 'components/search/Search';
import {GlobalSnackbar} from 'components/shared/GlobalSnackbar';
import {ROUTE_PATHS} from 'constant';
import {growthbook} from 'helpers/growthbook';
import {useMobile} from 'hooks/isMobile';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useRef} from 'react';
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {getSprig} from 'survey/sprig';
import {getEmployeeService} from 'utilities';

import styles from './LoggedInPage.module.css';
import {QueryClientWrapper} from './QueryClientWrapper';

/**
 * This component wraps logged in pages.
 *
 * In addition to enabling react-query, it also adds the following components:
 *  - NavBar
 *  - LeftNav
 *  - GlobalSearch
 */
export const LoggedInPage = () => {
  const [searchParams] = useSearchParams();
  const showSearch = useAtomValue(showSearchAtom);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const setIsAuthRequired = useSetAtom(isPrivatePageAtom);
  const setNewsPost = useSetAtom(newsPostIdAtom);
  const location = useLocation();

  setIsAuthRequired(true);
  const isLoggedIn = useAtomValue(pulseTokenAtom);
  const isMobile = useMobile();
  const [, setIsMobileState] = useAtom(isMobileAtom);
  const setShowSearch = useSetAtom(showSearchAtom);
  const setFilter = useSetAtom(filterAtom);
  const navigate = useNavigate();

  const firstLoad = useRef(true);

  useEffect(() => {
    if (!firstLoad.current) {
      // Subsequent TTVC loads require calls to `start()`
      start();
    }
    firstLoad.current = false;
  }, []);

  useEffect(() => {
    setIsMobileState(isMobile);
  }, [isMobile, setIsMobileState]);

  useEffect(() => {
    if (searchParams.get('s')) {
      setShowSearch('queryparam');
    }
  }, [navigate, searchParams, setShowSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
        setShowSearch(false);
        setFilter('all');
        if (searchParams.get('s')) {
          navigate(window.location.pathname, {replace: true});
        }
      }
    };

    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowSearch(false);
        if (searchParams.get('s')) {
          navigate(window.location.pathname, {replace: true});
        }
      }
    };

    const handleSearchShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault();
        setFilter('all');
        setShowSearch('keyboard');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapePress);
    document.addEventListener('keydown', handleSearchShortcut);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapePress);
      document.removeEventListener('keydown', handleSearchShortcut);
    };
  }, [navigate, overlayRef, searchParams, setFilter, setShowSearch]);

  useEffect(() => {
    if (!location.pathname.startsWith('/news')) {
      setNewsPost(undefined);
    }
  }, [location.pathname, setNewsPost]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <QueryClientWrapper>
      <ProfileLoader>
        {showSearch && (
          <div className={styles.overlay}>
            <div ref={overlayRef} className={styles.searchWrapper}>
              <SearchInput />
            </div>
          </div>
        )}

        {/* {location.pathname === '/' && (
          <Box className={isDarkMode ? styles.homeDark : styles.home} />
        )} */}

        <Navbar />
        <LeftNav />
        <Box
          id="main"
          style={{
            marginTop: 52,
            minHeight: 'calc(100vh - 52px)',
            marginLeft: 56,
            boxSizing: 'border-box',
          }}
        >
          <Outlet />
        </Box>
        <GlobalSnackbar />
        <ScrollRestoration />
        <ScrollToHash />
      </ProfileLoader>
    </QueryClientWrapper>
  );
};

const ScrollToHash = () => {
  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // Remove the '#' character
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        if (!lastHash.current) return;
        const element = document.getElementById(lastHash.current);
        if (element) {
          const top = window.scrollY + element.getBoundingClientRect().top - 60;
          document.body.scrollTo({top, behavior: 'smooth'});
        }
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
};

const getRange = () => {
  const width = window.innerWidth;

  if (width <= 600) {
    return '<=600';
  }
  if (width <= 1024) {
    return '>600 and <=1024';
  }
  if (width <= 1280) {
    return '>1024 and <=1280';
  }
  if (width <= 1440) {
    return '>1280 and <=1440';
  }
  if (width <= 1600) {
    return '>1440 and <=1600';
  }
  if (width <= 1920) {
    return '>1600 and <=1920';
  }
  return '>1920';
};

const getBrowserType = () => {
  const userAgent = navigator.userAgent;
  let browserType;

  if (userAgent.indexOf('Firefox') > -1) {
    browserType = 'Firefox';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserType = 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browserType = 'Safari';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browserType = 'Opera';
  } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
    browserType = 'Internet Explorer';
  } else {
    browserType = 'Unknown';
  }

  return browserType;
};

const ProfileLoader = ({children}: {children: React.ReactNode}) => {
  const [searchParams] = useSearchParams();
  const {archiveNotification} = useNotificationArchiver();
  const notificationId = searchParams.get('n');
  const user = useAtomValue(pulseUserAtom);
  const navigate = useNavigate();
  const setGrowthbook = useSetAtom(growthbookCacheAtom);
  const {data, status} = useQuery({
    enabled: !!user,
    queryKey: ['profile', user?.email.split('@')[0] ?? ''],
    queryFn: () =>
      getEmployeeService().getProfileApiV1PeopleUserIdGet(user?.email.split('@')[0] ?? ''),
  });
  const setUserProfile = useSetAtom(loggedInEmployeeAtom);

  useEffect(() => {
    if (status === 'success') {
      setUserProfile({
        ...data,
        delegatedBy: data.delegatedBy ?? [],
        delegates: data.delegates ?? [],
        workingWithMe: data.workingWithMe ?? '',
        hasProjects: data.hasProjects,
        hasGoals: data.hasGoals,
        admin: {} as Employee,
      });

      analyticsLogger().setProperty(
        'level',
        data.employee.type === 'Regular' ? data.employee.level : data.employee.type
      );

      analyticsLogger().logEvent('WEB_APP_LOADED', {
        nearest_100: Math.round(window.innerWidth / 100) * 100,
        range: getRange(),
        browser_width: window.innerWidth,
        browser_height: window.innerHeight,
        route: normalizedRoute(),
        browser: getBrowserType(),
      });

      growthbook.setAttributes({
        id: data?.employee?.user_id,
        loggedIn: true,
        employee:
          (data?.employee.email?.endsWith('@dropbox.com') ||
            data?.employee.email?.endsWith('@hellosign.com')) ??
          false,
        dbx51_email_regex: data?.employee.email,
      });

      growthbook.loadFeatures().then(() => {
        const isDropboxOSEnabled =
          growthbook.getFeatureValue('company_os_2025_01_21_external_release', 'OFF') === 'ON';
        if (!growthbook.isOn('company_os_enabled') && !isDropboxOSEnabled) {
          navigate(ROUTE_PATHS.WAITLIST);
          return;
        }
        setGrowthbook({
          isExportEnabled: growthbook.isOn('company_os_2024_12_05_export'),
          isSpritesAiEnabled: growthbook.isOn('company_os_sprites_ai'),
          isWorkstreamsEnabled: growthbook.isOn('companyos_2025_03_24_workstreams'),
          isDropboxOSEnabled,
        });
      });
    }
  }, [data, navigate, setGrowthbook, setUserProfile, status]);

  useEffect(() => {
    if (notificationId) {
      archiveNotification({id: parseInt(notificationId, 10)});
      analyticsLogger().logEvent('NOTIFICATION_EMAIL_CLICKED');
    }
  }, [archiveNotification, notificationId]);

  useEffect(() => {
    if (data?.employee.email?.length) {
      getSprig(data.employee.email);
    }
  }, [data?.employee.email]);

  return <>{children}</>;
};
