import {Box} from '@dropbox/dig-foundations';
import {Goal, GoalRead, KeyResult} from 'client';
import {Facepile} from 'components/DSYS/Facepile';
import {Table} from 'components/DSYS/Table';
import {isGoalType} from 'helpers/utils';

export const ContributorCell = ({
  row,
  ownerLdap,
  onClick,
  canEdit,
}: {
  row: Goal | KeyResult | GoalRead;
  ownerLdap: string;
  onClick?: () => void;
  canEdit?: boolean;
}) => (
  <Table.Cell onClick={onClick}>
    <Box as="div" paddingTop={canEdit ? '10' : '16'} paddingLeft="8" marginRight="8">
      <Facepile
        size="small"
        ownerIsFirst
        ldaps={[ownerLdap, ...(!isGoalType(row) ? row.contributors.map(({ldap}) => ldap) : [])]}
        onClick={onClick}
      />
    </Box>
  </Table.Cell>
);
