/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { Notifications } from '../models/Notifications';
import type { NotificationWithContent } from '../models/NotificationWithContent';
import type { ScheduledNotification } from '../models/ScheduledNotification';
import type { ScheduledNotificationCreate } from '../models/ScheduledNotificationCreate';
import type { ScheduledNotificationUpdate } from '../models/ScheduledNotificationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * Check
     * @returns Notifications Successful Response
     * @throws ApiError
     */
    public static checkApiV1NotificationsGet(): CancelablePromise<Notifications> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications',
        });
    }

    /**
     * Archived
     * @returns NotificationWithContent Successful Response
     * @throws ApiError
     */
    public static archivedApiV1NotificationsArchivedGet(): CancelablePromise<Array<NotificationWithContent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/archived',
        });
    }

    /**
     * Archive All
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static archiveAllApiV1NotificationsArchiveGet(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/archive',
        });
    }

    /**
     * Archive
     * @param id
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static archiveApiV1NotificationsArchiveIdGet(
        id: number,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/archive/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Seed
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static seedApiV1NotificationsSeedGet(): CancelablePromise<Array<Notification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/seed',
        });
    }

    /**
     * Upcoming
     * @param timezone
     * @returns NotificationWithContent Successful Response
     * @throws ApiError
     */
    public static upcomingApiV1NotificationsUpcomingGet(
        timezone: string,
    ): CancelablePromise<Array<NotificationWithContent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/upcoming',
            query: {
                'timezone': timezone,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Scheduled
     * @returns ScheduledNotification Successful Response
     * @throws ApiError
     */
    public static scheduledApiV1NotificationsScheduledGet(): CancelablePromise<Array<ScheduledNotification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/scheduled',
        });
    }

    /**
     * Create
     * Create new scheduled notification
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createApiV1NotificationsScheduledCreatePost(
        requestBody: ScheduledNotificationCreate,
    ): CancelablePromise<(ScheduledNotification | null)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notifications/scheduled/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * Create new update.
     * @param requestBody
     * @returns ScheduledNotification Successful Response
     * @throws ApiError
     */
    public static updateApiV1NotificationsScheduledUpdatePost(
        requestBody: ScheduledNotificationUpdate,
    ): CancelablePromise<ScheduledNotification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notifications/scheduled/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteApiV1NotificationsDeletePost(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notifications/delete',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Wipe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static wipeApiV1NotificationsWipeGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/wipe',
        });
    }

}
