import {useMutation, useQueries, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {strategiesAtom, strategiesByIdAtom, StrategyItem} from 'atoms/strategies';
import {
  Employee,
  EmployeeService,
  Goal,
  GoalService,
  NewsPost,
  NewsService,
  Project,
  ProjectBasic,
  Team,
  TeamService,
  TeamWithCounts,
  UpdateEmployeeTeam,
} from 'client';
import {Filters} from 'components/search/GlobalSearch';
import {DEFAULT_TIMEFRAME} from 'constant';
import {useAtomValue} from 'jotai';
import {useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {getEmployeeService, getFrontendUrl, getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

interface CustomItem {
  route: string;
}

export const useEmployeeSuspense = ({ldap}: {ldap: string}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['employee', ldap],
    queryFn: () => getService(EmployeeService).getEmployeeByLdapApiV1PeopleLdapLdapGet(ldap ?? ''),
  });

  return data;
};

export const useEmployee = ({
  ldap,
  throwOnError = true,
}: {
  ldap?: string;
  throwOnError?: boolean;
}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['employee', ldap],
    queryFn: () => getService(EmployeeService).getEmployeeByLdapApiV1PeopleLdapLdapGet(ldap ?? ''),
    throwOnError,
    enabled: !!ldap,
  });

  return {employee: data, isLoading};
};

export const useEmployees = ({ldaps}: {ldaps: string[]}) => {
  const dedupedLdaps = Array.from(new Set(ldaps));

  const employees = useQueries({
    queries: dedupedLdaps.map((ldap) => ({
      queryKey: ['employee', ldap],
      queryFn: () => getEmployeeService().getEmployeeByLdapApiV1PeopleLdapLdapGet(ldap),
      throwOnError: false,
      suspense: true,
    })),
  })
    .map(({data}) => {
      // Populate employee query keys to save an extra request
      if (data) {
        queryClient.setQueryData(['employee', data.ldap], data);
      }

      return data;
    })
    .filter(Boolean);

  return employees as Employee[];
};

export const useEmployeesBatch = ({ldaps, enabled}: {ldaps: string[]; enabled: boolean}) => {
  const {data: employees = [], isLoading} = useQuery({
    queryKey: ['employees', ldaps],
    queryFn: () => getEmployeeService().getEmployeesByLdapsApiV1PeopleLdapsPost(ldaps),
    enabled,
  });

  return {employees, isLoading};
};

export const useEmployeesBatchSuspense = ({
  ldaps,
  enabled,
}: {
  ldaps: string[];
  enabled: boolean;
}) => {
  const {data: employees = []} = useSuspenseQuery({
    queryKey: ['employees', ldaps],
    queryFn: enabled
      ? () => getEmployeeService().getEmployeesByLdapsApiV1PeopleLdapsPost(ldaps)
      : undefined,
  });

  return employees as Employee[];
};

export const useEmployeesNoSuspense = ({ldaps}: {ldaps: string[]}) => {
  const dedupedLdaps = Array.from(new Set(ldaps));

  const employees = useQueries({
    queries: dedupedLdaps.map((ldap) => ({
      queryKey: ['employee', ldap],
      queryFn: () => getEmployeeService().getEmployeeByLdapApiV1PeopleLdapLdapGet(ldap),
      throwOnError: false,
      // suspense: true,
    })),
  })
    .map(({data}) => {
      // Populate employee query keys to save an extra request
      if (data) {
        queryClient.setQueryData(['employee', data.ldap], data);
      }

      return data;
    })
    .filter(Boolean);

  return employees as Employee[];
};

export const useEditTeams = ({id, ldap}: {id: string; ldap: string}) => {
  const {mutateAsync: editTeams, isPending} = useMutation({
    mutationFn: ({teams}: {teams: (UpdateEmployeeTeam & {slug: string})[]}) =>
      getService(EmployeeService).editTeamsApiV1PeopleLdapTeamsPut(ldap, teams),
    onSettled: (_data, _error, {teams}) => {
      queryClient.invalidateQueries({queryKey: ['checkup', id]});
      queryClient.invalidateQueries({queryKey: ['profile', id]});
      teams.map((team) => queryClient.invalidateQueries({queryKey: ['checkup', team.slug]}));
    },
  });

  return {editTeams, isPending};
};

export const useSearches = ({ldaps}: {ldaps?: string[]}) => {
  const dedupedLdaps = Array.from(new Set(ldaps));

  const employees = useQueries({
    queries: dedupedLdaps.map((ldap) => ({
      queryKey: ['employee', 'all', ldap],
      queryFn: ldaps
        ? () => getEmployeeService().searchPeopleApiV1PeopleSearchGet(ldap ?? '')
        : undefined,
      throwOnError: false,
    })),
  });

  return employees.flatMap(({data}) => data).filter(Boolean) as Employee[];
};

// Search could populate `employee, ldap` query ^
export const useSearch = ({input}: {input: string | null}) => {
  const cached = useRef<Employee[]>([]);
  const {data, isLoading} = useQuery({
    queryKey: ['search', 'people', input],
    queryFn: () => getEmployeeService().searchPeopleApiV1PeopleSearchGet(input ?? ''),
    enabled: !!input,
  });

  if (!input?.length) {
    return [];
  }

  if (isLoading) {
    return cached.current;
  }

  cached.current = data ?? [];

  return cached.current;
};

export type GlobalSearchResults =
  | Employee
  | TeamWithCounts
  | ProjectBasic
  | Goal
  | StrategyItem
  | NewsPost
  | CustomItem
  | {idx: number}
  | undefined;

export const isEmployee = (selected: GlobalSearchResults): selected is Employee => {
  return (selected as Employee)?.user_id !== undefined;
};

export const isTeam = (selected: GlobalSearchResults): selected is Team => {
  return (selected as Team)?.team_id !== undefined;
};

export const isProject = (selected: GlobalSearchResults): selected is Project => {
  return (selected as Project)?.available !== undefined;
};

export const isGoal = (selected: GlobalSearchResults): selected is Goal => {
  return (selected as Goal)?.key_results !== undefined;
};

export const isStrategy = (selected: GlobalSearchResults): selected is StrategyItem => {
  return (selected as StrategyItem)?.owners !== undefined;
};

export const isNewsPost = (selected: GlobalSearchResults): selected is NewsPost => {
  return (selected as NewsPost)?.category !== undefined;
};

export const isCustom = (selected: GlobalSearchResults): selected is CustomItem => {
  return (selected as CustomItem)?.route !== undefined;
};

const useSearchFallbacks = () => {
  const {employee, teamMembers, reportingLine, directReports} = useAtomValue(loggedInEmployeeAtom);
  const strategies = useAtomValue(strategiesAtom);

  const [myProfile, manager, ...orgLine] = reportingLine;
  const members = directReports.length > 0 ? directReports : teamMembers;

  // const {data: projects} = useQuery({
  //   queryKey: ['projects'],
  //   queryFn: () => getService(ProjectService).getApiV1ProjectsGet(),
  // });

  const {data: goalsData} = useQuery({
    queryKey: ['goals', employee?.ldap],
    queryFn: () => getService(GoalService).readGoalsByLdapIdApiV1GoalsUsersLdapGet(employee?.ldap),
  });

  const {data: teams} = useQuery({
    queryKey: ['myTeamsPath'],
    queryFn: () => getService(TeamService).getHierarchyForTeamApiV1TeamsHierarchyGet(),
  });

  const {data: news} = useQuery({
    queryKey: ['news'],
    queryFn: () => getService(NewsService).getPostsApiV1NewsGet(),
  });

  return {
    all: [
      {...myProfile, name: 'Your profile', role: `${getFrontendUrl()}people/${myProfile?.ldap}`},
      manager,
      ...members.slice(0, 4),
      teams?.[0],
    ],
    people: [manager, ...members, ...orgLine, myProfile],
    teams: teams ?? [],
    goals: goalsData?.goals?.filter(({timeframe}) => timeframe === DEFAULT_TIMEFRAME) ?? [],
    projects: [],
    strategies: strategies[2025],
    news: news ?? [],
  };
};

// Search could populate `employee, ldap` query ^

export const INITIAL_RESULTS = 8;
export const MAX_RESULTS = 50; // Could be 50 after DSR-515

export const useGlobalSearch = ({
  input,
  filter,
  showMore,
  limit,
}: {
  input: string | null;
  filter: Filters;
  showMore: boolean;
  limit?: number;
}) => {
  const [searchParams] = useSearchParams();
  const strategies = useAtomValue(strategiesByIdAtom);
  const searchFallbacks = useSearchFallbacks();
  const cached = useRef<GlobalSearchResults[]>([]);
  const {data, isLoading} = useQuery({
    queryKey: ['search', input, filter, showMore, limit],
    queryFn: () =>
      getEmployeeService().searchPeopleByFilterApiV1SearchGet(
        input ?? '',
        filter,
        Boolean(searchParams.get('s')),
        limit
          ? limit
          : ['goals', 'all', 'people', 'news'].includes(filter)
            ? MAX_RESULTS
            : INITIAL_RESULTS
        // showMore ? MAX_RESULTS - INITIAL_RESULTS : INITIAL_RESULTS,
        // showMore ? INITIAL_RESULTS : 0
      ),
    enabled: !!input,
  });

  if (!input?.length) {
    return {data: searchFallbacks[filter] ?? [], isLoading};
  }

  if (isLoading) {
    return {data: cached.current, isLoading};
  }

  cached.current = data as GlobalSearchResults[];
  if (filter === 'strategies') {
    const lowercaseInput = input?.toLowerCase();
    cached.current =
      (data as GlobalSearchResults[] | undefined)?.concat(
        [...strategies.values()].filter((stragegy) =>
          stragegy.name.toLowerCase().includes(lowercaseInput)
        )
      ) ?? [];
  }

  return {data: cached.current, isLoading};
};
