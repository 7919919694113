import {pulseUserAtom} from 'atoms/auth';
import {AddBadgeModal} from 'components/badges/AddBadgeModal';
import {LoadedManageBadgesList, ManageBadgesList} from 'components/badges/ManageBadgeList';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/shared/ManagePage/Title';
import {ROUTE_PATHS} from 'constant';
import {isContentAdmin} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Suspense, useState} from 'react';
import {queryClient} from 'views/QueryClientWrapper';

export const ManageBadgesView = () => {
  const [addingBadge, setAddingBadge] = useState(false);
  const pulseUser = useAtomValue(pulseUserAtom);

  return (
    <>
      <Layout.Container
        breadcrumb={[
          {children: 'Admin', to: ROUTE_PATHS.ADMIN},
          {children: 'Badges', to: ROUTE_PATHS.ADMIN_MANAGE_BADGES},
        ]}
      >
        <Title
          text={t('manage_badges')}
          addButtonOnClick={
            pulseUser && isContentAdmin(pulseUser) ? () => setAddingBadge(true) : undefined
          }
        />

        <Suspense fallback={<ManageBadgesList />}>
          <LoadedManageBadgesList />
        </Suspense>
      </Layout.Container>
      <AddBadgeModal
        open={addingBadge}
        onClose={() => {
          queryClient.invalidateQueries({queryKey: ['ownedBadges']});
          setAddingBadge(false);
        }}
      />
    </>
  );
};
