import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Tooltip, TooltipRefObject} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronRightLine, CompassLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {StrategyItem} from 'atoms/strategies';
import {Link} from 'components/DSYS/Link';
import {t} from 'i18next';
import {useRef, useState} from 'react';
import {DrawerGoalData} from 'views/goals_v2/types';

import {StatusScores} from './StatusScores';

export const StrategyBox = ({
  timeframe,
  strategy,
  isSubStrategry,
  setSelectedGoal,
  hideArrow,
}: {
  timeframe: string;
  strategy: StrategyItem;
  isSubStrategry?: boolean;
  setSelectedGoal?: (data: DrawerGoalData) => void;
  hideArrow?: boolean;
}) => {
  const [showSubStrategies, setShowSubStrategies] = useState(false);
  const ref = useRef<TooltipRefObject>(null);

  const toggleTooltip = () => {
    if (ref && ref.current) {
      ref.current.update();
    }
  };

  return (
    <Stack
      gap="8"
      className={
        !showSubStrategies
          ? atoms({backgroundColor: 'Background Base', borderRadius: 'Medium'})
          : atoms({
              paddingLeft: '12',
              paddingRight: '20',
              paddingY: '16',
              borderRadius: 'Medium',
              borderStyle: 'Solid',
              borderWidth: '1',
              borderColor: 'Border Subtle',
              backgroundColor: 'Background Subtle',
            })
      }
    >
      <Box
        {...(showSubStrategies
          ? {}
          : withShade({
              className: atoms({
                display: 'block',
                paddingLeft: '12',
                paddingRight: '20',
                paddingY: '16',
                borderStyle: 'Solid',
                borderWidth: '1',
                borderRadius: 'Medium',
                borderColor: 'Border Subtle',
              }),
            }))}
      >
        <Split alignY="center" gap="16">
          <Split.Item width="fill">
            <Link to={`/strategies/${strategy.id}`} hasNoUnderline>
              <Box
                display="flex"
                alignItems="center"
                style={{gap: 4}}
                justifyContent="space-between"
              >
                <LabelGroup
                  align="top"
                  withLeftAccessory={
                    <UIIcon src={CompassLine} className={atoms({color: 'Text Subtle'})} />
                  }
                  withText={
                    <Text size="large" isBold>
                      {strategy.name}
                    </Text>
                  }
                  withSubtext={
                    <Text size="small" color="faint">
                      {strategy.owners.map((owner, index) => (
                        <Link
                          monochromatic
                          key={`${strategy.id}—${owner.ldap}`}
                          to={`/people/${owner.ldap}`}
                          hasNoUnderline
                          showUnderlineOnHover
                        >
                          {`${owner.name}${strategy.owners.length - 1 === index ? ' ' : ', '}`}
                        </Link>
                      ))}
                      {Boolean(strategy.link.length) && (
                        <>
                          •{' '}
                          <Link
                            monochromatic
                            to={strategy.link}
                            onClick={(e) => e.stopPropagation()}
                          >
                            View doc
                          </Link>
                        </>
                      )}
                    </Text>
                  }
                />
                <StatusScores strategy={strategy} timeframe={timeframe} />
              </Box>
            </Link>
          </Split.Item>
          {!isSubStrategry && !hideArrow && (
            <Split.Item width="hug">
              <Tooltip title={t('Pillar+1 scorecards')} ref={ref}>
                <IconButton
                  variant="outline"
                  size="small"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    toggleTooltip();
                    setShowSubStrategies(!showSubStrategies);
                  }}
                  className={atoms({visibility: !strategy.subStrategies ? 'hidden' : 'visible'})}
                >
                  <UIIcon src={showSubStrategies ? ChevronDownLine : ChevronRightLine} />
                </IconButton>
              </Tooltip>
            </Split.Item>
          )}
        </Split>
      </Box>
      {showSubStrategies && strategy.subStrategies?.length && (
        <Stack gap="8">
          {strategy.subStrategies.map((subStrategy) => (
            <StrategyBox
              key={subStrategy.id}
              timeframe={timeframe}
              strategy={subStrategy}
              setSelectedGoal={setSelectedGoal}
              isSubStrategry
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
