import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, ChevronDownLine, MoreHorizontalLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {TeamWithHierarchy} from 'client';
import {Link} from 'components/DSYS/Link';
import {isSuperUser} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const orgs = [
  {label: 'Core', slug: 'core'},
  {label: 'Infrastructure', slug: 'infrastructure'},
  {label: 'Business Platform', slug: 'business-platform'},
  {label: 'Office of the CEO (OCEO)', slug: 'office-of-the-ceo-oceo'},
  // {label: 'Organize the Cloud', slug: 'organize-the-cloud'},
];

type CrumbData = {name: string | null; slug: string | null};

const Crumb = ({
  data,
  disabled,
  active,
  showOrgs,
  activeOrgSlug,
  setActiveOrgSlug,
}: {
  data?: CrumbData;
  disabled?: boolean;
  active?: boolean;
  showOrgs?: boolean;
  activeOrgSlug?: string;
  setActiveOrgSlug?: (slug: string) => void;
}) => {
  const navigate = useNavigate();
  const pulseUser = useAtomValue(pulseUserAtom);
  const showCompanyOS = isSuperUser(pulseUser?.email);

  const handleSelection = (value: string) => {
    navigate(`/projects/${value}`);
    setActiveOrgSlug?.(value);
  };

  return !data ? null : (
    <Box
      as={Split.Item}
      flexShrink={0}
      overflow="hidden"
      whiteSpace="nowrap"
      display="flex"
      alignItems="center"
    >
      <Box as={Text} size="large" color="Border Subtle" marginRight="8">
        /
      </Box>
      <Box
        as={Text}
        color={active ? 'Text Base' : 'Border Bold'}
        isBold={active}
        display="flex"
        alignItems="center"
      >
        <Link
          to={disabled || data.slug === 'dropbox-teams' ? `/projects` : `/projects/${data.slug}`}
          monochromatic
          isClickable={!active}
          hasNoUnderline
          showUnderlineOnHover
          state={{source: 'breadcrumb'}}
        >
          {(data.name?.length ?? 0) > 30 ? data.name!.slice(0, 30) + '...' : data.name}
        </Link>
        {showOrgs && (
          <Menu.Wrapper onSelection={handleSelection} shouldPropagateClickOutsideMouseEvents>
            {({getContentProps, getTriggerProps}) => (
              <>
                <Box
                  as={IconButton}
                  {...getTriggerProps()}
                  variant="borderless"
                  shape="circular"
                  size="small"
                  color="Text Subtle"
                  marginLeft="4"
                >
                  <UIIcon src={ChevronDownLine} className={atoms({color: 'Text Subtle'})} />
                </Box>
                <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom">
                  <Menu.Segment>
                    {orgs
                      .filter(({slug}) => showCompanyOS || slug !== 'office-of-the-ceo-oceo')
                      .map((option) => (
                        <Menu.ActionItem
                          key={option.slug}
                          value={option.slug}
                          withRightAccessory={
                            option.slug === activeOrgSlug && <UIIcon src={CheckmarkLine} />
                          }
                        >
                          {option.label}
                        </Menu.ActionItem>
                      ))}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        )}
      </Box>
    </Box>
  );
};

export const ProjectBreadcrumbs = ({area, title}: {area: TeamWithHierarchy; title?: CrumbData}) => {
  const navigate = useNavigate();
  const [activeOrgSlug, setActiveOrgSlug] = useState(area.slug);
  const {t} = useTranslation();

  const org = area.hierarchy?.[0];
  const hierarchy = area.hierarchy?.slice(1, -1);
  const team =
    area.hierarchy && area.hierarchy.length > 1
      ? area.hierarchy?.[area.hierarchy.length - 1]
      : undefined;

  const handleSelection = (value: string) => {
    navigate(`/projects/${value}`);
  };

  return (
    <Box as={Split} paddingBottom="32" alignY="center" gap="8" wrap="wrap" overflow="hidden">
      <Split.Item>
        <Box as={Text} color="Border Bold">
          <Link
            to="/projects"
            state={{source: 'breadcrumb'}}
            monochromatic
            hasNoUnderline
            showUnderlineOnHover
          >
            {t('projects')}
          </Link>
        </Box>
      </Split.Item>
      {org && (
        <Crumb
          activeOrgSlug={activeOrgSlug ?? undefined}
          setActiveOrgSlug={setActiveOrgSlug}
          key={org.team_id}
          data={org}
          active={area.hierarchy!.length - 1 === 0 && !title}
          showOrgs
        />
      )}
      {
        Boolean(hierarchy?.length) && (
          <Box as={Split.Item} flexShrink={0} overflow="hidden" whiteSpace="nowrap" display="flex">
            <Box as={Text} size="large" color="Border Subtle" marginRight="8">
              /
            </Box>
            <Menu.Wrapper onSelection={handleSelection} shouldPropagateClickOutsideMouseEvents>
              {({getContentProps, getTriggerProps}) => (
                <>
                  <Box
                    as={IconButton}
                    {...getTriggerProps()}
                    variant="borderless"
                    shape="circular"
                    size="small"
                    color="Text Subtle"
                    marginLeft="4"
                  >
                    <UIIcon src={MoreHorizontalLine} />
                  </Box>
                  <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom">
                    <Menu.Segment>
                      {hierarchy?.map((option) => (
                        <Menu.ActionItem key={option.slug} value={option.slug}>
                          {option.name}
                        </Menu.ActionItem>
                      ))}
                    </Menu.Segment>
                  </Menu.Content>
                </>
              )}
            </Menu.Wrapper>
          </Box>
        )
        // {hierarchy?.map((parent, index) => {
        //   return (
        //     <Crumb
        //       key={parent.team_id}
        //       data={parent}
        //       active={area.hierarchy!.length - 1 === index && !title}
        //     />
        //   );
        // })}
      }

      {team && (
        <Crumb
          key={team.team_id}
          data={team}
          active={area.hierarchy!.length - 1 === area.hierarchy?.length && !title}
        />
      )}
      <Crumb data={title} active={Boolean(title)} />
    </Box>
  );
};
