import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ActivityLine, CommentLine, MoreHorizontalLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {MegaphoneMini} from '@dropbox/dig-illustrations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {growthbookCacheAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import {Thread, TrackerFull, Workstream} from 'client';
import {
  useCommentService,
  useThreadService,
  useTrackerThreads,
  useWorkstreamThreads,
} from 'components/comments/hooks';
import {Avatar} from 'components/DSYS/Avatar';
import {CommentThread} from 'components/DSYS/editor/plugins/comments/CommentThread';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {Fragment, Suspense, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {useTracker, useTrackerDelete, useWorkstream} from './hooks';
import {TrackerAddModal} from './TrackerAddModal';
import {focusedThreadAtom, TrackerTable} from './TrackerTable';
import {WorkstreamAuditLog} from './WorkstreamAuditLog';
import {WorkstreamUpdates} from './WorkstreamUpdateTabs';

export const TrackerWorkstream = ({reports}: {reports?: boolean}) => {
  const params = useParams() as {id: number | string};

  const isThreadFocused = useAtomValue(focusedThreadAtom);

  const location = useLocation();
  const navigate = useNavigate();

  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [showDrawer, setShowDrawer] = useState<undefined | 'comments' | 'logs' | 'updates'>();
  const [auditLogWorkstream, setAuditLogWorkstream] = useState<Workstream | undefined>();
  const [workstreamDrawer, setWorkstreamDrawer] = useState<Workstream | undefined>();

  const isUserTracker = isNaN(Number(params.id));

  const {employee} = useEmployee({ldap: isUserTracker ? (params.id as string) : undefined});

  const {data: tracker, isLoading} = useTracker(params.id, reports);

  const [workstreamId, setAddModalOpen] = useState<number>();

  const {threads, isFetched} = useTrackerThreads({tracker, disabled: isLoading});
  useDocumentTitle(
    (isUserTracker ? employee?.name + (reports ? ` (${t('reports')})` : '') : tracker?.name) ??
      t('workstreams')
  );

  useEffect(() => {
    if (location.state?.source === 'create' && location.state?.workstreamId) {
      setAddModalOpen(location.state?.workstreamId);
    }
  }, [location.state?.source, location.state?.workstreamId]);

  const {isWorkstreamsEnabled} = useAtomValue(growthbookCacheAtom);
  if (!isWorkstreamsEnabled) {
    return null;
  }

  if (reports && employee && !employee.total_report_count) {
    navigate(ROUTE_PATHS.WORKSTREAM_TRACKERS);
    return null;
  }

  if (!tracker && !isLoading) {
    return <NotFound />;
  }

  const commentCount = threads
    ?.filter((thread) => thread && !thread.is_resolved)
    .flatMap((thread) => thread.comments).length;

  return (
    <>
      <Layout.InlineDrawerContainer
        width="100%"
        preventEscapeClose={Boolean(isThreadFocused)}
        breadcrumb={[
          {children: t('workstreams'), to: '/workstreams'},
          {
            children: tracker?.name ?? (
              <Box style={{width: 105}}>
                <Skeleton.Box height={17} width={105} />
              </Box>
            ),
            to: `/workstreams/${params.id}`,
          },

          ...(reports ? [{children: t('reports'), to: `/workstreams/${params.id}/reports`}] : []),
        ]}
        open={Boolean(showDrawer)}
        drawerHeader={
          showDrawer && (
            <Title size={18}>
              {t(`workstream_${showDrawer}_count`, {
                count:
                  showDrawer === 'comments' ? threads.length : workstreamDrawer?.updates?.length,
              })}
            </Title>
          )
        }
        drawerIcon={
          showDrawer === 'comments'
            ? CommentLine
            : showDrawer === 'updates'
              ? undefined
              : ActivityLine
        }
        drawerBody={
          showDrawer === 'comments' ? (
            tracker && (
              <CommentsDrawer tracker={tracker} onHandleClose={() => setShowDrawer(undefined)} />
            )
          ) : showDrawer === 'updates' && workstreamDrawer ? (
            <>
              {/* <GitUpdateGraph {...workstreamDrawer}  /> */}
              <WorkstreamUpdates workstream={workstreamDrawer} />
            </>
          ) : (
            auditLogWorkstream && <WorkstreamAuditLog workstream={auditLogWorkstream} />
          )
        }
        onClose={() => {
          setAuditLogWorkstream(undefined);
          setShowDrawer(undefined);
        }}
      >
        <Stack gap="32">
          <Split gap="8">
            <Split.Item width="fill">
              <LabelGroup
                align="top"
                withLeftAccessory={isUserTracker && employee && <Avatar user={employee} />}
                withText={
                  <Title size={24}>
                    {isLoading ? (
                      <Box style={{width: 288}}>
                        <Skeleton.Box height={28} width={288} />
                      </Box>
                    ) : isUserTracker ? (
                      !employee?.name ? (
                        <Box style={{width: 288}}>
                          <Skeleton.Box height={28} width={288} />
                        </Box>
                      ) : (
                        employee?.name + (reports ? ` (${t('reports')})` : '')
                      )
                    ) : (
                      (tracker?.name ?? (
                        <Box style={{width: 288}}>
                          <Skeleton.Box width={288} height={28} />
                        </Box>
                      ))
                    )}
                  </Title>
                }
                withSubtext={
                  isLoading ? (
                    <Box style={{width: 200}}>
                      <Skeleton.Box height={15} width={200} />
                    </Box>
                  ) : isUserTracker ? (
                    employee?.role ? (
                      employee.role
                    ) : (
                      <Box style={{width: 200}}>
                        <Skeleton.Box height={15} width={200} />
                      </Box>
                    )
                  ) : (
                    t('workstreams_count', {count: tracker?.workstreams?.length ?? 0})
                  )
                }
              />
            </Split.Item>
            {loggedInEmployee.ldap === params.id && !reports && (
              <Split.Item>
                <ButtonLink
                  variant="primary"
                  to={ROUTE_PATHS.WORKSTREAM_STATUS_UPDATE}
                  disabled={!tracker?.workstreams.length}
                  isClickable={Boolean(tracker?.workstreams.length)}
                >
                  {t('update_all')}
                </ButtonLink>
              </Split.Item>
            )}
            <Split.Item>
              <Button
                variant="outline"
                onClick={() => setShowDrawer('comments')}
                withIconStart={<UIIcon size="small" src={CommentLine} />}
                isLoading={!isFetched}
              >
                {t('workstream_comments_count', {count: commentCount})}
              </Button>
            </Split.Item>
            {typeof tracker?.id === 'number' && (
              <Split.Item>
                <TrackerOverflowButton trackerId={tracker.id} />
              </Split.Item>
            )}
          </Split>

          <TrackerTable
            tracker={tracker}
            setAuditLogDrawer={(workstream) => {
              if (showDrawer === 'logs' && workstream.id === auditLogWorkstream?.id) {
                setShowDrawer(undefined);
                setAuditLogWorkstream(undefined);
              } else {
                setShowDrawer('logs');
                setAuditLogWorkstream(workstream);
              }
            }}
            setAddModalOpen={(workstream) => {
              setAddModalOpen(workstream.id);
            }}
            setWorkstreamDrawer={(workstream) => {
              if (showDrawer === 'updates' && workstream.id === workstreamDrawer?.id) {
                setShowDrawer(undefined);
                setWorkstreamDrawer(undefined);
              } else {
                setWorkstreamDrawer(workstream);
                setShowDrawer('updates');
              }
            }}
          />
        </Stack>
      </Layout.InlineDrawerContainer>

      <TrackerAddModal
        workstreamId={location.state?.workstreamId ?? workstreamId}
        open={Boolean(workstreamId)}
        onClose={() => setAddModalOpen(undefined)}
      />
    </>
  );
};

const DeleteTrackerModal = ({
  trackerId,
  open,
  onClose,
}: {
  trackerId: number;
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {deleteTracker, isPending} = useTrackerDelete();

  const handleDelete = async () => {
    await deleteTracker(trackerId);
    setSnackbarMessage({text: t('deleted')});
    onClose();
    navigate(ROUTE_PATHS.WORKSTREAM_TRACKERS);
  };

  return (
    <Modal
      open={open}
      withCloseButton="close"
      width="small"
      aria-label="Are you sure you want to delete?"
      onRequestClose={onClose}
    >
      <Modal.Header>
        <Title>{t('delete_tracker_confirm')}</Title>
      </Modal.Header>
      <Modal.Body>{t('delete_tracker_confirm_subtitle')}</Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose} disabled={isPending}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleDelete} isLoading={isPending}>
          {t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TrackerOverflowButton = ({trackerId}: {trackerId: number}) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <DeleteTrackerModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        trackerId={trackerId}
      />
      <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
        {({getContentProps, getTriggerProps}) => (
          <>
            <IconButton {...getTriggerProps()} variant="outline">
              <UIIcon size="small" src={MoreHorizontalLine} />
            </IconButton>
            <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-end">
              <Menu.Segment>
                <Menu.LinkItem
                  href={ROUTE_PATHS.WORKSTREAM_TRACKER_EDIT.replace(':id', trackerId.toString())}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      ROUTE_PATHS.WORKSTREAM_TRACKER_EDIT.replace(':id', trackerId.toString())
                    );
                  }}
                >
                  {t('edit_tracker')}
                </Menu.LinkItem>
                <Menu.LinkItem
                  href={ROUTE_PATHS.WORKSTREAM_NEW}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(ROUTE_PATHS.WORKSTREAM_NEW);
                  }}
                >
                  {t('add_workstream')}
                </Menu.LinkItem>
              </Menu.Segment>
              <Menu.Segment>
                <Menu.ActionItem onClick={() => setShowDeleteModal(true)}>
                  {t('delete_tracker')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </>
  );
};

const WorkstreamCommentWithInput = ({
  workstreamId,
  thread,
  handleClickThread,
}: {
  workstreamId: number;
  thread: Thread;
  handleClickThread: () => void;
}) => {
  const {workstream, isLoading} = useWorkstream(workstreamId);
  const {updateThread} = useThreadService();
  const {createComment} = useCommentService({
    id: workstreamId,
    type: 'workstream',
  });

  const handleResolveThread = () => {
    updateThread({
      threadId: thread.id,
      data: {...thread, is_resolved: true, is_deleted: false},
    });
  };

  const handleAddComment = (comment: string) =>
    createComment({
      data: {
        content: comment,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        reply_to: null,
        thread_id: thread.id,
      },
    });

  return (
    <Box
      borderRadius="Medium"
      cursor="pointer"
      onClick={handleClickThread}
      paddingX="16"
      paddingY="12"
      {...withShade({})}
    >
      <CommentThread
        expanded={false}
        thread={thread}
        beforeComment={
          isLoading ? (
            <Skeleton.Box width={240} height={20} />
          ) : (
            <Text isBold>{workstream?.name}</Text>
          )
        }
        onAddComment={handleAddComment}
        onResolveClick={handleResolveThread}
      />
    </Box>
  );
};

export const TrackerCommentThreads = ({
  tracker,
  closeDrawer,
}: {
  tracker: TrackerFull;
  closeDrawer: () => void;
}) => {
  const setFocusedThread = useSetAtom(focusedThreadAtom);
  const {threads, isFetched} = useTrackerThreads({tracker});

  const handleClickThread = (thread: Thread) => {
    closeDrawer();
    setFocusedThread(thread.id);

    // if (thread.id) {
    //   setTimeout(() => {
    //     const comment = document.querySelector(`[data-highlight-id="${thread.id}"]`);
    //     if (comment) {
    //       const commentRect = comment.getBoundingClientRect();
    //       if (commentRect.top < 0 || commentRect.bottom > window.innerHeight) {
    //         // window.scrollTo({
    //         //   top: commentRect.top + window.scrollY - 186,
    //         //   behavior: 'smooth',
    //         // });
    //       }
    //     }
    //   }, 400);
    // }
  };

  if (!threads.length || !isFetched) {
    return null;
  }

  const activeThreads = threads.filter((thread) => !thread.is_resolved && thread.comments?.length);
  const archivedThreads = threads.filter((thread) => thread.is_resolved && thread.comments?.length);

  return (
    <Stack gap="16">
      {activeThreads.map((thread) => {
        if (!thread.workstream_id) {
          return null;
        }

        return (
          <WorkstreamCommentWithInput
            key={thread.id}
            workstreamId={thread.workstream_id}
            thread={thread}
            handleClickThread={() => handleClickThread(thread)}
          />
        );
      })}

      {archivedThreads.map((thread) => {
        if (!thread.workstream_id) {
          return null;
        }

        return (
          <WorkstreamCommentWithInput
            key={thread.id}
            workstreamId={thread.workstream_id}
            thread={thread}
            handleClickThread={() => handleClickThread(thread)}
          />
        );
      })}
    </Stack>
  );
};

export const WorkstreamCommentThreads = ({
  workstream,
  closeDrawer,
}: {
  workstream: Workstream;
  closeDrawer: () => void;
}) => {
  const setFocusedThread = useSetAtom(focusedThreadAtom);
  const threads = useWorkstreamThreads({workstreamId: workstream.id});

  const activeThreads = threads.filter((thread) => !thread.is_resolved && thread.comments?.length);
  const archivedThreads = threads.filter((thread) => thread.is_resolved && thread.comments?.length);

  const handleClickThread = (thread: Thread) => {
    closeDrawer();
    setFocusedThread(thread.id);
  };

  if (!threads.length) {
    return null;
  }

  return (
    <Stack gap="16">
      {activeThreads.map((thread) => (
        <Box key={thread.id}>
          <WorkstreamCommentWithInput
            workstreamId={workstream.id}
            thread={thread}
            handleClickThread={() => handleClickThread(thread)}
          />
        </Box>
      ))}

      {archivedThreads.map((thread) => (
        <Fragment key={thread.id}>
          <WorkstreamCommentWithInput
            workstreamId={workstream.id}
            thread={thread}
            handleClickThread={() => handleClickThread(thread)}
          />
        </Fragment>
      ))}
    </Stack>
  );
};

const CommentsDrawer = ({
  tracker,
  onHandleClose,
}: {
  tracker: TrackerFull;
  onHandleClose: () => void;
}) => {
  const {threads, isFetched} = useTrackerThreads({tracker});

  if (!isFetched) {
    return <Spinner />;
  }

  if (!threads.length) {
    return (
      <EmptyState
        hideBorder
        title={t('workstream_comments_empty_title')}
        body={t('workstream_comments_empty_body')}
        image={<MegaphoneMini width={64} altText={''} />}
      />
    );
  }

  return (
    <Stack gap="16">
      <Suspense>
        <TrackerCommentThreads tracker={tracker} closeDrawer={onHandleClose} />
      </Suspense>
    </Stack>
  );
};
