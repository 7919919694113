import {Button, IconButton, IconButtonProps} from '@dropbox/dig-components/dist/buttons';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine, CloseLine} from '@dropbox/dig-icons/assets';
import {t} from 'i18next';

export const DrawerBackButton = ({onClick}: {onClick: () => void}) => {
  return (
    <Button
      variant="borderless"
      onClick={onClick}
      size="small"
      withIconStart={<UIIcon src={ArrowLeftLine} />}
      style={{marginLeft: -8}}
    >
      {t('back')}
    </Button>
  );
};

export const DrawerCloseButton = ({
  onClose,
  iconButtonProps,
}: {
  onClose: () => void;
  iconButtonProps?: Partial<IconButtonProps>;
}) => {
  return (
    <Box style={{marginTop: '-5px', marginRight: '-5px'}}>
      <IconButton {...iconButtonProps} variant="borderless" onClick={onClose}>
        <UIIcon src={CloseLine} />
      </IconButton>
    </Box>
  );
};
