import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';

const optionToTimeframe = (option: string) => {
  if (option === '2024') {
    return '2024';
  }
  return `${option.split(' ')[0]} ${option.split(' ')[1].replace('FY', '20')}`;
};

export const TimeframeSelector = ({
  options,
  timeframe,
  setTimeframe,
}: {
  options: {[year: number]: string[]}[];
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}) => (
  <Menu.Wrapper onSelection={setTimeframe} style={{marginRight: -8, marginLeft: 'auto'}}>
    {({getContentProps, getTriggerProps}) => (
      <>
        <Button variant="borderless" withDropdownIcon {...getTriggerProps()}>
          {optionToTimeframe(timeframe)}
        </Button>
        <Menu.Content {...getContentProps()} placement="bottom-end" style={{minWidth: 200}}>
          {options.map((option) => {
            const [year, quarters] = Object.entries(option)[0];
            return (
              <Menu.Segment key={year}>
                {quarters.map((quarter: string) => (
                  <Menu.ActionItem
                    key={`${year}-${quarter}`}
                    value={quarter}
                    withTitle={quarter.split(' ')[0]}
                    withRightAccessory={
                      quarter.includes('FY') && (
                        <Text size="small" color="faint">
                          {quarter.split(' ')[1].replace('FY', '20')}
                        </Text>
                      )
                    }
                  />
                ))}
              </Menu.Segment>
            );
          })}
        </Menu.Content>
      </>
    )}
  </Menu.Wrapper>
);
