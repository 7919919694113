import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Checkbox, Toggle} from '@dropbox/dig-components/dist/controls';
import {List} from '@dropbox/dig-components/dist/list';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CelebrateLine,
  GratitudeLine,
  LocationLine,
  MentionLine,
  OpenLine,
  PersonCircleLine,
  PhoneSupportLine,
  StarLine,
  ThemeLine,
} from '@dropbox/dig-icons/assets';
import {FingerprintMini} from '@dropbox/dig-illustrations';
import {useMutation, useSuspenseQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {delegatesAtom, loggedInEmployeeAtom} from 'atoms/employee';
import {themePreferenceAtom} from 'atoms/settings';
import {snackbarAtom} from 'atoms/snackbar';
import cx from 'classnames';
import {
  Body_put_image_api_v1_images__ldap__jpg_put,
  Employee,
  EmployeeFull,
  EmployeeService,
  Setting,
  SettingService,
} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {DraggableZoomableImage} from 'components/DSYS/DraggableZoomableImage';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {reportAndLogError} from 'helpers/logging';
import {useDebouncedValue} from 'hooks/useDebounce';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {ReactNode, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {getBackendUrl, getProfileImageUrl, getService} from 'utilities';
import {VacationLine} from 'views/home/VacationLine';
import {queryClient} from 'views/QueryClientWrapper';

import styles from './Settings.module.css';

type EditableSetting = Omit<Setting, 'id' | 'ldap' | 'created_at' | 'updated_at' | 'user_id'>;

export const Settings = () => {
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get('t') ?? 'general');
  useDocumentTitle(t('settings'));

  return (
    <Layout.Container>
      <Title size={24}>{t('settings')}</Title>
      <Suspense fallback={null}>
        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab} style={{minHeight: 386}}>
          <Tabs.Group hasHorizontalPadding={false}>
            <Tabs.Tab id="general">{t('settings_profile')}</Tabs.Tab>
            <Tabs.Tab id="notifications">{t('settings_notifications')}</Tabs.Tab>
          </Tabs.Group>
          <Tabs.Panel tabId="general">
            <SettingsControls />
          </Tabs.Panel>
          <Tabs.Panel tabId="notifications">
            <NotificationControls />
          </Tabs.Panel>
        </Tabs>
      </Suspense>
    </Layout.Container>
  );
};
const NotificationControls = () => {
  const {settings, setSettings} = useSettingsQuery();
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const handleToggle = async (property: keyof EditableSetting) => {
    try {
      await setSettings({
        ...settings,
        [property]: !settings[property],
      });
      setSnackbarMessage({text: t('saved')});
    } catch (error) {
      reportAndLogError(error, `Could not save ${property} settings`);
      setSnackbarMessage({text: t('couldnt_save')});
    }
  };

  return (
    <>
      <Box marginTop="24">
        <Text isBold>{t('settings_notification_activity')}</Text>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          text={<Text>{t('settings_badge_awarded_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.badge_awarded_email}
              onClick={() => handleToggle('badge_awarded_email')}
            />
          }
        />
      </List>

      <Box paddingTop="24" borderTop="Solid" borderColor="Border Subtle">
        <Text isBold>{t('settings_notification_birthday')}</Text>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          text={<Text>{t('settings_upcoming_birthday_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.upcoming_birthday_email}
              onClick={() => handleToggle('upcoming_birthday_email')}
            />
          }
        />

        <SettingComponent
          text={<Text>{t('settings_upcoming_birthday_peer_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.upcoming_birthday_peer_email}
              onClick={() => handleToggle('upcoming_birthday_peer_email')}
            />
          }
        />
      </List>
    </>
  );
};

const SettingsControls = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [themePreference, setThemePreference] = useAtom(themePreferenceAtom);
  const {settings, setSettings} = useSettingsQuery();
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const [isOpen, setIsOpen] = useState(urlParams.get('view') === 'photo');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('view')) {
      if (params.get('view') === 'photo') {
        setIsOpen(true);
      }
      params.delete('view');
      navigate({search: params.toString()}, {replace: true});
    }
  }, [location?.search, navigate]);

  const handleToggle = async (property: keyof EditableSetting) => {
    try {
      await setSettings({
        ...settings,
        [property]: !settings[property],
      });
      setSnackbarMessage({text: t('saved')});
    } catch (error) {
      reportAndLogError(error, `Could not save ${property} settings`);
      setSnackbarMessage({text: t('couldnt_save')});
    }
  };

  return (
    <>
      {employee?.ldap && (
        <ProfileImageModal isOpen={isOpen} setIsOpen={setIsOpen} employee={employee} />
      )}
      <Box borderRadius="Medium" backgroundColor="Background Subtle" padding="16" marginTop="16">
        <Split alignY="top">
          <Split.Item width="fill">
            <LabelGroup
              align="top"
              withLeftAccessory={
                <Box paddingRight="8" className={styles.fingerprint}>
                  <FingerprintMini width={36} altText={'workday'} />
                </Box>
              }
              withText={<Text isBold>{t('settings_edit_profile_workday')}</Text>}
              withSubtext={<Text color="subtle">{t('settings_visit_profile')}</Text>}
            />
          </Split.Item>
          <Split.Item>
            <ButtonLink
              size="small"
              to={
                employee?.workday_profile
                  ? `${employee.workday_profile}#TABINDEX=7&SUBTABINDEX=0`
                  : 'https://wd5.myworkday.com/dropbox/d/pex/home.htmld'
              }
              variant="opacity"
              withIconEnd={<Box as={UIIcon} color="Text Subtle" src={OpenLine} />}
            >
              {t('settings_edit_profile')}
            </ButtonLink>
          </Split.Item>
        </Split>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          icon={PersonCircleLine}
          text={t('settings_profile_image')}
          subtext={t('settings_profile_image_description')}
          accessory={
            <Button variant="opacity" onClick={() => setIsOpen(true)} size="small">
              {t('edit')}
            </Button>
          }
        />
        <SettingComponent
          icon={LocationLine}
          text={t('settings_share_my_location')}
          subtext={t('settings_display_metro')}
          accessory={
            <Toggle
              isToggled={settings.show_location}
              onClick={() => handleToggle('show_location')}
            />
          }
        />
        <SettingComponent
          icon={VacationLine}
          text={t('settings_share_timeoff')}
          subtext={t('settings_display_upcoming')}
          accessory={
            <Toggle isToggled={settings.show_pto} onClick={() => handleToggle('show_pto')} />
          }
        />

        <SettingComponent
          icon={MentionLine}
          text={t('settings_pronouns')}
          subtext={
            <Trans
              i18nKey="settings_pronouns_workday"
              t={t}
              components={{
                Link: (
                  <Link
                    monochromatic
                    to={
                      employee?.workday_profile
                        ? `${employee.workday_profile}#TABINDEX=7&SUBTABINDEX=2`
                        : 'https://wd5.myworkday.com/dropbox/d/pex/home.htmld'
                    }
                  />
                ),
              }}
            />
          }
          accessory={
            <Toggle
              isToggled={settings.show_pronouns}
              onClick={() => handleToggle('show_pronouns')}
            />
          }
        />

        <SettingComponent
          icon={CelebrateLine}
          text={t('settings_birthday')}
          subtext={t('settings_birthday_workday')}
          accessory={
            <Toggle
              isToggled={settings.show_birthday}
              onClick={() => handleToggle('show_birthday')}
            />
          }
        />

        <SettingComponent
          icon={StarLine}
          text={t('settings_working_with_me')}
          subtext={t('settings_working_with_me_subtext')}
          extra={
            <Suspense fallback={null}>
              <WorkingWithMe initialValue={settings.working_with_me ?? undefined} />
            </Suspense>
          }
          expanded={settings.enable_working_with_me}
          accessory={
            <Toggle
              isToggled={settings.enable_working_with_me}
              onClick={() => handleToggle('enable_working_with_me')}
            />
          }
        />

        <SettingComponent
          icon={PhoneSupportLine}
          text={t('settings_delegation')}
          subtext={t('settings_delegation_subtext')}
          extra={<Suspense fallback={null}>{<Delegates />}</Suspense>}
          expanded={settings.enable_delegation}
          accessory={
            <Toggle
              isToggled={settings.enable_delegation}
              onClick={() => handleToggle('enable_delegation')}
            />
          }
        />

        <SettingComponent
          icon={GratitudeLine}
          text={t('settings_gratitude_export')}
          subtext={t('settings_gratitude_export_subtext')}
          accessory={
            <Button
              variant="opacity"
              onClick={async () => {
                analyticsLogger().logEvent('SETTINGS_CLICKED', {type: 'gratitude_export'});
                // getService(GratitudeService).exportCsvApiV1GratitudesExportGet();
                window.location.href = getBackendUrl() + '/api/v1/gratitudes/export';
              }}
              size="small"
            >
              {t('export')}
            </Button>
          }
        />

        <SettingComponent
          icon={ThemeLine}
          text={t('settings_theme')}
          subtext={t('settings_theme_description')}
          accessory={
            <Select
              id="theme-select"
              defaultValue={themePreference}
              onChange={(value) =>
                setThemePreference(value as Parameters<typeof setThemePreference>[0])
              }
            >
              <Select.Option value="auto">
                {t('settings_theme_same_as_system') as string}
              </Select.Option>
              <Select.Option value="dark">{t('settings_theme_dark') as string}</Select.Option>
              <Select.Option value="bright">{t('settings_theme_bright') as string}</Select.Option>
            </Select>
          }
        />
      </List>
    </>
  );
};

const Delegates = () => {
  const [delegates, setDelegates] = useAtom(delegatesAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const removeEmployee = (employee: Employee) => {
    setDelegates((delegates ?? []).filter((e) => e.ldap !== employee.ldap));
    setSnackbarMessage({text: t('saved')});
  };

  const selectEmployee = useCallback(
    (selected: Employee) => {
      const found = delegates?.find((employee) => employee.ldap === selected.ldap);
      if (!found) {
        setDelegates([...delegates!, selected]);
        setSnackbarMessage({text: t('saved')});
      }
    },
    [delegates, setDelegates, setSnackbarMessage]
  );

  const selectEmployees = useCallback(
    (selected: Employee[]) => {
      const found = selected.filter(
        (employee) => !delegates?.find((e) => e.ldap === employee.ldap)
      );
      if (found.length) {
        setDelegates([...(delegates ?? []), ...found]);
        setSnackbarMessage({text: t('saved')});
      }
    },
    [delegates, setDelegates, setSnackbarMessage]
  );

  return (
    <PeopleSearchMenu
      selectedEmployees={delegates ?? []}
      onRemoveEmployee={removeEmployee}
      onSelectEmployee={selectEmployee}
      onSelectEmployees={selectEmployees}
    />
  );
};

const ProfileImageModal = ({
  employee,
  isOpen,
  setIsOpen,
}: {
  employee: EmployeeFull;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [crop, setCrop] = useState<{x: number; y: number; scale: number} | undefined>();
  const [image, setImage] = useState<string | File | undefined>(
    employee.profile_photo_url ?? undefined
  );
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [changesMade, setChangesMade] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const onSettled = () => {
    queryClient.invalidateQueries({queryKey: ['notifications']});
    setIsOpen(false);
    setIsConfirmed(false);
    setCanvas(undefined);
    setImage(undefined);
    setChangesMade(false);
    setErrorMessage(undefined);
    setSnackbarMessage({text: t('saved')});

    // Reload the avatar
    const url = getBackendUrl() + getProfileImageUrl(employee.ldap);
    const avatar = document.querySelector(`img[src="${url}"]`) as HTMLImageElement | null;
    const currentSrc = avatar?.src;
    if (currentSrc) {
      avatar.src = '';
      avatar.src = currentSrc;
    }
  };

  const mutation = useMutation({
    mutationFn: (formData: Body_put_image_api_v1_images__ldap__jpg_put) =>
      getService(EmployeeService).putImageApiV1ImagesLdapJpgPut(employee.ldap, formData),
    onSettled,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // 10MB check
      if (file.size > 10 * 1024 * 1024) {
        setErrorMessage(t('settings_profile_image_modal_file_size_error'));
        return;
      }

      // >= 300x300 check
      const imageSize = new Image();
      imageSize.src = URL.createObjectURL(file);
      imageSize.onload = () => {
        if (imageSize.width < 300 || imageSize.height < 300) {
          setErrorMessage(t('settings_profile_image_modal_size_error'));
        } else {
          setImage(file);
          setCrop({x: 0, y: 0, scale: 1});
          setIsConfirmed(false);
          setChangesMade(true);
        }
      };
    }
  };

  const cancel = () => {
    setCrop(undefined);
    setImage(undefined);
    setCanvas(undefined);
    setIsOpen(false);
    setIsConfirmed(false);
    setErrorMessage(undefined);
    setChangesMade(false);
  };

  const save = (event: React.FormEvent) => {
    event.preventDefault();

    if (!canvas) {
      console.error('No canvas to save');
      return;
    }

    canvas.toBlob((thumbnail) => {
      if (thumbnail) {
        if (image instanceof File) {
          mutation.mutate({image, thumbnail});
        } else {
          mutation.mutate({thumbnail});
        }
      }
    }, 'image/png');
  };

  const handleMove = (c: HTMLCanvasElement, newCrop: {x: number; y: number; scale: number}) => {
    setCanvas(c);
    setCrop(newCrop);
  };

  return (
    <Modal
      isCentered
      width="large"
      open={isOpen}
      onRequestClose={() => setIsOpen(false)}
      withCloseButton="profile-image-close"
    >
      <Modal.Header>
        <Modal.Title>{t('settings_profile_image')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap="12">
          <Split gap="44">
            <Split.Item>
              {image instanceof File ? (
                <DraggableZoomableImage
                  isLoading={!image}
                  image={image}
                  crop={crop ?? {x: 0, y: 0, scale: 1}}
                  onMove={handleMove}
                />
              ) : (
                <Avatar size="edit" user={employee} />
              )}
            </Split.Item>
            <Split.Item>
              <Box as={Stack} marginTop="20">
                <Button type="file" variant="outline" onClick={() => fileInputRef.current?.click()}>
                  {t('upload')}
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{display: 'none'}}
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {errorMessage && (
                  <Box
                    display="block"
                    as={Text}
                    color="Alert Base"
                    size="small"
                    marginTop="12"
                    isBold
                  >
                    {errorMessage}
                  </Box>
                )}
                <Box paddingTop="20" paddingBottom="8">
                  <Text isBold size="small" color="faint">
                    {t('settings_profile_image_modal_title')}
                  </Text>
                </Box>
                <Text size="small" color="faint">
                  <Box as="ul" marginLeft="16" style={{listStyleType: 'disc'}}>
                    <li>{t('settings_profile_image_modal_restriction_1')}</li>
                    <li>{t('settings_profile_image_modal_restriction_2')}</li>
                    <li>{t('settings_profile_image_modal_restriction_3')}</li>
                    <li>{t('settings_profile_image_modal_restriction_4')}</li>
                  </Box>
                </Text>
                {changesMade && (
                  <Box
                    backgroundColor="Primary Surface"
                    paddingX="16"
                    paddingY="8"
                    borderRadius="Medium"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{marginTop: 24}}
                  >
                    <Text tagName="label" htmlFor="confirm-checkbox">
                      {t('settings_profile_image_modal_confirm_banner')}
                    </Text>
                    <Checkbox
                      aria-label="Confirm that the uploaded image meets the requirements"
                      id="confirm-checkbox"
                      checked={isConfirmed}
                      onChange={() => setIsConfirmed(!isConfirmed)}
                    />
                  </Box>
                )}
              </Box>
            </Split.Item>
          </Split>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={mutation.isPending} onClick={cancel} variant="opacity">
          {t('cancel')}
        </Button>
        <Button
          disabled={!isConfirmed || !changesMade || mutation.isPending}
          isLoading={mutation.isPending}
          onClick={save}
          variant="primary"
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const WorkingWithMe = ({initialValue}: {initialValue: string | undefined}) => {
  const [value, setValue] = useState<EditorState>();

  const content = useRef(initialValue);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const debouncedValue = useDebouncedValue(value, 300);
  const {settings, setSettings} = useSettingsQuery();

  useEffect(() => {
    const debouncedJson = debouncedValue?.toString() ?? '';
    if (debouncedValue && debouncedJson !== settings.working_with_me) {
      setSettings({...settings, working_with_me: debouncedJson}).then(() =>
        setSnackbarMessage({text: t('saved')})
      );
    }
  }, [settings, setSettings, debouncedValue, setSnackbarMessage]);

  return (
    <div style={{overflow: 'auto', maxHeight: '280px'}}>
      <RichTextArea
        editable
        autoFocus={false}
        topOffset={0}
        theme="small"
        placeholder={t('settings_working_with_me_placeholder')}
        value={content.current}
        onChange={(state) => setValue(state)}
      />
    </div>
  );
};

interface SettingProps {
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  text: ReactNode;
  subtext?: ReactNode;
  accessory?: ReactNode;
  extra?: ReactNode;
  expanded?: boolean;
}

const SettingComponent = ({icon, text, subtext, accessory, extra, expanded}: SettingProps) => (
  <>
    <Box display="flex" flexDirection="column">
      <List.Item className={styles.settingsListItem}>
        {icon && (
          <List.Accessory>
            <Box as={UIIcon} color="Text Subtle" src={icon} />
          </List.Accessory>
        )}
        <List.Content>
          <LabelGroup
            withText={<Text isBold>{text}</Text>}
            withSubtext={<Text color="subtle">{subtext}</Text>}
          />
        </List.Content>
        <List.Accessory>{accessory}</List.Accessory>
      </List.Item>
      <div className={cx(styles.extra, {[styles.expanded]: expanded})}>{extra}</div>
    </Box>
  </>
);

export const useSettingsQuery = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  // Get settings data
  const {data: settings} = useSuspenseQuery({
    queryKey: ['settings'],
    queryFn: getService(SettingService).getSettingApiV1SettingsGet,
  });

  // Update settings data
  const {mutateAsync: setSettings} = useMutation({
    mutationFn: getService(SettingService).upsertSettingApiV1SettingsPost,
    onMutate: async (newSettings) => {
      await queryClient.cancelQueries({queryKey: ['settings']});

      // Optimistically update
      queryClient.setQueryData(['settings'], newSettings);
    },
    onError: () => queryClient.setQueryData(['settings'], settings),
    onSettled: (response) => {
      queryClient.setQueryData(['settings'], response);
      if (pulseUser) {
        queryClient.invalidateQueries({queryKey: ['profile', pulseUser.email.split('@')[0]]});
      }
    },
  });

  return {settings, setSettings};
};
