import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CalendarLine,
  ChevronDownLine,
  ChevronUpLine,
  CircleSmallFilledFill,
  VideoLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {MegaphoneMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {format, parseISO} from 'date-fns';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';

import {Section} from './Section';

const formatLeaveDate = (dateUnformatted: string) => {
  const parsedDate = parseISO(dateUnformatted);

  return {date: format(parsedDate, 'MMM d'), time: format(parsedDate, 'h:mm a')};
};

const EventItem = ({event}: {event: any}) => {
  const urlParams = new URLSearchParams(window.location.search);

  const start = formatLeaveDate(event.start.date ?? event.start.dateTime);
  const end = formatLeaveDate(event.end.date ?? event.end.dateTime);

  let startDate = `${start.date} ${start.time}`;
  let endDate = end.time;
  if (event.end.date) {
    startDate = start.date;
    endDate = end.date;
  }

  const handleEventClick = () =>
    analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'event'});

  const handleZoomJoin = () => analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'zoom'});

  const zoomLink = event.location?.includes('dropbox.zoom.us') ? event.location : null;
  const isLive =
    zoomLink &&
    ((new Date() > new Date(event.start.dateTime) && new Date() < new Date(event.end.dateTime)) ||
      urlParams.get('live') === '1');

  return (
    <Box
      key={event.id}
      paddingY="8"
      paddingX="12"
      borderRadius="Medium"
      cursor="pointer"
      backgroundColor={isLive ? 'Primary On Base' : undefined}
      {...withShade({duration: 'None', style: {marginLeft: -8, marginRight: -8}})}
    >
      <Link to={event.htmlLink} hasNoUnderline onClick={handleEventClick}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <LabelGroup
            withText={<Text isBold>{event.summary}</Text>}
            withSubtext={
              <Text
                size="small"
                color="faint"
                className={atoms({display: 'flex', alignItems: 'center'})}
                style={{gap: 4}}
              >
                {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
                {zoomLink ? <UIIcon src={VideoLine} size="small" /> : null}
              </Text>
            }
          />
          {isLive && (
            <Box display="flex" style={{gap: 4}}>
              <Box
                backgroundColor="Alert Surface"
                display="flex"
                alignItems="center"
                borderRadius="Small"
                paddingLeft="4"
                paddingRight="8"
                paddingY="4"
              >
                <UIIcon
                  size="small"
                  src={CircleSmallFilledFill}
                  className={atoms({color: 'Alert Base'})}
                />
                <Text size="xsmall" isBold style={{fontSize: 11, lineHeight: '11px'}}>
                  Live
                </Text>
              </Box>
              {zoomLink && (
                <ButtonLink to={zoomLink} variant="primary" onClick={handleZoomJoin} size="small">
                  Join
                </ButtonLink>
              )}
            </Box>
          )}
        </Box>
      </Link>
    </Box>
  );
};

export const CalendarSection = () => {
  const [showAllDay, setShowAllDay] = useState(false);
  const {data, isLoading} = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['home', 'calendar'],
    queryFn: getService(HomeService).calendarApiV1HomeCalendarGet,
  });

  // check query param for "override = 1"
  const urlParams = new URLSearchParams(window.location.search);
  const showAllEvents = urlParams.get('events') === '1';

  const handleEventClick = () =>
    analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'header'});

  const groupedData = data?.reduce(
    (acc: any, event: any) => {
      const key = event.all_day ? 'all_day' : 'time';

      const now = new Date(); // Use real 'now' for event-end checks

      // Helper function to parse all-day dates as local dates
      const parseLocalDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Month is zero-based in Date
      };

      // Helper function to check if today falls within the event range
      const isTodayWithinRange = (startDate: Date, endDate: Date) => {
        const todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0); // Start of today
        const todayEnd = new Date(todayStart);
        todayEnd.setHours(23, 59, 59, 999); // End of today

        return (
          startDate <= todayEnd && endDate >= todayStart // Overlaps today
        );
      };

      if (key === 'time') {
        const eventStart = new Date(event.start.dateTime);
        const eventEnd = new Date(event.end.dateTime);

        // Use real now for checking if event has ended
        if (isTodayWithinRange(eventStart, eventEnd) && now < eventEnd) {
          acc[key].push(event);
        } else if (showAllEvents) {
          acc[key].push(event);
        }
      } else {
        const eventStart = parseLocalDate(event.start.date);
        const eventEnd = parseLocalDate(event.end.date);

        // Check if today is within the event range
        if (isTodayWithinRange(eventStart, eventEnd)) {
          acc[key].push(event);
        } else if (showAllEvents) {
          acc[key].push(event);
        }
      }

      return acc;
    },
    {all_day: [], time: []}
  );

  const hasTimeEvents = Boolean(groupedData?.time.length ?? 0 > 0);

  return (
    <Section
      title={
        <Link
          hasNoUnderline
          to="https://calendar.google.com/calendar/u/0/embed?src=c_1l2ip0fso394omg6ikk7coti14@group.calendar.google.com"
          onClick={handleEventClick}
        >
          {t('home_calendar')}
        </Link>
      }
      withAccessoryStart={<UIIcon src={CalendarLine} className={atoms({color: 'Text Subtle'})} />}
      isLoading={isLoading}
      loadingSkeleton={
        <Stack>
          {Array.from({length: 3}).map((_, index) => (
            <Box
              key={index}
              paddingY="8"
              paddingX="12"
              borderRadius="Medium"
              {...withShade({duration: 'None', style: {marginLeft: -8}})}
            >
              <LabelGroup
                size="large"
                withText={<Box as={Skeleton.Text} style={{width: 240}} />}
                withSubtext={<Skeleton.Text size="small" width={90} />}
              />
            </Box>
          ))}
        </Stack>
      }
      content={
        <Stack gap="4">
          {groupedData?.all_day.length ? (
            <Stack>
              {hasTimeEvents && (
                <Box
                  cursor="pointer"
                  paddingX="8"
                  paddingY="4"
                  borderRadius="Medium"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    analyticsLogger().logEvent('HOME_CALENDAR_CLICKED', {type: 'all_day_toggle'});
                    setShowAllDay(!showAllDay);
                  }}
                  {...withShade({style: {marginLeft: -8, marginRight: -8}})}
                >
                  <Text color="subtle">
                    {t('home_calendar_all_day', {count: groupedData.all_day.length})}
                  </Text>
                  <UIIcon
                    className={atoms({color: 'Text Subtle'})}
                    src={showAllDay ? ChevronUpLine : ChevronDownLine}
                  />
                </Box>
              )}
              {showAllDay || !hasTimeEvents
                ? groupedData.all_day.map((post: any) => <EventItem key={post.id} event={post} />)
                : null}
            </Stack>
          ) : null}
          {hasTimeEvents ? (
            <Box
              as={Stack}
              borderTop={groupedData?.all_day.length ? 'Solid' : undefined}
              marginTop="8"
              borderWidth="1"
              borderColor="Border Subtle"
            >
              {groupedData.time
                .sort((a: any, b: any) => {
                  const aHasDateTime = !!a.start.dateTime;
                  const bHasDateTime = !!b.start.dateTime;

                  const aIsSameDay = a.start.date && a.end.date && a.start.date === a.end.date;
                  const bIsSameDay = b.start.date && b.end.date && b.start.date === b.end.date;

                  // If a has dateTime and b doesn't, a comes first
                  if (aHasDateTime && !bHasDateTime) {
                    return -1;
                  }
                  // If b has dateTime and a doesn't, b comes first
                  else if (!aHasDateTime && bHasDateTime) {
                    return 1;
                  }
                  // If a is same day and b isn't, a comes first
                  else if (aIsSameDay && !bIsSameDay) {
                    return -1;
                  }
                  // If b is same day and a isn't, b comes first
                  else if (!aIsSameDay && bIsSameDay) {
                    return 1;
                  }
                  // If both have the same criteria, keep the original order
                  else {
                    return 0;
                  }
                })
                .map((post: any) => (
                  <EventItem key={post.id} event={post} />
                ))}
            </Box>
          ) : null}
          {!groupedData?.all_day.length && !groupedData?.time.length && (
            <EmptyState
              title={t('home_empty_calendar_title')}
              body={t('home_empty_calendar_body')}
              image={<MegaphoneMini width="64" altText={t('home_empty_calendar_title')} />}
              hideBorder
              marginBottom="0"
              paddingY="24"
            />
          )}
        </Stack>
      }
    />
  );
};
