import {Button, ButtonProps, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import cx from 'classnames';
import {forwardRef, ReactNode, useEffect, useRef, useState} from 'react';
// eslint-disable-next-line no-restricted-imports
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';

import styles from './Link.module.css';

export type LinkProps = {
  isBold?: boolean;
  monochromatic?: boolean;
  isClickable?: boolean;
  hasNoUnderline?: boolean;
  showUnderlineOnHover?: boolean;
} & RouterLinkProps;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      isClickable = true,
      isBold,
      monochromatic,
      showUnderlineOnHover,
      hasNoUnderline,
      children,
      ...linkProps
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    const internalRef = useRef<HTMLAnchorElement>(null);
    const [isFocusVisible, setIsFocusVisible] = useState(false);

    const isExternalLink = typeof linkProps.to === 'string' && /^(https?:\/\/)/.test(linkProps.to);

    useEffect(() => {
      const node = internalRef.current;
      if (!node) return;

      const updateFocusVisible = () => {
        setIsFocusVisible(node.hasAttribute('data-focus-visible-added'));
      };

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-focus-visible-added'
          ) {
            updateFocusVisible();
          }
        });
      });

      observer.observe(node, {attributes: true});

      updateFocusVisible();

      return () => observer.disconnect();
    }, []);

    if (isExternalLink) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          {...linkProps}
          href={linkProps.to as string}
          ref={internalRef}
          target="_blank"
          className={cx(styles.link, linkProps.className, {
            [styles.focused]: isFocusVisible,
            [styles.monochromatic]: monochromatic,
            [styles.underline]: !hasNoUnderline,
            [styles.showUnderlineOnHover]: showUnderlineOnHover,
            [styles.bold]: isBold,
          })}
        >
          {children}
        </a>
      );
    }

    return isClickable ? (
      <RouterLink
        {...linkProps}
        ref={internalRef}
        className={cx(styles.link, linkProps.className, {
          [styles.focused]: isFocusVisible,
          [styles.monochromatic]: monochromatic,
          [styles.underline]: !hasNoUnderline,
          [styles.showUnderlineOnHover]: showUnderlineOnHover,
          [styles.bold]: isBold,
        })}
      >
        {children}
      </RouterLink>
    ) : (
      <Box className={linkProps.className}>{children}</Box>
    );
  }
);
Link.displayName = 'Link';

export const LinkStyledButton = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => (
  <Box as="button" onClick={onClick} className={cx(styles.linkStyledButton)}>
    <Text size="small" color="faint" isBold>
      {children}
    </Text>
  </Box>
);

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  LinkProps & ButtonProps & {rounded?: boolean}
>((props, ref) => {
  const routerLinkProps: LinkProps = {
    to: props.to,
    target: props.target,
    replace: props.replace,
    state: props.state,
    reloadDocument: props.reloadDocument,
    preventScrollReset: props.preventScrollReset,
    relative: props.relative,
    viewTransition: props.viewTransition,
    isClickable: props.isClickable,
    className: props.className,
    style: props.style,
    onClick: props.onClick,
  };

  const {children, ...buttonProps} = props;

  return (
    <Link hasNoUnderline {...routerLinkProps} ref={ref}>
      {props.rounded ? (
        <IconButton
          tabIndex={-1}
          {...buttonProps}
          onClick={undefined}
          variant={props.variant === 'primary' ? 'outline' : props.variant}
        >
          {children}
        </IconButton>
      ) : (
        <Button tabIndex={-1} {...buttonProps} onClick={undefined}>
          {children}
        </Button>
      )}
    </Link>
  );
});
ButtonLink.displayName = 'ButtonLink';
