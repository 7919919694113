/* eslint-disable no-param-reassign */
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddMediaLine,
  BoldLine,
  HeaderLine,
  ItalicLine,
  LinkLine,
  OrderedListLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/assets';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$createHeadingNode, $isHeadingNode} from '@lexical/rich-text';
import {$setBlocksType} from '@lexical/selection';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {snackbarAtom} from 'atoms/snackbar';
import {ACCEPTABLE_FILE_TYPES, handleFiles} from 'components/DSYS/editor/nodes/fileUploadUtil';
import {getSelectedNode, LowPriority} from 'components/DSYS/editor/utils';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useMediaCreate} from 'views/news/hooks';

export const RichTextToolbar = ({
  top,
  media,
  disableStickyToolbar,
}: {
  top: number;
  media?: boolean;
  disableStickyToolbar?: boolean;
}) => {
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const [editor] = useLexicalComposerContext();
  const {uploadMedia} = useMediaCreate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [blockType, setBlockType] = useState('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const validFiles = Array.from(event.target.files).filter(
        (file) => file.size <= 6 * 1024 * 1024
      );

      if (validFiles.length !== event.target.files.length) {
        setSnackbarMessage({
          text: t('rich_text_upload_size_error'),
        });
      }

      handleFiles([...validFiles], uploadMedia, editor);
      // Reset file input
      event.target.value = '';
    },
    [editor, setSnackbarMessage, uploadMedia]
  );

  // Highlight toolbar items based on what's selected
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
        url: 'https://',
        target: '_blank',
        rel: 'noopener noreferrer',
      });
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const insertMedia = useCallback(() => fileInputRef.current?.click(), []);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = () => {
    if (!/h\d$/.test(blockType)) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode('h1'));
      });
    } else {
      formatParagraph();
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <Box
      id="rich-text-toolbar"
      position={disableStickyToolbar ? 'relative' : 'sticky'}
      backgroundColor="Background Base"
      style={{top, zIndex: 1, margin: '-1px -1px'}}
    >
      <Box
        borderTop="Solid"
        borderLeft="Solid"
        borderRight="Solid"
        borderColor="Border Subtle"
        borderWidth="1"
        style={{borderTopLeftRadius: 6, borderTopRightRadius: 6}}
      >
        <Split padding="4" gap="6" paddingBottom="8">
          {media && (
            <Split.Item>
              <IconButton variant="borderless" onClick={formatHeading}>
                <Box
                  as={UIIcon}
                  color={/h\d$/.test(blockType) ? 'Text Base' : 'Text Subtle'}
                  src={HeaderLine}
                />
              </IconButton>
            </Split.Item>
          )}
          <Split.Item>
            <IconButton
              variant="borderless"
              disabled={/h\d$/.test(blockType)}
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            >
              <Box
                as={UIIcon}
                color={
                  /h\d$/.test(blockType) ? 'Disabled On Base' : isBold ? 'Text Base' : 'Text Subtle'
                }
                src={BoldLine}
              />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton
              variant="borderless"
              disabled={/h\d$/.test(blockType)}
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
            >
              <Box
                as={UIIcon}
                color={
                  /h\d$/.test(blockType)
                    ? 'Disabled On Base'
                    : isItalic
                      ? 'Text Base'
                      : 'Text Subtle'
                }
                src={ItalicLine}
              />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton variant="borderless" onClick={formatBulletList}>
              <Box
                as={UIIcon}
                color={blockType === 'ul' ? 'Text Base' : 'Text Subtle'}
                src={UnorderedListLine}
              />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton variant="borderless" onClick={formatNumberedList}>
              <Box
                as={UIIcon}
                color={blockType === 'ol' ? 'Text Base' : 'Text Subtle'}
                src={OrderedListLine}
              />
            </IconButton>
          </Split.Item>

          <Split.Item>
            <Box borderLeft="Solid" marginTop="6" style={{height: 20}} />
          </Split.Item>

          <Split.Item>
            <IconButton variant="borderless" onClick={insertLink}>
              <Box as={UIIcon} color={isLink ? 'Text Base' : 'Text Subtle'} src={LinkLine} />
            </IconButton>
          </Split.Item>

          {media && (
            <Split.Item>
              <IconButton variant="borderless" onClick={insertMedia}>
                <Box as={UIIcon} color="Text Subtle" src={AddMediaLine} />
              </IconButton>
            </Split.Item>
          )}
        </Split>
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        accept={ACCEPTABLE_FILE_TYPES.join(',')}
        onChange={handleFileChange}
      />
    </Box>
  );
};
