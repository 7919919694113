/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Checkup } from '../models/Checkup';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ToolsService {

    /**
     * Team Endpoint
     * @param id
     * @returns Checkup Successful Response
     * @throws ApiError
     */
    public static teamAuditApiV1AuditToolIdGet(
        id: string,
    ): CancelablePromise<Checkup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/audit/tool/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @param id
     * @returns Checkup Successful Response
     * @throws ApiError
     */
    public static getTeamAuditApiV1ToolIdGet(
        id: string,
    ): CancelablePromise<Checkup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tool/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Csv
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportCsvApiV1AuditTeamCsvGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/audit/team/csv',
        });
    }

    /**
     * Get Errors
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getErrorsApiV1GetErrorsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/get/errors',
        });
    }

}
