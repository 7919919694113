import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {CodeHighlightNode, CodeNode} from '@lexical/code';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {ListItemNode, ListNode} from '@lexical/list';
import {TRANSFORMERS} from '@lexical/markdown';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import * as Sentry from '@sentry/react';
import {Thread} from 'client';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $selectAll,
  EditorState,
  RootNode,
} from 'lexical';
import {forwardRef, ReactNode, useEffect, useImperativeHandle} from 'react';

import styles from './editor/Editor.module.css';
import {CaptureNode} from './editor/nodes/CaptureNode';
import {HighlightNode} from './editor/nodes/HighlightNode';
import {ImageNode} from './editor/nodes/ImageNode';
import {KalturaNode} from './editor/nodes/KalturaNode';
import {LinkNodePlugin} from './editor/nodes/LinkNodePlugin';
import {MentionNode} from './editor/nodes/MentionNode';
import {VideoNode} from './editor/nodes/VideoNode';
import {VimeoNode} from './editor/nodes/VimeoNode';
import {YouTubeNode} from './editor/nodes/YouTubeNode';
import {AutoEmbedPlugin} from './editor/plugins/AutoEmbedPlugin';
import {AutoLinkPlugin} from './editor/plugins/AutoLinkPlugin';
import {CapturePlugin} from './editor/plugins/CapturePlugin';
import {CommentPlugin} from './editor/plugins/CommentPlugin';
import {DragDropPastePlugin} from './editor/plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './editor/plugins/DraggableBlockPlugin';
import {FloatingLinkEditorPlugin} from './editor/plugins/FloatingLinkEditor';
import {ImagesPlugin} from './editor/plugins/ImagesPlugin';
import {KalturaPlugin} from './editor/plugins/KalturaPlugin';
import {LexicalClickableLinkPlugin} from './editor/plugins/LexicalClickableLinkPlugin';
import {LinkPlugin} from './editor/plugins/LinkPlugin';
import {NewMentionsPlugin} from './editor/plugins/MentionPlugin';
import {RichTextToolbar} from './editor/plugins/RichTextToolbarPlugin';
import {VideosPlugin} from './editor/plugins/VideosPlugin';
import {VimeoPlugin} from './editor/plugins/VimeoPlugin';
import {YouTubePlugin} from './editor/plugins/YouTubePlugin';
import getEditorTheme, {EditorTheme} from './editor/themes/EditorTheme';

const config = {
  namespace: 'Editor',
  onError(error: any) {
    throw error;
  },
  nodes: [
    HeadingNode,
    HighlightNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    YouTubeNode,
    CaptureNode,
    VimeoNode,
    MentionNode,
    KalturaNode,
    ImageNode,
    VideoNode,
  ],
};

interface Props {
  links?: boolean;
  source?: string;
  truncateLines?: number;
  hideToolbar?: boolean;
  theme?: EditorTheme;
  autoFocus?: boolean;
  placeholder?: ReactNode;
  topOffset?: number;
  media?: boolean;
  minHeight?: number;
  mentions?: boolean;
  disableStickyToolbar?: boolean;
  editable?: boolean;
  defaultText?: ReactNode;
  comments?: boolean;
  threads?: Thread[];
  editablePaddingX?: string;
  backgroundHighlight?: BoxProps<'div'>['backgroundColor'];
  borderHighlight?: boolean;
  selectAll?: boolean;
  value?: string;

  activeThreadId?: string;
  setActiveThreadId?: (id: string | undefined) => void;

  onChange?: (state: EditorState, rawText: string) => void;
  onReady?: (editorNodes: ChildNode[]) => void;
  onToggleFocused?: (focused: boolean) => void;
  onAddComment?: {
    (props: {comment: string; threadId: string}): Promise<string>;
    (props: {comment: string; text: string; start: number; end: number}): Promise<string>;
  };
  // Don't use this one
  onRootReady?: (root: RootNode) => void;
}

// eslint-disable-next-line react/display-name
const EditorContent = forwardRef(
  (
    {
      source,
      placeholder,
      autoFocus = true,
      links = true,
      editable,
      hideToolbar,
      comments,
      threads = [],
      activeThreadId,
      setActiveThreadId,
      minHeight = 150,
      disableStickyToolbar,
      editablePaddingX = '10',
      defaultText = null,
      media,
      truncateLines = undefined,
      topOffset = 48,
      mentions,
      selectAll,
      backgroundHighlight,
      value,
      onChange,
      onReady,
      onAddComment,
      onRootReady,
      onToggleFocused,
    }: Props,
    ref
  ) => {
    const [editor] = useLexicalComposerContext();

    useImperativeHandle(ref, () => ({
      focusEditor: () => editor.focus(),
      clearEditor: (resetValue?: string) => {
        editor.update(() => {
          if (!resetValue) {
            $getRoot().clear();
          } else {
            const root = $getRoot();
            root.clear();

            try {
              const json = JSON.parse(resetValue);
              editor.setEditorState(editor.parseEditorState(json));
            } catch (e) {
              // Fallback just loading a string
              root.clear();

              const paragraphNode = $createParagraphNode();
              paragraphNode.append($createTextNode(value));
              root.append(paragraphNode);

              if (source) {
                Sentry.captureException(new Error(`[RichTextEditor] Invalid JSON in ${source}`));
              }
            }
          }
        });
      },
    }));

    useEffect(() => {
      editor.setEditable(editable ?? false);
    }, [editable, editor]);

    useEffect(() => {
      if (value) {
        editor.update(() => {
          const root = $getRoot();
          try {
            const json = JSON.parse(value);
            editor.setEditorState(editor.parseEditorState(json));
          } catch (e) {
            // Fallback just loading a string
            root.clear();

            const paragraphNode = $createParagraphNode();
            paragraphNode.append($createTextNode(value));
            root.append(paragraphNode);

            if (source) {
              Sentry.captureException(new Error(`[RichTextEditor] Invalid JSON in ${source}`));
            }
          }

          onRootReady?.(root);
          onReady?.([...(editor.getRootElement()?.childNodes ?? [])]);
        });

        if (selectAll) {
          editor.update(() => {
            $selectAll();
          });
        }
      }
    }, [editor, selectAll, value, onReady, onRootReady, source]);

    if (!editable && !value && !defaultText) {
      return null;
    }

    // if comments and !onAddComment , throw error
    if (comments && !onAddComment) {
      throw new Error('RichTextArea: onAddComment are required when comments are enabled');
    }

    return (
      <Box
        borderWidth="1"
        borderStyle={editable && !backgroundHighlight ? 'Solid' : undefined}
        borderRadius={backgroundHighlight ? undefined : 'Small'}
        borderColor="Border Subtle"
        backgroundColor={editable && backgroundHighlight ? backgroundHighlight : undefined}
        display="block"
        style={
          editable
            ? undefined
            : {
                display: '-webkit-box',
                WebkitLineClamp: truncateLines,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }
        }
      >
        {editable && !hideToolbar && (
          <RichTextToolbar
            top={topOffset}
            media={Boolean(media)}
            disableStickyToolbar={disableStickyToolbar}
          />
        )}

        <Box position="relative" borderRadius="Small">
          <RichTextPlugin
            contentEditable={
              <Box paddingX={editable ? (editablePaddingX as any) : undefined}>
                <ContentEditable
                  onFocus={() => onToggleFocused?.(true)}
                  onBlur={() => onToggleFocused?.(false)}
                  className={editable ? styles.input : undefined}
                  style={editable ? {minHeight} : undefined}
                />
              </Box>
            }
            placeholder={
              editable ? (
                <Text className={styles.placeholder}>{placeholder}</Text>
              ) : (
                <Box>{defaultText}</Box>
              )
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {autoFocus && <AutoFocusPlugin />}
          <ListPlugin />
          <LinkPlugin />
          <LinkNodePlugin />
          <AutoLinkPlugin />
          {comments && (
            <CommentPlugin
              activeThreadId={activeThreadId}
              setActiveThreadId={setActiveThreadId}
              threads={threads}
              onAddComment={onAddComment!}
            />
          )}
          {links && (!editable ? <LexicalClickableLinkPlugin /> : <FloatingLinkEditorPlugin />)}
          {mentions && <NewMentionsPlugin />}
          {media && (
            <>
              <DraggableBlockPlugin />
              <AutoEmbedPlugin />
              <YouTubePlugin />
              <CapturePlugin />
              <VimeoPlugin />
              <KalturaPlugin />
              <ImagesPlugin />
              <VideosPlugin />
              <DragDropPastePlugin />
            </>
          )}
          <OnChangePlugin
            onChange={(state, editorInstance) => {
              const jsonState = state.toJSON();
              const {children} = jsonState.root;
              let lastChildrenNodeWithChildren = children.length - 1;
              for (let i = children.length - 1; i >= 0; i--) {
                const child = children[i] as any;
                if (child && child.children && child.children.length) {
                  lastChildrenNodeWithChildren = i;
                  break;
                }
              }

              jsonState.root.children = children.slice(0, lastChildrenNodeWithChildren + 1);

              // Extract raw text content
              let rawText = '';
              editorInstance.getEditorState().read(() => {
                rawText = $getRoot().getTextContent();
              });

              onChange?.(
                Object.assign(state, {
                  toString: () =>
                    !editorInstance.getRootElement()?.textContent?.trim().length
                      ? ''
                      : JSON.stringify(jsonState),
                }),
                rawText
              );
            }}
          />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </Box>
      </Box>
    );
  }
);

// eslint-disable-next-line react/display-name
export const RichTextArea = forwardRef(({...props}: Props, ref) => {
  return (
    <LexicalComposer
      initialConfig={{
        ...config,
        editable: props.editable ?? false,
        theme: getEditorTheme(props.theme),
      }}
    >
      <EditorContent {...props} ref={ref} />
    </LexicalComposer>
  );
});
