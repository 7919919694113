import {useTrackerCreate} from './hooks';
import {TrackerModify} from './TrackerModify';

export const TrackerCreate = () => {
  const {createTracker, isPending} = useTrackerCreate();

  return (
    <TrackerModify
      tracker={undefined}
      isPending={isPending}
      onSubmit={({data}) => createTracker({data})}
    />
  );
};
