import {Box} from '@dropbox/dig-foundations';
import {PlugMini} from '@dropbox/dig-illustrations';
import {Title} from 'components/DSYS/Title';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {t} from 'i18next';

import styles from './NewGoalHeader.module.css';

export const NewGoalHeader = ({
  isEditing,
  goalCreated,
  delegateId,
  setDelegateId,
}: {
  isEditing?: boolean;
  goalCreated: boolean;
  delegateId?: string;
  setDelegateId: (userId?: string) => void;
}) => {
  return (
    <Box style={{position: 'relative'}}>
      <Box display="flex" marginBottom="24">
        <Box display="flex" style={{gap: '8px'}} alignItems="center">
          <Box className={styles.plugMini} style={{width: '32px', height: '32px'}}>
            <PlugMini altText="plugMini" width={32} height={32} />
          </Box>
          <Title size={18}>{isEditing ? t('edit_goal') : t('create_a_new_goal')}</Title>
        </Box>
        <DelegateSelectMenu
          disabled={goalCreated || isEditing}
          delegateId={delegateId}
          setDelegateId={setDelegateId}
        />
      </Box>
    </Box>
  );
};
