import {$wrapSelectionInMarkNode, MarkNode, SerializedMarkNode} from '@lexical/mark';
import styles from 'components/DSYS/editor/plugins/Comment.module.css';
import {$applyNodeReplacement, LexicalNode, NodeKey, RangeSelection, Spread} from 'lexical';

export type SerializedHighlightNode = Spread<
  {
    className?: string;
  },
  SerializedMarkNode
>;

export class HighlightNode extends MarkNode {
  __className?: string;

  static getType(): string {
    return 'highlight-mark';
  }

  static clone(node: HighlightNode): HighlightNode {
    return new HighlightNode(node.__ids, node.__className, node.__key);
  }

  constructor(ids: string[], className?: string, key?: NodeKey) {
    super(ids, key);
    this.__className = className;
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span');
    span.classList.add(this.__className || 'highlight'); // Default class if none provided
    span.setAttribute('data-highlight-id', this.__ids[0]);

    // Attach event listeners for hover effect
    span.addEventListener('mouseenter', () => this.handleMouseEnter());
    span.addEventListener('mouseleave', () => this.handleMouseLeave());

    return span;
  }

  handleMouseEnter() {
    const id = this.__ids[0];
    if (!id) return;

    // Find all elements with the same mark ID and highlight them
    document
      .querySelectorAll(`span[data-highlight-id="${id}"]`)
      .forEach((el) => el.classList.add(styles.highlighted));
  }

  handleMouseLeave() {
    const id = this.__ids[0];
    if (!id) return;

    // Remove the highlight from all elements with the same mark ID
    document
      .querySelectorAll(`span[data-highlight-id="${id}"]`)
      .forEach((el) => el.classList.remove(styles.highlighted));
  }

  updateDOM(): boolean {
    return false;
  }

  exportJSON(): SerializedHighlightNode {
    return {
      ...super.exportJSON(),
      type: 'highlight-mark',
      version: 1,
      className: this.__className,
    };
  }

  static importJSON(serializedNode: SerializedHighlightNode): HighlightNode {
    return new HighlightNode(serializedNode.ids, serializedNode.className);
  }
}

export function $createHighlightNode(id: string, className?: string): HighlightNode {
  return $applyNodeReplacement(new HighlightNode([id], className));
}

export function $wrapSelectionInHighlightNode(
  selection: RangeSelection,
  id: string,
  className?: string
) {
  $wrapSelectionInMarkNode(selection, false, id, () => $createHighlightNode(id, className));
}

export function $isHighlightNode(node: LexicalNode | null | undefined): node is HighlightNode {
  return node instanceof HighlightNode;
}
