import {init, onTTVC} from '@dropbox/ttvc';
import {ROUTE_PATHS} from 'constant';

import {
  currentRoute,
  getGoalsViewType,
  isGoalRoute,
  isProfileRoute,
  isProjectIDRoute,
  isProjectTeamRoute,
  isTeamRoute,
} from './routeInfo';

// List of supported routes to collect TTVC information.
const supportedRoutes = [
  ROUTE_PATHS.GOALS,
  ROUTE_PATHS.PEOPLE,
  ROUTE_PATHS.TEAMS,
  ROUTE_PATHS.STRATEGIES,
  ROUTE_PATHS.PROJECTS,
  ROUTE_PATHS.SETTINGS,
  ROUTE_PATHS.DASHBOARDS,
  ROUTE_PATHS.HOME,
  '/projects/name',
  '/projects/team',
  '/goals/id',
  '/teams/name',
  '/people/name',
] as const;

export function loadTTVCTracker() {
  // Track TTVC
  init({debug: false, idleTimeout: 2000});
  onTTVC(async ({start, duration: realDuration}) => {
    // round the duration to avoid long decimal numbers
    const duration = Math.round(realDuration);

    // lazily import the amplitude client to not impact ttvc
    const {analyticsLogger} = await import('../analytics/analyticsLogger');

    // normalized route to report to amplitude
    const route = normalizedRoute();
    if (!route) {
      return;
    }

    // start is 0 the first time this is called.
    const subsequent = start !== 0;
    const view = route.includes(ROUTE_PATHS.GOALS) ? getGoalsViewType() : undefined;
    if (subsequent) {
      analyticsLogger().logEvent('TTVC_SUBSEQUENT', {duration, route, view});
    } else {
      analyticsLogger().logEvent('TTVC_INIT', {duration, route, view});
    }
  });
}

export const normalizedRoute = () => {
  let route = currentRoute();
  if (isGoalRoute()) {
    route = '/goals/id';
  } else if (isTeamRoute()) {
    route = '/teams/name';
  } else if (isProfileRoute()) {
    route = '/people/name';
  } else if (isProjectTeamRoute()) {
    route = '/projects/name';
  } else if (isProjectIDRoute()) {
    route = '/projects/id';
  }

  if (supportedRoutes.includes(route)) {
    return route;
  }

  return undefined;
};
