/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tracker } from '../models/Tracker';
import type { TrackerCreate } from '../models/TrackerCreate';
import type { TrackerEdit } from '../models/TrackerEdit';
import type { TrackerFull } from '../models/TrackerFull';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrackerService {

    /**
     * Edit
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static editApiV1TrackerPut(
        requestBody: TrackerEdit,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tracker',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createApiV1TrackerPost(
        requestBody: TrackerCreate,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tracker',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive
     * @param id
     * @returns number Successful Response
     * @throws ApiError
     */
    public static archiveApiV1TrackerIdArchivePost(
        id: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tracker/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All
     * @returns Tracker Successful Response
     * @throws ApiError
     */
    public static getAllApiV1TrackerAllGet(): CancelablePromise<Array<Tracker>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tracker/all',
        });
    }

    /**
     * Get
     * @param id
     * @param reports
     * @returns TrackerFull Successful Response
     * @throws ApiError
     */
    public static getApiV1TrackerIdGet(
        id: string,
        reports?: (boolean | null),
    ): CancelablePromise<TrackerFull> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tracker/{id}',
            path: {
                'id': id,
            },
            query: {
                'reports': reports,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
