import {Text} from '@dropbox/dig-components/dist/typography';
import {Table} from 'components/DSYS/Table';

export const EmptyCell = ({className}: {className?: string}) => {
  return (
    <Table.Cell>
      <Text color="subtle" className={className}>
        —
      </Text>
    </Table.Cell>
  );
};
