import {Workstream, WorkstreamEdit} from 'client';
import {useLoaderData} from 'react-router-dom';

import {useWorkstreamEdit} from './hooks';
import {WorkstreamModify} from './WorkstreamModify';

export const WorkstreamEditor = () => {
  const workstream = useLoaderData() as Workstream;
  const {editWorkstream, isPending} = useWorkstreamEdit();

  return (
    <WorkstreamModify
      isEditing
      onSubmit={(data) => editWorkstream({data: data.data as WorkstreamEdit, reason: data.reason})}
      isPending={isPending}
      workstream={workstream}
    />
  );
};
