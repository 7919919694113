import {useQuery} from '@tanstack/react-query';
import {GoalService} from 'client';
import {getService} from 'utilities';

export const useStrategy = ({strategyId, timeframe}: {strategyId: string; timeframe: string}) => {
  return useQuery({
    queryKey: ['strategy', timeframe, strategyId],
    queryFn: () =>
      getService(GoalService).getGoalsByStrategyApiV1GoalsStrategyStrategyIdGet(
        strategyId,
        timeframe
      ),
  });
};
