import {Button, IconButton, SplitButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  ActivityLine,
  AddCircleLine,
  BriefcaseLine,
  CheckmarkCircleLine,
  ChevronDownLine,
  ChevronUpLine,
  DeleteLine,
  EmailLine,
  LinkLine,
  PersonMultipleLine,
  SlackExternalLogo,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, TeamInfoFull, TeamLink} from 'client';
import {CircleIcon} from 'components/DSYS/CircleIcon';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {OrgChart} from 'pages/OrgChart/Chart';
import {FocusData} from 'pages/OrgChart/helpers';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getProfileImageUrl} from 'utilities';
import {Checkmark} from 'views/tools/Checkmark';

import {useTeamDelete} from './hooks';
import styles from './TeamBody.module.css';

const toURL = (url: string) => {
  try {
    return url.startsWith('http') ? new URL(url) : new URL(`http://${url}`);
  } catch (e) {
    return url;
  }
};

const formatSlack = (slacks: string[]) =>
  slacks.map((channel) => (channel?.startsWith('#') ? channel : '#' + channel));

const formatEmail = (emails: string[]) =>
  emails.map((address) => ({
    url: `mailto:${address}`,
    text: address,
  }));

const formatURL = (urls: TeamLink[]) =>
  urls.map(({url, text}) => ({
    url: toURL(url).toString(),
    text,
  }));

const getSlackURL = (slackChannel: string) => {
  const channel = slackChannel.replace('#', '');
  if (channel.startsWith('https://')) {
    return channel;
  } else {
    return 'https://dropbox.slack.com/archives/' + channel;
  }
};

function getMemberKey(teamCount?: number) {
  if (!teamCount) {
    return 'member';
  }
  return teamCount === 1 ? 'member_with_team' : 'member_with_teams';
}

export const TeamPrimaryContact = ({
  primaryContact,
  totalEmployees,
}: {
  primaryContact: Employee;
  totalEmployees: number;
}) => (
  <Box
    paddingBottom="8"
    marginBottom="8"
    display="flex"
    justifyContent="space-between"
    borderBottom={totalEmployees === 1 ? undefined : 'Solid'}
    borderColor="Border Subtle"
    borderWidth="1"
    alignItems="center"
  >
    <ProfileCard
      profile_username={primaryContact.ldap}
      source="teams"
      profile_photo_url={getProfileImageUrl(primaryContact.ldap)}
      display_name={primaryContact.name}
      profile_info={primaryContact.role}
    />
    <Split alignY="center" gap="10">
      <Split.Item paddingRight="6">
        <Chip size="small">{t('primary_contact')}</Chip>
      </Split.Item>
      <Split.Item>
        <Link to={`https://dropbox.slack.com/team/${primaryContact.ldap}`} target="_blank">
          <IconButton variant="outline" size="medium" shape="circular">
            <Box as={UIIcon} color="Text Subtle" src={SlackExternalLogo} size="medium" />
          </IconButton>
        </Link>
      </Split.Item>
      <Split.Item>
        <Link to={`mailto:${primaryContact.ldap}@dropbox.com`}>
          <IconButton variant="outline" size="medium" shape="circular">
            <Box as={UIIcon} color="Text Subtle" src={EmailLine} size="medium" />
          </IconButton>
        </Link>
      </Split.Item>
    </Split>
  </Box>
);

export const TeamBody = ({
  team,
  expanded,
  setExpanded,
  handleOpenDrawer,
}: {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  team: TeamInfoFull;
  handleOpenDrawer: (type: 'audit' | 'members') => void;
}) => {
  const navigate = useNavigate();
  const [isConfirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState(false);

  const primaryContact = team.employees.find(
    (member) => member.ldap === team.primary_contact?.ldap
  );

  return (
    <Box className={styles.teamBodyContainer}>
      <Box
        opacity={expanded ? 0 : 1}
        style={{
          transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
        }}
      >
        <div className={styles.teamNameButtonContainer}>
          <Box display="flex" alignItems="center" style={{gap: 8}} width="100%">
            <Title className={atoms({flexShrink: 1, marginRight: '4'})}>{team.name}</Title>
            <Checkmark id={team.slug} type="teams" />
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <SplitButton
                variant="outline"
                onClick={() => navigate(`/teams/${team.slug}/edit`)}
                renderMenu={({buttonProps}) => (
                  <Menu.Wrapper>
                    {({getTriggerProps, getContentProps}) => (
                      <>
                        <Button
                          // to={`/teams/${team.slug}/edit`}
                          {...buttonProps}
                          {...getTriggerProps()}
                        />
                        <Menu.Content {...getContentProps()} placement="bottom-end">
                          <Menu.Segment>
                            <Menu.LinkItem
                              href={`/teams/${team.slug}/edit`}
                              withLeftAccessory={
                                <UIIcon src={TeamLine} className={atoms({color: 'Text Subtle'})} />
                              }
                            >
                              {t('team_info')}
                            </Menu.LinkItem>
                            <Menu.ActionItem
                              onClick={() => handleOpenDrawer('members')}
                              withLeftAccessory={
                                <UIIcon
                                  src={PersonMultipleLine}
                                  className={atoms({color: 'Text Subtle'})}
                                />
                              }
                            >
                              {t('members_title')}
                            </Menu.ActionItem>
                          </Menu.Segment>{' '}
                          <Menu.Segment>
                            <Menu.LinkItem
                              href={`/teams/new?parent=${team.slug}`}
                              withLeftAccessory={
                                <UIIcon
                                  src={AddCircleLine}
                                  className={atoms({color: 'Text Subtle'})}
                                />
                              }
                            >
                              {t('add_subteam')}
                            </Menu.LinkItem>
                            <Menu.ActionItem
                              onClick={() => setConfirmDeletionModalOpen(true)}
                              withLeftAccessory={
                                <UIIcon
                                  src={DeleteLine}
                                  className={atoms({color: 'Text Subtle'})}
                                />
                              }
                            >
                              {t('delete')}
                            </Menu.ActionItem>
                          </Menu.Segment>
                          <Menu.Segment>
                            <Menu.ActionItem
                              onClick={() => handleOpenDrawer('audit')}
                              withLeftAccessory={
                                <UIIcon
                                  src={ActivityLine}
                                  className={atoms({color: 'Text Subtle'})}
                                />
                              }
                            >
                              {t('audit_log_title')}
                            </Menu.ActionItem>
                            <Menu.LinkItem
                              href={`/teams/${team.slug}/checkup`}
                              withLeftAccessory={
                                <UIIcon
                                  src={CheckmarkCircleLine}
                                  className={atoms({color: 'Text Subtle'})}
                                />
                              }
                            >
                              {t('checkup_title')}
                            </Menu.LinkItem>
                          </Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                )}
              >
                {t('edit')}
              </SplitButton>
            </Box>
          </Box>
        </div>
        {team.description && (
          <Text
            size="large"
            color="faint"
            style={{whiteSpace: 'pre-line'}}
            className={atoms({display: 'block', marginTop: '8'})}
          >
            {team.description}
          </Text>
        )}
      </Box>
      {team.slack_channels?.length || team.total_employee_count ? (
        <Box
          opacity={expanded ? 0 : 1}
          style={{
            transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
          }}
        >
          <div className={styles.teamInfosContainer}>
            {team.total_employee_count && (
              <TeamInfoBox
                icon={PersonMultipleLine}
                title={t('people')}
                subtext={t(getMemberKey(team.children?.length), {
                  count: team.total_employee_count,
                  countString: team.total_employee_count.toLocaleString(),
                  teams: team.children?.length,
                })}
              />
            )}
            {team.emails && Boolean(team.emails?.length) && (
              <TeamInfoBox
                icon={EmailLine}
                title={t('email')}
                type="email"
                subtext={formatEmail(team.emails).map(({url, text}) => (
                  <Link to={url} key={url} hasNoUnderline monochromatic showUnderlineOnHover>
                    <Truncate tooltipControlProps={{placement: 'top'}}>{text}</Truncate>
                  </Link>
                ))}
              />
            )}
            {team.slack_channels && Boolean(team.slack_channels?.length) && (
              <TeamInfoBox
                icon={SlackExternalLogo}
                title="Slack"
                type="slack"
                subtext={formatSlack(team.slack_channels).map((channel) => (
                  <Link
                    to={getSlackURL(channel)}
                    key={channel}
                    target="_blank"
                    hasNoUnderline
                    monochromatic
                    showUnderlineOnHover
                  >
                    <Truncate tooltipControlProps={{placement: 'top'}}>
                      {channel.startsWith('#https://') ? t('open_channel') : `${channel}`}
                    </Truncate>
                  </Link>
                ))}
              />
            )}
            {team.links && Boolean(team.links?.length) && (
              <TeamInfoBox
                icon={LinkLine}
                title={t('resources')}
                type="url"
                subtext={formatURL(team.links).map(({url, text}) => (
                  <Link
                    to={url}
                    key={url}
                    target="_blank"
                    hasNoUnderline
                    monochromatic
                    showUnderlineOnHover
                  >
                    <Truncate tooltipControlProps={{placement: 'top'}}>{text}</Truncate>
                  </Link>
                ))}
              />
            )}
            {Boolean(team.count_projects) && (
              <TeamInfoBox
                icon={BriefcaseLine}
                title={t('projects')}
                subtext={
                  <Link
                    to={`/projects/${team.slug}`}
                    hasNoUnderline
                    monochromatic
                    showUnderlineOnHover
                  >
                    {t('table_projects', {count: team.count_projects})}
                  </Link>
                }
              />
            )}
          </div>
        </Box>
      ) : (
        ''
      )}
      <Box
        as={Stack}
        gap="8"
        opacity={expanded ? 0 : 1}
        style={{
          transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
        }}
        paddingX="20"
        paddingY="16"
        borderColor="Border Subtle"
        borderWidth="1"
        borderStyle="Solid"
        borderRadius="Medium"
      >
        <Title
          size="small"
          withAccessoryStart={<UIIcon src={PersonMultipleLine} />}
          withAccessoryEnd={
            <Button size="small" variant="transparent" onClick={() => handleOpenDrawer('members')}>
              {t('edit')}
            </Button>
          }
        >
          {t('members_title')}
        </Title>
        {team.employees.length > 0 ? (
          <Box marginTop="24">
            {primaryContact && (
              <TeamPrimaryContact
                primaryContact={primaryContact}
                totalEmployees={team.employees.length}
              />
            )}
            <div className={styles.teamMembersContainer}>
              {team.employees
                .filter((member) => member.ldap !== team.primary_contact?.ldap)
                // .sort(sortEmployees)
                .map((person, index) => (
                  <ProfileCard
                    key={person.ldap + index}
                    source="teams"
                    profile_username={person.ldap}
                    profile_photo_url={getProfileImageUrl(person.ldap)}
                    display_name={person.name}
                    profile_info={person.role}
                  />
                ))}
            </div>
          </Box>
        ) : (
          <Text variant="paragraph" size="large" tagName="p" color="faint">
            {t('no_members')}
          </Text>
        )}
      </Box>
      {!team.children?.length ? null : (
        <Box
          opacity={expanded ? 0 : 1}
          style={{
            transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
          }}
        >
          <Title>{t('supporting_teams')}</Title>
          <Box marginTop="24" className={styles.teamMembersContainer}>
            {team.children.map((subTeam, index) => (
              <TeamInfoBox
                key={subTeam.team_id || index}
                icon={TeamLine}
                title={subTeam.name}
                isSupportingTeam={true}
                subTeamSlug={subTeam.slug}
                subtext={t('member', {
                  count: subTeam.total_employee_count ?? 0,
                  countString: (subTeam.total_employee_count ?? 0).toLocaleString(),
                })}
              />
            ))}
          </Box>
        </Box>
      )}
      <TeamOrgChart team={team} expanded={expanded} setExpanded={setExpanded} />
      <DeleteModal
        team={team}
        open={isConfirmDeletionModalOpen}
        onClose={() => setConfirmDeletionModalOpen(false)}
      />
    </Box>
  );
};

interface TeamInfoProps {
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  title: string;
  type?: 'email' | 'slack' | 'url';
  subtext?: React.ReactNode;
  isSupportingTeam?: boolean;
  subTeamDbxTeamId?: number;
  subTeamSlug?: string;
}

const infoBoxMap = {
  email: {overflow: t('email_count')},
  slack: {overflow: t('slack_count')},
  url: {overflow: t('link_count')},
};

const TeamInfoBox = ({
  icon,
  title,
  type,
  subtext,
  isSupportingTeam,
  subTeamSlug,
}: TeamInfoProps) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const handleSupportingTeamClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate('/teams/' + subTeamSlug);
    event.stopPropagation();
  };

  const isExpandable =
    Array.isArray(subtext) && subtext.length > 1 && Object.keys(infoBoxMap).includes(type ?? '');

  return (
    <Box
      onClick={(e) => (isSupportingTeam ? handleSupportingTeamClick(e) : undefined)}
      {...(isSupportingTeam
        ? {
            ...withShade({
              className: styles.teamInfoContainer,
              style: {cursor: 'pointer'},
            }),
          }
        : {className: styles.teamInfoContainer})}
    >
      <Box display="flex" alignItems="flex-start" flexGrow={1} overflow="hidden">
        <CircleIcon src={icon} className={atoms({marginRight: '12', flexShrink: 0})} />
        <Box
          display="flex"
          flexDirection="column"
          className={atoms({overflow: 'hidden'})}
          style={{textOverflow: 'ellipsis'}}
        >
          <Text isBold>{title}</Text>
          <Text size="small" color="faint">
            {subtext && (
              <Text size="small" color="subtle">
                {isExpandable && !expanded
                  ? t(infoBoxMap[type ?? 'email'].overflow, {count: subtext.length})
                  : subtext}
              </Text>
            )}
          </Text>
        </Box>
      </Box>

      {isExpandable && (
        <IconButton
          variant="transparent"
          className={atoms({flexShrink: 0})}
          onClick={(e: any) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <UIIcon src={expanded ? ChevronUpLine : ChevronDownLine} />
        </IconButton>
      )}
    </Box>
  );
};

const TeamOrgChart = ({
  team,
  expanded,
  setExpanded,
}: {
  team: TeamInfoFull;
  expanded: boolean;
  setExpanded: (expan: boolean) => void;
}) => {
  const [focus, setFocus] = useState<FocusData>(team as any as FocusData);

  useEffect(() => {
    setFocus(team as any as FocusData);
    setExpanded(false);
  }, [setExpanded, team]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setExpanded]);

  return (
    <OrgChart
      allowZoom
      toggle={{
        team: team as any as FocusData,
      }}
      focus={focus}
      setFocus={setFocus}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
};

const DeleteModal = ({
  team,
  open,
  onClose,
}: {
  team: TeamInfoFull;
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {deleteTeam, isPending} = useTeamDelete();

  const handleDelete = async () => {
    await deleteTeam(team.slug);
    setSnackbarMessage({text: t('deleted')});
    onClose();
    navigate(`/teams/${team.parent?.slug}`);
  };

  return (
    <Modal
      open={open}
      withCloseButton="close"
      width="small"
      aria-label="Are you sure you want to delete?"
      onRequestClose={onClose}
    >
      <Modal.Header>
        <Title>{t('delete_team_confirm')}</Title>
      </Modal.Header>
      <Modal.Body>{t('delete_team_confirm_subtitle')}</Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose} disabled={isPending}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleDelete} isLoading={isPending}>
          {t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// const ActionsModal = ({
//   modal,
//   open,
//   close,
//   handleClick,
// }: {
//   modal: string;
//   open: 'delete' | 'unpublish' | undefined;
//   close: () => void;
//   handleClick: () => void;
// }) => (
//   <Modal open={open === modal} withCloseButton="close" onRequestClose={close}>
//     <Modal.Header>
//       <Title>{t(`${modal}_post_confirm`)}</Title>
//     </Modal.Header>
//     <Modal.Body>{t(`${modal}_post_confirm_subtitle`)}</Modal.Body>
//     <Modal.Footer>
//       <Button variant="opacity" onClick={close}>
//         {t('cancel')}
//       </Button>
//       <Button
//         variant="primary"
//         onClick={() => {
//           close();
//           handleClick();
//         }}
//       >
//         {t(`${modal}`)}
//       </Button>
//     </Modal.Footer>
//   </Modal>
// );

// <Modal
//         open={isConfirmDeletionModalOpen}
//         isCentered
//         width="small"
//         withCloseButton="Close"
//         aria-label="Are you sure you want to delete?"
//         onRequestClose={(e: React.SyntheticEvent) => {
//           setConfirmDeletionModalOpen(false);
//           e.stopPropagation();
//         }}
//       >
//         <Modal.Header hasBottomSpacing="title-standard">
//           <Modal.Title>
//             {t('delete_this')} {goal ? t('goal') : t('key_result_alone')}?
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <DelegationBanner delegatingAs={user} padding />
//           {t('delete_confirm')}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="opacity"
//             onClick={(e: React.SyntheticEvent) => {
//               e.stopPropagation();
//               setConfirmDeletionModalOpen(false);
//             }}
//           >
//             {t('cancel')}
//           </Button>
//           <Button
//             variant="primary"
//             onClick={(e: React.SyntheticEvent) => {
//               e.stopPropagation();
//               performDelete();
//               setConfirmDeletionModalOpen(false);
//             }}
//           >
//             {t('delete')}
//           </Button>
//         </Modal.Footer>
//       </Modal>
