import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {isMobileAtom} from 'atoms/layout';
import {getPublishedStatuses, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {DashboardsGoalCounts} from 'hooks/useDashboards';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {DashboardsGoalsOverviewGraph} from 'views/dashboards/DashboardsGoalsOverviewGraph';
import {getStatusGoalCountsData} from 'views/dashboards/util';

interface DashboardsGoalsOverviewProps {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  setSelectedStatus: (status: string) => void;
  dashboardsGoalCounts?: DashboardsGoalCounts;
}

const DASHBOARDS_HEATMAP_OFFSET = 175;

export const DashboardsGoalsOverview = ({
  heatmapStatusSelectorRef,
  timeframe,
  setSelectedStatus,
  dashboardsGoalCounts,
}: DashboardsGoalsOverviewProps) => {
  const isMobile = useAtomValue(isMobileAtom);

  const isTotalCountsLoading = dashboardsGoalCounts?.is_total_counts_loading;
  const goalCounts = dashboardsGoalCounts?.total_counts;

  return (
    <DashboardsChartContainer
      title={t('goals_overview')}
      subtitle={t('goals_overview_subtitle')}
      icon={TargetLine}
    >
      <Box marginTop="16" marginBottom="16">
        <DashboardsGoalsOverviewGraph
          timeframe={timeframe}
          height={87}
          width="100%"
          onClick={(status) => {
            const element = heatmapStatusSelectorRef.current;
            if (element) {
              const rect = element.getBoundingClientRect();
              const scrollOffset =
                window.innerHeight / 2 - rect.height / 2 - DASHBOARDS_HEATMAP_OFFSET;
              window.scrollBy({top: rect.top - scrollOffset, behavior: 'smooth'});
            }
            analyticsLogger().logEvent('DASHBOARD_OVERVIEW_SEGMENT_CLICKED', {status});
            setSelectedStatus(status);
          }}
          goalCounts={goalCounts}
          showTooltip={!isMobile}
          isLoading={isTotalCountsLoading}
        />
      </Box>
      <Box
        as={Split}
        style={{
          gap: isMobile ? '12px' : '43px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {getPublishedStatuses().map((status) => {
          const goalCountsData = getStatusGoalCountsData(status, goalCounts);
          return (
            <Box
              as="div"
              key={`dashboards-goals-overview-status-label__${status}`}
              alignItems="flex-start"
              display="flex"
              style={{gap: '4px'}}
            >
              <StatusButtonIcon status={status} />
              <LabelGroup
                size="small"
                withText={
                  <Text size="small" isBold>
                    {t(status)}
                  </Text>
                }
                withSubtext={t('goals_overview_status_subtitle', {
                  count: goalCountsData?.statusCount ?? 0,
                  countString: (goalCountsData?.statusCount ?? 0).toLocaleString(),
                  perc: goalCountsData?.percentageCeil ?? 0,
                })}
              />
            </Box>
          );
        })}
      </Box>
    </DashboardsChartContainer>
  );
};
