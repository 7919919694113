import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {FilterLine} from '@dropbox/dig-icons/assets';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {t} from 'i18next';

export type Filters = {
  status: string[];
};

type Filter = {type: keyof Filters; value: string};

const FilterChips = ({
  filter,
  setFilter,
}: {
  filter: Filters;
  setFilter: (filter: Filters) => void;
}) => {
  return (
    <Split gap="4" alignX="right" style={{flexWrap: 'wrap'}}>
      {Object.entries(filter).map(([type, values]) =>
        (values as string[]).map((value) => (
          <Split.Item key={`chip-${type}-${value}`}>
            <Chip
              isSelected
              size="small"
              onDelete={() => {
                const toUpdate = filter[type as keyof Filters];
                const index = toUpdate.indexOf(value);
                toUpdate.splice(index, 1);

                setFilter({...filter, [type]: toUpdate});
              }}
            >
              <Chip.IconAccessory>
                <StatusButtonIcon status={value} completeNew />
              </Chip.IconAccessory>
              <Chip.Content>{t(value)}</Chip.Content>
            </Chip>
          </Split.Item>
        ))
      )}
    </Split>
  );
};

export const TableFilter = ({
  filter,
  setFilter,
}: {
  filter: Filters;
  setFilter: (filter: Filters) => void;
}) => {
  const handleSelection = ({type, value}: Filter) => {
    const toUpdate = filter[type];
    const index = toUpdate.indexOf(value);
    if (index === -1) {
      toUpdate.push(value);
    } else {
      toUpdate.splice(index, 1);
    }
    setFilter({...filter, [type]: toUpdate});
  };

  const isSelected = (data: Filter) => filter[data.type].includes(data.value.toString());

  return (
    <Split alignY="center" gap="8">
      <Split.Item>
        <FilterChips filter={filter} setFilter={setFilter} />
      </Split.Item>
      <Split.Item>
        <Menu.Wrapper closeOnSelection={false} onSelection={handleSelection} isPortaled={false}>
          {({getContentProps, getTriggerProps}) => (
            <>
              <IconButton {...getTriggerProps()} variant="transparent">
                <UIIcon src={FilterLine} />
              </IconButton>
              <Menu.Content {...getContentProps()} placement="bottom-end" style={{minWidth: 200}}>
                <Menu.Segment withLabel={t('status')}>
                  {['no_status', 'at_risk', 'off_track', 'on_track', 'cancelled', 'complete'].map(
                    (value) => (
                      <Menu.ActionItem
                        className={atoms({
                          borderRadius: 'Small',
                          marginLeft: '8',
                          marginRight: '8',
                        })}
                        key={`status-${value}`}
                        value={{value, type: 'status'}}
                        withLeftAccessory={
                          <Checkbox
                            checked={isSelected({type: 'status', value})}
                            readOnly
                            tabIndex={-1}
                          />
                        }
                      >
                        <LabelGroup
                          withLeftAccessory={<StatusButtonIcon status={value} completeNew />}
                          withText={
                            <Text isBold={isSelected({type: 'status', value})}>{t(value)}</Text>
                          }
                        />
                      </Menu.ActionItem>
                    )
                  )}
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      </Split.Item>
    </Split>
  );
};
