import {Text} from '@dropbox/dig-components/dist/typography';
import {useSuspenseQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {CeleryMetataskService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Table} from 'components/DSYS/Table';
import {ROUTE_PATHS} from 'constant';
import {isSuperUser} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useAtomValue} from 'jotai';
import {Suspense} from 'react';
import {redirect} from 'react-router-dom';
import {getService} from 'utilities';

const columns = [
  {type: 'id', minWidth: 20},
  {type: 'task_id', minWidth: 100},
  {type: 'status', minWidth: 100},
  {type: 'date_done', minWidth: 100},
  {type: 'traceback', minWidth: 800},
  {type: 'name', minWidth: 100},
  {type: 'worker', minWidth: 100},
  {type: 'retries', minWidth: 100},
  {type: 'queue', minWidth: 100},
];

export const CeleryTaskMetaAdmin = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  useDocumentTitle('Tasks');

  if (!pulseUser) {
    return <></>;
  }

  if (!isSuperUser(pulseUser.email)) {
    redirect('/');
  }

  return (
    <Layout.Container
      width="100%"
      breadcrumb={[
        {children: 'Admin', to: ROUTE_PATHS.ADMIN},
        {children: 'Tasks', to: ROUTE_PATHS.ADMIN_CELERY_TASKS},
      ]}
    >
      <Suspense fallback={null}>
        <TaskMetaTableView />
      </Suspense>
    </Layout.Container>
  );
};

const TaskMetaTableView = () => {
  const {data: tasks} = useSuspenseQuery({
    queryKey: ['celery_taskmeta'],
    queryFn: getService(CeleryMetataskService).getTasksApiV1CeleryMetataskGet,
  });

  return (
    <Suspense fallback={null}>
      <div>
        <Table columns={columns} data={tasks ?? []}>
          <Table.Header />
          <Table.Body>
            {tasks &&
              tasks.map((task, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Text>{task.id}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.task_id}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.status}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.date_done}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.traceback}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.name}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.worker}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.retries}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.queue}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    </Suspense>
  );
};
