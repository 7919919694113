import {Button} from '@dropbox/dig-components/dist/buttons';
import {Box} from '@dropbox/dig-foundations';

import styles from './EditSaveButtons.module.css';

export const EditSaveButtons = ({
  size = 'medium',
  border = true,
  cta,
  disableSave,
  isLoading,
  handleCancelClick,
  handleSaveClick,
  containerStyleProps = {},
}: {
  cta: string;
  disableSave: boolean;
  isLoading: boolean;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  size?: 'small' | 'medium';
  border?: boolean;
  containerStyleProps?: {[key: string]: any};
}) => {
  return (
    <Box
      className={styles.keyResultActionContainer}
      style={containerStyleProps}
      borderTop={border ? 'Solid' : undefined}
      borderColor="Border Subtle"
      paddingTop={border ? '24' : '0'}
      paddingBottom={border ? '28' : '4'}
      paddingLeft="32"
    >
      <div className={styles.keyResultActionItemContainer}>
        <Button
          variant="opacity"
          size={size}
          fullWidth={false}
          className={styles.cancelButton}
          onClick={handleCancelClick}
          disabled={isLoading}
        >
          {'Cancel'}
        </Button>
        <Button
          isLoading={isLoading}
          variant="primary"
          size={size}
          fullWidth={false}
          className={styles.addKeyResultButton}
          onClick={handleSaveClick}
          disabled={disableSave}
        >
          {cta}
        </Button>
      </div>
    </Box>
  );
};
