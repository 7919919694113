import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ActivityLine, PersonMultipleLine, SearchLine} from '@dropbox/dig-icons/assets';
import DropboxIcon from 'assets/DropboxIcon.svg';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import cx from 'classnames';
import {TeamInfo, TeamInfoFull, TeamWithHierarchy} from 'client';
import {Breadcrumb} from 'components/DSYS/Breadcrumb';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useTeam, useTeamEdit, useTeamsCount, useTeamsTreeSuspense} from 'components/teams/hooks';
import {TeamAuditLog} from 'components/teams/TeamAuditLog';
import {TeamBody} from 'components/teams/TeamBody';
import {sortEmployeesPrimaryFirst, TeamMemberTypeahead} from 'components/teams/TeamModify';
import {TeamsTree} from 'components/teams/TeamsTree';
import {getDefaultTeamsRoute} from 'constant';
import {sortEmployees} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {Suspense, useEffect, useMemo, useRef, useState} from 'react';

import styles from './TeamsView.module.css';

function findTeamPath(teams: TeamWithHierarchy[], teamId: string, path: number[] = []): number[] {
  for (let idx = 0; idx < teams.length; idx++) {
    const team = teams[idx];
    if (team.team_id === teamId) {
      return [...path, idx];
    }
    if (team.children) {
      const result = findTeamPath(team.children, teamId, [...path, idx]);
      if (result?.length) {
        return result;
      }
    }
  }
  return [];
}

function flattenTree(tree: TeamWithHierarchy[]) {
  let result: TeamWithHierarchy[] = [];

  tree.forEach((node) => {
    result.push(node);
    if (node.children) {
      result = result.concat(flattenTree(node.children));
    }
  });

  return result;
}

function findPathToTeam(
  teams: TeamWithHierarchy[],
  targetTeamId: string
): TeamWithHierarchy[] | null {
  for (const team of teams) {
    if (team.team_id === targetTeamId) {
      return [team];
    }

    if (team.children) {
      const path = findPathToTeam(team.children, targetTeamId);
      if (path) {
        return [team, ...path];
      }
    }
  }

  return null;
}

const TeamNav = ({
  team,
  search,
  setSearch,
}: {
  team: TeamInfo;
  search: string;
  setSearch: (search: string) => void;
}) => {
  const teams = useTeamsTreeSuspense();
  const flattenedTree = useMemo(() => flattenTree(teams.children ?? []), [teams]);
  const selectedTeamPath = useMemo(
    () => findTeamPath(teams.children ?? [], team.team_id),
    [teams, team]
  );
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      style={
        isMobile
          ? undefined
          : {
              maxHeight: 'calc(100vh - 241px)',
              overflow: 'auto',
            }
      }
    >
      {search.length ? (
        <>
          {flattenedTree
            .filter(({name}) => name?.toLowerCase().includes(search.toLowerCase()))
            .map(({name, team_id, slug}) => (
              <Link
                to={`/teams/${slug}`}
                key={team_id}
                onClick={() => setSearch('')}
                className={cx(styles.teamsTreeItemContainer, styles.teamsTreeSearchItem)}
                hasNoUnderline
              >
                <Text size="small" variant="paragraph" isBold>
                  {name}
                </Text>
              </Link>
            ))
            .slice(0, 20)}
        </>
      ) : (
        <TeamsTree teams={teams.children ?? []} selectedIndicies={selectedTeamPath} />
      )}
    </Box>
  );
};

const TotalTeamCounts = ({employees}: {employees: number}) => {
  return (
    <Box style={{minHeight: '20px'}}>
      <Text size="small" color="faint">
        {t('people_count_formatted', {count: employees, countString: employees.toLocaleString()})}
      </Text>
    </Box>
  );
};

export const TeamsView = ({team: {slug}}: {team: TeamInfoFull}) => {
  const team = useTeam({slug: slug})!;
  const {isLoading, data} = useTeamsCount();

  console.log({isLoading, data});

  const [search, setSearch] = useState<string>('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState<'audit' | 'members'>();
  const [members, setMembers] = useState(team.employees.sort(sortEmployees));
  const [primaryContact, setPrimaryContact] = useState(team.primary_contact ?? undefined);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);
  const navRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  const {editTeam, isPending} = useTeamEdit();

  useEffect(() => {
    if (!isDrawerOpen) {
      setTimeout(() => {
        setDrawerType(undefined);
      }, 300);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    setMembers(team.employees);
    setPrimaryContact(team.primary_contact ?? undefined);

    setDrawerOpen(false);
    setDrawerType(undefined);
  }, [team.employees, team.primary_contact, team.slug]);

  // const onClick = (path: string) => {
  //   analyticsLogger().logEvent('TEAMS_BREADCRUMB_CLICKED', {path});
  // };

  useEffect(() => {
    if (!navRef.current || !navRef.current.parentElement || !navRef.current.style) {
      return;
    }

    navRef.current.style.width = `${navRef.current.parentElement.offsetWidth}px`;
    window.addEventListener('resize', () => {
      if (!navRef.current) {
        return;
      }

      navRef.current.style.width = `${navRef.current.parentElement!.offsetWidth}px`;
    });
  }, []);

  return (
    <Layout.InlineDrawerContainer
      open={Boolean(isDrawerOpen)}
      drawerHeader={
        <Title size={18}>
          {drawerType === 'audit' ? t('audit_log_title') : t('members_title')}
        </Title>
      }
      drawerIcon={drawerType === 'audit' ? ActivityLine : PersonMultipleLine}
      drawerBody={
        drawerType === 'audit' ? (
          <TeamAuditLog slug={team.slug} />
        ) : drawerType === 'members' ? (
          <TeamMemberTypeahead
            initialSelections={members.sort(sortEmployeesPrimaryFirst(primaryContact?.ldap))}
            initialPrimaryContact={primaryContact}
            isPending={isPending}
            onBeforeSave={async (selections, contact) => {
              try {
                await editTeam({
                  teamId: team?.slug ?? '',
                  data: {
                    parent_team: team?.parent?.team_id,
                    primary_contact: contact?.ldap,
                    employees: selections.map(({user_id}) => user_id),
                    name: team.name,
                    description: team.description,
                  },
                });

                setSnackbarMessage({text: t('saved')});
              } catch (e) {
                setSnackbarMessage({text: t('error_saving')});
              }
            }}
            onSave={(selections, primary) => {
              setPrimaryContact(primary);
              setMembers(selections);
              setDrawerOpen(false);
            }}
            onCancel={() => setDrawerOpen(false)}
          />
        ) : null
      }
      onClose={() => setDrawerOpen(false)}
    >
      <Layout.Nav className={expanded ? atoms({display: 'none'}) : undefined}>
        <Stack gap="12">
          <Box
            as={Link}
            to={getDefaultTeamsRoute(isDropboxOSEnabled)}
            hasNoUnderline
            display="flex"
            paddingBottom="16"
            alignItems="center"
            marginBottom="4"
            marginX="4"
            borderBottom="Solid"
            borderColor="Border Subtle"
            borderWidth="1"
            onClick={() => setSearch('')}
          >
            <img src={DropboxIcon} width="40" />
            <Box marginLeft="12" width="100%">
              {isLoading ? (
                <>
                  <Skeleton.Text width={100} />
                  <Skeleton.Text width={50} />
                </>
              ) : (
                <>
                  <Text size="large" tagName="div" isBold>
                    {data?.rootTeamName}
                  </Text>
                  <TotalTeamCounts employees={data?.employees ?? 0} />
                </>
              )}
            </Box>
          </Box>
          <TextInput
            placeholder={t('select_teams').toString()}
            withLeftAccessory={
              <UIIcon src={SearchLine} className={atoms({color: 'Text Subtle'})} />
            }
            value={search}
            isTransparent
            onChange={(e) => setSearch(e.target.value)}
          />
          <Suspense
            fallback={
              <Box display="flex" flexDirection="row" alignItems="center" padding="4" margin="4">
                <Skeleton.Avatar size="small" />
                <Box marginLeft="8" width="100%">
                  <Skeleton.Text width={200} />
                </Box>
              </Box>
            }
          >
            <TeamNav team={team} search={search} setSearch={setSearch} />
          </Suspense>
        </Stack>
      </Layout.Nav>
      <Layout.Body
        breadcrumb={
          <Suspense
            fallback={
              <Breadcrumb
                path={[{children: 'Teams', to: getDefaultTeamsRoute(isDropboxOSEnabled)}]}
              />
            }
          >
            <TeamBreadcrumb team={team} />
          </Suspense>
        }
      >
        <TeamBody
          expanded={expanded}
          setExpanded={setExpanded}
          team={team}
          handleOpenDrawer={(type) => {
            setDrawerOpen(true);
            setDrawerType(type);
          }}
        />
      </Layout.Body>
    </Layout.InlineDrawerContainer>
  );
};

export const TeamBreadcrumb = ({team}: {team: TeamInfoFull}) => {
  const {isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);
  const teams = useTeamsTreeSuspense();

  // find team in teams
  const selectedTeamInTeams = useMemo(() => {
    return findPathToTeam(teams.children ?? [], team.team_id);
  }, [teams, team]);

  return (
    <Breadcrumb
      path={[
        {children: 'Teams', to: getDefaultTeamsRoute(isDropboxOSEnabled)},
        ...(selectedTeamInTeams ?? []).map(({name, slug}) => ({
          children: name!,
          to: `/teams/${slug}`,
        })),
      ]}
    />
  );
};

// const prefixBreadcrumbs = [
//   {name: t('teams'), path: '/teams', isBold: true},
//   {name: 'Dropbox', path: '/teams'},
// ];

// const formatDashboardsBreadcrumbs = (teamHierarchy: Team[]): BreadcrumbData[] => {
//   return teamHierarchy.map((team) => ({
//     name: team.name!,
//     path: `/teams/${team.slug}`,
//   }));
// };
