import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleFill, CircleStandardLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {EmployeeLdap} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';
import {ROUTE_PATHS} from 'constant';
import {t} from 'i18next';
import {ReactNode} from 'react';

export const TrackerCard = ({
  name,
  id,
  owner,
  workstreams,
  showUpdateButton,
  showAvatar,
  isSelectable,
  selected,
  onSelect,
}: {
  name: ReactNode;
  owner: EmployeeLdap;
  workstreams: number;
  id: string | number;
  showAvatar?: boolean;
  showUpdateButton?: boolean;
  isSelectable?: boolean;
  selected?: boolean;
  onSelect?: (id: number) => void;
}) => (
  <Link to={`/workstreams/${id}`} hasNoUnderline isClickable={!isSelectable && !!id}>
    <Box
      onClick={() => isSelectable && typeof id === 'number' && onSelect?.(id as number)}
      {...withShade({
        state: isSelectable && typeof id !== 'number' ? 'default' : undefined,
        className: atoms({
          cursor: isSelectable && typeof id === 'number' ? 'pointer' : undefined,
          padding: '16',
          borderRadius: 'Medium',
          borderColor: 'Border Subtle',
          borderWidth: '1',
          borderStyle: 'Solid',
        }),
        style: {
          height: 106,
        },
      })}
    >
      <Stack gap="8">
        <LabelGroup
          align="top"
          withText={<Text isBold>{name}</Text>}
          withLeftAccessory={
            showAvatar &&
            (owner?.ldap ? <Avatar size="small" user={owner} /> : <Skeleton.Avatar size="small" />)
          }
          withSubtext={<Text size="xsmall">{t('workstreams_count', {count: workstreams})}</Text>}
        />
        <Box display="flex" justifyContent="flex-end">
          {showUpdateButton && (
            <ButtonLink
              disabled={!workstreams}
              isClickable={Boolean(workstreams)}
              variant="primary"
              to={ROUTE_PATHS.WORKSTREAM_STATUS_UPDATE}
              size="small"
            >
              {t('update_all')}
            </ButtonLink>
          )}
          {isSelectable && (
            <OptionalTooltip
              title={t('workstream_auto_add_tooltip')}
              enabled={typeof id !== 'number'}
            >
              <IconButton variant="borderless" disabled={typeof id !== 'number'}>
                <UIIcon
                  src={selected ? CheckmarkCircleFill : CircleStandardLine}
                  className={atoms({
                    color:
                      typeof id !== 'number'
                        ? 'Disabled Base'
                        : selected
                          ? 'Primary Base'
                          : 'Border Subtle',
                  })}
                />
              </IconButton>
            </OptionalTooltip>
          )}
        </Box>
      </Stack>
    </Box>
  </Link>
);
