import {Checkbox} from '@dropbox/dig-components/controls';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {BACKSPACE_KEY} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, SearchLine} from '@dropbox/dig-icons/assets';
import {derivedShowAIGoalAssistantDrawerAtom} from 'atoms/goals';
import {strategiesByIdAtom, StrategyItem} from 'atoms/strategies';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useRef, useState} from 'react';

interface StrategyConnectSearchProps {
  title: string;
  initialStrategyIds?: string;
  handleStrategiesSelected: (strategies: string) => void;
}

const getStrategyIdToNameMap = (
  strategies: Map<
    number,
    StrategyItem & {
      year: string;
    }
  >
) => {
  const strategyIdToNameMap: Map<number, string> = new Map();
  strategies.forEach((strategy) => {
    if (strategy.year === '2025') {
      strategyIdToNameMap.set(
        strategy.id,
        strategy.parent ? `${strategy.parent.name} — ${strategy.name}` : strategy.name
      );
    }
  });
  return strategyIdToNameMap;
};

export const StrategyConnectSearch = ({
  title,
  initialStrategyIds,
  handleStrategiesSelected,
}: StrategyConnectSearchProps) => {
  const strategies = useAtomValue(strategiesByIdAtom);
  const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);

  const options: number[] = Array.from(getStrategyIdToNameMap(strategies).keys());
  const strategyIdToNameMap: Map<number, string> = getStrategyIdToNameMap(strategies);

  const initialStrategyIdsArray: number[] = initialStrategyIds
    ? initialStrategyIds.split(',').map((id) => parseInt(id, 10))
    : [];

  const initialStrategyNames: string[] = initialStrategyIdsArray.map(
    (id) => strategyIdToNameMap.get(id) as string
  );
  const [chips, setChips] = useState<string[]>(
    initialStrategyIds && initialStrategyIds.length > 0 ? initialStrategyNames : []
  );

  const [selected, setSelected] = useState<number[]>(initialStrategyIdsArray);

  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputContainerRef = useRef<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleSelection = (value: number) => {
    const isChecked = selected.indexOf(value) === -1;
    const newSelected = isChecked
      ? selected.concat(value)
      : selected.filter((item: number) => item !== value);
    setSelected(newSelected);
    handleStrategiesSelected(newSelected.join(','));
    const newChips = isChecked
      ? chips.concat(strategyIdToNameMap.get(value) as string)
      : chips.filter((item: string) => item !== strategyIdToNameMap.get(value));
    setChips(newChips);
    setInputValue('');
    setFilteredOptions(options);
  };

  const renderTypeaheadRow = (result: number) => {
    const isChecked = selected.indexOf(result) !== -1;
    return (
      <Typeahead.Row
        key={result}
        value={result}
        withTitle={<>{strategyIdToNameMap.get(result)}</>}
        role="menuitemcheckbox"
        aria-label={`Option ${result}`}
        aria-checked={isChecked}
        withLeftAccessory={<Checkbox checked={isChecked} readOnly tabIndex={-1} />}
      />
    );
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    if (!e.currentTarget.value) {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter((o) =>
          strategyIdToNameMap
            .get(o)
            ?.toLocaleLowerCase()
            .includes(e.currentTarget.value.toLocaleLowerCase())
        )
      );
    }
  };

  const clearChip = (label: string) => {
    setChips(chips.filter((c) => c !== label));
    const strategyId = Array.from(strategyIdToNameMap.entries()).find(
      ([, name]) => name === label
    )?.[0];
    const newSelected = selected.filter((item: number) => item !== strategyId);
    if (strategyId) {
      setSelected(newSelected);
      handleStrategiesSelected(newSelected.join(','));
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === BACKSPACE_KEY && inputValue === '') {
      const chipValue = chips[chips.length - 1];
      // find strategy id from name
      const strategyId = Array.from(strategyIdToNameMap.entries()).find(
        ([, name]) => name === chipValue
      )?.[0];
      if (strategyId) {
        setSelected(selected.filter((item) => item !== strategyId));
      }
      setChips(chips.slice(0, -1));
    }
  };

  return (
    <FormRow>
      <FormLabel htmlFor="connectedStrategy">{title}</FormLabel>

      <Typeahead.Wrapper
        onSelection={handleSelection}
        closeOnSelection={false}
        onClick={() => setShowAIGoalAssistantDrawer(false)}
        hasMaxHeight={520}
      >
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInput.Container ref={inputContainerRef} size="large">
              <TextInput.Accessory>
                <UIIcon src={SearchLine} />
              </TextInput.Accessory>
              <TextInput.ChipsContainer>
                {!!chips.length &&
                  chips.map((chip, i) => (
                    <Chip key={i} isSelected size="small" onDelete={() => clearChip(chip)}>
                      <Chip.Content>
                        {chip.length > 24 ? chip.substring(0, 24) + '...' : chip}
                      </Chip.Content>
                    </Chip>
                  ))}
                <TextInput.Input
                  placeholder={chips.length ? '' : t('select_strategies').toString()}
                  value={inputValue}
                  {...getTriggerProps({
                    onChange: onInputChange,
                    onKeyDown: handleKeyDown,
                  })}
                />
              </TextInput.ChipsContainer>
              <TextInput.Accessory>
                <UIIcon src={ChevronDownLine} />
              </TextInput.Accessory>
            </TextInput.Container>
            <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
              <Typeahead.Results
                title={t('strategy_plural')}
                results={filteredOptions}
                renderRow={renderTypeaheadRow}
                initialResults={500}
              />
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    </FormRow>
  );
};
