import {Table} from './Table';

// Example data type
type DSYSData = {
  id: string;
  name: string;
  status: string;
  responses: number;
  total: number;
};

// Example columns
const columns = [
  {
    type: 'name',
    // width: 200,
    minWidth: 200,
    accessor: (row: DSYSData) => row.name,
    sort: (a: DSYSData, b: DSYSData) => a.name.localeCompare(b.name),
  },
  {
    type: 'status',
    minWidth: 150,
    accessor: (row: DSYSData) => row.status,
    sort: (a: DSYSData, b: DSYSData) => a.status.localeCompare(b.status),
  },
  {
    type: 'participation',
    minWidth: 180,
    accessor: (row: DSYSData) => {
      const pct = Math.round((row.responses / (row.total || 1)) * 100);
      return `${pct}% (${row.responses} of ${row.total})`;
    },
    sort: (a: DSYSData, b: DSYSData) => a.responses / (a.total || 1) - b.responses / (b.total || 1),
  },
];

// Example data
const data = [
  {id: '1', name: 'Youcef', status: 'Active', responses: 10, total: 20},
  {id: '2', name: 'Kyle', status: 'Inactive', responses: 15, total: 20},
  {id: '3', name: 'Seth', status: 'Active', responses: 5, total: 20},
  {id: '4', name: 'Baochen', status: 'Active', responses: 5, total: 20},
  {id: '5', name: 'Melody', status: 'Active', responses: 5, total: 20},
];

export const DemoTable = () => {
  return (
    <Table columns={columns} data={data}>
      <Table.Header />
      <Table.Body>
        {data.map((row) => (
          <Table.Row key={row.id}>
            <Table.Cell>{columns[0].accessor(row)}</Table.Cell>
            <Table.Cell>{columns[1].accessor(row)}</Table.Cell>
            <Table.Cell>{columns[2].accessor(row)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
