/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotesUpdate } from '../models/NotesUpdate';
import type { Tracker } from '../models/Tracker';
import type { Workstream } from '../models/Workstream';
import type { WorkstreamAddRemove } from '../models/WorkstreamAddRemove';
import type { WorkstreamAssistance } from '../models/WorkstreamAssistance';
import type { WorkstreamAuditLog } from '../models/WorkstreamAuditLog';
import type { WorkstreamCreate } from '../models/WorkstreamCreate';
import type { WorkstreamEdit } from '../models/WorkstreamEdit';
import type { WorkstreamUpdateCreate } from '../models/WorkstreamUpdateCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkstreamService {

    /**
     * Generate
     * @param workstreamId
     * @param comment
     * @returns WorkstreamAssistance Successful Response
     * @throws ApiError
     */
    public static generateApiV1WorkstreamUpdateGenerateGet(
        workstreamId: number,
        comment: string,
    ): CancelablePromise<WorkstreamAssistance> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/update/generate',
            query: {
                'workstream_id': workstreamId,
                'comment': comment,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Status
     * @param employeeId
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static statusApiV1WorkstreamUpdatePost(
        employeeId: string,
        requestBody: WorkstreamUpdateCreate,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream/update',
            query: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createApiV1WorkstreamPost(
        requestBody: WorkstreamCreate,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit
     * @param employeeId
     * @param requestBody
     * @param reason
     * @returns number Successful Response
     * @throws ApiError
     */
    public static editApiV1WorkstreamPut(
        employeeId: string,
        requestBody: WorkstreamEdit,
        reason?: (string | null),
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workstream',
            query: {
                'reason': reason,
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Notes
     * @param id
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static notesApiV1WorkstreamIdNotesPut(
        id: number,
        requestBody: NotesUpdate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workstream/{id}/notes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add
     * @param id
     * @param requestBody
     * @returns WorkstreamAddRemove Successful Response
     * @throws ApiError
     */
    public static addApiV1WorkstreamIdAddPost(
        id: number,
        requestBody: Array<number>,
    ): CancelablePromise<WorkstreamAddRemove> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream/{id}/add',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @param employeeId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static deleteApiV1WorkstreamIdArchivePost(
        id: number,
        employeeId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream/{id}/archive',
            path: {
                'id': id,
            },
            query: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getAllApiV1WorkstreamAllGet(): CancelablePromise<Array<Workstream>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/all',
        });
    }

    /**
     * Get Reports
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getReportsApiV1WorkstreamReportsGet(): CancelablePromise<Array<Workstream>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/reports',
        });
    }

    /**
     * Seed
     * @returns string Successful Response
     * @throws ApiError
     */
    public static seedApiV1WorkstreamSeedGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/seed',
        });
    }

    /**
     * Get Logs
     * @param id
     * @returns WorkstreamAuditLog Successful Response
     * @throws ApiError
     */
    public static getLogsApiV1WorkstreamIdLogsGet(
        id: number,
    ): CancelablePromise<Array<WorkstreamAuditLog>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/{id}/logs',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Trackers
     * @param id
     * @returns Tracker Successful Response
     * @throws ApiError
     */
    public static getTrackersApiV1WorkstreamIdTrackersGet(
        id: number,
    ): CancelablePromise<Array<Tracker>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/{id}/trackers',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param id
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getApiV1WorkstreamIdGet(
        id: (number | string),
    ): CancelablePromise<Workstream> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
