import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Box} from '@dropbox/dig-foundations';
import {Column, Table} from 'components/DSYS/Table';
import styles from 'views/goals_v2/Table.module.css';

export const TableSkeleton = ({
  columnConfigs,
  disableBorders,
}: {
  columnConfigs: Column<any>[];
  disableBorders?: boolean;
}) => (
  <Box maxWidth="100%">
    {disableBorders ? null : <Skeleton.Box width={200} height={24} style={{marginBottom: 4}} />}
    <Table
      columns={columnConfigs}
      data={[]}
      className={disableBorders ? styles.goalTable : undefined}
    >
      <Table.Header />
      <Table.Body>
        <Box style={{height: '100vh'}} />
      </Table.Body>
    </Table>
  </Box>
);
