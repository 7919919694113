import {analyticsLogger} from 'analytics/analyticsLogger';
import {ButtonLink} from 'components/DSYS/Link';
import {addHours, format, isAfter, isBefore} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import {ReactNode} from 'react';

import styles from './Banner.module.css';

interface BannerConfigProps {
  type: 'allhands' | 'focus' | 'qbr' | 'goodbye' | 'aprilfools';
  title: string;
  image?: string;
  imageBackground?: string;
  calendarRegex: string;
  excludeContractor?: boolean;
  isSingleDayEvent?: boolean;
  marqueeText?: string;
  force?: 'before' | 'after' | 'live';
  subtitle?: ReactNode;
  background: string;
}

export class BannerConfig {
  type: 'allhands' | 'focus' | 'qbr' | 'goodbye' | 'aprilfools';
  title: string;
  image?: string;
  imageBackground?: string;
  calendarRegex: string;
  marqueeText?: string;
  subtitle?: ReactNode;
  excludeContractor?: boolean;
  isSingleDayEvent: boolean;
  force?: 'before' | 'after' | 'live';
  background: string;

  constructor(config: BannerConfigProps) {
    this.type = config.type;
    this.title = config.title;
    this.force = config.force;
    this.image = config.image;
    this.imageBackground = config.imageBackground;
    this.marqueeText = config.marqueeText;
    this.subtitle = config.subtitle;
    this.calendarRegex = config.calendarRegex;
    this.background = config.background;
    this.excludeContractor = config.excludeContractor || false;
    this.isSingleDayEvent = config.isSingleDayEvent || false;
  }

  variant = (eventStart: Date) => {
    const now = new Date();
    if (this.force) {
      return this.force;
    }

    if (isBefore(now, eventStart)) {
      return 'before';
    }
    if (isAfter(now, addHours(eventStart, 1))) {
      return 'after';
    }
    return 'live';
  };

  getSubtitle(eventStart: Date) {
    if (this.subtitle) {
      return this.subtitle;
    }

    const startTime = formatInTimeZone(
      eventStart,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      'ha'
    ).toLowerCase();
    const endTime = formatInTimeZone(
      addHours(eventStart, 1),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      'ha'
    ).toLowerCase();
    const date = format(eventStart, 'EEEE, MMMM d');
    const timezone = formatInTimeZone(
      eventStart,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      'zzz'
    );

    const text = {
      before: (
        <>
          Join on {date} from {startTime}-{endTime} {timezone}
        </>
      ),
      after: (
        <>
          If you missed it or want to re-live the action,{' '}
          <ButtonLink
            className={styles.buttonLink}
            variant="transparent"
            to="/news"
            onClick={() =>
              analyticsLogger().logEvent('HOME_BANNER_CLICKED', {action: 'after', type: this.type})
            }
          >
            watch the recording
          </ButtonLink>
        </>
      ),
      live: (
        <>
          <ButtonLink
            variant="transparent"
            to={'https://dropbox.zoom.us/j/97969974319?pwd=OxdFuERdI2TIRjskAqaPAVzidbWCnB.1'}
            onClick={() =>
              analyticsLogger().logEvent('HOME_BANNER_CLICKED', {action: 'live', type: this.type})
            }
            className={styles.buttonLink}
          >
            Join now
          </ButtonLink>{' '}
          to hear the latest around Dropbox
        </>
      ),
    };

    return text[this.variant(eventStart)];
  }

  getMarquee(eventStart: Date) {
    if (this.isSingleDayEvent) {
      return this.marqueeText ?? this.title;
    }

    // based on before/after/live, return the correct text
    const text = {
      before: '$DATE',
      after: 'roll the tape',
      live: 'live now',
    };

    return text[this.variant(eventStart)];
  }
}
