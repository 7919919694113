import {Button} from '@dropbox/dig-components/dist/buttons';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, SearchLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, EmployeeWithDelegates} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {ProfileAvatarName} from 'components/shared/ProfileAvatarName';
import {ROUTE_PATHS} from 'constant';
import {isUserAdmin} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useDelegateService} from './hooks';

export const Delegates = () => {
  const {employees, updateEmployeeDelegate} = useDelegateService();
  const navigate = useNavigate();

  const pulseUser = useAtomValue(pulseUserAtom);

  useDocumentTitle(t('manage_delegation'));

  if (!pulseUser) {
    return null;
  }

  if (!isUserAdmin(pulseUser?.email)) {
    navigate('/');
    return null;
  }

  return (
    <ProjectContributorEditor
      employees={employees}
      updateDelegate={(employee) => updateEmployeeDelegate({employee})}
    />
  );
};

const renderTypeaheadRow = (employee: Employee) => {
  return (
    <Typeahead.Row
      key={employee.user_id}
      value={employee.ldap}
      withTitle={<Text isBold>{employee.name}</Text>}
      withSubtitle={
        <Text size="small" color="faint">
          {employee.role}
        </Text>
      }
      withLeftAccessory={<Avatar user={employee} />}
    />
  );
};

const EmployeeSearch = ({
  isInvalid,
  autoFocus,
  onSelection,
  employees,
}: {
  isInvalid?: boolean;
  autoFocus?: boolean;
  onSelection: (employee: Employee) => void;
  employees: EmployeeWithDelegates[];
}) => {
  const {directReports} = useAtomValue(loggedInEmployeeAtom);
  const [userInputValue, setUserInputValue] = useState('');
  const searchResult = useSearch({input: userInputValue});

  const employeeHints = searchResult.length ? searchResult : directReports;

  const employeeHintsWithoutExisting = employeeHints.filter(
    (e) => !employees.some((e2) => e2.user_id === e.user_id)
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (ldap: string) => {
    const selectedEmployee = employeeHints.find((e) => e.ldap === ldap);
    if (selectedEmployee) {
      onSelection(selectedEmployee);
      setUserInputValue('');
    }
  };

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            isInvalid={isInvalid}
            placeholder="Employee name..."
            autoFocus={autoFocus}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            style={{height: '40px'}}
            spellCheck={false}
            value={userInputValue}
            withRightAccessory={
              <Box as={UIIcon} src={SearchLine} color="Text Subtle" marginTop="16" />
            }
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={50}
              results={employeeHintsWithoutExisting}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};
const emptyContributor = {
  name: '',
  enable_delegation: true,
  delegates: [],
} as any as EmployeeWithDelegates;

export const ProjectContributorEditor = ({
  employees = [],
  updateDelegate,
}: {
  employees?: EmployeeWithDelegates[];
  updateDelegate: (employee: EmployeeWithDelegates) => Promise<EmployeeWithDelegates>;
}) => {
  const [staged, setStagedContributors] = useState<EmployeeWithDelegates[]>(employees);
  const [newEmployeeIds, setNewEmployeeIds] = useState<Set<string>>(new Set());

  const addEmployee = useCallback(() => {
    setStagedContributors((prevStaged) => [emptyContributor, ...prevStaged]);
  }, []);

  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const handleUpdateContributors = async (
    index: number,
    updatedContributor: EmployeeWithDelegates
  ) => {
    setStagedContributors((prevStaged) => [
      ...prevStaged.slice(0, index),
      updatedContributor,
      ...prevStaged.slice(index + 1),
    ]);
  };

  useEffect(() => {
    setStagedContributors((prevStaged) => {
      const updatedStaged = prevStaged.map((stagedEmployee) => {
        const matchingEmployee = employees.find(
          (employee) => employee.name === stagedEmployee.name
        );
        return matchingEmployee
          ? {
              ...matchingEmployee,
              delegates: matchingEmployee.delegates,
            }
          : {...stagedEmployee, delegates: []};
      });

      const newEmployees = employees.filter(
        (employee) => !prevStaged.some((s) => s.name === employee.name)
      );

      return [...updatedStaged, ...newEmployees];
    });
  }, [employees]);

  return (
    <Layout.Container
      breadcrumb={[
        {children: 'Admin', to: ROUTE_PATHS.ADMIN},
        {children: 'Delegates', to: ROUTE_PATHS.ADMIN_DELEGATES},
      ]}
    >
      <Title
        size={24}
        withAccessoryEnd={
          <Button
            onClick={addEmployee}
            variant="outline"
            withIconStart={<UIIcon color="var(--dig-color__text__subtle)" src={AddLine} />}
          >
            <Text color="faint">Add delegation</Text>
          </Button>
        }
      >
        {t('manage_delegation')}
      </Title>

      <Stack paddingTop="24" gap="16">
        {staged
          .sort((a, b) => {
            const aIsNew = newEmployeeIds.has(a.user_id);
            const bIsNew = newEmployeeIds.has(b.user_id);

            if (aIsNew && !bIsNew) return -1;
            if (!aIsNew && bIsNew) return 1;

            return a.name.localeCompare(b.name);
          })
          .map((employee, index) => (
            <Stack key={`delegation-${index}`} gap="4">
              <Split alignY="center">
                <Split.Item width="fill">
                  {employee.name ? (
                    <ProfileAvatarName user={employee} detail={''} source="delegation" />
                  ) : (
                    <Stack paddingBottom="44" gap="4">
                      <Title size="small">New delegation</Title>
                      <EmployeeSearch
                        autoFocus
                        employees={staged}
                        onSelection={(selectedEmployee) => {
                          setNewEmployeeIds((prevIds) =>
                            new Set(prevIds).add(selectedEmployee.user_id)
                          );
                          return handleUpdateContributors(index, {
                            ...selectedEmployee,
                            enable_delegation: true,
                            delegates: [],
                          });
                        }}
                      />
                    </Stack>
                  )}
                </Split.Item>
                {employee.name && (
                  <Split.Item>
                    <Toggle
                      onClick={() =>
                        updateDelegate({
                          ...employee,
                          enable_delegation: !employee.enable_delegation,
                        })
                      }
                      checked={employee.enable_delegation}
                    />
                  </Split.Item>
                )}
              </Split>
              {employee.name && (
                <Box>
                  <PeopleSearchMenu
                    placeholderText="Delegates..."
                    selectedEmployees={employee.delegates}
                    allowSelf
                    onSelectEmployee={(selectedEmployee) => {
                      if (employee.name) {
                        updateDelegate({
                          ...employee,
                          enable_delegation: true,
                          delegates: [...employee.delegates, selectedEmployee],
                        });
                        setSnackbarMessage({text: t('saved')});
                      }
                    }}
                    onSelectEmployees={(selectedEmployees) => {
                      if (employee.name) {
                        updateDelegate({
                          ...employee,
                          enable_delegation: true,
                          delegates: [...employee.delegates, ...selectedEmployees],
                        });
                        setSnackbarMessage({text: t('saved')});
                      }
                    }}
                    onRemoveEmployee={async (employeeToRemove) => {
                      if (employee.name) {
                        updateDelegate({
                          ...employee,
                          enable_delegation: true,
                          delegates: employee.delegates.filter(
                            (delegate) => delegate.user_id !== employeeToRemove.user_id
                          ),
                        });

                        setSnackbarMessage({text: t('saved')});
                      }
                    }}
                  />
                </Box>
              )}
            </Stack>
          ))}
      </Stack>
    </Layout.Container>
  );
};
