// eslint-disable-next-line no-restricted-imports
import {Breadcrumb as DIGBreadcrumb} from '@dropbox/dig-components/breadcrumb';
import {LinkProps as DIGBreadcrumbLinkProps} from '@dropbox/dig-components/dist/breadcrumb/types';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms} from '@dropbox/dig-foundations';

import {Link, LinkProps} from './Link';

export type BreadcrumbLinkProps = Omit<DIGBreadcrumbLinkProps, 'href'> & LinkProps;

// This seems to not work. Maybe something about a ref not being passed from <DIGBreadcrumb.Link> to <Link>?
const BreadcrumbLink = ({
  withLeftIcon,
  withMenuItemIcon,
  isCurrentPath,
  children,
  ...props
}: BreadcrumbLinkProps) => (
  // eslint-disable-next-line no-restricted-syntax
  <DIGBreadcrumb.Link
    isCurrentPath={isCurrentPath}
    withLeftIcon={withLeftIcon}
    withMenuItemIcon={withMenuItemIcon}
    onClick={(e) => e.preventDefault()}
  >
    <Link {...props} to={props.to} hasNoUnderline monochromatic>
      {children}
    </Link>
  </DIGBreadcrumb.Link>
);

const breadcrumbLinkBuilder = (breadcrumbs: BreadcrumbLinkProps[]) =>
  breadcrumbs.map((breadcrumb, index) => (
    <DIGBreadcrumb.Link
      key={`breadcrumb__${breadcrumb.to.toString()}__${index}`}
      isCurrentPath={!index}
      withLeftIcon={breadcrumb.withLeftIcon}
      withMenuItemIcon={breadcrumb.withMenuItemIcon}
      onClick={(e) => e.preventDefault()}
    >
      <Link {...breadcrumb} to={breadcrumb.to} hasNoUnderline monochromatic>
        {!index ? (
          <Text color="subtle" isBold>
            {breadcrumb.children}
          </Text>
        ) : (
          breadcrumb.children
        )}
      </Link>
    </DIGBreadcrumb.Link>
  ));

const Breadcrumb = ({path}: {path: BreadcrumbLinkProps[]}) => (
  <DIGBreadcrumb
    linksDisplayed={3}
    typography="text"
    size="medium"
    isRootOverflow={false}
    className={atoms({marginBottom: '36'})}
  >
    {breadcrumbLinkBuilder(path)}
  </DIGBreadcrumb>
);

export {Breadcrumb, BreadcrumbLink, breadcrumbLinkBuilder, DIGBreadcrumb};
