import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {getPublishedStatuses, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {formatTimeframe} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {getGraphSegments, getStatusGoalCountsData, GoalCountsData} from 'views/dashboards/util';

import styles from './Dashboards.module.css';

interface DashboardsGoalsOverviewGraphProps {
  timeframe: string;
  height: number;
  width: string;
  goalCounts?: {[status: string]: number};
  onClick?: (status: string) => void;
  style?: React.CSSProperties;
  showTooltip?: boolean;
  showInfoOnHover?: boolean;
  isLoading?: boolean;
}

export const DashboardsGoalsOverviewGraph = (props: DashboardsGoalsOverviewGraphProps) => {
  const {height, width, goalCounts, isLoading, style = {}, showTooltip, timeframe} = props;
  const {firstSegmentStatus, lastSegmentStatus} = getGraphSegments(goalCounts);

  const [tooltipGoalsCountData, setTooltipGoalsCountData] = useState<GoalCountsData | undefined>(
    undefined
  );

  useEffect(() => {
    // Whenever goal counts change, reset tooltip data
    setTooltipGoalsCountData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(goalCounts)]);

  const handleMouseMove: React.MouseEventHandler<HTMLElement> = (evt) => {
    const tooltipEl = document.querySelector('#dashboards-goals-overview-tooltip') as HTMLElement;
    let newLeft = evt.clientX + 30;
    const newTop = evt.clientY - 75;
    if (window.innerWidth - newLeft < 350) {
      newLeft = evt.clientX - 200;
    }
    if (tooltipEl) {
      tooltipEl.style.left = `${newLeft}px`;
      tooltipEl.style.top = `${newTop}px`;
    }
  };

  if (isLoading) {
    return <Skeleton.Box style={{height, width}} />;
  }

  return (
    <>
      <Box
        className={styles.dashboardsGoalsOverviewGraph}
        backgroundColor="Background Subtle"
        borderRadius="Medium"
        borderColor="Border Subtle"
        borderStyle="Solid"
        borderWidth="1"
        style={{...style, height, width}}
        onMouseMove={handleMouseMove}
      >
        {getPublishedStatuses().map((status) => {
          return (
            <DashboardsGoalsOverviewSegment
              key={`dashboards-goals-overview-status-segment__${status}`}
              status={status}
              setTooltipGoalsCountData={setTooltipGoalsCountData}
              isFirstSegment={status === firstSegmentStatus}
              isLastSegment={status === lastSegmentStatus}
              {...props}
            />
          );
        })}
        {showTooltip && tooltipGoalsCountData && (
          <Box
            id="dashboards-goals-overview-tooltip"
            className={styles.dashboardsGoalsOverviewTooltip}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{width: '150px'}}
            >
              <Box
                as="div"
                alignItems="flex-start"
                display="flex"
                style={{gap: '4px', marginBottom: '18px'}}
              >
                <StatusButtonIcon status={tooltipGoalsCountData.status} />
                <LabelGroup
                  size="small"
                  withText={
                    <Text size="small" isBold>
                      {t(tooltipGoalsCountData.status)}
                    </Text>
                  }
                  withSubtext={formatTimeframe(timeframe)}
                />
              </Box>
              <Box style={{alignSelf: 'flex-end'}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  style={{gap: '2px'}}
                >
                  <Text style={{fontSize: '23px'}} isBold>
                    {`${tooltipGoalsCountData?.percentageCeil ?? 0}%`}
                  </Text>
                  <Text size="xsmall" color="subtle">
                    {tooltipGoalsCountData
                      ? t('goals_overview_out_of', {
                          statusCount: (tooltipGoalsCountData.statusCount ?? 0).toLocaleString(),
                          totalCount: (tooltipGoalsCountData.totalCount ?? 0).toLocaleString(),
                        })
                      : undefined}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export const DashboardsGoalsOverviewSegment = ({
  status,
  setTooltipGoalsCountData,
  goalCounts,
  onClick,
  height,
  isFirstSegment = false,
  isLastSegment = false,
  showInfoOnHover = false,
}: DashboardsGoalsOverviewGraphProps & {
  status: string;
  setTooltipGoalsCountData: (data: GoalCountsData | undefined) => void;
  isFirstSegment?: boolean;
  isLastSegment?: boolean;
}) => {
  const goalCountsData = getStatusGoalCountsData(status, goalCounts);
  const perc = goalCountsData?.percentageCeil ?? 0;

  const [isHovered, setHovered] = useState(false);

  const cssClasses = [styles.dashboardsGoalsOverviewSegment];
  if (isFirstSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedLeft);
  }
  if (isLastSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedRight);
  }

  const handleMouseEnter: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(true);
    setTooltipGoalsCountData(goalCountsData);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(false);
  };

  return (
    <>
      {isHovered && showInfoOnHover && (
        <Box position="absolute" style={{top: '-21px'}}>
          <Text size="xsmall" color="subtle">{`${perc}% ${t(status)}`}</Text>
        </Box>
      )}
      {!!perc && (
        <Box
          className={cssClasses.join(' ')}
          style={{
            backgroundColor: goalCountsData?.segmentStatusColor,
            height: height - 2, // Subtract 2 for border
            width: `${perc}%`,
          }}
          onClick={() => onClick?.(status)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};
