/* eslint-disable no-param-reassign */
import {$getMarkIDs, $isMarkNode, $unwrapMarkNode} from '@lexical/mark';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {createDOMRange} from '@lexical/selection';
import {mergeRegister} from '@lexical/utils';
import {Thread} from 'client';
import {$wrapSelectionInHighlightNode} from 'components/DSYS/editor/nodes/HighlightNode';
import {
  $createRangeSelection,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  $setSelection,
  LexicalEditor,
  LexicalNode,
  // SELECTION_CHANGE_COMMAND,
  TextNode,
} from 'lexical';
import {useEffect, useRef, useState} from 'react';

import styles from './Comment.module.css';
import {CommentToolbar} from './comments/CommentToolbar';
import {ADD_COMMENT_COMMAND} from './comments/util';

const createRangeSelection = (
  startNode: TextNode,
  startOffset: number,
  endNode: TextNode,
  endOffset: number
) => {
  const selection = $createRangeSelection();
  selection.anchor.set(startNode.getKey(), startOffset, 'text');
  selection.focus.set(endNode.getKey(), endOffset, 'text');
  $setSelection(selection);
  return selection;
};

const getAllTextNodes = (node: LexicalNode): TextNode[] => {
  const textNodes: TextNode[] = [];

  const traverse = (currentNode: LexicalNode) => {
    if ($isTextNode(currentNode)) {
      textNodes.push(currentNode);
    } else if ($isElementNode(currentNode)) {
      currentNode.getChildren().forEach(traverse);
    }
  };

  traverse(node);
  return textNodes;
};

const applyThreadsToEditor = (
  threads: Thread[],
  editor: LexicalEditor,
  activeThreadId?: string
) => {
  editor.update(() => {
    const root = $getRoot();

    // Remove all existing marks
    const removeMarks = (node: LexicalNode) => {
      if ($isMarkNode(node)) {
        $unwrapMarkNode(node);
      } else if ($isElementNode(node)) {
        node.getChildren().forEach(removeMarks);
      }
    };

    removeMarks(root);

    const textNodes = root.getAllTextNodes();

    // Sort threads by starting offset in descending order
    const sortedThreads = [...threads].sort((a, b) => {
      const startA = a.start ?? Number.NEGATIVE_INFINITY;
      const startB = b.start ?? Number.NEGATIVE_INFINITY;
      return startB - startA;
    });

    sortedThreads.forEach((thread) => {
      const {start, end, is_resolved, id} = thread;
      if (!thread.comments?.length || (is_resolved && activeThreadId !== id)) return;

      if (start !== null && end !== null && start < end) {
        let currentOffset = 0;
        let startNode: TextNode | null = null;
        let startOffset = 0;
        let endNode: TextNode | null = null;
        let endOffset = 0;

        for (const node of textNodes) {
          const nodeText = node.getTextContent();
          const nodeLength = nodeText.length;

          const nodeStart = currentOffset;
          const nodeEnd = currentOffset + nodeLength;

          if (start >= nodeStart && start < nodeEnd && !startNode) {
            startNode = node;
            startOffset = start - nodeStart;
          }

          if (end > nodeStart && end <= nodeEnd && !endNode) {
            endNode = node;
            endOffset = end - nodeStart;
          }

          currentOffset += nodeLength;

          if (startNode && endNode) break;
        }

        if (startNode && endNode) {
          const selection = createRangeSelection(startNode, startOffset, endNode, endOffset);

          // Assign a class based on some condition, or use a default
          const markClass = id === activeThreadId ? styles.focused : styles.comment;

          $wrapSelectionInHighlightNode(selection, thread.id, markClass);
        }
      }
    });
  });
};

export const CommentPlugin = ({
  threads,
  activeThreadId,
  setActiveThreadId,
  onAddComment,
}: {
  threads: Thread[];
  activeThreadId?: string;
  setActiveThreadId?: (id: string | undefined) => void;
  onAddComment: {
    (props: {comment: string; threadId: string}): Promise<string>;
    (props: {comment: string; text: string; start: number; end: number}): Promise<string>;
  };
}) => {
  const [editor] = useLexicalComposerContext();
  const [toolbarPosition, setToolbarPosition] = useState<{top: number; left: number} | undefined>(
    undefined
  );

  const selectionHighlightRef = useRef<HTMLDivElement | null>(null);

  const activeThread = threads.find((t) => t.id === activeThreadId);

  // Register the command to add a comment

  const clearTempHighlight = () => {
    if (selectionHighlightRef.current) {
      selectionHighlightRef.current.style.display = 'none';
      selectionHighlightRef.current.innerHTML = '';
    }
  };

  // Update toolbar visibility based on selection
  useEffect(() => {
    const applyMarks = () => {
      if (!threads.length) {
        // setToolbarPosition(undefined);
        return;
      }
      clearTempHighlight();

      editor.update(() => {
        applyThreadsToEditor(threads, editor, activeThreadId);
      });
    };

    const timeout = setTimeout(applyMarks, 50);

    return () => clearTimeout(timeout);
  }, [threads, editor, activeThreadId]);

  useEffect(() => {
    const updateToolbarPosition = () => {
      editor.update(() => {
        if (!activeThread || activeThread.start === null || activeThread.end === null) {
          setToolbarPosition(undefined);
          return;
        }

        const {start, end} = activeThread;
        if (start === null || end === null) {
          setToolbarPosition(undefined);
          return;
        }

        let currentOffset = 0;
        let startNode: TextNode | null = null;
        let startOffset = 0;
        let endNode: TextNode | null = null;
        let endOffset = 0;

        const textNodes = $getRoot().getAllTextNodes();

        for (const node of textNodes) {
          const nodeText = node.getTextContent();
          const nodeLength = nodeText.length;

          const nodeStart = currentOffset;
          const nodeEnd = currentOffset + nodeLength;

          if (start >= nodeStart && start < nodeEnd && !startNode) {
            startNode = node;
            startOffset = start - nodeStart;
          }

          if (end > nodeStart && end <= nodeEnd && !endNode) {
            endNode = node;
            endOffset = end - nodeStart;
          }

          currentOffset += nodeLength;

          if (startNode && endNode) break;
        }

        if (startNode && endNode) {
          const domRange = createDOMRange(editor, startNode, startOffset, endNode, endOffset);

          if (!domRange) {
            setToolbarPosition(undefined);
            return;
          }

          const rect = domRange.getBoundingClientRect();
          if (rect) {
            setToolbarPosition({
              top: rect.bottom + window.scrollY + 10,
              left: rect.left,
            });
          }
        }
      });
    };

    updateToolbarPosition();

    const handleMouseUp = () => {
      if (activeThread) {
        return;
      }

      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const {anchor, focus} = selection;

          const checkForMark = (node: LexicalNode) => {
            if ($isTextNode(node)) {
              const commentIDs = $getMarkIDs(node, anchor.offset);

              return commentIDs && commentIDs.length > 0 ? commentIDs[0] : undefined;
            }
            return undefined;
          };

          const activeId = checkForMark(anchor.getNode()) || checkForMark(focus.getNode());
          if (activeId) {
            setActiveThreadId?.(activeId);
            return;
          }

          if (selection.getTextContent().trim() === '') {
            return;
          }

          const domRange = createDOMRange(
            editor,
            anchor.getNode(),
            anchor.offset,
            focus.getNode(),
            focus.offset
          );

          if (domRange) {
            const boundingRect = domRange.getBoundingClientRect();
            if (boundingRect) {
              const rects = Array.from(domRange.getClientRects());
              const filteredRects = rects.filter(
                (rect) => rect.width > 0 && rect.height > 0 && rect.height <= 18
              );

              if (!selectionHighlightRef.current) {
                selectionHighlightRef.current = document.createElement('div');
                selectionHighlightRef.current.style.display = 'none';
                document.body.appendChild(selectionHighlightRef.current);
              }

              selectionHighlightRef.current.innerHTML = '';

              filteredRects.forEach((rect) => {
                const highlight = document.createElement('span');
                highlight.style.position = 'absolute';
                highlight.style.top = `${rect.top + window.scrollY}px`;
                highlight.style.left = `${rect.left}px`;
                highlight.style.width = `${rect.width}px`;
                highlight.style.height = `${rect.height}px`;
                highlight.classList.add(styles.commenting);

                selectionHighlightRef.current?.appendChild(highlight);
              });

              setToolbarPosition({
                top: boundingRect.bottom + window.scrollY + 10,
                left: boundingRect.left,
              });
            }
          }
        } else {
          setToolbarPosition(undefined);
        }
      });
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      if (selectionHighlightRef.current) {
        document.body.removeChild(selectionHighlightRef.current);
        selectionHighlightRef.current = null;
      }
    };
  }, [editor, setActiveThreadId, activeThread]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        ADD_COMMENT_COMMAND,
        ({comment, threadId}: {comment: string; threadId?: string}) => {
          editor.update(() => {
            const selection = $getSelection();

            if (threadId) {
              onAddComment({comment, threadId});
            } else if ($isRangeSelection(selection)) {
              const root = $getRoot();
              const textNodes = getAllTextNodes(root);

              let currentOffset = 0;
              let startOffset = 0;
              let endOffset = 0;
              let isStartFound = false;
              let isEndFound = false;

              for (const node of textNodes) {
                const nodeText = node.getTextContent();
                const nodeLength = nodeText.length;

                if (!isStartFound && node.getKey() === selection.anchor.key) {
                  startOffset = currentOffset + selection.anchor.offset;
                  isStartFound = true;
                }

                if (!isEndFound && node.getKey() === selection.focus.key) {
                  endOffset = currentOffset + selection.focus.offset;
                  isEndFound = true;
                }

                currentOffset += nodeLength;

                if (isStartFound && isEndFound) break;
              }

              const text = selection.getTextContent();

              const start = Math.min(startOffset, endOffset);
              const end = Math.max(startOffset, endOffset);

              onAddComment({comment, text, start, end}).then((newThreadId) => {
                setActiveThreadId?.(newThreadId);
              });
            }
          });
          return true;
        },
        0
      )
    );
  }, [editor, onAddComment, setActiveThreadId]);

  return (
    <CommentToolbar
      position={toolbarPosition}
      thread={threads.find((comment) => activeThreadId === comment.id)}
      clearHighlight={() => {
        setActiveThreadId?.(undefined);
        clearTempHighlight();
        setToolbarPosition(undefined);
        editor.update(() => {
          $setSelection(null);
        });
      }}
      onFocus={() => {
        if (selectionHighlightRef.current) {
          selectionHighlightRef.current.style.display = 'block';
        }
      }}
    />
  );
};
