import {addWeeks, format, isBefore, setDay} from 'date-fns';

export const toShortDate = (date: Date) =>
  date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});

export function getExpectedDate(day: number) {
  const today = new Date();
  let date = setDay(today, day, {weekStartsOn: 0}); // Week starts on Sunday

  // If the calculated date is before today, move to the next week
  if (isBefore(date, today)) {
    date = addWeeks(date, 1);
  }

  return format(date, 'MMM d');
}

export function getI18nDayName(day: number) {
  const date = setDay(new Date(), day, {weekStartsOn: 1}); // Ensure Monday is 1
  return format(date, 'EEEE');
}
