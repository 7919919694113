import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {useQuery} from '@tanstack/react-query';
import classNames from 'classnames';
import {GratitudeService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {CardGallery} from 'components/gratitiude/CardGallery';
import cardStyles from 'components/gratitiude/CardGallery.module.css';
import imageStyles from 'components/gratitiude/ImageComponent.module.css';
import {LabelText} from 'components/gratitiude/LabelText';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {getService} from 'utilities';

import {Gratitude} from './Gratitude';
import styles from './InboxOutboxView.module.css';

const gratitudeService = getService(GratitudeService);

export const InboxOutboxView = ({mailbox}: {mailbox: 'inbox' | 'outbox'}) => {
  const {data: gratitudes, isLoading} = useQuery({
    queryKey: ['gratitudes', mailbox],
    queryFn:
      mailbox === 'inbox'
        ? gratitudeService.getGratitudesSentToUserApiV1GratitudesReceivedUserIdGet
        : gratitudeService.getGratitudesSentByUserApiV1GratitudesSentUserIdGet,
  });
  useDocumentTitle(t(mailbox));

  return (
    <Layout.Container width="100%">
      <Gratitude
        title={t(mailbox)}
        flavor={
          <LabelText>
            <span className={styles.count}>{isLoading ? '-' : gratitudes?.length}</span>
            <span>
              {t(mailbox === 'outbox' ? 'gratitude_card_to' : 'gratitude_card_from', {
                count: gratitudes?.length,
              })}
            </span>
          </LabelText>
        }
      >
        {isLoading ? (
          <div className={cardStyles.rows}>
            <div className={cardStyles.item}>
              <div className={classNames(imageStyles.image, imageStyles.landscape)}>
                <Skeleton.Box
                  className={imageStyles.skeleton}
                  height={158}
                  style={{
                    height: '213px',
                    width: '300px',
                  }}
                />
              </div>
            </div>
            <div className={cardStyles.item}>
              <div className={classNames(imageStyles.image, imageStyles.landscape)}>
                <Skeleton.Box
                  className={imageStyles.skeleton}
                  height={158}
                  style={{
                    height: '213px',
                    width: '300px',
                  }}
                />
              </div>
            </div>
            <div className={cardStyles.item}>
              <div className={classNames(imageStyles.image, imageStyles.landscape)}>
                <Skeleton.Box
                  className={imageStyles.skeleton}
                  height={158}
                  style={{
                    height: '213px',
                    width: '300px',
                  }}
                />
              </div>
            </div>
            <div className={cardStyles.item}>
              <div className={classNames(imageStyles.image, imageStyles.landscape)}>
                <Skeleton.Box
                  className={imageStyles.skeleton}
                  height={158}
                  style={{
                    height: '213px',
                    width: '300px',
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <CardGallery cards={gratitudes ?? []} mailbox={mailbox} />
        )}
      </Gratitude>
    </Layout.Container>
  );
};
