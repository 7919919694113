import {Badge} from '@dropbox/dig-components/dist/badge';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {NotificationLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {totalNotificationsAtom} from 'atoms/notifications';
import {NotificationService} from 'client';
import {useAtom} from 'jotai';
import {useEffect, useState} from 'react';
import {getService} from 'utilities';

import {NotificationCenter} from './NotificationCenter';

export const NotificationButton = () => {
  const [actionItems, setTotalNotifications] = useAtom(totalNotificationsAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  useEffect(() => {
    const handleFocus = () => setIsWindowFocused(true);
    const handleBlur = () => setIsWindowFocused(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const {data} = useQuery({
    queryKey: ['notifications'],
    queryFn: getService(NotificationService).checkApiV1NotificationsGet,
    refetchInterval: isWindowFocused ? 1000 * 30 : false, // Refetch every 30 seconds when window is focused
    staleTime: 0,
  });

  useEffect(() => {
    if (data) {
      setTotalNotifications(data?.action_items?.length ?? 0);
    }
  }, [data, setTotalNotifications]);

  const allNotifications = (data?.notifications?.length ?? 0) + (data?.action_items?.length ?? 0);

  return (
    <Menu.Wrapper closeOnSelection onToggle={(menu) => setIsOpen(menu.isOpen)}>
      {({getContentProps, getTriggerProps, closeMenu}) => (
        <>
          <Box
            as="button"
            {...getTriggerProps()}
            color={isOpen || allNotifications ? 'Text Base' : 'Text Subtle'}
            backgroundColor={isOpen ? 'Opacity Surface' : 'Background Base'}
            borderStyle="Solid"
            borderColor={isOpen ? 'Opacity Surface' : 'Border Subtle'}
            borderWidth={allNotifications ? '1' : '0'}
            paddingX="4"
            cursor="pointer"
            outlineColor="Focus Ring"
            outlineOffset="1"
            {...withShade({
              direction: 'up',
              style: {borderRadius: 20, paddingTop: 3, paddingBottom: 3},
            })}
          >
            <Box display="flex" alignItems="center">
              <UIIcon src={NotificationLine} />
              {actionItems ? (
                <Box as={Badge} marginRight="2" variant="alert">
                  {actionItems}
                </Box>
              ) : allNotifications ? (
                <Box
                  backgroundColor="Alert Base"
                  marginRight="2"
                  style={{width: 14, height: 14, borderRadius: '100%'}}
                />
              ) : null}
            </Box>
          </Box>

          <Menu.Content
            {...getContentProps()}
            style={{width: 440, maxWidth: '100%', minHeight: 386, height: '100%'}}
          >
            <NotificationCenter handleSelection={() => closeMenu({})} />
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
