import {Layout} from 'components/DSYS/Layout';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {ErrorBoundary} from 'react-error-boundary';
import {useParams} from 'react-router-dom';
import {EditGoalView} from 'views/goals/EditGoalView';

import {NotFound} from './NotFound';

const EditGoal = () => {
  const params = useParams();
  useDocumentTitle(t('edit_goal'));

  const {goalId} = params as {goalId: string};
  if (goalId === undefined) {
    return <NotFound />;
  }

  return (
    <Layout.Container>
      <ErrorBoundary FallbackComponent={NotFound}>
        <EditGoalView goalId={parseInt(goalId, 10)} />
      </ErrorBoundary>
    </Layout.Container>
  );
};

export {EditGoal};
