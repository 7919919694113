import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddLine,
  ArrowRightLine,
  ChevronDownLine,
  ChevronRightLine,
  DeleteLine,
  DragHandleLine,
  FlagLine,
} from '@dropbox/dig-icons/assets';
import {WorkstreamMetricEdit} from 'client';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {t} from 'i18next';
import {useRef, useState} from 'react';

import {MetricLine} from './MetricLine';
import styles from './WorkstreamMetricEditor.module.css';

export type WrappedWorkstreamMetric = WorkstreamMetricEdit & {id: number};

export const MetricEditor = ({
  metrics,
  updateMetrics: updateMetrics,
}: {
  metrics: WrappedWorkstreamMetric[];
  updateMetrics: (metrics: WorkstreamMetricEdit[]) => void; //Dispatch<SetStateAction<WrappedWorkstreamMetric[]>>;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addMetric = (type: 'milestone' | 'metric') =>
    updateMetrics([
      ...metrics,
      {
        id: metrics.length * -1 - 1,
        type,
        title: '',
        order: metrics.length + 1,
        current: null,
        target: null,
      },
    ]);

  const removeMetric = (id: number) =>
    updateMetrics(metrics?.filter((metric) => metric.id !== id) ?? []);

  const handleTextChange = (id: number, update: Partial<WorkstreamMetricEdit>) =>
    updateMetrics(metrics.map((metric) => (metric.id === id ? {...metric, ...update} : metric)));

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (!over || active.id === over.id) return;

    const oldIndex = metrics.findIndex((m) => m.id === active.id);
    const newIndex = metrics.findIndex((m) => m.id === over.id);

    updateMetrics(arrayMove(metrics, oldIndex, newIndex));
  }

  return (
    <Stack gap="8">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={metrics} strategy={verticalListSortingStrategy}>
          {metrics?.map((metric) => (
            <ReorderableMetricRow
              key={`metric-row-${metric.id}`}
              metric={metric}
              autofocus={metric.id < 0}
              removeMetric={removeMetric}
              handleTextChange={handleTextChange}
            />
          ))}
        </SortableContext>
      </DndContext>

      <MetricEditorAdd onClick={addMetric} />
    </Stack>
  );
};

const MetricEditorAdd = ({onClick}: {onClick: (type: 'milestone' | 'metric') => void}) => {
  return (
    <Menu.Wrapper shouldPropagateClickOutsideMouseEvents onSelection={onClick}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant="outline" size="small">
            <UIIcon src={AddLine} className={atoms({color: 'Border Base'})} />
          </IconButton>

          <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-start">
            <Menu.Segment>
              <Menu.ActionItem value="milestone" withLeftAccessory={<UIIcon src={FlagLine} />}>
                {t('milestone')}
              </Menu.ActionItem>
              <Menu.ActionItem value="metric" withLeftAccessory={<UIIcon src={MetricLine} />}>
                {t('metric')}
              </Menu.ActionItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

const ReorderableMetricRow = ({
  autofocus,
  metric,
  removeMetric,
  handleTextChange,
}: {
  autofocus: boolean;
  metric: WrappedWorkstreamMetric;
  removeMetric: (id: number) => void;
  handleTextChange: (id: number, update: Partial<WorkstreamMetricEdit>) => void;
}) => {
  const [hover, setHover] = useState<number | undefined>();
  const {id, title, type, current, target} = metric;

  const focusRef = useRef<HTMLDivElement>(null);

  useOutsideClick(focusRef, () => {
    setExpanded(false);
  });

  const [expanded, setExpanded] = useState(true);

  const {attributes, isSorting, listeners, transform, setNodeRef, transition, isDragging} =
    useSortable({id});

  const style = {
    transition,
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    zIndex: isDragging ? 1 : undefined,
    opacity: isSorting && !isDragging ? 0.7 : 1,
  };

  return (
    <Box
      backgroundColor="Background Base"
      borderRadius="Medium"
      boxShadow={isDragging ? 'Floating' : undefined}
      borderColor="Border Subtle"
      borderWidth="1"
      borderStyle="Solid"
      position="relative"
      ref={setNodeRef}
      style={style}
    >
      <Box
        position="absolute"
        color={isDragging ? 'Border Base' : 'Border Subtle'}
        style={{left: -26, top: 12, cursor: isDragging ? 'grabbing' : 'grab'}}
        {...attributes}
        {...listeners}
        tabIndex={-1}
      >
        <Box as={UIIcon} src={DragHandleLine} size="medium" color="Border Base" />
      </Box>

      <Box ref={focusRef}>
        <Stack onMouseEnter={() => setHover(id)} onMouseLeave={() => setHover(undefined)}>
          <Box
            className={styles.disableBorder}
            borderBottom={expanded && type === 'metric' ? 'Solid' : undefined}
            borderColor="Border Subtle"
          >
            <TextInput
              placeholder={t('start_writing_placeholder')}
              autoFocus={autofocus}
              onFocus={() => setExpanded(true)}
              withLeftAccessory={
                <Split alignY="center">
                  {type === 'metric' && (
                    <Split.Item style={{marginLeft: -8}}>
                      <IconButton variant="transparent" onClick={() => setExpanded(!expanded)}>
                        <UIIcon src={expanded ? ChevronDownLine : ChevronRightLine} />
                      </IconButton>
                    </Split.Item>
                  )}
                  <Split.Item paddingTop="4">
                    <UIIcon
                      className={atoms({color: 'Text Subtle'})}
                      style={{width: 24}}
                      src={type === 'milestone' ? FlagLine : MetricLine}
                    />
                  </Split.Item>
                </Split>
              }
              withRightAccessory={
                hover === id ? (
                  <IconButton
                    shape="circular"
                    variant="borderless"
                    onClick={() => removeMetric(id)}
                  >
                    <UIIcon color="var(--dig-color__border__base)" src={DeleteLine} size="medium" />
                  </IconButton>
                ) : (
                  type === 'metric' && (
                    <Text color="disabled" size="small" style={{whiteSpace: 'nowrap'}}>
                      {target && current ? `${current} / ${target}` : t('workstream_metric_empty')}
                    </Text>
                  )
                )
              }
              value={title}
              onChange={(e) => handleTextChange(id, {title: e.target.value})}
            />
          </Box>
          {expanded && type === 'metric' && (
            <Split alignY="center" gap="8" className={atoms({paddingX: '44', paddingTop: '16'})}>
              <Split.Item width="fill">
                <FormRow>
                  <FormLabel>{t('current')}</FormLabel>
                  <TextInput
                    onFocus={() => setExpanded(true)}
                    placeholder={t('add_value')}
                    value={current ?? ''}
                    onChange={(e) => handleTextChange(id, {current: e.target.value})}
                  />
                </FormRow>
              </Split.Item>
              <Split.Item paddingTop="8">
                <UIIcon src={ArrowRightLine} className={atoms({color: 'Border Subtle'})} />
              </Split.Item>
              <Split.Item width="fill">
                <FormRow>
                  <FormLabel>{t('target')}</FormLabel>
                  <TextInput
                    onFocus={() => setExpanded(true)}
                    placeholder={t('add_value')}
                    value={target ?? ''}
                    onChange={(e) => handleTextChange(id, {target: e.target.value})}
                  />
                </FormRow>
              </Split.Item>
            </Split>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
