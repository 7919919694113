// Instead projects do epics

import {EmployeeProject, Project, ProjectTicket, ProjectTicketShallow} from 'client';
import {DEFAULT_TIMEFRAME} from 'constant';

export function isProject(
  value: ProjectTicket | ProjectTicketShallow | Project | Project[]
): value is Project {
  return (value as Project).available !== undefined;
}

export type ProjectTableFilter = {
  team: string[];
  availability: string[];
  status: string[];
  progress?: number;
  updated: string[];
  search: string;
};

export const getEmptyFilter = (): ProjectTableFilter => ({
  team: [],
  availability: [],
  status: [],
  progress: undefined,
  updated: [],
  search: '',
});

export const getDefaultFilter = (): ProjectTableFilter => ({
  ...getEmptyFilter(),
  availability: [DEFAULT_TIMEFRAME],
});

export type JiraStatus = keyof typeof JIRA_STATUS_MAP;

export const JIRA_STATUS_MAP = {
  Open: 'no_status',
  Triage: 'no_status',
  Backlog: 'no_status',
  'Needs QA': 'on_track',
  'In Progress': 'on_track',
  'In Review': 'on_track',
  Blocked: 'at_risk',
  'On Hold': 'off_track',
  Cancelled: 'cancelled',
  Duplicate: 'cancelled',
  Done: 'complete',
};

const jiraSortOrder = ['off_track', 'at_risk', 'on_track', 'no_status', 'complete', 'cancelled'];

export const sortJIRAStatus = (
  a: ProjectTicketShallow | ProjectTicket | Project,
  b: ProjectTicketShallow | ProjectTicket | Project
) => {
  try {
    if (isProject(a) && isProject(b)) {
      return (a.latest_update?.status ?? '') < (b.latest_update?.status ?? '') ? -1 : 1;
      // jiraSortOrder.indexOf(a.latest_update?.status ?? '') -
      // jiraSortOrder.indexOf(b.latest_update?.status ?? '')
    } else {
      return (
        jiraSortOrder.indexOf(JIRA_STATUS_MAP[(a as ProjectTicket).status as JiraStatus]) -
        jiraSortOrder.indexOf(JIRA_STATUS_MAP[(b as ProjectTicket).status as JiraStatus])
      );
    }
  } catch (e) {
    return 0;
  }
};

const getProgressRatio = ({tickets}: {tickets?: ProjectTicket[]}) => {
  if (!tickets) {
    return {completed: 0, total: 0};
  }
  const completed = tickets.filter((issue: any) =>
    ['Done', 'Cancelled'].includes(issue.status)
  ).length;
  const total = tickets.length;
  return {completed, total};
};

export const calculateTicketProgress = (tickets?: ProjectTicket[]) => {
  const {completed, total} = getProgressRatio({tickets});
  if (total === 0) {
    return 0;
  }
  return Math.ceil((completed / total) * 100);
};

export const calculateProgress = (progress: number = 0, total_tickets?: number) => {
  if (!total_tickets || total_tickets === 0) {
    return 0;
  }
  return Math.ceil((progress / total_tickets) * 100);
};

const roleOrder = ['product', 'engineering', 'design', 'marketing'];
export const sortContributors = (a: EmployeeProject, b: EmployeeProject) => {
  if (a.role === b.role) {
    return a.employee.name.localeCompare(b.employee.name);
  }
  return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role);
};

export const sortTable =
  (sort: any) => (a: ProjectTicket | Project, b: ProjectTicket | Project) => {
    const direction = sort.asc ? 1 : -1;
    if (sort.type === 'name') {
      return direction * a.title.localeCompare(b.title);
    }
    if (sort.type === 'status') {
      return direction * sortJIRAStatus(a, b);
    }
    if (sort.type === 'progress') {
      if (isProject(a) && isProject(b)) {
        // Aggregate progress of all tickets
        const ticketCountA =
          (a.epics?.filter((epic) => epic.type !== 'Epic').length ?? 0) + (a.total_tickets ?? 0);
        const completedCountA =
          (a.epics?.filter((epic) => epic.type !== 'Epic' && epic.status === 'Done').length ?? 0) +
          (a.progress ?? 0);

        const ticketCountB =
          (a.epics?.filter((epic) => epic.type !== 'Epic').length ?? 0) + (b.total_tickets ?? 0);
        const completedCountB =
          (b.epics?.filter((epic) => epic.type !== 'Epic' && epic.status === 'Done').length ?? 0) +
          (b.progress ?? 0);

        if (ticketCountA === 0 && !a.epics?.length) {
          return -1;
        }

        if (ticketCountB === 0 && !b.epics?.length) {
          return 1;
        }

        return (
          direction *
          (calculateProgress(completedCountA, ticketCountA) -
            calculateProgress(completedCountB, ticketCountB))
        );
      }

      return direction;
    }

    if (sort.type === 'update') {
      if (isProject(a) && isProject(b)) {
        return (
          direction *
          (b.latest_update?.updated_at ?? '').localeCompare(a.latest_update?.updated_at ?? '')
        );
      }
    }
    if (sort.type === 'available' && isProject(a) && isProject(b)) {
      // sort nulls to the end for sort.asc
      if (a.available === null) {
        return direction;
      }
      if (b.available === null) {
        return -direction;
      }
      if (a.available === b.available) {
        return direction * sortJIRAStatus(a, b);
      }
      return sort.asc
        ? a.available.localeCompare(b.available)
        : b.available.localeCompare(a.available);
    }

    return 0;
  };

export const filterTable =
  (
    filter: ProjectTableFilter,
    seenUpdates: {
      [projectId: number]: number;
    }
  ) =>
  (data: ProjectTicketShallow | Project) => {
    let show = true;

    if (filter.progress !== undefined) {
      if (isProject(data)) {
        const ticketCount =
          (data.epics?.filter((epic) => epic.type !== 'Epic').length ?? 0) +
          (data.total_tickets ?? 0);
        const completedCount =
          (data.epics?.filter((epic) => epic.type !== 'Epic' && epic.status === 'Done').length ??
            0) + (data.progress ?? 0);

        const percentage = calculateProgress(completedCount, ticketCount);

        show = percentage <= filter.progress;
      } else {
        const percentage = calculateProgress(data.progress ?? 0, data.total_tickets ?? 0);

        show = percentage <= filter.progress;
      }
    }

    if (isProject(data) && filter.team.length) {
      show = show && filter.team.includes(data.team?.name ?? '');
    }

    if ('available' in data && filter.availability.length) {
      const date = new Date(data.available);

      show =
        show &&
        filter.availability.some((value) => {
          if (value.startsWith('Q')) {
            const quarter = parseInt(value.slice(1), 10);
            const thisYear = new Date().getFullYear();
            const quarterStart = new Date(thisYear, (quarter - 1) * 3, 1);
            const quarterEnd = new Date(thisYear, quarter * 3, 0);

            return date >= quarterStart && date <= quarterEnd;
          }
          if (value.startsWith('FY')) {
            const year = parseInt(`20${value.slice(2)}`, 10);
            return date >= new Date(year, 0, 1) && date <= new Date(year, 11, 31);
          }

          return false;
        });
    }

    if (filter.updated.length && isProject(data)) {
      const lastUpdated = new Date(data.latest_update?.updated_at ?? '');
      const now = new Date();
      const days = Math.floor((now.getTime() - lastUpdated.getTime()) / (1000 * 60 * 60 * 24));
      if (filter.updated.includes('filter_update_this')) {
        show = show && days < 7;
      }
      if (filter.updated.includes('filter_update_last')) {
        show = show && days >= 7 && days < 14;
      }
      if (filter.updated.includes('filter_update_stale')) {
        show = (show && days >= 14) || isNaN(days);
      }
      if (filter.updated.includes('filter_update_new')) {
        show = show && seenUpdates?.[data.id] !== data.latest_update?.id;
      }
    }

    if (filter.status.length) {
      if (isProject(data)) {
        if (!filter.status.includes(data.latest_update?.status ?? 'no_status')) {
          show = false;
        }
      } else if (!filter.status.includes(JIRA_STATUS_MAP[data.status as JiraStatus])) {
        show = false;
      }
      if (filter.status.includes('no_status') && !data.total_tickets && show) {
        show = true;
      }
    }

    if (filter.search) {
      show = show && data.title.toLowerCase().includes(filter.search.toLowerCase());
    }

    return show;
  };
