import {useWorkstreamCreate} from './hooks';
import {WorkstreamModify} from './WorkstreamModify';

export const WorkstreamCreate = () => {
  const {createWorkstream, isPending} = useWorkstreamCreate();

  return (
    <WorkstreamModify
      workstream={undefined}
      isPending={isPending}
      onSubmit={({data}) => createWorkstream({data})}
    />
  );
};
