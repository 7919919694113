/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Badge } from '../models/Badge';
import type { BadgeLeaderboard } from '../models/BadgeLeaderboard';
import type { BadgeUpdate } from '../models/BadgeUpdate';
import type { Body_create_badge_api_v1_badges__post } from '../models/Body_create_badge_api_v1_badges__post';
import type { Body_update_badge_image_api_v1_badges__id__image_post } from '../models/Body_update_badge_image_api_v1_badges__id__image_post';
import type { Employee } from '../models/Employee';
import type { ManageBadgeOwners } from '../models/ManageBadgeOwners';
import type { ManageBadgeRecipients } from '../models/ManageBadgeRecipients';
import type { Nomination } from '../models/Nomination';
import type { NominationCreate } from '../models/NominationCreate';
import type { NominationUpdate } from '../models/NominationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BadgeService {

    /**
     * Get Badges
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static getBadgesApiV1BadgesGet(): CancelablePromise<Array<Badge>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/',
        });
    }

    /**
     * Create Badge
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createBadgeApiV1BadgesPost(
        formData: Body_create_badge_api_v1_badges__post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Owned Badges
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static getOwnedBadgesApiV1BadgesOwnedGet(): CancelablePromise<Array<Badge>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/owned',
        });
    }

    /**
     * Get Active Nominations
     * @returns Nomination Successful Response
     * @throws ApiError
     */
    public static getActiveNominationsApiV1BadgesNominationsGet(): CancelablePromise<Record<string, Array<Nomination>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/nominations',
        });
    }

    /**
     * Leaderboard
     * @returns BadgeLeaderboard Successful Response
     * @throws ApiError
     */
    public static leaderboardApiV1BadgesLeaderboardGet(): CancelablePromise<Array<BadgeLeaderboard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/leaderboard',
        });
    }

    /**
     * Update Badge
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBadgeApiV1BadgesIdPost(
        id: number,
        requestBody: BadgeUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Badge
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBadgeApiV1BadgesIdGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Badge Image
     * @param id
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBadgeImageApiV1BadgesIdImagePost(
        id: number,
        formData: Body_update_badge_image_api_v1_badges__id__image_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Nominations
     * @param id
     * @returns Nomination Successful Response
     * @throws ApiError
     */
    public static getNominationsApiV1BadgesIdNominationsGet(
        id: number,
    ): CancelablePromise<Array<Nomination>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/{id}/nominations',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Nomination
     * @param id
     * @param requestBody
     * @returns Nomination Successful Response
     * @throws ApiError
     */
    public static addNominationApiV1BadgesIdNominationsPost(
        id: number,
        requestBody: Array<NominationCreate>,
    ): CancelablePromise<Array<Nomination>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}/nominations',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Nomination
     * @param id
     * @param nominationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateNominationApiV1BadgesIdNominationsNominationIdPost(
        id: number,
        nominationId: number,
        requestBody: NominationUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}/nominations/{nomination_id}',
            path: {
                'id': id,
                'nomination_id': nominationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Award Badge
     * @param id
     * @param requestBody
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static awardBadgeApiV1BadgesIdAwardPost(
        id: number,
        requestBody: ManageBadgeRecipients,
    ): CancelablePromise<Badge> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}/award',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Badge Recipients
     * @param id
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getBadgeRecipientsApiV1BadgesIdRecipientsGet(
        id: number,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/{id}/recipients',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Badge For Recipient
     * @param id
     * @param requestBody
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static removeBadgeForRecipientApiV1BadgesIdRecipientsDelete(
        id: number,
        requestBody: ManageBadgeRecipients,
    ): CancelablePromise<Badge> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/badges{id}/recipients',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Badge Owners
     * @param id
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getBadgeOwnersApiV1BadgesIdOwnersGet(
        id: number,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/{id}/owners',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Owner For Badge
     * @param id
     * @param requestBody
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static addOwnerForBadgeApiV1BadgesIdOwnersPost(
        id: number,
        requestBody: ManageBadgeOwners,
    ): CancelablePromise<Badge> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/badges/{id}/owners',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Owner For Badge
     * @param id
     * @param requestBody
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static removeOwnerForBadgeApiV1BadgesIdOwnersDelete(
        id: number,
        requestBody: ManageBadgeOwners,
    ): CancelablePromise<Badge> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/badges{id}/owners',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Image
     * @param key
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiV1BadgesImagesKeyGet(
        key: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/badges/images/{key}',
            path: {
                'key': key,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
