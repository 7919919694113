import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TeamLine} from '@dropbox/dig-icons/assets';
import {Project, ProjectTicket, ProjectTicketShallow, TeamSlug} from 'client';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Link} from 'components/DSYS/Link';
import {Table} from 'components/DSYS/Table';
import {Title} from 'components/DSYS/Title';
import {useEpicTickets} from 'components/projects/hooks';
import {QuarterCell} from 'components/shared/table/QuarterCell';
import {StatusCell} from 'components/shared/table/StatusCell';
import {UpdateCell} from 'components/shared/table/UpdateCell';
import {Fragment, ReactNode, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isProject, ProjectTableFilter, sortJIRAStatus} from 'views/projects/utils';

import {ProgressCell} from './ProgressCell';
import {ProjectNameCell} from './ProjectNameCell';

const indentWidth: {[index: number]: BoxProps<'div'>['marginLeft']} = {
  0: '0',
  1: '36',
  2: '76',
};

const columns = [
  {type: 'name', minWidth: 220},
  {type: 'avl', fixed: true},
  {type: 'status', fixed: true},
  {type: 'progress', fixed: true},
  {type: 'update', minWidth: 350, width: 350},
];

const RenderIssueRows = ({
  projectId,
  epicKey,
  expanded,
  tickets,
  rowType,
  indent,
  paddingLeft,
  onUpdatesClick,
  onStatusClick,
  onClick,
  tableWidth,
}: {
  projectId: number;
  epicKey?: string;
  expanded: {[key: string]: boolean};
  tickets: ProjectTicketShallow[] | ProjectTicket[] | Project[];
  rowType: 'current work';
  indent: keyof typeof indentWidth;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  onUpdatesClick?: (id: number) => void;
  onStatusClick?: (id: number) => void;
  onClick?: (id: number) => void;
  tableWidth: number;
}) => {
  return (
    <>
      {tickets
        .sort((a, b) => a.created_at.localeCompare(b.created_at))
        .sort(sortJIRAStatus)
        .map((epic) => (
          <ProjectTableRow
            key={epic.id}
            projectId={projectId}
            {...epic}
            tableWidth={tableWidth}
            expanded={expanded}
            indent={indent}
            paddingLeft={paddingLeft}
            rowType={rowType}
            onClick={onClick}
            onUpdatesClick={onUpdatesClick}
            onStatusClick={onStatusClick}
          />
        ))}
      {tickets.length === 0 && epicKey && (
        <Box marginLeft={paddingLeft === '12' ? '20' : '8'}>
          <Box marginLeft={indentWidth[indent]} width="100%" display="block" paddingLeft="16">
            <Text tagName="div" color="faint" isBold>
              Nothing found!
            </Text>

            <Text color="faint" size="small">
              Visit{' '}
              <Link to={`https://jira.dropboxer.net/browse/${epicKey}`}>
                <Text color="faint" isBold size="small">
                  JIRA
                </Text>
              </Link>{' '}
              to create tickets
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

const ProjectTableRow = ({
  tableWidth,
  isGroupedTable,
  uselessTable,
  expanded,
  withLeftAccessory,
  onUpdatesClick,
  onStatusClick,
  indent = 0,
  projectId,
  id,
  expandId,
  rowType,
  title,
  setExpanded,
  onClick,
  paddingLeft,
  ...rest
}: (ProjectTicketShallow | ProjectTicket | Project) & {
  tableWidth: number;
  projectId: number;
  isGroupedTable?: boolean;
  uselessTable?: boolean;
  expandId?: string;
  expanded: {[key: string]: boolean};
  editable?: boolean;
  withLeftAccessory?: ReactNode;
  onUpdatesClick?: (id: number) => void;
  onStatusClick?: (id: number) => void;
  setExpanded?: (value: React.SetStateAction<{[id: string]: boolean}>) => void;
  indent?: number;
  onClick?: (id: number) => void;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  rowType?: 'current work';
}) => {
  const isProjectRow = isProject(rest as Project);
  const {tickets, isLoading} = useEpicTickets({
    id,
    enabled: Boolean(!isProjectRow && expanded[`epic-${projectId}-${id}`]),
  });

  let allNonEpics: ProjectTicketShallow[] = [];
  let allEpics: ProjectTicketShallow[] = [];
  if (isProjectRow) {
    allNonEpics = (rest as Project).epics?.filter((epic) => epic.type !== 'Epic') ?? [];
    allEpics = (rest as Project).epics?.filter((epic) => epic.type === 'Epic') ?? [];
  }

  const {marginLeft} = useMemo(() => {
    const left = parseInt(indentWidth[indent] as any, 10);

    return {
      marginLeft: left.toString() as any,
      // width: uselessTable
      //   ? columnConfigs[0].width + 34
      //   : columnConfigs[0].width - left - (indent === 1 ? 24 : 0) - (indent === 2 ? -72 : 0),
    };
  }, [indent]);

  return (
    <Fragment key={id}>
      <Box as={Table.Row} onClick={() => onClick?.(id)} isSelectable={Boolean(onClick)}>
        <ProjectNameCell
          uselessTable={uselessTable}
          withLeftAccessory={withLeftAccessory}
          isGroupedTable={isGroupedTable}
          hasAssignee
          // width={width}
          indent={indent}
          jira={'jira' in rest ? rest.jira : undefined}
          title={title}
          assignee={'assignee' in rest ? (rest.assignee ?? undefined) : undefined}
          type={'type' in rest ? rest.type : undefined}
          subtitle={'subtitle' in rest ? rest.subtitle : undefined}
          marginLeft={marginLeft}
          paddingLeft={paddingLeft}
        />
        <QuarterCell
          timeframe={('available' in rest ? rest.available : undefined) ?? undefined}
          // width={columnConfigs[1].width}
        />
        <StatusCell
          status={'status' in rest ? rest.status : undefined}
          onClick={() => onStatusClick?.(id)}
          // width={columnConfigs[2].width}
          projectId={'type' in rest ? undefined : id}
        />
        <ProgressCell
          projectId={id}
          isProject={isProjectRow}
          epics={'epics' in rest ? (rest.epics ?? []) : []}
          progress={'progress' in rest ? (rest.progress ?? undefined) : undefined}
          total_tickets={'total_tickets' in rest ? (rest.total_tickets ?? undefined) : undefined}
          // width={columnConfigs[3].width}
        />
        <UpdateCell
          projectId={'type' in rest ? undefined : id}
          onClick={() => onUpdatesClick?.(id)}
          // width={columnConfigs[4].width}
        />
      </Box>
      {expandId && expanded[expandId] && (
        <>
          {allNonEpics
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .sort(sortJIRAStatus)
            .map((epic) => (
              <ProjectTableRow
                key={epic.id}
                projectId={projectId}
                {...epic}
                tableWidth={tableWidth}
                expanded={expanded}
                indent={indent + (allEpics.length ? 2 : 1)}
                uselessTable
                isGroupedTable={isGroupedTable}
                rowType={rowType}
                onUpdatesClick={onUpdatesClick}
                onStatusClick={onStatusClick}
                paddingLeft={paddingLeft}
              />
            ))}
          {'type' in rest ? (
            isLoading ? (
              <>
                <Box
                  as="div"
                  marginLeft={indentWidth[indent + 1]}
                  marginBottom="2"
                  paddingLeft={paddingLeft === '12' ? '20' : '8'}
                  style={{height: 60}}
                >
                  <Skeleton.Box />
                </Box>
              </>
            ) : (
              <RenderIssueRows
                projectId={projectId}
                epicKey={'jira' in rest && 'type' in rest && rest.type === 'Epic' ? rest.jira : ''}
                expanded={expanded}
                paddingLeft={paddingLeft}
                tickets={tickets ?? []}
                indent={indent + 1}
                tableWidth={tableWidth}
                rowType="current work"
              />
            )
          ) : (
            ('epics' in rest ? allEpics : tickets)?.map((ticket) => (
              <ProjectTableRow
                projectId={projectId}
                key={`epic-${id}-${ticket.id}`}
                tickets={'tickets' in ticket ? ticket.tickets : []}
                {...ticket}
                tableWidth={tableWidth}
                id={ticket.id}
                expandId={`epic-${id}-${ticket.id}`}
                paddingLeft={paddingLeft}
                isGroupedTable={isGroupedTable}
                withLeftAccessory={
                  <Box
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setExpanded?.({
                        ...expanded,
                        [`epic-${id}-${ticket.id}`]: !expanded[`epic-${id}-${ticket.id}`],
                      });
                    }}
                    style={{height: 44}}
                  >
                    <AccordionIconButton
                      isHidden={
                        ('tickets' in ticket ? ticket.tickets : [])?.length === 0 &&
                        ticket.type !== 'Epic'
                      }
                      isActive={expanded[`epic-${id}-${ticket.id}`]}
                      toggle={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded?.({
                          ...expanded,
                          [`epic-${id}-${ticket.id}`]: !expanded[`epic-${id}-${ticket.id}`],
                        });
                      }}
                    />
                  </Box>
                }
                expanded={expanded}
                indent={1}
              />
            ))
          )}
        </>
      )}
    </Fragment>
  );
};

export const ProjectTable = ({
  isLoading,
  isProjectTable,
  hideTeamHeader,
  team,
  data,
  onUpdatesClick,
  onStatusClick,
}: {
  isLoading?: boolean;
  noResults?: boolean;
  isProjectTable?: boolean;
  hideTeamHeader?: boolean;
  team?: TeamSlug;
  data: Project[];
  filter: ProjectTableFilter;
  onUpdatesClick: (id: number) => void;
  onStatusClick: (id: number) => void;
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<{[id: string]: boolean}>({});

  const [tableWidth, setTableWidth] = useState(0);

  const groupedProjects = useMemo(
    () =>
      data.reduce(
        (acc, project) => {
          // Group by team
          const key = project.team?.name ?? '—';
          if (!acc[key]) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = [];
          }
          acc[key].push(project);

          return acc;
        },
        {} as Record<string, Project[]>
      ),
    [data]
  );

  const sortedProjects = useMemo(() => {
    return Object.entries(groupedProjects).sort(([a], [b]) => a.localeCompare(b));
  }, [groupedProjects]);

  const uselessTable = data.every((project) => !project.epics?.length);

  // table width

  useEffect(() => {
    setTableWidth(window.innerWidth);

    const handleResize = () => {
      setTableWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setExpanded(
      isProjectTable && data.length === 1
        ? data[0].epics?.length === 1
          ? {
              [`project-${data[0].id}`]: true,
              [`epic-${data[0].id}-${data[0].epics[0].id}`]: true,
            }
          : {[`project-${data[0].id}`]: true}
        : {}
    );
  }, [data, isProjectTable]);

  // const tableWidth = 270 + columnConfigs.reduce((acc, {width}) => acc + width, 0);
  const isGroupedTable = !isProjectTable && Object.values(groupedProjects).length > 1;

  if (!Object.values(groupedProjects).length) {
    return null;
  }

  return (
    <Box as="div" maxWidth="100%" marginX="auto">
      {!hideTeamHeader && team ? (
        <Box
          as={Title}
          id={team.slug ?? undefined}
          size={18}
          cursor="pointer"
          paddingTop="4"
          paddingBottom="12"
          display="block"
          onClick={() => navigate(`/projects/${team.slug}`, {state: {source: 'header'}})}
        >
          {team.name}
        </Box>
      ) : null}

      <Table columns={columns} data={data}>
        <Table.Header />
        <Table.Body>
          {sortedProjects.map(([, projects]) => {
            // const filteredSortedTable = projects
            //   .filter(!isProjectTable ? filterTable(filter, seenUpdates) : () => true)
            //   .sort(sortTable(sort));

            if (!projects.length) {
              return null;
            }

            const projectTeam = projects[0].team;

            return (
              <>
                {isGroupedTable && (
                  <>
                    <Table.Row>
                      <Table.Cell
                        colSpan={columns.length}
                        className={atoms({
                          backgroundColor: 'Primary Surface',
                        })}
                      >
                        <Box display="flex" marginY="8" alignItems="center" style={{gap: 4}}>
                          <Box as={UIIcon} src={TeamLine} size="small" color="Text Subtle" />
                          <Box
                            as={Eyebrow}
                            id={projectTeam?.slug ?? undefined}
                            cursor="pointer"
                            onClick={() =>
                              navigate(`/projects/${projectTeam?.slug}`, {
                                state: {source: 'header'},
                              })
                            }
                            color="Text Subtle"
                          >
                            {projectTeam?.name ?? '—'}
                          </Box>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
                {projects.map((row) => (
                  <>
                    <ProjectTableRow
                      {...row}
                      tableWidth={tableWidth}
                      projectId={row.id}
                      uselessTable={uselessTable}
                      key={`project-${row.id}`}
                      expandId={`project-${row.id}`}
                      id={row.id}
                      expanded={expanded}
                      isGroupedTable={isGroupedTable}
                      setExpanded={setExpanded}
                      onClick={
                        isLoading
                          ? undefined
                          : (id) => navigate(`/projects/${id}`, {state: {source: 'row'}})
                      }
                      editable={isProject(row)}
                      onUpdatesClick={onUpdatesClick}
                      onStatusClick={onStatusClick}
                      paddingLeft="0"
                      withLeftAccessory={
                        <Box
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setExpanded({
                              ...expanded,
                              [`project-${row.id}`]: !expanded[`project-${row.id}`],
                            });
                          }}
                          style={{height: 44}}
                        >
                          <AccordionIconButton
                            isHidden={(row.epics?.length ?? 0) === 0}
                            isActive={expanded[`project-${row.id}`]}
                            toggle={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setExpanded({
                                ...expanded,
                                [`project-${row.id}`]: !expanded[`project-${row.id}`],
                              });
                            }}
                          />
                        </Box>
                      }
                    />
                  </>
                ))}
              </>
            );
          })}

          {/* <Table.Row isSelectable={false}>
            <CreateCell
              tooltip={(isProjectTable ? data[0] : undefined) ? 'Add epic' : 'Add project'}
              to={
                (isProjectTable ? data[0] : undefined)
                  ? ROUTE_PATHS.PROJECT_EDIT.replace(/:identifier/, data[0].id.toString())
                  : ROUTE_PATHS.PROJECT_NEW
              }
              state={{
                team_id: team.team_id,
                autofocus: (isProjectTable ? data[0] : undefined) ? 'epics' : undefined,
              }}
              marginLeft="0"
            />
          </Table.Row> */}
        </Table.Body>
      </Table>
    </Box>
  );
};
