import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {pulseUserAtom} from 'atoms/auth';
import {App} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Table} from 'components/DSYS/Table';
import {LabelGroupCell, LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {ROUTE_PATHS} from 'constant';
import {isSuperUser} from 'helpers/utils';
import {useAtomValue} from 'jotai';

import styles from './ExternalApp.module.css';
import {useExternalAppService} from './hooks';

const DSYSTableRow = ({name, appKey, allowed_ips, permissions}: App & {appKey: string}) => (
  <Table.Row isSelectable className={styles.row}>
    <LabelGroupCell
      isBold
      text={name}
      subText={
        <Text size="small" color="subtle" className={styles.key}>
          {appKey}
        </Text>
      }
    />
    <LabelGroupSubtleCell text={permissions?.join(',')} />
    <LabelGroupSubtleCell text={allowed_ips?.join(',')} />
  </Table.Row>
);

const columns = [
  {type: 'name', minWidth: 470},
  {type: 'permissions', minWidth: 140},
  {type: 'allowed ips', short: 'ips', minWidth: 100},
];

export const ExternalApp = () => {
  const {apps, createApp} = useExternalAppService();

  const handleCreateApp = async () => {
    await createApp({name: 'Dropbox Directory', permissions: ['teams.readonly']});
  };

  const user = useAtomValue(pulseUserAtom);
  if (!user || (user && !isSuperUser(user.email))) {
    return null;
  }

  return (
    <Layout.Container
      breadcrumb={[
        {children: 'Admin', to: ROUTE_PATHS.ADMIN},
        {children: 'Apps', to: ROUTE_PATHS.ADMIN_APPS},
      ]}
    >
      <Button variant="outline" onClick={handleCreateApp} style={{display: 'none'}}>
        Create Dropbox Directory App
      </Button>

      <Table columns={columns} data={apps ?? []}>
        <Table.Header />
        <Table.Body>
          {apps?.map(({key: appKey, ...app}) => (
            <DSYSTableRow key={appKey} appKey={appKey} {...app} />
          ))}
        </Table.Body>
      </Table>
    </Layout.Container>
  );
};
