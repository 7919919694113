import {Button} from '@dropbox/dig-components/dist/buttons';
import {WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Box, Split} from '@dropbox/dig-foundations';
import {useQueryClient} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {showNewBannerAtom, showNewDraftGoalBannerAtom} from 'atoms/goals';
import {totalNotificationsAtom} from 'atoms/notifications';
import {snackbarAtom} from 'atoms/snackbar';
import {GoalData, Team} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useGoalService, useKeyResultService} from 'components/goals/hooks';
import {TableSkeleton} from 'components/shared/table/TableSkeleton';
import {getNextQuarter, isCurrentQuarter, parseTimeframe} from 'components/shared/TimeAgo';
import {DEFAULT_TIMEFRAME, ROUTE_PATHS, STEAM_TEAM_SLUG} from 'constant';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useLoaderData, useLocation, useNavigate, useParams} from 'react-router-dom';
import {emailToLdap} from 'utilities';

import {GoalsV2Drawer} from './Drawers/GoalsV2Drawer';
import {SetGoalsV2DrawerDataType} from './Drawers/types';
import {shouldCloseAndResetDrawers} from './Drawers/utils';
import {GoalsHeader, GoalsHeaderSkeleton} from './GoalsHeader';
import {QuickList, QuickListSkeleton} from './QuickList';
import {CompanyGoalsTableView} from './TableViews/CompanyGoalsTableView';
import {DirectReportsGoalsTableView} from './TableViews/DirectReportsGoalsTableView';
import {EmployeesGoalsTableView} from './TableViews/EmployeesGoalsTableView';
import {OtherGoalsTableView} from './TableViews/OtherGoalsTableView';
import {TeamsGoalsTableView} from './TableViews/TeamsGoalsTableView';
import {
  ContinueGoalData,
  DrawerGoalData,
  GoalsHeaderData,
  GoalsTableFilter,
  GoalsTablePropsWithoutData,
  SelectedKey,
} from './types';
import {
  createUrlFromFilter,
  getBrowsePath,
  getCurrentUrl,
  getEmptyFilter,
  getEmptyPlaceholderTeam,
  getFilterData,
  getFiltersFromUrl,
  getGoalDataFromPath,
  getSelectedQuickFilterFromUrl,
  hasFiltersSelected,
  hasPrimaryFiltersSelected,
  initialQuickFilterSelected,
} from './utils';

export const GoalsView = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const {goalid: goalIdFromPath, keyresultid: keyResultIdFromPath} = params;

  const props = useLoaderData() as GoalData | undefined;
  const goalOwnerLdap = props ? emailToLdap(props.users?.[0].email) : undefined;
  const goalTimeframe = props?.timeframe;
  const contributorLdapsFromPath =
    props?.key_results
      .flat()
      .map((kr) => kr.contributors.map((c) => c.ldap))
      .flat() ?? [];

  const location = useLocation();
  const urlPath = ROUTE_PATHS.GOALS;
  const urlParams = new URLSearchParams(location.search);
  const goalDataFromPath = getGoalDataFromPath(
    goalIdFromPath,
    keyResultIdFromPath,
    !!props?.is_draft
  );
  const notificationCount = useAtomValue(totalNotificationsAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const [showRequestEdit, setShowRequestEdit] = useState(Boolean(urlParams.get('requestedit')));
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrolledToRow, setScrolledToRow] = useState(!goalDataFromPath && !keyResultIdFromPath);
  const [shouldOpenFilter, setShouldOpenFilter] = useState(false);

  const {reportingLine, employeeTeams, directReports, delegatedBy} =
    useAtomValue(loggedInEmployeeAtom);
  const user = useAtomValue(pulseUserAtom);
  const {userLdap, managerLdap} = getFilterData(user, reportingLine);

  const [goalData, setGoalData] = useState<undefined | DrawerGoalData>(goalDataFromPath);
  const [goalsV2DrawerData, setGoalsV2DrawerData] = useState<SetGoalsV2DrawerDataType>({
    drawerType: 'goal',
    isOpen: false,
    source: '',
  });
  const [continueGoalData, setContinueGoalData] = useState<ContinueGoalData>({goalData: null});

  const setShowNewBanner = useSetAtom(showNewBannerAtom);
  const setShowNewDraftBanner = useSetAtom(showNewDraftGoalBannerAtom);

  const selectedGoalData = goalData;

  const [goalsHeaderData, setGoalsHeaderData] = useState<GoalsHeaderData | undefined>(undefined);
  const [quickFilterSelected, setQuickFilterSelected] = useState<SelectedKey | undefined>(
    undefined
  );

  const pageTimeframe =
    urlParams.get('t') ?? location.state?.timeframe ?? goalTimeframe ?? DEFAULT_TIMEFRAME;
  const [timeframe, setTimeframe] = useState<string>(pageTimeframe);
  const [nextTimeframe, setNextTimeframe] = useState<string>(getNextQuarter(timeframe));
  const [filter, setFilter] = useState<GoalsTableFilter>(
    getFiltersFromUrl(goalIdFromPath, keyResultIdFromPath, goalOwnerLdap)
  );

  const {continueGoal} = useGoalService({timeframe: nextTimeframe});
  const delegatedByLdaps = delegatedBy?.map((d) => d.ldap) ?? [];
  const {continueKeyResult} = useKeyResultService({
    timeframe: nextTimeframe,
    ownerLdaps: [userLdap, ...delegatedByLdaps],
  });

  const navigate = useNavigate();
  const handleSetFilter = useCallback(
    (
      newFilter: GoalsTableFilter,
      shouldNotUpdateUrl: boolean = false,
      shouldResetGoalData: boolean = false
    ) => {
      setFilter(newFilter);
      if (!shouldNotUpdateUrl) {
        const urlTimeframe = isCurrentQuarter(timeframe) ? undefined : timeframe;
        const newGoalData = shouldResetGoalData ? undefined : goalData;
        const currentUrl = getCurrentUrl(location);
        const newUrl = createUrlFromFilter(userLdap, newFilter, newGoalData, urlTimeframe);
        if (newUrl !== currentUrl) {
          navigate(newUrl, {preventScrollReset: true});
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(goalData), timeframe, userLdap, location.search]
  );

  // render title: if goalIdFromPath or keyResultIdFromPath is present, use the title from drawer
  useEffect(() => {
    if (goalIdFromPath || keyResultIdFromPath) {
      return;
    }
    document.title =
      notificationCount > 0
        ? `(${notificationCount}) ${t('goals')} - ${t('dropbox_os')}`
        : t('goals') + ' - ' + t('dropbox_os');
  }, [goalIdFromPath, keyResultIdFromPath, notificationCount]);

  // Used to navigate a goal search result, notification or drawer click to /goals + open goal details drawer
  useEffect(() => {
    if (location.state?.source === 'notification' && user) {
      if (goalDataFromPath?.goalId) {
        queryClient.invalidateQueries({queryKey: ['goals', userLdap]});
        queryClient.invalidateQueries({queryKey: ['goal', goalDataFromPath.goalId]});
      }
      setQuickFilterSelected(initialQuickFilterSelected);
      const peopleFromUrl = urlParams.get('people')?.toLowerCase()?.split(',') || [];
      const isContributor = contributorLdapsFromPath.includes(userLdap);
      setFilter({
        ...getEmptyFilter(),
        people: peopleFromUrl.length
          ? peopleFromUrl
          : !isContributor && goalOwnerLdap
            ? [goalOwnerLdap]
            : [emailToLdap(user.email)],
      });
      setGoalData(goalDataFromPath);
      setTimeframe(pageTimeframe);
      setScrolledToRow(false);
    } else if (
      ['search', 'drawer'].includes(location.state?.source) &&
      goalOwnerLdap &&
      goalDataFromPath &&
      (goalDataFromPath.goalId !== goalData?.goalId ||
        goalDataFromPath.keyResultId !== goalData?.keyResultId)
    ) {
      setQuickFilterSelected('others');
      setFilter({...getEmptyFilter(), people: [goalOwnerLdap]});
      setGoalData(goalDataFromPath);
      setTimeframe(goalTimeframe ?? DEFAULT_TIMEFRAME);
      setScrolledToRow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.source, goalDataFromPath?.goalId]);

  // Used to update selected goal data when URL changes
  useEffect(() => {
    if (
      goalDataFromPath?.goalId !== goalData?.goalId ||
      goalDataFromPath?.keyResultId !== goalData?.keyResultId
    ) {
      setGoalData(goalDataFromPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalDataFromPath?.goalId, goalDataFromPath?.keyResultId]);

  // Used to update filter when status or last updated changes in URL (e.g. via back/forward browser button)
  useEffect(() => {
    const newFilter = getFiltersFromUrl(goalIdFromPath, keyResultIdFromPath, goalOwnerLdap);
    const newStatusFilter = newFilter.status.sort();
    const newUpdatedFilter = newFilter.updated.sort();
    if (
      JSON.stringify(newStatusFilter) !== JSON.stringify(filter.status.sort()) ||
      JSON.stringify(newUpdatedFilter) !== JSON.stringify(filter.updated.sort())
    ) {
      setFilter(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, JSON.stringify(filter)]);

  useEffect(() => {
    if (!user) {
      return; // wait for current user to load
    }

    // If URL has no primary filters selected, default to my goals table
    const hasNoFiltersSelected = !hasPrimaryFiltersSelected(filter) && !goalDataFromPath?.goalId;
    if (location.state?.resetView || hasNoFiltersSelected) {
      setQuickFilterSelected(initialQuickFilterSelected);
      const prevFilter = location.state?.resetView ? getEmptyFilter() : filter;
      setFilter({...prevFilter, people: [user.email.split('@')[0]]});
      setGoalData(undefined);
      setTimeframe(pageTimeframe);
      handleCloseAndResetDrawers(); // reset drawers
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, location.state?.resetView]);

  useEffect(() => {
    if (!(user?.email && employeeTeams && managerLdap)) {
      return; // wait for employee info to load
    }

    // Update quick filters based on URL
    const quickFiltersFromUrl = getSelectedQuickFilterFromUrl(
      userLdap,
      managerLdap,
      employeeTeams,
      goalIdFromPath,
      keyResultIdFromPath,
      goalOwnerLdap
    );
    setQuickFilterSelected(quickFiltersFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, employeeTeams?.length, managerLdap]);

  useEffect(() => {
    // Toggle goal details drawer based on URL or notification
    if (goalData?.goalId && !goalsV2DrawerData.isOpen) {
      setGoalsV2DrawerData({
        drawerType: 'goal',
        isOpen: true,
        source: location.state?.source ?? 'url',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalData?.goalId, location.state?.source]);

  const handleQuickFilterSelected = (
    selectedItem: SelectedKey,
    newFilter?: GoalsTableFilter,
    openFilter?: boolean
  ) => {
    analyticsLogger().logEvent('GOAL_TABLE_QUICK_FILTER', {type: selectedItem});
    handleCloseAndResetDrawers();
    setQuickFilterSelected(selectedItem);
    if (selectedItem === 'myGoals') {
      handleSetFilter({...getEmptyFilter(), people: [userLdap]}, false, true);
    } else if (selectedItem === 'managerGoals') {
      handleSetFilter({...getEmptyFilter(), people: [managerLdap]}, false, true);
    } else if (selectedItem === 'teamGoals') {
      handleSetFilter(
        {
          ...getEmptyFilter(),
          teams: [...(employeeTeams ?? [])],
        },
        false,
        true
      );
    } else if (selectedItem === 'companyGoals') {
      handleSetFilter(
        {...getEmptyFilter(), teams: [getEmptyPlaceholderTeam(STEAM_TEAM_SLUG)]},
        false,
        true
      );
    } else if (selectedItem === 'directReportsGoals') {
      handleSetFilter({...getEmptyFilter(), reports: '1'}, false, true);
    } else if (selectedItem === 'others' && newFilter) {
      if (openFilter && !hasFiltersSelected(newFilter)) {
        setShouldOpenFilter(true);
      } else {
        setShouldOpenFilter(false);
      }
      const urlTimeframe = isCurrentQuarter(timeframe) ? undefined : timeframe;
      navigate(createUrlFromFilter(userLdap, newFilter, selectedGoalData, urlTimeframe), {
        preventScrollReset: true,
      });
    }
  };

  useEffect(() => {
    setNextTimeframe(getNextQuarter(timeframe));
  }, [timeframe, continueGoalData.goalData?.goalId]);

  useEffect(() => {
    const filterValues = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (!value) {
          return acc;
        }
        if (key === 'reports') {
          if (value === '1') {
            // eslint-disable-next-line no-param-reassign
            acc[key] = true;
          }
        } else if (key === 'search') {
          // eslint-disable-next-line no-param-reassign
          acc[key] = true;
        } else if (key === 'teams') {
          if ((value as Team[])?.length) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = (value as Team[])?.length;
          }
        } else if (key === 'people') {
          if ((value as string[])?.length) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = (value as string[])?.length;
          }
        } else {
          if (value?.length) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = value as string | string[];
          }
        }
        return acc;
      },
      {} as Record<string, boolean | number | string | string[]>
    );

    const type = quickFilterSelected;
    if (!Object.keys(filterValues).length) {
      analyticsLogger().logEvent('GOAL_TABLE_FILTER', {
        noFilter: true,
      });
    } else {
      if (
        Object.keys(filterValues).length === 1 &&
        type &&
        ((['myGoals', 'managerGoals'].includes(type) && (filterValues['people'] as number) === 1) ||
          (['teamGoals', 'companyGoals'].includes(type) && (filterValues['teams'] as number) === 1))
      ) {
        return;
      }

      analyticsLogger().logEvent('GOAL_TABLE_FILTER', {
        quickFilter: type,
        ...filterValues,
      });
    }
  }, [filter, managerLdap, userLdap, quickFilterSelected]);

  useEffect(() => {
    analyticsLogger().logEvent('GOAL_TABLE_VIEW', {
      source: location.state?.source ?? 'page-load',
    });
  }, [location.state?.source]);

  const filterString = JSON.stringify(filter);
  const handleCloseAndResetDrawers = useCallback(() => {
    setGoalsV2DrawerData({drawerType: 'goal', isOpen: false, source: ''});
    setGoalData(undefined);

    document.title =
      notificationCount > 0
        ? `(${notificationCount}) ${t('goals')} - ${t('dropbox_os')}`
        : t('goals') + ' - ' + t('dropbox_os');

    const currentUrl = getCurrentUrl(location);
    const newUrl = location.search ? `${urlPath}${location.search}` : urlPath;
    if (currentUrl !== newUrl) {
      navigate(newUrl, {preventScrollReset: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString, goalOwnerLdap, location.search, notificationCount, urlPath, userLdap]);

  const handleToggleGoalDetailsDrawer = useCallback(
    (
      {goalId, keyResultId, teamInfo, isDraft, isSubGoal}: DrawerGoalData,
      toggleGoalDetailsDrawer: boolean = false
    ) => {
      // Only toggle drawer if user clicks on same goal or key result
      const shouldToggleDrawer =
        goalData && goalData.goalId === goalId && goalData.keyResultId === keyResultId && isSubGoal;
      const isOpen = shouldToggleDrawer ? toggleGoalDetailsDrawer : true;
      const toggledGoalData = isOpen
        ? {goalId, keyResultId, teamInfo, isDraft, isSubGoal}
        : undefined;
      setGoalsV2DrawerData({
        drawerType: 'goal',
        isOpen,
        source: 'table',
      });
      setGoalData(toggledGoalData);
      if (isOpen) {
        analyticsLogger().logEvent('GOAL_DETAILS_DRAWER_OPENED', {
          type: keyResultId ? 'kr' : 'objective',
        });
      }
      navigate(getBrowsePath(toggledGoalData, true), {preventScrollReset: true});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalData?.goalId, goalData?.keyResultId, setShowNewBanner, setShowNewDraftBanner]
  );

  const handleSetTimeframe = useCallback(
    (newTimeframe: string) => {
      const newUrlParams = new URLSearchParams(location.search);
      newUrlParams.set('t', newTimeframe);
      navigate({pathname: urlPath, search: newUrlParams.toString()}, {preventScrollReset: true});
      setTimeframe(newTimeframe);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search, urlPath]
  );

  const handleOpenContributorsDrawer = useCallback(
    (drawerGoalData: DrawerGoalData, source: string) => {
      setGoalData(drawerGoalData);
      if (drawerGoalData.keyResultId) {
        setGoalsV2DrawerData({
          drawerType: 'contributor',
          isOpen: true,
          source,
          autoFocus: true,
          showBackButton: false,
        });
      }
    },
    []
  );

  const handleContinueGoalClick = useCallback(
    async (
      {goalId, keyResultId, goalOwnerId, goalOwnerEmail, latestUpdateStatus}: DrawerGoalData,
      source: string
    ) => {
      const {quarterNum: currQuarterNum} = parseTimeframe(timeframe);
      const {quarterNum: nextQuarterNum} = parseTimeframe(nextTimeframe);
      const loggingData = {
        type: keyResultId ? 'kr' : 'objective',
        status: latestUpdateStatus,
        new_quarter_difference: nextQuarterNum - currQuarterNum,
        source,
      };
      const delegateEmployee = delegatedBy?.find((d) => d.email === goalOwnerEmail);
      if (goalOwnerId) {
        if (keyResultId) {
          const continuedKeyResult = await continueKeyResult({
            keyResultId,
            userId: goalOwnerId,
            nextTimeframe,
            employeeId: delegateEmployee?.user_id,
          });
          // Log continue goal success
          analyticsLogger().logEvent('GOAL_CONTINUED', loggingData);
          const continuedkeyResultPath = getBrowsePath(
            {goalId: continuedKeyResult.goal_id, keyResultId: continuedKeyResult.id},
            false
          );
          setSnackbarMessage({
            text: t('continue_goal_success', {nextTimeframe}),
            action: {
              text: t('continue_goal_view_draft'),
              onClick: () => {
                navigate(continuedkeyResultPath);
              },
            },
          });
          setContinueGoalData({goalData: null});
        } else {
          const continuedGoal = await continueGoal({
            goalId,
            userId: goalOwnerId,
            nextTimeframe,
            employeeId: delegateEmployee?.user_id,
          });
          // Log continue goal success
          analyticsLogger().logEvent('GOAL_CONTINUED', loggingData);
          const continuedGoalPath = getBrowsePath({goalId: continuedGoal.id}, false);
          setSnackbarMessage({
            text: t('continue_goal_success', {nextTimeframe}),
            action: {
              text: t('continue_goal_view_draft'),
              onClick: () => {
                navigate(continuedGoalPath);
              },
            },
          });
          setContinueGoalData({goalData: null});
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [continueGoal, continueKeyResult, nextTimeframe, setSnackbarMessage, delegatedBy, timeframe]
  );

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const header = document.querySelector('.dpc-GlobalHeader');
      const drawer = document.querySelector('.dig-Drawer');
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        (!header || !header.contains(event.target as Node)) &&
        (!drawer || !drawer.contains(event.target as Node)) &&
        shouldCloseAndResetDrawers(event.target as HTMLElement)
      ) {
        handleCloseAndResetDrawers();
      }
    };

    if (containerRef.current) {
      document.body.addEventListener('click', handleClick);
    }

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [handleCloseAndResetDrawers, location.state?.source]);

  const filterButtonRef = useRef<WrapperRefObject>(null);
  const handlerAddFilterButtonClick = useCallback(() => {
    filterButtonRef.current?.focusTrigger();
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement) {
        const spacebarKeydownEvent = new KeyboardEvent('keydown', {
          key: ' ',
          code: 'Space',
          keyCode: 32,
          charCode: 32,
          which: 32,
          bubbles: true,
          cancelable: true,
        });
        const spacebarKeyupEvent = new KeyboardEvent('keyup', {
          key: ' ',
          code: 'Space',
          keyCode: 32,
          charCode: 32,
          which: 32,
          bubbles: true,
          cancelable: true,
        });
        focusedElement.dispatchEvent(spacebarKeydownEvent);
        setTimeout(() => {
          focusedElement.dispatchEvent(spacebarKeyupEvent);
        }, 100); // Adjust the delay as needed
      }
    }, 0);
  }, []);

  const goalsHeaderSkeleton = <GoalsHeaderSkeleton marginTop="16" marginBottom="12" />;
  const tableProps: GoalsTablePropsWithoutData = {
    timeframe,
    nextTimeframe,
    setNextTimeframe,
    filter,
    isGoalsV2DrawerOpen: goalsV2DrawerData.isOpen,
    onRowClick: handleToggleGoalDetailsDrawer,
    onContributorsClick: handleOpenContributorsDrawer,
    onContinueGoalClick: handleContinueGoalClick,
    hasScrolledToRow,
    setScrolledToRow,
    selectedGoalData,
    goalsHeaderData,
    setGoalsHeaderData,
    handlerAddFilterButtonClick,
    quickFilterSelected,
  };

  return (
    <>
      <Layout.Container width="100%">
        <Title size={24}>{t('goals')}</Title>
        <Box marginTop="16" marginBottom="16">
          <Split>
            <Split.Item
              marginRight={{
                small: 'auto',
              }}
            >
              <Suspense fallback={<QuickListSkeleton />}>
                {!user ? (
                  <QuickListSkeleton />
                ) : (
                  <QuickList
                    filter={filter}
                    handleQuickFilterSelected={handleQuickFilterSelected}
                  />
                )}
              </Suspense>
            </Split.Item>
            <Split.Item paddingTop="8">
              <ButtonLink
                variant="primary"
                to={ROUTE_PATHS.NEW_GOAL}
                state={{timeframe, source: 'table-cta'}}
              >
                {t('add_goal')}
              </ButtonLink>
            </Split.Item>
          </Split>
        </Box>
        <Box ref={containerRef}>
          <Suspense fallback={goalsHeaderSkeleton}>
            {goalsHeaderData ? (
              <GoalsHeader
                goalsHeaderData={goalsHeaderData}
                timeframe={timeframe}
                setTimeframe={handleSetTimeframe}
                filter={filter}
                setFilter={handleSetFilter}
                quickFilterSelected={quickFilterSelected}
                handleQuickFilterSelected={handleQuickFilterSelected}
                filterButtonRef={filterButtonRef}
              />
            ) : (
              goalsHeaderSkeleton
            )}
          </Suspense>
          <Suspense fallback={<TableSkeleton columnConfigs={[]} disableBorders />}>
            {quickFilterSelected &&
              (['myGoals', 'managerGoals'].includes(quickFilterSelected) ? (
                <EmployeesGoalsTableView {...tableProps} />
              ) : quickFilterSelected === 'directReportsGoals' ? (
                <DirectReportsGoalsTableView userLdap={userLdap} {...tableProps} />
              ) : quickFilterSelected === 'teamGoals' ? (
                <TeamsGoalsTableView {...tableProps} />
              ) : quickFilterSelected === 'companyGoals' ? (
                <CompanyGoalsTableView {...tableProps} />
              ) : quickFilterSelected === 'others' ? (
                <OtherGoalsTableView
                  shouldOpenFilter={shouldOpenFilter}
                  filterButtonRef={filterButtonRef}
                  handlerAddFilterButtonClick={handlerAddFilterButtonClick}
                  {...tableProps}
                />
              ) : null)}
          </Suspense>
        </Box>
      </Layout.Container>
      <Suspense fallback={null}>
        {goalData && (
          <GoalsV2Drawer
            drawerType={goalsV2DrawerData.drawerType}
            isOpen={goalsV2DrawerData.isOpen}
            source={goalsV2DrawerData.source}
            goalData={goalData}
            timeframe={timeframe}
            nextTimeframe={nextTimeframe}
            setNextTimeframe={setNextTimeframe}
            onClose={handleCloseAndResetDrawers}
            setGoalData={setGoalData}
            setGoalsV2DrawerData={setGoalsV2DrawerData}
            handleContinueGoalClick={handleContinueGoalClick}
            userLdaps={
              quickFilterSelected === 'directReportsGoals'
                ? [...filter.people, ...directReports.map((report) => report.ldap)]
                : filter.people
            }
            teamsSlug={filter.teams.map((team) => team.slug ?? '')}
            autoFocus={goalsV2DrawerData.autoFocus}
            showBackButton={goalsV2DrawerData.showBackButton}
          />
        )}
      </Suspense>
      {showRequestEdit && (
        <Modal open onRequestClose={() => setShowRequestEdit(false)} withCloseButton="close">
          <Modal.Header>
            <Title>{t('request_edit_title')}</Title>
          </Modal.Header>
          <Modal.Body>{t('request_edit_subtitle')}</Modal.Body>
          <Modal.Footer>
            <Button variant="opacity" onClick={() => setShowRequestEdit(false)}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                close();
                window.open(
                  'https://www.dropbox.com/scl/fi/8eul6r24enbhyrydjydd1/Narrative-OKR-Change-Requests.paper?rlkey=j0q8dpl4gytt63jkb5m6kwu0v&st=rig7pzr3&dl=0',
                  '_blank'
                );
              }}
            >
              {t('request_edit_submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
