import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, CloseLine, WarningLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {derivedThemeAtom} from 'atoms/layout';
import {ToolsService} from 'client';
import {ButtonLink} from 'components/DSYS/Link';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useRef, useState} from 'react';
import {getService} from 'utilities';

import {CheckLine, ErrorLine} from './CheckupIcon';
import styles from './CheckupTool.module.css';
import {getErrorCounts} from './util';

export const Checkmark = ({id, type}: {id: string; type: 'people' | 'teams'}) => {
  const mode = useAtomValue(derivedThemeAtom) === 'bright' ? 'dark' : 'bright';

  const triggerRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const {data: checkup, isLoading} = useQuery({
    queryKey: ['checkup', id],
    queryFn: () => getService(ToolsService).teamAuditApiV1AuditToolIdGet(id),
  });

  if (isLoading) {
    return <Skeleton.Box width={24} height={24} />;
  }

  if (!checkup) {
    return null;
  }

  const errorCounts = getErrorCounts(checkup.employees);

  const hasError =
    Object.values(errorCounts).some((count) => count > 0) || !checkup.employees.length;

  return (
    <ThemeProvider mode={mode}>
      <ThemeContainer>
        <Tooltip.Control
          className={styles.tooltip}
          variant="rich"
          open={show}
          auto
          triggerRef={triggerRef}
          maxWidth={490}
          placement="bottom-start"
        >
          <>
            {!checkup.employees.length ? (
              <Box paddingX="16" paddingY="8">
                <LabelGroup
                  withLeftAccessory={<UIIcon src={WarningLine} />}
                  withText={<Text color="faint">{t('checkup_team_no_members')}</Text>}
                />
              </Box>
            ) : (
              <>
                <Box
                  paddingX="16"
                  paddingY="8"
                  borderBottom="Solid"
                  borderColor="Border Subtle"
                  borderWidth="1"
                >
                  <LabelGroup
                    withLeftAccessory={
                      <UIIcon src={errorCounts.noTeams ? WarningLine : CheckmarkLine} />
                    }
                    withText={
                      <Text color="faint">
                        {errorCounts.noTeams
                          ? t('checkup_no_teams_total', {
                              count: errorCounts.noTeams,
                            })
                          : t('checkup_no_teams_ok')}
                      </Text>
                    }
                  />
                </Box>
                <Box
                  paddingX="16"
                  paddingY="8"
                  borderBottom="Solid"
                  borderColor="Border Subtle"
                  borderWidth="1"
                >
                  <LabelGroup
                    withLeftAccessory={
                      <UIIcon src={errorCounts.reportErrors ? WarningLine : CheckmarkLine} />
                    }
                    withText={
                      <Text color="faint">
                        {errorCounts.reportErrors
                          ? t('checkup_no_teams_reports_total', {
                              count: errorCounts.reportErrors,
                            })
                          : t('checkup_no_teams_reports_ok')}
                      </Text>
                    }
                  />
                </Box>
                <Box
                  paddingX="16"
                  paddingY="8"
                  borderBottom="Solid"
                  borderColor="Border Subtle"
                  borderWidth="1"
                >
                  <LabelGroup
                    withLeftAccessory={
                      <UIIcon src={errorCounts.onlyMember ? WarningLine : CheckmarkLine} />
                    }
                    withText={
                      <Text color="faint">
                        {errorCounts.onlyMember
                          ? t('checkup_only_member_total', {
                              count: errorCounts.onlyMember,
                            })
                          : t('checkup_only_member_ok')}
                      </Text>
                    }
                  />
                </Box>
                <Box
                  paddingX="16"
                  paddingY="8"
                  borderBottom="Solid"
                  borderColor="Border Subtle"
                  borderWidth="1"
                >
                  <LabelGroup
                    withLeftAccessory={
                      <UIIcon src={errorCounts.allocationErrors ? WarningLine : CheckmarkLine} />
                    }
                    withText={
                      <Text color="faint">
                        {errorCounts.allocationErrors
                          ? t('checkup_allocation_total', {
                              count: errorCounts.allocationErrors,
                            })
                          : t('checkup_allocation_ok')}
                      </Text>
                    }
                  />
                </Box>
                <Box paddingX="16" paddingY="8">
                  <LabelGroup
                    withLeftAccessory={
                      <UIIcon src={errorCounts.tooManyTeams ? WarningLine : CheckmarkLine} />
                    }
                    withText={
                      <Text color="faint">
                        {errorCounts.tooManyTeams
                          ? t('checkup_too_many_teams_total', {
                              count: errorCounts.tooManyTeams,
                            })
                          : t('checkup_too_many_teams_ok')}
                      </Text>
                    }
                  />
                </Box>
              </>
            )}
          </>
        </Tooltip.Control>
        <Box
          as="div"
          ref={triggerRef}
          onMouseOver={() => setShow(true)}
          onMouseOut={() => setShow(false)}
          marginTop="4"
        >
          <ButtonLink to={`/${type}/${id}/checkup`} variant="transparent" hasNoUnderline>
            <UIIcon
              src={hasError ? ErrorLine : CheckLine}
              className={atoms({color: 'Text Subtle'})}
            />
            {hasError && (
              <UIIcon
                size="small"
                className={atoms({
                  position: 'absolute',
                  color: 'Text Base',
                })}
                src={CloseLine}
              />
            )}
          </ButtonLink>
        </Box>
      </ThemeContainer>
    </ThemeProvider>
  );
};
