import {PrivacyConsentCategories} from 'types/privacyConsent';

export const tryPrivacyConsentInit = ({
  emailProofToken,
  updateCategoriesCallback,
}: {
  emailProofToken: string;
  updateCategoriesCallback: (categories: Partial<PrivacyConsentCategories>) => void;
}) => {
  if (typeof window !== 'undefined' && window.dropbox) {
    window.dropbox.privacyConsent.init({
      injectToFooter: true,
      injectFloatingButton: false,
      disableBanner: false,
      thirdPartyConfig: {
        cookieDomain: window.location.hostname,
        emailProofToken,
      },
      consentChangedCallback: updateCategoriesCallback,
      priorConsentCallback: updateCategoriesCallback,
      consentDeclinedCallback: updateCategoriesCallback,
    });
  } else {
    setTimeout(() => {
      tryPrivacyConsentInit({emailProofToken, updateCategoriesCallback});
    }, 50);
  }
};

let invisibleManageCookiesLink: HTMLAnchorElement;

export const activateCookiesModule = () => {
  invisibleManageCookiesLink?.click();
};

export const initializePrivacyModule = () => {
  const script = document.createElement('script');
  script.src = 'https://www.dropbox.com/pithos/privacy_consent';
  script.async = true;
  document.head.appendChild(script);
  invisibleManageCookiesLink = document.createElement('a');
  invisibleManageCookiesLink.id = 'invisible-manage-cookies-link';
  invisibleManageCookiesLink.style.display = 'none';
  invisibleManageCookiesLink.href = '#manage-cookies';
  document.body.append(invisibleManageCookiesLink);
};
