import {SVGProps} from 'react';

export const WorkstreamLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3045 12.0977L13.386 16.7875L9.93618 13.852L4.68209 17.6996L5.88623 19.0375L9.86123 16.0375L13.6485 19.15L18.579 13.3688L18.5807 13.3705L17.3045 12.0977Z"
        fill={iconFill}
      />
      <path
        d="M15.7879 4.56187L12.3289 9.60028L8.61773 7.00307L3.74981 11.3289L5.07473 12.5474L8.74917 9.18594L12.813 11.9275L17.1765 5.70717L17.1784 5.70873L15.7879 4.56187Z"
        fill={iconFill}
      />
      <ellipse cx="18.5814" cy="12.0976" rx="1.9125" ry="1.9125" fill={iconFill} />
      <ellipse
        cx="17.0584"
        cy="4.44046"
        rx="1.9125"
        ry="1.9125"
        transform="rotate(-5.41009 17.0584 4.44046)"
        fill={iconFill}
      />
      <ellipse cx="5.41885" cy="18.3227" rx="1.9125" ry="1.9125" fill={iconFill} />
      <ellipse
        cx="4.54133"
        cy="11.8794"
        rx="1.9125"
        ry="1.9125"
        transform="rotate(-5.41009 4.54133 11.8794)"
        fill={iconFill}
      />
      <circle cx="9.56582" cy="15.0248" r="1.9125" fill={iconFill} />
      <ellipse
        cx="8.3592"
        cy="8.2056"
        rx="1.9125"
        ry="1.9125"
        transform="rotate(-5.41009 8.3592 8.2056)"
        fill={iconFill}
      />
      <ellipse cx="13.7128" cy="18.1176" rx="1.9125" ry="1.9125" fill={iconFill} />
      <ellipse
        cx="12.7796"
        cy="10.8926"
        rx="1.9125"
        ry="1.9125"
        transform="rotate(-5.41009 12.7796 10.8926)"
        fill={iconFill}
      />
    </svg>
  );
};
