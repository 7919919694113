import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {StrategyItem} from 'atoms/strategies';
import {Link} from 'components/DSYS/Link';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';

import {useStrategy} from './hooks';

export const StatusScores = ({
  strategy,
  timeframe,
}: {
  strategy: StrategyItem;
  timeframe: string;
}) => {
  const {data, isLoading} = useStrategy({strategyId: strategy.id.toString(), timeframe});

  if (isLoading) {
    return (
      <Box display="flex" style={{gap: 8}} paddingTop="2" paddingLeft="2">
        <Skeleton.Box borderRadius="Circular" width={11} height={11} />
        <Skeleton.Box borderRadius="Circular" width={11} height={11} />
        <Skeleton.Box borderRadius="Circular" width={11} height={11} />
      </Box>
    );
  }

  return (
    <Box display="flex" style={{gap: 4}}>
      {!data?.goals.length ? (
        <Text color="subtle">—</Text>
      ) : (
        data.goals.map((goal) => (
          <Tooltip
            title={
              <Box
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '232px',
                }}
              >
                {goal.title}
              </Box>
            }
            key={goal.id}
          >
            <Box>
              <Link
                hasNoUnderline
                to={`/strategies/${strategy.id}?g=${goal.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <StatusButtonIcon
                  status={goal.updates?.[goal.updates.length - 1]?.status}
                  size="small"
                />
              </Link>
            </Box>
          </Tooltip>
        ))
      )}
    </Box>
  );
};
